export function GetMergeCols(columnValues, cleanedHeaders){
    let mappingCols = [];
    columnValues.forEach(value=>{
        let valueField = {
            field: value, 
            cellStyle:{'backgroundColor': 'var(--light-blue)'}, 
            headerClass: 'medium-blue-background',
            pinned: 'left'}
        mappingCols.push(valueField);
    });

    let originalCols = [];
    cleanedHeaders.forEach(header=>{
        let fieldHeader = { field: header };
        originalCols.push(fieldHeader);
    });

    let pushedMergedCols = [
        {headerName: 'Active Columns', headerClass: 'blue-background', children: mappingCols},
        {headerName: 'Original Timecard Columns', headerClass: 'tab-color', children: originalCols}
    ];

    return pushedMergedCols;
}

export function GetMergeRows(
    timecardHeaderLine,
    orignalTimecardData,
    activeRowData
){
    let allMergedRows = [];
    let activeRowCount = 0;

    for(var j = timecardHeaderLine; j<orignalTimecardData.length; j++){
        let currentTCObj = orignalTimecardData[j];
        let currentActiveOb = activeRowData[activeRowCount];
        let mergedObj = {...currentActiveOb, ...currentTCObj};
        allMergedRows.push(mergedObj);
        activeRowCount++
    }
    return allMergedRows;
}