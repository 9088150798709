import { Form } from "react-bootstrap";
import Select from 'react-select';

export default function EmployeeIdSelectionView({
    idTypes,
    selectedIdType,
    setSelectedIdType,
    idModifier,
    setIdModifier,
    customSelectStyles
}){
    return (
        <>
        <div className="vertical-card-fit two-col-even">
            <div className="flex-card-row center-align">
                <Form.Label className="label-gap">Employee ID Type:</Form.Label>
                <Select
                    className="dropdown-styling"
                    options={idTypes}
                    value={selectedIdType}
                    placeholder='Select Employee Identifier Type'
                    onChange={(e)=>setSelectedIdType(e)}
                    styles={customSelectStyles}
                />
            </div>
            <div className="flex-card-row center-align">
                <Form.Label className="label-gap">ID Length:</Form.Label>
                <Form.Control 
                    type="number"
                    className="input-text-modal-small"
                    value={idModifier}
                    onChange={(e)=>{setIdModifier(e.target.value)}}
                />
            </div>
        </div>
        </>
    )
}