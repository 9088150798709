import CustomerTreeDropdown from 'Components/CustomComponents/CustomerTreeDropdown/CustomerTreeDropdown'
import React from 'react'
import { Accordion, Modal, Row, Col, Button, Form, ProgressBar, Card, Spinner } from 'react-bootstrap'
import Select from 'react-select'

function AssignCandidatePopup({ 
        orderPostObj,
        handleChange,
        branches,
        defaultBranch,
        show,
        setShow,
        HandleSubmit,
        jobOrders,
        candidates,
        candidatesThatPosted,
        candidatesThatDidNotPost,
        currentPostingJobOrder,
        setSelectedJobOrders,
        selectedJobOrders,
        statuses,
        setStatusCodeID,
        setNotes
     }){
    return(
      <>
        <Modal show={show} onHide={()=>setShow(false)} size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>{!currentPostingJobOrder?'Add to Job Order':'Adding Candidates'}</Modal.Title>
          </Modal.Header>
          {/* First we display the form, then once they submit we show the loading bars */}
          {!currentPostingJobOrder?
          <Modal.Body>
            <Form>
              <Form.Label>Branches:<span className='red-text'>*</span></Form.Label>
              {(branches&&defaultBranch)&&
              <Select isMulti defaultValue={defaultBranch} onChange={(e)=>handleChange(e, 'BranchIds','BranchID')} options={branches.map((branch)=>{return{value:branch,label:branch.BranchName}})} />
            }
              {(orderPostObj.BranchIds.length>0)&&
              <>
                <Form.Label>Customers:</Form.Label>
                <CustomerTreeDropdown isMulti onChange={(e)=>handleChange(e, 'CustomerIds','CustomerID')}  defaultBranchIDs={orderPostObj.BranchIds} />

                <Form.Label>Job Orders<span className='red-text'>*</span></Form.Label>
                <Select isDisabled={(jobOrders?false:true)} isMulti={true} options={(jobOrders&&jobOrders)} onChange={(e)=>setSelectedJobOrders(e)} />
              </>
              }

              {/* Add a status list dropdown and a notes section, that will apply for all candidates */}

              <Form.Label>Status</Form.Label>
              {statuses?
                <Select options={statuses.map(status=>{return{value:status,label:(status.Status+' | '+status.Description),isDisabled:status.isDisabled}})} onChange={(e)=>setStatusCodeID(e.value.CandidateStatusID)} defaultValue={{value:{CandidateStatusID: 1, Status: "Candidate", Description: "Applicant is being considered as a candidate for this order"},label:"Candidate | Applicant is being considered as a candidate for this order"}} />
              :
                <Spinner animation='border' />
              }

              <Form.Label>Notes</Form.Label>
              <Form.Control onChange={(e)=>setNotes(e.target.value)} />
            </Form>
          </Modal.Body>
          :
          <Modal.Body>
            <Form.Label as='h6'>{(((candidatesThatPosted.length+candidatesThatDidNotPost.length)===(candidates.length*selectedJobOrders.length))?'Added':`Adding Candidates...`)}</Form.Label>

            <Form.Label>Progress:</Form.Label>
            <div>
              <ProgressBar variant="success" now={((candidatesThatPosted.length/(candidates.length*selectedJobOrders.length))*100)} className='margin-bottom' />
              <ProgressBar variant="danger" now={((candidatesThatDidNotPost.length/(candidates.length*selectedJobOrders.length))*100)} className='margin-bottom' />
              {(candidatesThatDidNotPost.length>0)&&
               <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Failures</Accordion.Header>
                  <Accordion.Body>
                    {candidatesThatDidNotPost.map(candidate=>{
                      return <Card>
                        <Card.Header>{candidate.EmployeeID+" | "+candidate.FirstName+" "+candidate.LastName}</Card.Header>
                      </Card>
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              }
            </div>
          </Modal.Body>
          }

          <Modal.Footer>
            <Row className='full-width'>
              <Col className='full-width'>
                <Button variant="secondary" onClick={()=>setShow(false)} className='full-width'>Close</Button>
              </Col>
            {(selectedJobOrders.length>0)?
            <Col className='full-width'>
            {((candidatesThatPosted.length+candidatesThatDidNotPost.length)===(candidates.length*selectedJobOrders.length))?
              <Button disabled className='full-width'>Added</Button>
              :
              <Button disabled={currentPostingJobOrder?true:false} variant="primary" onClick={HandleSubmit} className='full-width'>
              {currentPostingJobOrder? 'Adding...' : 'Add to Order'}
            </Button>
            }
            </Col>
            :
            <Col className='full-width'>
              <Button disabled className='full-width'>Add to Order</Button>
            </Col>
            }
            </Row>
          </Modal.Footer>
        </Modal>
      </>
    )
}

export default AssignCandidatePopup;