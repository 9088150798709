import { Form } from "react-bootstrap"
import Select from 'react-select';

export default function CustomerSelectionView({
    newOrEdit,
    isNewCustomer,
    HandleNewOrEdit,

    customToggleLabel,
    customNameField,
    useDepartmentsName,
    HandleUseDepartments,

    customerSelections,
    customSelectStyles,

    HandleUseCustomerName,
    HandleSelectCustomer,
    HandleSelectDepartment
}){
    return(
        <>
        <div className="vertical-card-fit two-col-even">
            <div className="flex-card-row">
            <div className="flex-card-row">
                    <div className="employer-toggle toggle-width-small">
                        <Form.Check
                            type="switch"
                            label={newOrEdit}
                            onChange={()=>HandleNewOrEdit()}
                        />
                    </div>
                </div>
                <div className="flex-card-row">
                    <Form.Label>Map Departments:</Form.Label>
                    <div className="employer-toggle toggle-width-small">
                        <Form.Check
                            type="switch"
                            label={useDepartmentsName}
                            onChange={()=>HandleUseDepartments()}
                        />
                    </div>
                </div>
                
            </div>
            <div className="flex-card-row">
                <div className="flex-card-row">
                    <Form.Label>Customer Source:</Form.Label>
                    <div className="employer-toggle">
                        <Form.Check
                            type="switch"
                            label={customToggleLabel}
                            onChange={()=>HandleUseCustomerName()}
                        />
                    </div>
                </div>

            {customNameField&&<div className="flex-card-row center-align">
                    <Form.Label className="label-gap">Custom Label:</Form.Label>
                    <Form.Control 
                        type="text"
                        className="input-text-modal-small"
                        value={customerSelections.customerSelection}
                        onChange={(e)=>{HandleSelectCustomer(e.target.value)}}
                    />
                </div>}
            </div> 
        </div>
        {!customNameField&&<div className="vertical-card-fit two-col-even">
                <div className="flex-card-row center-align">
                    <Form.Label className="label-gap">Root Customer:</Form.Label>
                    <Select
                        options={customerSelections.customerDropdown}
                        value={customerSelections.customerSelection}
                        placeholder='Select a Customer'
                        onChange={(e) => HandleSelectCustomer(e)}
                        styles={customSelectStyles}
                    />
                </div>
                <div className="flex-card-row center-align">
                    <Form.Label className="label-gap">Customer ID:</Form.Label>
                    <Form.Control 
                        type="text"
                        className="input-text-modal-small"
                        value={customerSelections.customerIdentifier}
                    />
                    
                </div>
                <><div className="flex-card-row center-align margin-top">
                    <Form.Label className="label-gap">Department:</Form.Label>
                    <Select
                        options={customerSelections.departmentDropdown}
                        value={customerSelections.departmentSelection}
                        placeholder='Select a Customer'
                        onChange={(e) => HandleSelectDepartment(e)}
                        styles={customSelectStyles}
                    />
                </div>
                <div className="flex-card-row center-align margin-top">
                    <Form.Label className="label-gap">Customer Name:</Form.Label>
                        <Form.Control 
                            type="text"
                            className="input-text-modal-small"
                            value={customerSelections.displayName}
                        />
                </div></>
            </div>}
        </>
    )
}