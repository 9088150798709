export function CleanHeaders(timecardHeaders){
    // solves issue where column headers come through as empty, and assigns them a value instead
    let cleanedHeaders = [];
    for(var i = 0; i< timecardHeaders.length; i++){
        let cleanHeader = timecardHeaders[i] ? timecardHeaders[i] : "";
        cleanedHeaders.push(cleanHeader);
    }
    return cleanedHeaders;
}

export function GetOriginalTimecardCols(timecardHeaders){
    let timecardColumnFields = [];
    timecardHeaders.forEach(header=>{
        let headerField = {field: header};
        timecardColumnFields.push(headerField);
    });
    return timecardColumnFields;
}

export function GetOriginalTimecardRows(fileData, cleanedHeaders){
    let orignalTimecardData = [];
    fileData.forEach(row=>{
        if(row[0]!==cleanedHeaders[0]){ //makes sure row is not null and headers are not assigned as values
            let rowObj = Object.assign(...cleanedHeaders.map((header,value)=>({
                [header]: row[value]
            })));
            orignalTimecardData.push(rowObj);
        }
    });
    return orignalTimecardData;
}