import React, { useState, useContext, useEffect } from 'react'
import AssignCandidatePopup from './AssignCandidatePopup';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import useForceUpdate from 'Components/CustomComponents/CustomHooks/useForceUpdate'

function AssignCandidatePopupLogic({ show, setShow, candidates }){
    const [jobOrders, setJobOrders] = useState();
    const [selectedJobOrders, setSelectedJobOrders] = useState([])
    const { REACT_APP_API_URL } = process.env;
    const { cookies } = useContext(CustomerContext);
    const [defaultBranch, setDefaultBranch] = useState();
    const [notes, setNotes] = useState()
    const [statusCodeID, setStatusCodeID] = useState(1);
    const [candidatesThatPosted, setCandidatesThatPosted] = useState([]);
    const [candidatesThatDidNotPost, setCandidatesThatDidNotPost] = useState([]);
    const [currentPostingJobOrder, setCurrentPostingJobOrder] = useState();
    const [orderPostObj, setOrderPostObj] = useState({
        BranchIds: [cookies.get('customer').BranchID],
        SRIdents: [],
        CustomerIds: [],
        ParentCustomerIds: null,
        OrderStatus: ["Open","Unfilled"],
        ShowAllDepartments: true,
        StartDate:new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toJSON().split('T')[0],
        EndDate: new Date().toJSON().split('T')[0],
    })
    const { data: branches } = useFetchGet(`${REACT_APP_API_URL}/Candidates/GetBranchList?token=${cookies.get('customer').Token}`,cookies)
    const { data: orders } = useFetchPost(((orderPostObj.BranchIds.length>0)&&`${REACT_APP_API_URL}/Candidates/GetTWOrders?token=${cookies.get('customer').Token}`),cookies,orderPostObj)
    const {data: statuses} = useFetchGet(`${REACT_APP_API_URL}/Candidates/GetCandidateStatusList?token=${cookies.get('customer').Token}`,cookies)

    const forceUpdate = useForceUpdate()

    useEffect(()=>{
      if(orders){
        TransformRequests(orders)
      }
  // eslint-disable-next-line
    },[orders])

    useEffect(()=>{
      if(branches){
          branches.forEach(branch=>{
              if(branch.BranchName===cookies.get('customer').BranchName){
                  setDefaultBranch([{value:branch,label:branch.BranchName}])
              }
          })
      }
  // eslint-disable-next-line
  },[branches])
    
    function HandleSubmit(e){
        let selectedJobOrdersData = selectedJobOrders.map(order=>order.value)
        PostCandidates(selectedJobOrdersData, candidates)
    }

    function Wait() {
        return new Promise(r => setTimeout(r, 500))
    }

    const PostCandidates = (jobOrders, candidates) => {
        const DBCalls = []
        for (let index = 0; index < jobOrders.length; index++) {
            candidates.forEach(candidate=>{
              DBCalls.push({
                jobOrder:jobOrders[index],
                candidate: candidate
            })
          })
        }
        setCandidatesThatDidNotPost([])
        setCandidatesThatPosted([])
        let chain = Promise.resolve();
        setCurrentPostingJobOrder('Posting')
        for (let obj of DBCalls){
            chain = chain.then(()=>PostOrderCandidateToDB(obj.jobOrder,obj.candidate,0))
            .then(Wait)
        };
        //Once all the candidates have been posted to DB
    }

    const PostOrderCandidateToDB = (currentOrder, candidate, count) => {
        if(count<3){
            fetch(`${REACT_APP_API_URL}/Candidates/PostOrderCandidate?Token=${cookies.get('customer').Token}&OrderID=${currentOrder.OrderID}&EmployeeID=${candidate.EmployeeID}&CandidateStatusID=${statusCodeID}&Notes=${notes}`,{
                method: 'POST',
                headers: {
                    'Content-Type':'application/json'
                }
            })
            .then(res => {
                if(res.status===200){
                  return res.text();
              }else if(res.status===503||res.status===500){
                setTimeout(() => {
                  PostOrderCandidateToDB(count++)
                }, 1000);
                return [];
              }else if(!res.ok){
                res.text().then(text=>{
                  if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                    setTimeout(() => {
                      PostOrderCandidateToDB(count++)
                    }, 1000);
                  }else{
                    throw Error(res.statusText)
                  }
                })
              }
            })
            .then(text=>{
                candidatesThatPosted.push(candidate)
                setCandidatesThatPosted(candidatesThatPosted)
                forceUpdate()
                return {didPost:true,candidate:candidate}
            })
            .catch(error => {
                let newCandidatesArray = [...candidatesThatDidNotPost]
                newCandidatesArray.push(candidate)
                setCandidatesThatDidNotPost(newCandidatesArray)
                forceUpdate()
                return {didPost:false,candidate:candidate}
            });
          };
    }

    const TransformRequests = (requests) => { 
        if(requests.length===0){
            setJobOrders([{value:'',label:'No Job Requests'}])
        }
        let transformmedRequests = requests.map(req =>{
            return {value:req,label:`${req.OrderID} | ${req.PublicJobTitle} | ${req.CustomerName}`}
        })
        setJobOrders(transformmedRequests)
     }

   const handleChange = (e, objName, valueName) => { 
    if(e.length>0){
        // Must update state state to cause re-render and call useFetchPost, unless I make a new object react won't re-render
        let newObj = {...orderPostObj};
        newObj[objName] = e.map(customer=>customer.value[valueName])
        setOrderPostObj(newObj)
    } else{
        let newObj = {...orderPostObj};
        newObj[objName] = []
        setOrderPostObj(newObj)
    }
 }
    
    return(
        <AssignCandidatePopup
            orderPostObj={orderPostObj}
            handleChange={handleChange}
            branches={branches}
            defaultBranch={defaultBranch}
            show={show}
            setShow={setShow}
            HandleSubmit={HandleSubmit}
            jobOrders={jobOrders}
            candidates={candidates}
            candidatesThatPosted={candidatesThatPosted}
            candidatesThatDidNotPost={candidatesThatDidNotPost}
            currentPostingJobOrder={currentPostingJobOrder}
            setSelectedJobOrders={setSelectedJobOrders}
            selectedJobOrders={selectedJobOrders}
            statuses={statuses}
            setStatusCodeID={setStatusCodeID}
            setNotes={setNotes}
        ></AssignCandidatePopup>
    )
}

export default AssignCandidatePopupLogic;