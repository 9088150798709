import React from 'react'
import { Card } from 'react-bootstrap';
import { CustomerProvider } from 'Components/CustomComponents/Context/CustomerContext';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '', errorInfo: '', cookies: CustomerProvider.cookies };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if(!this.cookies){
      window.location.reload()
    }
    // You can also log the error to an error reporting service
    console.error('cought the nasty little error',error.message);
    console.error('and here is the info',errorInfo)
    this.setState({error: error, errorInfo: errorInfo})
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Card>
          <Card.Header>
            <h3>Something went wrong.</h3>
          </Card.Header>
          <Card.Body>
            <p>If you send the below info to your development team they will be very appreciative!</p>
            <p>I know you don't like errors, but errors help us fix things faster.</p>
            {(this.state.error&&this.state.errorInfo)&&
            <Card className='padding'>
              <h4>Error info:</h4>
              <p>Stack trace: {this.state.errorInfo.componentStack.split(' at ')[1]}</p>
              <p>Error: {this.state.error.message}</p>
            </Card>
            }
          </Card.Body>
        </Card>
      );
    }

    return this.props.children; 
  }
}