export default function TotalsCompareTable({
    timecardHoursMatch,
    rawSums,
    calculatedSums,
    mismatchSums,
    uploadSums
}){
    return (
        <>
            <div className="card vertical-card-fit">
                    {timecardHoursMatch!=='Match'&&<div className="totals-mismatch-warning">
                        <p>Caution! {timecardHoursMatch}</p>    
                    </div>}
                    <div className="highlighted-box overflow-override">
                        <h5 className="white-header-label">Totals</h5>
                        <div className="four-column-div">
                            <p className="button-div white-left tc-compare-header">Raw TC Totals</p>
                            <p className="button-div tab-color tc-compare-header">Active Totals</p>
                            <p className="button-div red-background tc-compare-header">Mismatch</p>
                            <p className="button-div blue-background tc-compare-header">Upload</p>
                        </div>
                        <div className="four-column-div">
                            <p className="white-left tc-compare">Total:  {rawSums.mappingHoursTotal}</p>
                            <p className="tab-color tc-compare">Total:  {calculatedSums.timecardHoursTotal}</p>
                            <p className="red-background tc-compare"> Total:  {mismatchSums.mismatchHoursTotal}</p>
                            <p className="blue-background tc-compare"> Total:  {uploadSums.uploadHoursTotal}</p>
                        </div>
                        <div className="four-column-div">
                            <p className="white-left tc-compare ">Reg:  {rawSums.mappingHoursReg}</p>
                            <p className="tab-color tc-compare ">Reg:  {calculatedSums.timecardReg}</p>
                            <p className="red-background tc-compare">Reg:  {mismatchSums.mismatchHoursReg}</p>
                            <p className="blue-background tc-compare">Reg:  {uploadSums.uploadHoursReg}</p>
                        </div>
                        <div className="four-column-div">
                            <p className="white-left tc-compare">OT:  {rawSums.mappingHoursOT}</p>
                            <p className="tab-color tc-compare">OT:  {calculatedSums.timecardOT}</p>
                            <p className="red-background tc-compare">OT:  {mismatchSums.mismatchHoursOT}</p>
                            <p className="blue-background tc-compare">OT:  {uploadSums.uploadHoursOT}</p>
                        </div>
                        <div className="four-column-div">
                            <p className="white-left tc-compare">DT:  {rawSums.mappingHoursDT}</p>
                            <p className="tab-color tc-compare">DT:  {calculatedSums.timecardDT}</p>
                            <p className="red-background tc-compare">DT:  {mismatchSums.mismatchHoursDT}</p>
                            <p className="blue-background tc-compare">DT:  {uploadSums.uploadHoursDT}</p>
                        </div>
                        <div className="four-column-div">
                            <p className="white-left tc-compare">MP:  {rawSums.mappingHoursMP}</p>
                            <p className="tab-color tc-compare">MP:  {calculatedSums.timecardMP}</p>
                            <p className="red-background tc-compare">MP:  {mismatchSums.mismatchHoursMP}</p>
                            <p className="blue-background tc-compare">MP:  {uploadSums.uploadHoursMP}</p>
                        </div>
                    </div>
                </div>
        </>
    )
}