import React from 'react'
import Select from 'react-select';
import { Row } from 'react-bootstrap';

function AllBranches({ 
    allBranches, 
    SetSelectedBranch, 
    selectedBranch,
    customSelectStyles
}){
    
    return(
        <div className="all-branches-wrapper">
            {allBranches ?
                <Row>
                    <Select 
                        options={allBranches} 
                        defaultValue={{value:selectedBranch, label:selectedBranch.BranchName}} 
                        onChange={SetSelectedBranch} 
                        styles={customSelectStyles}
                    />
                </Row>
            : null}
        </div>
    )
}

export default AllBranches;