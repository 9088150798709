import React from 'react'
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap'
import Select from 'react-select'

function JobOrderStatus({ canSubmit, response, setCanSubmit, setSelectedStatus, show, setShow, statuses, jobOrder, defaultStatus }) {
  return (
    <Modal show={show} onHide={setShow}>
        <Modal.Header closeButton>
            <h5>Update Job Order Status | {jobOrder.OrderID}</h5>
        </Modal.Header>
        <Modal.Body>
            <Form.Label>Order Status:<span style={{'color':'red'}}>*</span></Form.Label>
            {(statuses&&defaultStatus)&&
                <Select onChange={(e)=>setSelectedStatus(e.value)} defaultValue={defaultStatus} options={statuses.map(status=>{return{value:status,label:status.OrderStatus}})} className='margin-bottom' />
            }
            {canSubmit&&
            <Alert>
                <Spinner animation='border' />
            </Alert>
            }
            {response&&
            <Alert>{response.Response}</Alert>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button disabled={(response?true:false)} onClick={(e)=>setCanSubmit(true)}>Submit</Button>
            <Button onClick={(e)=>setShow(false)}>Close</Button>
        </Modal.Footer>
    </Modal>
  )
}

export default JobOrderStatus