import * as XLSX from "xlsx";

export default function LoadCashReport(
    e, 
    rowDataSetter
) {
    const allFiles = e.target.files;
    const file = allFiles[0];
    const reader = new FileReader();
    const returnData = [];


    reader.onload = (evt) => {
        evt.preventDefault();

        const fileRead = evt.target.result;
        const workbook = XLSX.read(fileRead, { type: "binary" });
        const wsName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[wsName];  
        const fileData = XLSX.utils.sheet_to_json(worksheet, { head: 1 });

        fileData.forEach(row => {
            if(row["Customer Name"] && row["Customer Name"] !== ""){
                returnData.push(row);
            }
        });
        rowDataSetter(returnData);
    };
    reader.readAsBinaryString(file);
    return returnData;
}