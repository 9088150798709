import { FormatDecimalHundredths } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function GetMappingHours(delayedRows, columnMap, mappingHoursSetters){
    //get totals from timcard mapping for a more direct comparison here
    let mapKeys = Object.keys(columnMap);

    let idColumnFinder = columnMap['IdNumber'];
    let totalColumnFinder = mapKeys.includes('TotalHours') ? columnMap['TotalHours'] : undefined;
    let regColumnFinder = mapKeys.includes('Reg') ? columnMap['Reg'] : undefined;
    let otColumnFinder = mapKeys.includes('OT') ? columnMap['OT'] : undefined;
    let dtColumnFinder = mapKeys.includes('DT') ? columnMap['DT'] : undefined;
    let mpColumnFinder = mapKeys.includes('MP') ? columnMap['MP'] : undefined;

    let totalHours = 0;
    let regTotal = 0;
    let otTotal = 0;
    let dtTotal = 0;
    let mpTotal = 0;

    // console.log(delayedRows);

    delayedRows.forEach(row=>{
        if(row[idColumnFinder] !== '' && row[idColumnFinder] !== undefined){
            // console.log(row);
            regTotal+= row[regColumnFinder] > 0 || row[regColumnFinder] < 0 ? parseFloat(row[regColumnFinder]) : 0;
            otTotal+= row[otColumnFinder] > 0 || row[otColumnFinder] < 0 ? parseFloat(row[otColumnFinder]) : 0;
            dtTotal+= row[dtColumnFinder] > 0 || row[dtColumnFinder] < 0 ? parseFloat(row[dtColumnFinder]) : 0;
            mpTotal+= row[mpColumnFinder] > 0 || row[mpColumnFinder] < 0 ? parseFloat(row[mpColumnFinder]) : 0;

            totalHours+= row[totalColumnFinder] > 0 || row[totalColumnFinder] < 0 ? parseFloat(row[totalColumnFinder]) : 0;
        }
    });
    
    let summedHourTypes = regTotal + otTotal + dtTotal + mpTotal;

    let calculatedTotalHours = totalHours > 0 ? FormatDecimalHundredths(totalHours) : FormatDecimalHundredths(summedHourTypes);
    let calculatedRegHours = FormatDecimalHundredths(regTotal);
    let calculatedOTHours = FormatDecimalHundredths(otTotal);
    let calculatedDTHours = FormatDecimalHundredths(dtTotal);
    let calculatedMPHours = FormatDecimalHundredths(mpTotal);

    mappingHoursSetters.setMappingHoursTotal(calculatedTotalHours);
    mappingHoursSetters.setMappingHoursReg(calculatedRegHours);
    mappingHoursSetters.setMappingHoursOT(calculatedOTHours);
    mappingHoursSetters.setMappingHoursDT(calculatedDTHours);
    mappingHoursSetters.setMappingHoursMP(calculatedMPHours);
}