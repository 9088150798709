import { useState, useEffect, useContext } from "react";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import useFetchGet from "Components/CustomComponents/Fetch/useFetchGet";
import useFetchPost from "Components/CustomComponents/Fetch/useFetchPost";
import AssignmentsNotPaidView from './AssignemntsNotPaidView';

export default function AssignemntsNotPaid() {
  const { cookies } = useContext(CustomerContext);
  const [branchIds, setBranchIds] = useState();
  const [selectedBranch, setSelectedBranch] = useState();
  const { REACT_APP_API_URL} = process.env;
  const { data: anp } = useFetchPost((branchIds&&`${REACT_APP_API_URL}/payroll/getassignmentsnotpaid?token=${cookies.get('customer').Token}`), cookies, branchIds);
  const { data: branchData } = useFetchGet(`${REACT_APP_API_URL}/compliance/getbranches?token=${cookies.get('customer').Token}`, cookies);

  const [gridApi, setGridApi] = useState();

  const onFirstDataRendered = (params) => { 
    params.columnApi.autoSizeAllColumns();
    setGridApi(params.api);
  };

  const[defaultColDef] = useState ({
    sortable: true,
    resizable: true,
    filter: true,       
  });

  const [rowData, setRowData] = useState();
  const [branchFilter, setBranchFilter] = useState();

  const [anpColDefs] = useState([
    { field: 'Status' },
    { field: 'BranchName' },
    { field: 'CustomerName' },
    { field: 'EmployeeName' },
    { field: 'AssignmentID', cellClass: 'linked-cell' },
    { field: 'StartDate', valueFormatter: (anp)=>FormatDate(anp) },
    { field: 'MaxCheckDate', valueFormatter: (anp)=>FormatDate(anp) },
    { field: 'ServiceRepName' }
  ]);

  useEffect(() => {
    if (branchData) {
      let formattedBranches = branchData.map((branch) => {
        if(branch.BranchName===cookies.get('customer').BranchName){
          HandleBranchChange([{ value: branch, label: branch.BranchName }])
          return { value: branch, label: branch.BranchName }
        }else{
          return { value: branch, label: branch.BranchName }
        }
      });
      setBranchFilter(formattedBranches);
    }
    // eslint-disable-next-line
  }, [branchData]);

  useEffect(() => {
    if (anp) {
      let anpArray = [...anp];
      setRowData(anpArray);
    }
  }, [anp])

  const FormatDate = (anp) =>{
    if(anp.value){
      let fixedDate = anp.value.split('T')[0]
      return fixedDate
    }else{
      return ''
    }
  };

  const ExportToCSV = (params) => {
    gridApi.exportDataAsCsv(params);
  };

  const onAssignmentClick = (e) => {
    if (e.colDef.field === "AssignmentID") {
      openInNewTab('https://beyond.ontempworks.com/assignment/' + e.data.AssignmentID)
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  };

  const HandleBranchChange = (e) => {
    setSelectedBranch(e);
    if(e&&e.length>0){
      setBranchIds(e.map(branch=>branch.value.BranchID))
    }else{
      setBranchIds([]);
    }

    if(anp&&anp.length>0){
      let anpArray = [...anp]
      let filteredRows = anpArray.filter(row => row.BranchName === e.label);
      setRowData(filteredRows);
    }
  };

  const customSelectStyles = {
    control: base => ({
      ...base,
      width: '400px'
    }),
  };

  return (
    <>
      <AssignmentsNotPaidView
        rowData={rowData}
        onFirstDataRendered={onFirstDataRendered}
        columnDefs={anpColDefs}
        defaultColDef={defaultColDef}
        ExportToCSV={ExportToCSV}
        onAssignmentClick={onAssignmentClick}
        branchFilter={branchFilter}
        selectedBranch={selectedBranch}
        HandleBranchChange={HandleBranchChange}
        customSelectStyles={customSelectStyles}
      />
    </>
  )
}