import { useState, useEffect } from "react";
import Select from "react-select";
import { GetDocTypes } from "Components/Admin/CallApi";

export default function DocTypesDropdown({
  cookies,
  selectedDocType,
  setSelectedDocType
}){
  const [docTypes, setDocTypes] = useState();

  useEffect(()=>{
    GetDocTypes(cookies).then(docs=>{
      console.log(docs);
      let docTypesList = [];
      docs.forEach(doc=>{
        let listItem = {
          value: doc.DocTypeID,
          label: doc.description
        };
        docTypesList.push(listItem);
      })
      setDocTypes(docTypesList);
    })
    // eslint-disable-next-line
  },[]);

  const customSelectStyles = {
    control: base => ({
      ...base,
      width: '400px',
      marginRight: "10px"
    })
  };

  return(
    <>
    <Select
      options={docTypes}
      value={selectedDocType || ''}
      onChange={(e)=>setSelectedDocType(e)}
      placeholder='Select a DocType'
      styles={customSelectStyles}
    />
    </>
  )
}