import React from 'react'
import { Button, Modal } from 'react-bootstrap'

function PostToTWConfirmation({ show, setShow, jobOrderID }) {

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

  return (
    <Modal show={show} onHide={setShow}>
        <>
        <Modal.Header closeButton>
            <h5>Success! You have made a new Job Order.</h5>
        </Modal.Header>
        <Modal.Body>
            <p>Please allow up to 15 minutes for your new Job Order to show up in this application.</p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={(e)=>openInNewTab(`https://beyond.ontempworks.com/joborder/${jobOrderID}`)}>View Job Order</Button>
        </Modal.Footer>
        </>
    </Modal>
  )
}

export default PostToTWConfirmation