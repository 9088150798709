import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import React, { useContext, useEffect, useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';
import useFetchPatch from 'Components/CustomComponents/Fetch/useFetchPatch';
import CreatableSelect from 'react-select/creatable';
import { FcCheckmark } from 'react-icons/fc';
import { BiErrorCircle } from 'react-icons/bi';

function DocumentForm({ show, setShow, formData, categoryOptions, groupOptions }) {
    const {cookies} = useContext(CustomerContext)
    const { REACT_APP_API_URL} = process.env;
    const [customerPostObj] = useState([]);
    const [customerOptions, setCustomerOptions] = useState();
    const [stateRestrictions, setStateRestrictions] = useState();
    const [selectedRestriction, setSelectedRestriction] = useState();
    const [canPost, setCanPost] = useState(false);
    const [canPut, setCanPut] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState();
    const [postObj, setPostObj] = useState(
        formData?
        {
            ...formData,
            BeginUseDate: formData.BeginUseDate&&new Date(formData.BeginUseDate).toJSON().split('T')[0],
            EndUseDate: formData.EndUseDate&&new Date(formData.EndUseDate).toJSON().split('T')[0],
        }
        :
        {
            FormID: 0,
            FormName: null,
            BeginUseDate: null,
            EndUseDate: null,
            Version: null,
            FormCat: null,
            FormGroup: null,
            StateTypeID: 0,
            isCustomerForm:true,
            isHRCenterSurvey:false
        }
    );
    const [documentPostObj, setDocumentPostObj] = useState();
    const [yesNoOptions] = useState([{value:false,label:'No'},{value:true,label:'Yes'}])
    const { data: unformattedRestrictions, loading: loadingRestrictions } = useFetchGet(`${REACT_APP_API_URL}/Utilities/GetStateRestrictionTypes?Token=${cookies.get('customer').Token}`,cookies)
    const { data: unformattedCustomers, loading: loadingCustomers } = useFetchPost(`${REACT_APP_API_URL}/Candidates/GetCustomers?Token=${cookies.get('customer').Token}`, cookies, customerPostObj)
    const { data: postData ,status: postStatus, loading: postLoading, error: postError } = useFetchPost((canPost&&`${REACT_APP_API_URL}/Utilities/PostFormList?Token=${cookies.get("customer").Token}`), cookies, postObj);
    const { data: putData ,status: putStatus, loading: putLoading, error: putError } = useFetchPatch((canPut&&`${REACT_APP_API_URL}/Utilities/PatchFormList?Token=${cookies.get("customer").Token}`), cookies, postObj);
    const { status: formStatus, loading: formLoading } = useFetchPost((((postData&&documentPostObj)||(putData&&documentPostObj))&&`${REACT_APP_API_URL}/Utilities/PostCustomersRequiredDocument?Token=${cookies.get('customer').Token}&FormID=${postData?postData.FormID:putData.FormID}`), cookies, documentPostObj)

    useEffect(()=>{
        if(unformattedCustomers){
            let defaultSelectedCustomers = []
            let formattedCustomers = unformattedCustomers.map(cust=>{
                let newOption = {value: cust, label: (cust.CustomerName+' | '+cust.DepartmentName+' | '+cust.CustomerID)}
                if(formData?.CustomerName&&formData.CustomerNames.find(obj=>obj.CustomerID===cust.CustomerID)){
                    defaultSelectedCustomers.push(newOption)
                    return newOption
                }
                return newOption
            })
            setSelectedCustomers(defaultSelectedCustomers)
            setCustomerOptions(formattedCustomers)
        }
    // eslint-disable-next-line
    },[unformattedCustomers])

    useEffect(()=>{
        if(unformattedRestrictions){
            let formattedRestrictions = unformattedRestrictions.map(obj=>{
                if(formData&&(obj.StateTypeID===formData.StateTypeID)){
                    setSelectedRestriction({value:obj,label:obj.StateRestriction})
                    return {value:obj,label:obj.StateRestriction}
                }
                return {value:obj,label:obj.StateRestriction}
            })
            setStateRestrictions(formattedRestrictions)
        }
    // eslint-disable-next-line
    },[unformattedRestrictions])

    useEffect(()=>{
        if(selectedCustomers){
            let IDs = selectedCustomers.map(cust=>cust.value.CustomerID)
            setDocumentPostObj(IDs)
        }

    },[selectedCustomers])

    const HandleChange = (event,fieldName) => { 
        if(fieldName==='StateTypeID'){
            let newPostObj = {...postObj}
            newPostObj[fieldName]=event.value.StateTypeID;
            setPostObj(newPostObj);
        }else if(fieldName==='FormCat'||fieldName==='FormGroup'||fieldName==='isHRCenterSurvey'||fieldName==='isCustomerForm'){
            let newPostObj = {...postObj}
            newPostObj[fieldName]=event.value;
            setPostObj(newPostObj);
        }else{
            let newPostObj = {...postObj}
            newPostObj[fieldName]=event.target.value;
            setPostObj(newPostObj);
        }
     }

    return (
    <Modal show={show} onHide={setShow} >
        <Modal.Header closeButton>
            <h3>{formData?'Edit Form':'New Form'}</h3>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group controlId="FormName">
                    <Form.Label>Form Name<span className='red-text'>*</span></Form.Label>
                    <Form.Control disabled={formData?true:false} value={postObj.FormName} onChange={(e)=>HandleChange(e,'FormName')} placeholder="Release Form" />
                </Form.Group>
                <Form.Group controlId="BeginUseDate">
                    <Form.Label>Begin Use Date</Form.Label>
                    <Form.Control type='date' value={postObj.BeginUseDate} onChange={(e)=>HandleChange(e,'BeginUseDate')} />
                </Form.Group>
                <Form.Group controlId="EndUseDate">
                    <Form.Label>End Use Date</Form.Label>
                    <Form.Control type='date' value={postObj.EndUseDate} onChange={(e)=>HandleChange(e,'EndUseDate')} />
                </Form.Group>
                {/* <Form.Group controlId="Version">
                    <Form.Label>Version</Form.Label>
                    <Form.Control value={postObj.Version} onChange={(e)=>HandleChange(e,'Version')} placeholder="July 2020, warehouse only, etc" />
                </Form.Group> */}
                <Form.Group controlId="FormCat">
                    <Form.Label>Form Category</Form.Label>
                    <CreatableSelect isClearable options={categoryOptions} defaultValue={categoryOptions.find(option=>option.value===postObj.FormCat)} onChange={(e)=>HandleChange(e,'FormCat')} />
                </Form.Group>
                <Form.Group controlId="FormGroup">
                    <Form.Label>Form Group</Form.Label>
                    <CreatableSelect isClearable options={groupOptions} defaultValue={groupOptions.find(option=>option.value===postObj.FormGroup)} onChange={(e)=>HandleChange(e,'FormGroup')} />
                </Form.Group>
                <Form.Group controlId="StateTypeID">
                    <Form.Label>State Requirement<span className='red-text'>*</span></Form.Label>
                    {loadingRestrictions?
                    <Spinner animation='border' size='sm' />
                    :
                    <Select options={stateRestrictions} value={selectedRestriction} onChange={(e)=>HandleChange(e,'StateTypeID')} />
                    }
                </Form.Group>
                <Form.Group controlId="Customers">
                    <Form.Label>Customers</Form.Label>
                    {loadingCustomers?
                    <Spinner animation='border' size='sm'/>
                    :
                    <Select isDisabled={formData?true:false} options={customerOptions} onChange={(e)=>setSelectedCustomers(e)} value={selectedCustomers} isMulti={true} />
                    }
                </Form.Group>
                <Form.Group controlId="isCustomerForm">
                        <Form.Label>Is Customer Form</Form.Label>
                        <Select options={yesNoOptions} defaultValue={postObj&&yesNoOptions.find(obj=>obj.value===postObj.isCustomerForm)} onChange={(e)=>HandleChange(e,'isCustomerForm')} />
                </Form.Group>
                <Form.Group controlId="isHRCenterSurvey">
                        <Form.Label>Is HRCenter Survey</Form.Label>
                        <Select options={yesNoOptions} defaultValue={postObj&&yesNoOptions.find(obj=>obj.value===postObj.isHRCenterSurvey)} onChange={(e)=>HandleChange(e,'isHRCenterSurvey')} />
                </Form.Group>
            </Form>
            {(postError||putError)&&<Alert variant='warning'>There as an issue please try again</Alert>}
            {(canPost||canPut)&&
            <Alert className='margin-top'>
                <ol>
                    <li>Saving changes {visualizeStatus(putLoading,putStatus)}{visualizeStatus(postLoading,postStatus)}</li>
                    {selectedCustomers&&
                    <li>Linking Customers and form {visualizeStatus(formLoading,formStatus)}</li>
                    }
                </ol>
                {((putStatus&&putStatus===200)||(postStatus&&postStatus===200))&&<p className='full-width text-center'>Successfully saved! You may now close this window</p>}
            </Alert>
            }
        </Modal.Body>
        <Modal.Footer>
            {(formData&&!putStatus)?
            <Button onClick={()=>setCanPut(true)}>{putLoading?<Spinner animation='border' size='sm' />:'Save Changes'}</Button>
            :
            (putStatus===200)&&<Button variant='secondary' onClick={()=>setShow(false)}>Close</Button>
            }
            {(!formData&&!postStatus)?
            <Button onClick={()=>setCanPost(true)}>{postLoading?<Spinner animation='border' size='sm' />:'Add Form'}</Button>
            :
            (postStatus===200)&&<Button variant='secondary' onClick={()=>setShow(false)}>Close</Button>
            }
        </Modal.Footer>
    </Modal>
  )
}

// break out the button
// add button and status to the Fetch folder

function visualizeStatus(loading,status){
    if(loading){
        return <Spinner animation='border' size='sm' />
    }else{
        if(status===200){
            return <FcCheckmark />
        }else if(status&&status!==200){
            return <BiErrorCircle style={{color:'red'}} />
        }else{
            return ''
        }
    }
}

export default DocumentForm