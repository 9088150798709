import React, { useState, useEffect, useMemo } from "react";
import '../../Payroll.css';
import { AgGridReact } from "ag-grid-react";
import { FormatDate, FormatUSD, CustomComparator } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function ReconciledOaps({
    oapRowData,
    leftoverOapList
}) {
    const [allOapRows, setAllOapRows] = useState();
    const [allOapColDefs, setAllOapColDefs] = useState();
    const [defaultColDef] = useState ({
        sortable: true,
        resizable: true,
        filter: true,      
    });

    useEffect(() => {
        if (oapRowData&&oapRowData.length) {
            let oapRows = [];
            oapRowData.forEach(oap => oap.OAPNumber.startsWith("OAP") ? oapRows.push(oap) : null);
            
            let reconciledOaps = [];
            oapRows.forEach(oap => {
                let uniqueOapNumber = reconciledOaps.find(customer => customer.OAPNumber === oap.OAPNumber);
                if (uniqueOapNumber === undefined && (oap.OAPBalance === 0 || oap.OAPBalance.isNaN)) {
                    reconciledOaps.push(oap);
                }
            })

            setAllOapRows(reconciledOaps);
        };
        // eslint-disable-next-line
    },[oapRowData])

    useEffect(() => {
        if (allOapRows) {
            setAllOapColDefs([
                { field: "OAPNumber" },
                {
                    field: "DepositDate",
                    valueFormatter: (data) => FormatDate(data),
                },
                {
                    field: "ItemDate",
                    valueFormatter: (data) => FormatDate(data),
                },
                { field: "OAPAmount", valueFormatter: data => FormatUSD(data) },
                
                {
                    field: "CustomerName",
                    sort: 'asc',
                    comparator: CustomComparator
                }
            ]);
        }
        // eslint-disable-next-line
    }, [allOapRows]);
    
    const onFirstDataRendered = (params) => { 
        params.columnApi.autoSizeAllColumns();
    };
    
    const rowClassRules = useMemo(() => {
        if (oapRowData) {
            return {
                'leftover-overlay': params => {
                    var currentOapNumber = params.data.OAPNumber;
                    var leftoverOAP = leftoverOapList.find(oap => oap.OAPNumber === currentOapNumber);
                    return leftoverOAP;
                }, 
            };
        }
        // eslint-disable-next-line
    }, [oapRowData]);

    return (
        <>
            <h5>Reconciled OAPs</h5>
            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={allOapRows}
                    columnDefs={allOapColDefs}
                    defaultColDef={defaultColDef}
                    onFirstDataRendered={onFirstDataRendered}
                    rowClassRules={rowClassRules}
                />
            </div>
        </>
    )
}