import { useState, useEffect, useContext } from 'react'
import TextingListsView from './EngagementHomeView';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';

export default function EngagementHome() {
  const {REACT_APP_API_URL} = process.env;
  const {cookies} = useContext(CustomerContext)
  const [activeKey, setActiveKey] = useState('birthdays');
  const [branches, setBranches] = useState();
  const [defaultBranch, setDefaultBranch] = useState();
  const [branchIDs, setBranchIDs] = useState([cookies.get('customer').BranchID])
  const [startDate, setStartDate] = useState(new Date().toJSON());
  const [endDate, setEndDate] = useState(new Date().toJSON());
  const { data } = useFetchGet(`${REACT_APP_API_URL}/Compliance/GetBranches?token=${cookies.get('customer').Token}`, cookies)
  const { data: rowData, loading } = useFetchPost(`${REACT_APP_API_URL}/Compliance/GetEmployeesComingBirthdays?token=${cookies.get('customer').Token}&StartDate=${startDate}&EndDate=${endDate}`,cookies,branchIDs);
  const [gridApi, setGridApi] = useState();

  useEffect(()=>{
    const TransformData = (data) => { 
      let newArray = data.map(obj=>{
        if(obj.BranchName===cookies.get('customer').BranchName){
          setDefaultBranch([{value:obj,label:obj.BranchName}]);
        }
        return {value: obj, label: obj.BranchName}
      })
      setBranches(newArray);
    }
    
    if(!branches&&data){TransformData(data);}
  // eslint-disable-next-line
  },[data]);

  const SetDate = (event, variable) => { 
    if(variable==='start'){
      let transformedDate = new Date(event.target.value).toJSON().split('T')[0]+'T00:00:00.000Z';
      setStartDate(transformedDate);
    }else{
      let transformedDate = new Date(event.target.value).toJSON().split('T')[0]+'T23:59:59.999Z';
      setEndDate(transformedDate);
    }
  }

  const HandleBranchChange = (e) => { 
    if(e.length>0){
      let IDs = e.map(obj=>{return obj.value.BranchID;});
      setBranchIDs(IDs);
    }
    else{setBranchIDs([]);}
  }

  return (
    <TextingListsView
      branches={branches}
      defaultBranch={defaultBranch}
      HandleBranchChange={HandleBranchChange}
      activeKey={activeKey}
      setActiveKey={setActiveKey}
      branchIDs={branchIDs}
      startDate={startDate}
      endDate={endDate}
      SetDate={SetDate}
      rowData={rowData}
      loading={loading}
      gridApi={gridApi}
      setGridApi={setGridApi}
    />
  )
}