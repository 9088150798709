import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'

function EmployeeLevelViewWarning({ show, setShow, setActiveKey }) {
    const [checked, setChecked] = useState(false);

    const HandleContinue = () => { 
        setActiveKey('advanced')
        setShow(false);
     }

  return (
      <Modal show={show} onHide={setShow}>
        <Modal.Header closeButton>
          <Modal.Title>Read before continuing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Label>Do not provide the Employee Detail data to customers without written (email) approval from Human Resources.</Form.Label>
                <div className='employee-view-checkbox'>
                    <Form.Check 
                        type="checkbox"
                        checked={checked}
                        onChange={(e)=>setChecked(e.target.checked)}
                        label="I understand and wish to continue"
                        />
                </div>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShow(false)}>
            Go Back
          </Button>
          {checked&&
            <Button  variant="primary" onClick={()=>HandleContinue()}>
                Continue
            </Button>
          }
        </Modal.Footer>
      </Modal>
  );
}

export default EmployeeLevelViewWarning;