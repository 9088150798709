import React from "react";
import '../Payroll.css';
import { AgGridReact } from "ag-grid-react";
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import CustomerInfoPopup from "./CustomerInfoPopup/CustomerInfoPopup";
import EditTable from "Components/Compliance/Payroll/InvoiceAging/Emailer/EditTable";
import GetCustomerList from "./Grid/GetCustomerList";

export default function InvoiceAgingView({
  gridParams,
  gridFunctions,
  componentParams                                              
}){

  return(
    <div>
      <div className="header-card">
        <h3>Invoice Aging</h3>
        <div className="spaced">
          <Select
            options={GetCustomerList()}
            value={componentParams.selectedCustomer}
            placeholder='Select a Customer'
            onChange={(e) => gridFunctions.onCustomerSelected(e)}
            className="dropdown-styling"
          />
          <div>
            <Button className="standard-button" onClick={() => gridFunctions.EmailSelectedInvoices()}>Email Invoices</Button>
            <Button className="header-export-button" onClick={()=>gridFunctions.ExportToCSV(true)}>Export to CSV</Button>
          </div>
        </div>  
      </div>
      
      {componentParams.contactPopupIsOpen&&<CustomerInfoPopup
        selectedCustomerID={componentParams.selectedCustomerID}
        show={componentParams.contactPopupIsOpen} 
        onHide={componentParams.setContactPopupIsOpen}/>}
          
      {componentParams.showMailer&&<EditTable
        selectedRowData={componentParams.selectedRowData}
        setSelectedRowData={componentParams.setSelectedRowData}
        selectedCustomerID={componentParams.selectedCustomerID}
        showMailer={componentParams.showMailer}
        setShowMailer={componentParams.setShowMailer}
        onHide={componentParams.setShowMailer}
        colDefs={gridParams.colDefs}
      />}

      {gridParams.rowData&&<div className="card oap-card">
        <div className="ag-theme-alpine ag-border-dark">
          <AgGridReact
            rowData={gridParams.rowData}
            onFirstDataRendered={gridParams.onFirstDataRendered}
            columnDefs={gridParams.colDefs}
            defaultColDef={gridParams.defaultColDef}
            pinnedBottomRowData={gridParams.pinnedBottomRowData}
            onFilterChanged={gridFunctions.onFilterChanged}
            onCellDoubleClicked={gridFunctions.OpenInfoCard}
            rowSelection={'multiple'}
          />
        </div> 
      </div>}
    </div>       
  );
};