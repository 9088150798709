import React, {useState, useContext, useEffect, useRef} from "react";
import EmailerView from "./EmailerView";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import useFetchPost from "Components/CustomComponents/Fetch/useFetchPost";
import CreateMessageString from "Components/CustomComponents/TempworksEmailer/CreateMessageString";

export default function Emailer({emailerParams}){ 
  // states to hold the HTML table and message string to build the email body
  const [myTable, setMyTable] = useState();
  const [messageString, setMessageString] = useState();

  // cookies and API connections pull in user token and customer contacts, and send email to log through Tempworks
  const { cookies } = useContext(CustomerContext);
  const { REACT_APP_API_URL } = process.env;
  const [canPost, setCanPost] = useState(false);                    
  const { status } = useFetchPost((canPost&&`${REACT_APP_API_URL}/utilities/sendtwemail?token=${cookies.get('customer').Token}`),cookies, messageString);
  
  // state that shows/hides the status message
  const [showSend, setShowSend] = useState(true);
  
  // send refs collect and store necessary contact information to send email to included contacts
  const sendTo = useRef([]);
  const sendCC = useRef([]);
  const sendBCC = useRef([]);

  // names refs collect and store contact email addresses for display in the view
  const namesTo = useRef([]);
  const namesCC = useRef();
  const namesBCC = useRef();

  // below values create the JSON
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState();

  const [tableIsVisible, setTableIsVisible] = useState(false);

  // useEffect builds the table and sets all the variables used to post it in email
  useEffect(()=>{
    if(tableIsVisible){
      const copiedTable = document.getElementById('invoiceTable');
      console.log(copiedTable);
      copiedTable.innerHTML = (copiedTable.children[0].outerHTML + copiedTable.children[1].outerHTML)
      setMyTable(copiedTable.outerHTML);       
    };
    // eslint-disable-next-line
  },[emailerParams.selectedRowData]);

  let selectedCustomerID = emailerParams.selectedCustomerID
  // HandleSend builds object that is passed to useFetchPost to send email through API
  const HandleSend = () => {
    let message = CreateMessageString({
      selectedCustomerID,
      body,
      myTable,
      sendTo,
      sendCC,
      sendBCC,
      subject
    });

    setMessageString(message);
    setCanPost(true);
    if (emailerParams.showMailer === false) {
      window.alert("Message Sent", "OK");
    };
  };

  // toggles view of table that will be in the body of the email being sent
  const HandleShowTable = () => {
    tableIsVisible ? setTableIsVisible(false) : setTableIsVisible(true);
  };

  const emailViewParams = {
    namesTo,
    namesCC,
    namesBCC,
    subject,
    setSubject,
    body,
    setBody,
    HandleSend,
    sendTo,
    sendCC,
    sendBCC,
    setShowMailer: emailerParams.setShowMailer,
    showSend,
    setShowSend,
    selectedCustomerID,
  };

  const emailTableParams = {
    show: emailerParams.showMailer,
    onHide: emailerParams.onHide,
    selectedRowData: emailerParams.selectedRowData,
    tableIsVisible,
    HandleShowTable,
    zeroToThirty: emailerParams.zeroToThirty,
    thirtyOneToSixty: emailerParams.thirtyOneToSixty,
    sixtyOneToNinety: emailerParams.sixtyOneToNinety,
    overNinety: emailerParams.overNinety,
    balanceShown: emailerParams.balanceShown
  };

  return (
    <EmailerView
      emailViewParams={emailViewParams}
      emailTableParams={emailTableParams}
      status={status}
    />
  );
};