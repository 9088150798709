import { RenameForm } from "../CallApi";

export default function DocNameChage(e, gridApi, cookies, selectedFormName, selectedDocType){
  e.preventDefault();
  // add in doc name change options here
  const formData = new FormData(e.currentTarget);
  const customName = formData.get("customName")?.toString();
  let newFormName = selectedFormName ? selectedFormName.label.toString() : customName;
  const newDocType = selectedDocType ? selectedDocType.value : 0;

  let selectedRows = gridApi.getSelectedRows();
  console.log(selectedRows);
  console.log(newFormName);
  console.log(newDocType);

  if(newFormName !== undefined && customName !== ''){
    
    let formsToRename = [];
    selectedRows.forEach(row=>{
      console.log(row);
      row.formName = newFormName;
      formsToRename.push(row);
    });
  
    formsToRename.forEach(editedForm => {
      console.log(editedForm)
      let oldFormName = editedForm.DocName + "." + editedForm.FileType;
      let identifier = editedForm.EmployeeID;
      newFormName = newFormName + "." + editedForm.FileType;
      console.log(oldFormName, newFormName, identifier);
      RenameForm(identifier, oldFormName, newFormName, newDocType, cookies).then(res=>{
        console.log(res);
        window.alert("Change request sent! Updates may take 15 minutes or more to be visible in this tool.", "Okay");
      });
    });
  }

  if (newDocType !== 0){
    let formtoChangeDocType = [];
    selectedRows.forEach(row=>{
      row.docType = newDocType;
      formtoChangeDocType.push(row);
    });

    formtoChangeDocType.forEach(editedForm => {
      let oldFormName = editedForm.DocName + "." + editedForm.FileType;
      let identifier = editedForm.EmployeeID;
      let newFormName = oldFormName;
      RenameForm(identifier, oldFormName, newFormName, newDocType, cookies).then(res=>{
        console.log(res);
        window.alert("Change request sent! Updates may take 15 minutes or more to be visible in this tool.", "Okay");
      });
    });
  }
}