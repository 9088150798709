import React, { useContext, useState } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import GridLogic from '../Grid/GridLogic'

function Covid(){
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const {
        cookies
    } = useContext(CustomerContext)
    const [defaultColDefs] = useState([
        {headerName:'Employee ID', field:'EmployeeID', cellClass:'linked-cell'},
        {headerName:'Assignment ID',field:'AssignmentID', cellClass:'linked-cell'},
        {headerName:'Proof of COVID Vax',field:'HasCovidDocument', valueFormatter: (vax)=>{return TransformVax(vax)}},
        {headerName:'Vax Opt Out',field:'OptOut'},
        {headerName:'Vax Attest Status', field:'VaxStatus'},
        {headerName:'Last COVID message',field:'LastMessageDate', valueFormatter: (date)=>{return TransformDate(date)}},
        {headerName:'Last Pay Date', field:'LastPayDate', valueFormatter: (date)=>{return TransformDate(date)}},
        {headerName:'Name', field:'EmpName'},
        {headerName:'Email',field:'Email', cellClass:'linked-cell'},
        {headerName:'Cell Phone', field:'CellPhone', cellClass:'linked-cell'},
        {headerName:'Home Phone', field:'HomePhone', cellClass:'linked-cell'},
        {headerName:'Root Customer',field:'RootCustomer'},
        {headerName:'Customer',field:'CustomerName'},
        {headerName:'Department',field:'DepartmentName'},
        {headerName:'Job Title',field:'JobTitle'},
        {headerName:'Branch',field:'Branch'},
        {headerName:'Staffing Specialist', field:'RepFullName'},
    ]);
    const [currentGrid] = useState('COVID');
    const [currentPage] = useState('COVID');
    const [apiUrl] = useState(`${REACT_APP_API_URL}/Compliance/GetCovidByBranch?token=${cookies.get('customer').Token}`);
    const [customerColumnDefs] = useState([
        {headerName:'Employee ID', field:'EmployeeID'},
        {headerName:'Proof of COVID Vax',field:'HasCovidDocument'},
        {headerName:'Name', field:'EmpName'},
        {headerName:'Root Customer',field:'RootCustomer'},
        {headerName:'Customer',field:'CustomerName'},
        {headerName:'Department',field:'DepartmentName'},
    ]);
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState();
    const [popoutOptions, setPopoutOptions] = useState();

    function TransformDate(date){
        if(date.value){
            return date.value.split('T')[0];
        }else{
            return 'None'
        }
    }

    function TransformVax(vax){
        if(vax){
            return 'Yes'
        }else{
            return 'No'
        }
    }
    

    function HandleClick(e){
        if(e.colDef.field==='EmployeeID'){
            openInNewTab('https://beyond.ontempworks.com/employee/'+e.data.EmployeeID)
        }else if(e.colDef.field==='AssignmentID'){
            openInNewTab('https://beyond.ontempworks.com/assignment/'+e.data.AssignmentID)
        }else if(e.colDef.field==='CellPhone'){
            window.open('tel:'+e.data.CellPhone)
        }else if(e.colDef.field==='HomePhone'){
            window.open('tel:'+e.data.HomePhone)
        }else if(e.colDef.field==='Email'){
            window.open('mailto:'+e.data.Email)
        }else if(e.colDef.field==='VaxStatus'){
            setShow(true);
            setPopoutOptions([
                {value:'Vax Stat ETS-Declined Vax',label:'Vax Stat ETS-Declined Vax'},
                {value:'Vax Stat ETS-Fully Vaxed',label:'Vax Stat ETS-Fully Vaxed'},
                {value:'Vax Stat ETS-Med Exempt',label:'Vax Stat ETS-Med Exempt'},
                {value:'Vax Stat ETS-Partial Vax',label:'Vax Stat ETS-Partial Vax'},
                {value:'Vax Stat ETS-Relig Exempt',label:'Vax Stat ETS-Relig Exempt'},
            ])
            setRowData(e.data)
        }
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    function SortData(data){
        let filteredData = [];
        data.forEach(candidate=>{
            if(candidate.CallList===1){
                filteredData.push(candidate)
            }
        })
        return filteredData
    }

    return(
        <GridLogic
            popupInfo={{
                rowData:rowData,
                show:show,
                setShow:setShow,
                options:popoutOptions
            }}
            currentPage={currentPage}
            currentGrid={currentGrid}
            apiUrl={apiUrl}
            defaultColDefs={defaultColDefs}
            customerColumnDefs={customerColumnDefs}
            HandleClick={HandleClick}
            SortData={SortData}
        ></GridLogic>
    )
}

export default Covid;