import { AgGridReact } from "ag-grid-react";
import { useState } from 'react';
import { FormatUSD, CustomComparator, FormatDate } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";

export default function LeftoverOAPs({
    leftoverOapList
}){
    const [gridApi, setGridApi] = useState();
    const leftoverColDefs = [
        { field: "OAPNumber" },
        {
            field: "DepositDate",
            valueFormatter: (data) => FormatDate(data),
        },
        {
            field: "ItemDate",
            valueFormatter: (data) => FormatDate(data),
        },
        { field: "OAPAmount", valueFormatter: data => FormatUSD(data) },
        {
            field: "CustomerName",
            sort: 'asc',
            comparator: CustomComparator
        }
    ];

    const [defaultColDef] = useState ({
        sortable: true,
        resizable: true,
        filter: true,      
    });

    const onFirstDataRendered = (params) => { 
        setGridApi(params.api);
        params.columnApi.autoSizeAllColumns();
    };

    const HandleExportLeftovers = (params) => {
        gridApi.exportDataAsCsv(params);
    };

    return (
        <>
            <div className="grouped-buttons center-all margin-bottom">
                <h5 className="totals-gap">Leftover OAPs</h5>
                <OverlayTrigger overlay={<Tooltip
                    style={{position:"fixed"}}
                >Export Leftover OAPs</Tooltip>} >
                    <Button
                        onClick={() => HandleExportLeftovers()}
                        className="aging-buttons"
                    ><BsDownload/></Button>
                </OverlayTrigger> 
                
            </div>
            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={leftoverOapList}
                    columnDefs={leftoverColDefs}
                    defaultColDef={defaultColDef}
                    onFirstDataRendered={onFirstDataRendered}
                />
            </div>
        </>
    )
}