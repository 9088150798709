import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Modal, Container, Button } from "react-bootstrap";

export default function EditTableView({editTableParams}) 
{
  console.log(editTableParams.colDefs)
  return (
    <>
    <Modal dialogClassName="modal-70w" show={editTableParams.showMailer} onHide={editTableParams.onHide}>
      <Modal.Header closeButton>
        <h3>Edit Invoice Table</h3>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="ag-theme-alpine">
            <AgGridReact
              suppressExcelExport={true}
              rowData={editTableParams.rowData}
              onFirstDataRendered={editTableParams.onFirstDataRendered}
              columnDefs={editTableParams.colDefs}
              defaultColDef={editTableParams.defaultColDef}
              pinnedBottomRowData={editTableParams.pinnedBottomRowData}
              onFilterChanged={editTableParams.onFilterChanged}
              onCellValueChanged={editTableParams.onCellValueChanged}
              frameworkComponents={editTableParams.frameworkComponents}
            />
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
          <Button className="link-button-next" onClick={() => editTableParams.EmailTable()}>Next</Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}
    