import { Form } from "react-bootstrap";
import Select from 'react-select';

export default function WeekAndRowSelectionView({
    weekEndingDates,
    selectedWEDate,
    setSelectedWEDate,
    firstDataRow,
    setFirstDataRow,
    customSelectStyles
}){
    return(
        <>
            <div className="vertical-card-fit two-col-even">
                <div className="flex-card-row center-align">
                    <Form.Label className="label-gap">Week Ending Day:</Form.Label>
                    <Select
                        className="dropdown-styling"
                        options={weekEndingDates}
                        value={selectedWEDate}
                        placeholder='Select Week Ending Day'
                        onChange={(e)=>setSelectedWEDate(e)}
                        styles={customSelectStyles}
                    />
                </div>
                <div className="flex-card-row center-align">
                    <Form.Label className="label-gap">First Data Row:</Form.Label>
                    <Form.Control 
                        type="number"
                        className="input-text-modal-small"
                        value={firstDataRow}
                        onChange={(e)=>setFirstDataRow(e.target.value)}
                    />
                </div>
            </div>
        </>
    )
}