import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import LoadTimecard from '../../FileHandling/LoadTimecard/LoadTimecard';
import ExportUploadXL from '../../FileHandling/ExportUploadXL';

export default function UploadsHeaderView({
  customerList,
  selectedCustomer,
  setSelectedCustomer,
  processingParams,

  loaderParams,
  setIsResearchView,
  exportParams,
  setAddEditCustomer,
  calculatedSums,
  HandleProcessTimecard
}) {
  const customSelectStyles = {
    control: base => ({
      ...base,
      width: '400px'
    }),
  };

  return (
    <>
      <div className="header-card">
        <div className="grouped-input margin-top">
          <h3>Payroll Uploads</h3>
          <div className="right-align">
            <Select
              className="dropdown-styling"
              options={customerList}
              value={selectedCustomer}
              placeholder='Please Select a Customer'
              onChange={(e) => setSelectedCustomer(e)}
              styles={customSelectStyles}
            />
            <div className="labelled-loader">
              <p className="label-gap">Upload Timecard:</p>
              <Form.Group>
                <Form.Control
                  type="file"
                  size="sm"
                  id="fileInput"
                  onChange={(e) => LoadTimecard(
                    e,
                    loaderParams)}
                />
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="two-col-head">
          <div className="left-align">
            <div>

              <Button className="module-button" onClick={() => setIsResearchView(false)}>Timecard</Button>
              <Button className="module-button" onClick={() => setIsResearchView(true)}>Research</Button>
            </div>
            <div className="div-horizontal-margin">
              <Button className="aging-buttons" onClick={() => HandleProcessTimecard(processingParams)}>Calculate Hours</Button>
              <Button className="header-export-button" onClick={() => ExportUploadXL(exportParams)}>Export Sheets</Button>
              <Button className="aging-buttons" onClick={() => setAddEditCustomer(true)}>Add/Edit Customer</Button>
            </div>
          </div>

          {calculatedSums.timecardHoursTotal > 0 &&
            <div className="tc-totals-readout">
              <p className="bolded-info-text center-all white-background-borderless">Calculated Sums</p>
              <p className="label-gap center-all white-background-borderless">Total: {calculatedSums.timecardHoursTotal}</p>
              <p className="label-gap white-background-borderless">Reg: {calculatedSums.timecardReg}</p>
              <p className="label-gap white-background-borderless">OT: {calculatedSums.timecardOT}</p>
              <p className="label-gap white-background-borderless">DT: {calculatedSums.timecardDT}</p>
              <p className="label-gap white-background-borderless">MP: {calculatedSums.timecardMP}</p>
            </div>}
        </div>
      </div>
    </>
  )
}