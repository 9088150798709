import React from 'react'
import ReqSelect from '../ReqSelect/ReqSelect'

function WorkSite({ FindDefaultValue, defaultFormData, contacts, formData, apiUrl, cookies, HandleChange }) {
  return (
    <>
      <ReqSelect isRequired={true} defaultOption={defaultFormData&&defaultFormData.WorkSite} options={contacts} apiUrl={`${apiUrl}/OrderRequest/GetCustomerSites?token=${cookies.get('customer').Token}&CustomerID=${(formData.Department.CustomerID?formData.Department.CustomerID:formData.DepartmentID)}`} labelName={'AddressDesc'} selectLabel={'Work Sites'} HandleChange={(e)=>HandleChange(e, 'WorkSite')} />

      {/* <h4 className='text-center padding'>Assign roles to your Company contacts</h4>

      <Row className='margin-bottom'>
        <Col><Form.Label>Requestor </Form.Label></Col>
        <Col><Select defaultValue={()=>FindDefaultValue(defaultFormData,'Requestor',contacts)} options={contacts} onChange={(e)=>HandleChange(e, 'Requestor')} /></Col>
      </Row>
      <Row className='margin-bottom'>
        <Col><Form.Label>Report To </Form.Label></Col>
        <Col><Select defaultValue={()=>FindDefaultValue(defaultFormData,'ReportTo',contacts)} options={contacts}  onChange={(e)=>HandleChange(e, 'ReportTo')} /></Col>
      </Row>
      <Row className='margin-bottom'>
        <Col><Form.Label>Supervisor </Form.Label></Col>
        <Col><Select defaultValue={()=>FindDefaultValue(defaultFormData,'Supervisor',contacts)} options={contacts}  onChange={(e)=>HandleChange(e, 'Supervisor')} /></Col>
      </Row>
      <Row className='margin-bottom'>
        <Col><Form.Label>Alternate Approver 1</Form.Label></Col>
        <Col><Select defaultValue={()=>FindDefaultValue(defaultFormData,'AlternateApprover1',contacts)} options={contacts} onChange={(e)=>HandleChange(e, 'AlternateApprover1')} /></Col>
      </Row>
      <Row className='margin-bottom'>
        <Col><Form.Label>Alternate Approver 2</Form.Label></Col>
        <Col><Select defaultValue={()=>FindDefaultValue(defaultFormData,'AlternateApprover2',contacts)} options={contacts}  onChange={(e)=>HandleChange(e, 'AlternateApprover2')} /></Col>
      </Row> */}
    </>
  )
}

export default WorkSite