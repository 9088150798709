import React, { useContext, useState } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import AssignEmployee from './AssignEmployee'
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet'

function AssignEmployeeLogic({ show, setShow, candidates }) {
    const [addCustomListOpen, setAddCustomListOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [param, setParam] = useState();
    const [postObj, setPostObj] = useState({
        EmployeeIds: candidates.map(candidate=>candidate.EmployeeID),
        ListItemIds: []
    }); 
    const [columnDefs] = useState([
      {headerName:'Name',field:'LabelName',headerCheckboxSelection: true, checkboxSelection: true},
      {headerName:'Description',field:'LabelDescription'},
      {headerName:'Branch',field:'BranchName'},
      {headerName:'Service Rep',field:'ServiceRepName'},
    ])
    const [defaultColDef] = useState({
      resizable: true,
      sortable: true,
      filter: true
    })
    const { cookies } = useContext(CustomerContext);    
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const {data: lists} = useFetchGet(`${REACT_APP_API_URL}/Candidates/GetCustomLabels?token=${cookies.get('customer').Token}&LabelCategoryID=1`,cookies)

    function HandleCreateOption(e){
        setAddCustomListOpen(true);
        setParam(e)
    }

    function handleRowSelected(e){
        postObj.ListItemIds = e.api.getSelectedRows().map(list=>list.LabelID)
        setPostObj(postObj)
    }

    function HandleSubmit(e){
        setLoading(true);
        fetch(`${REACT_APP_API_URL}/Candidates/PostEmployeeListItems?token=${cookies.get('customer').Token}`,{
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(postObj)
        })
        .then(res=>{
            if(res.status===200){
                setLoading(false);
                HandleClose()
            }else if(res.status===401){
                cookies.remove('customer', {path:'/'})
                window.location.reload();
              }else if(res.status===503||res.status===500){
                setTimeout(() => {
                  HandleSubmit()
                }, 1000);
                return [];
              }else if(!res.ok){
                res.text().then(text=>{
                  if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                    setTimeout(() => {
                      HandleSubmit()
                    }, 1000);
                  }else{
                    throw Error(res.statusText)
                  }
                })
              }
        })
        .catch(err=>{
            setLoading(false)
            console.error(err)
        })
    }

    function HandleClose(){
        setShow(false);
        setPostObj({
            candidates: [],
            branches: [],
            customers: [],
            postings: [],
            labels:[],
            orderrequests: []
        })
    }

  return (
    <AssignEmployee
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      show={show}
      param={param}
      loading={loading}
      addCustomListOpen={addCustomListOpen}
      setAddCustomListOpen={setAddCustomListOpen}
      lists={lists}
      handleRowSelected={handleRowSelected}
      HandleCreateOption={HandleCreateOption}
      HandleSubmit={HandleSubmit}
      HandleClose={HandleClose}
    ></AssignEmployee>
  )
}

export default AssignEmployeeLogic