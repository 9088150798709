import { FormatDate, FormatUSD, FormatDecimalHundredths } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export function ApplyInvoices(
    remitRowData,
    setRemitRowData,
    invoicesRowData,
    setMatchedRowData,
    setMismatchedRowData,
    setMatchedSum,
    setMismatchedSum
) {
    let remitRows = remitRowData.map(row => ({
        ...row,
        rowNameID: row["Week Worked"] + (row.Employee.trim()).toUpperCase() + FormatDecimalHundredths(row.Hours).toString(),
        rowID: row["Week Worked"] + row["ID"] + row.Hours.toString()
    }));
   
    let invoiceRows = invoicesRowData.map(invRow => ({
        ...invRow,
        invNameID: FormatDate(invRow.WeekendDate) + (invRow.LastName.trim()).toUpperCase() + " " + (invRow.FirstName.trim()).toUpperCase() + FormatDecimalHundredths(invRow.TotalHours).toString(),
        invID: FormatDate(invRow.WeekendDate) + invRow.AltID + FormatDecimalHundredths(invRow.TotalHours).toString(),
        invANum: FormatDate(invRow.WeekendDate) + invRow.AltANum + FormatDecimalHundredths(invRow.TotalHours).toString()
    }));

    let matchedArr = [];
    let mismatchedArr = [];

    remitRows.forEach(identifier => {
        let foundReconRow = invoiceRows.find(invoice =>
            identifier.rowID === invoice.invID || identifier.rowID === invoice.invANum || identifier.rowNameID === invoice.invNameID
        );

        if (foundReconRow !== undefined) {
            let matchObj = {
                "Employee": identifier.Employee,
                "WeekendDate": identifier["Week Worked"],
                "InvoiceNumber": foundReconRow.InvoiceNumber,
                "PayAmount": identifier["Paid Invoice Amount"]
            }
            matchedArr.push(matchObj);
        }
        else {
            let mismatchObj = {
                "Employee": identifier.Employee,
                "WeekendDate": identifier["Week Worked"],
                "Hours": identifier.Hours,
                "PaidInvoiceAmount": identifier["Paid Invoice Amount"],
                "rowID": identifier.rowID,
                "rowNameID": identifier.rowNameID
            }
            mismatchedArr.push(mismatchObj);
        }
    });

    let matchedSum = 0;
    matchedArr.forEach(item => {
        matchedSum += Number(item.PayAmount);
    });

    let mismatchedSum = 0;
    mismatchedArr.forEach(item => {
        mismatchedSum += Number(item["Paid Invoice Amount"]);
    });

    remitRows.forEach(row => {
        let foundMismatch = mismatchedArr.find(item =>
            item.rowID === row.rowID || item.rowNameID === row.rowNameID
        );
        if (foundMismatch) {
            row.IsMismatched = true;
        }
        else
            row.IsMismatched = false;
    });

    setMatchedSum(FormatUSD(matchedSum));
    setMismatchedSum(FormatUSD(mismatchedSum));
    setRemitRowData(remitRows);
    setMatchedRowData(matchedArr);
    setMismatchedRowData(mismatchedArr);
}

export function AddSelectedInvoice(gridApi, matchedRowData, setMatchedRowData) {
    let addRows = gridApi.getSelectedRows();
    addRows.forEach(row => {
        row.IsMismatched = false;
    });

    let updatedArray = matchedRowData.concat(addRows);
    setMatchedRowData(updatedArray);
}