import { FormatDate, FormatDecimalHundredths } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default async function BuildInvoiceList(cookies, searchCustomer, apiSetters, setInvoicesRowData) {

    var transactionDataReturn = [];
    let runCount = apiSetters.length;

    const token = cookies.get('customer').Token;
    const { REACT_APP_API_URL } = process.env;
    let resolve = Promise.resolve();

    for (var i = 0; i < runCount; i++){
        var empName = apiSetters[i].empName;
        var altId = apiSetters[i].altId;
        var altANum = apiSetters[i].altANum;
        var hoursWorked = apiSetters[i].hoursWorked;
        var weDate = apiSetters[i].weDate;
        

        const fetchURL = `${REACT_APP_API_URL}/payroll/searchassignments?token=${token}&customerName=${searchCustomer}&altId=${altId}&employeeName=${empName}&altANum=${altANum}&weekendDate=${weDate}&hoursWorked=${hoursWorked}`;

        resolve = resolve.then(() => fetchFunction(fetchURL, transactionDataReturn, setInvoicesRowData)); 
    };

    resolve.then(resolvedFetch => {
        resolvedFetch.forEach(row => {
            let formattedWeDate = FormatDate(row.WeekendDate);
            row.WeekendDate = formattedWeDate;
            row.TotalHours = FormatDecimalHundredths(row.RHours + row.OHours + row.DHours);
        })
        setInvoicesRowData(resolvedFetch)
    })
};

const fetchFunction = (fetchURL, transactionDataReturn, setInvoicesRowData) => { 
    return fetch(fetchURL)
        .then(
            (response) => response.json()
        ).then((data => {
            transactionDataReturn.push(...data);
            return transactionDataReturn
        }));
 }