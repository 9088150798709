import React, { useState } from 'react'
import DoNotCall from './DoNotCall/DoNotCall'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import openCellInNewTab from 'Components/CustomComponents/openCellInNewTab';

function ContactInformationPanel({
    candidateInfo,
    className
}) {
    const [doNotCallPopup,setDoNotCallPopup] = useState(false);
    const openInNewTab = (url) => {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
    }

  return (
    <>
    {doNotCallPopup&&<DoNotCall show={doNotCallPopup} setShow={setDoNotCallPopup} candidateInfo={candidateInfo} />}
    <div className={className+' padding'}>        
        {candidateInfo.DoNotCall&&
        <Alert variant='warning'>Candidate is flagged for Do Not Call. Please check message codes to see when the Do Not Call flag was set before making contact.</Alert>}
        <div className='contact-info-p-wrapper'>
            <b className='margin-top'>Employee ID:</b> 
            <p onClick={(e)=>openInNewTab('https://beyond.ontempworks.com/employee/'+candidateInfo.EmployeeID)} className='linked-cell'>{candidateInfo.EmployeeID}</p>
        </div>
        <div className='contact-info-p-wrapper'>
            <b>Phone: </b>
            <p onClick={(e)=>openCellInNewTab('tel:'+candidateInfo.CellPhone,e)} className='linked-cell'>{candidateInfo.CellPhone}</p>
        </div>
        <div className='contact-info-p-wrapper'>
            <b>Email: </b>
            <p onClick={(e)=>openInNewTab('mailto:'+candidateInfo.Email)} className='linked-cell'>{candidateInfo.Email}</p>
        </div>
        <div className='contact-info-p-wrapper'>
            <b>Branch:</b>
            <p>{candidateInfo.BranchName}</p>
        </div>
        <div className='contact-info-p-wrapper'>
            <b>Contact For Work:</b> 
            <p>{candidateInfo.DoNotCall?'Do Not Contact':'Contact'}</p>
        </div>
        <Button onClick={()=>setDoNotCallPopup(true)} variant={candidateInfo.DoNotCall?'success':'danger'} className='full-width'>{candidateInfo.DoNotCall?'Set Looking For Work':'Set Not Looking For Work'}</Button>
    </div>
    </>
  )
}

export default ContactInformationPanel