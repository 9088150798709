import { Form } from "react-bootstrap";
import Select from 'react-select';

export default function ProcessingSelectionsView({
    overtimeRules,
    selectedOtRule,
    setSelectedOtRule,

    processTypes,
    selectedProcessType,
    HandleSelectProcessType,

    customSelectStyles
}){
    return (
        <>
        <div className="vertical-card-fit two-col-even">
                <div className="flex-card-row center-align">
                    <Form.Label className="label-gap">Overtime Calculation Type:</Form.Label>
                    <Select
                        className="dropdown-styling"
                        options={overtimeRules}
                        value={selectedOtRule}
                        placeholder='Select Overtime Calculation Type'
                        onChange={(e)=>setSelectedOtRule(e)}
                        styles={customSelectStyles}
                    />
                </div>
                <div className="flex-card-row center-align">
                    <Form.Label className="label-gap">Process Type:</Form.Label>
                    <Select
                        className="dropdown-styling"
                        options={processTypes}
                        value={selectedProcessType}
                        placeholder='Select Timecard Processing Type'
                        onChange={(e)=>HandleSelectProcessType(e)}
                        styles={customSelectStyles}
                    />
                </div>
            </div>
        </>
    )
}