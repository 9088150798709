import React, { useState, useEffect, useContext, useMemo } from "react";
import InvoiceMatchingView from "./InvoiceMatchingView";
import LoadRemitFile from "../FileHandling/LoadRemitFile";
import { MatchCustomers } from "./InvoiceMatchingVars";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import BuildInvoiceList from "./BuildInvoiceList";
import { FormatDate, CustomComparator } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function InvoiceMatchingController({
    matchCustomer,
    setMatchedInvoices
}){
    const [gridApi, setGridApi] = useState();
    const [matchedGridApi, setMatchedGridApi] = useState();
    const [gridApiSecondary, setGridApiSecondary] = useState();

    const [remitRowData, setRemitRowData] = useState();
    const [searchCustomer, setSearchCustomer] = useState();
    const { cookies } = useContext(CustomerContext);
    
    const [invoicesRowData, setInvoicesRowData] = useState();
    const [matchedRowData, setMatchedRowData] = useState();
    const [mismatchedRowData, setMismatchedRowData] = useState();

    const matchCustomers = MatchCustomers();
    const [customer, setCustomer] = useState();

    const [defaultColDef] = useState ({
        sortable: true,
        resizable: true,
        filter: true,
        editable: true
    });

    const [remitCols, setRemitCols] = useState();

    const [invoiceCols, setInvoiceCols] = useState();
    const [matchedCols, setMatchedCols] = useState();
    const [mismatchedCols, setMismatchedCols] = useState();

    const [remitSum, setRemitSum] = useState();
    const [matchedSum, setMatchedSum] = useState();
    const [mismatchedSum, setMismatchedSum] = useState();

    useEffect(() => { // matchCustomer comes from Reconcile page
        if (matchCustomer) {
            let customerToSelect = matchCustomers.find(cust => cust.value === matchCustomer);
            setCustomer(customerToSelect);
            setSearchCustomer(customerToSelect.value);

        }
        // eslint-disable-next-line
    }, [matchCustomer]);

    useEffect(() => {
        if (remitRowData) {
            setRemitCols([
                {
                    field: "Employee",
                    sort: 'asc',
                    comparator: CustomComparator,
                    checkboxSelection: true,
                    headerCheckboxSelection: true
                },
                { field: 'ID'},
                { field: "Week Worked", headerName: 'Week', sort: 'asc' },
                { field: "Hours" },
                { field: "Paid Invoice Amount", headerName: 'Amount' },
                { field: "IsMismatched", hide: true }
            ]);
        }
        // eslint-disable-next-line
    }, [remitRowData]);

    useEffect(() => {
        if (invoicesRowData) {
            setInvoiceCols([
                {
                    field: 'LastName',
                    sort: 'asc',
                    comparator: CustomComparator
                },
                {
                    field: 'FirstName',
                    sort: 'asc',
                    comparator: CustomComparator
                },
                { field: 'EmployeeId', headerName: 'AltID' },
                { field: 'AltANum'},
                { field: 'WeekendDate', headerName: 'Week', sort: 'asc'},
                { field: 'InvoiceNumber', headerName: 'Invoice' },
                { field: 'TotalHours', headerName: 'Hours'}
            ]);
        }
        // eslint-disable-next-line
    }, [invoicesRowData])
    
    useEffect(() =>  {
        if (remitRowData) {
            var apiSetters = [];

            remitRowData.forEach(lineItem => {
                var setterRow = {
                    'empName': lineItem.Employee,
                    'altId': lineItem.ID,
                    'altANum': lineItem.ID, 
                    'hoursWorked': lineItem.Hours,
                    'weDate': lineItem["Week Worked"]
                };
                apiSetters.push(setterRow);
            });

            BuildInvoiceList(cookies, searchCustomer, apiSetters, setInvoicesRowData);
        }
        // eslint-disable-next-line
    }, [remitRowData]);

    useEffect(() => {
        if (matchedRowData) {
            setMatchedCols([
                {
                    field: 'Employee',
                    sort: 'asc',
                    comparator: CustomComparator,
                },
                { field: 'WeekendDate' },
                { field: 'InvoiceNumber' },
                { field: "PayAmount", headerName: 'Amount' },
            ]);
        }
    }, [matchedRowData]);

    useEffect(() => {
        if (mismatchedRowData) {
            setMismatchedCols([
                {
                    field: 'Employee',
                    sort: 'asc',
                    comparator: CustomComparator,
                    checkboxSelection: true,
                    headerCheckboxSelection: true
                },
                { field: 'WeekendDate' },
                { field: 'Hours' },
                { field: "PaidInvoiceAmount", headerName: 'Amount' },
            ]);
        };
    },[mismatchedRowData]);

    const rowClassRules = useMemo(() => {
        if (remitRowData) {
            return {
                'mismatched-row': 'data.IsMismatched'
            };
        }
        // eslint-disable-next-line
    }, [remitRowData]);

    const onFirstDataRendered = (params) => {
        params.api.autoSizeAllColumns();
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.autoSizeAllColumns();
    };

    const onSecondaryGridReady = (params) => {
        setGridApiSecondary(params.api);
        params.api.autoSizeAllColumns();
    }

    const onMatchGridReady = (params) => {
      setMatchedGridApi(params.api);
      params.api.autoSizeAllColumns();
    }

    const HandleCustomerSelection = (e) => {
        setCustomer(e);
        setSearchCustomer(e.value);
    };

    const HandleLoadRemitFile = (e) => {
        LoadRemitFile(e, setRemitRowData, setRemitSum, customer.value);
    };

    const HandleSaveInvoices = () => {
        let paidInvoices = matchedRowData.map(row =>
            ({ "InvoiceNumber": row.InvoiceNumber, "PayAmount": Number(row.PayAmount) }));

        setMatchedInvoices(paidInvoices);
    };

    const HandleDateAdd = () => {
        const selectedRows = gridApiSecondary.getSelectedRows();
        selectedRows.forEach(row => {
            let datevar = (row["Week Worked"].split("-")).toString();
            let weekDate = new Date(datevar);
            weekDate = new Date(weekDate);
            let addDay = weekDate.setDate(weekDate.getDate() + 1);
            addDay = new Date(addDay);
            addDay = addDay.toISOString();
            addDay = FormatDate(addDay);
            row["Week Worked"] = addDay;
        });
        gridApiSecondary.refreshCells();
    };

    const HandleDateSubtract = () => {
        const selectedRows = gridApiSecondary.getSelectedRows();
        selectedRows.forEach(row => {
            let datevar = (row["Week Worked"].split("-")).toString();
            let weekDate = new Date(datevar);
            weekDate = new Date(weekDate);
            let addDay = weekDate.setDate(weekDate.getDate() - 1);
            addDay = new Date(addDay);
            addDay = addDay.toISOString();
            addDay = FormatDate(addDay);
            row["Week Worked"] = addDay;
        });
        gridApiSecondary.refreshCells();
    };

    const customSelectStyles = {
        control: base => ({
            ...base,
            width: '200px'
        })
    };

    const HandleExportMatchProgress = (params) => {
      matchedGridApi.exportDataAsCsv(params);
  };

    return (
        <>
            <InvoiceMatchingView
                matchCustomers={matchCustomers}
                customer={customer}
                HandleCustomerSelection={HandleCustomerSelection}
                HandleLoadRemitFile={HandleLoadRemitFile}
                HandleSaveInvoices={HandleSaveInvoices}
                HandleDateAdd={HandleDateAdd}
                HandleDateSubtract={HandleDateSubtract}
                customSelectStyles={customSelectStyles}

                remitRowData={remitRowData}
                setRemitRowData={setRemitRowData}
                defaultColDef={defaultColDef}
                remitCols={remitCols}
                onSecondaryGridReady={onSecondaryGridReady}
                rowClassRules={rowClassRules}

                invoicesRowData={invoicesRowData}
                invoiceCols={invoiceCols}

                matchedRowData={matchedRowData}
                setMatchedRowData={setMatchedRowData}
                matchedCols={matchedCols}
                onFirstDataRendered={onFirstDataRendered}

                mismatchedRowData={mismatchedRowData}
                setMismatchedRowData={setMismatchedRowData}
                mismatchedCols={mismatchedCols}
                gridApi={gridApi}
                onGridReady={onGridReady}
                onMatchGridReady={onMatchGridReady}
                HandleExportMatchProgress={HandleExportMatchProgress}
                
                remitSum={remitSum}
                matchedSum={matchedSum}
                setMatchedSum={setMatchedSum}
                mismatchedSum={mismatchedSum}
                setMismatchedSum={setMismatchedSum}
            />
        </>
    )
}