import React, { useContext, useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { BsDownload, BsLayoutThreeColumns } from 'react-icons/bs';
import ColumnSavingButtonGroup from 'Components/ATS/Candidates/CandidatesGrid/CandidateInfoPopup/ColumnSavingButtonGroup';
import { Alert } from 'react-bootstrap';

function DefaultColumnEditing() {
    const [columnDefs, setColumnDefs] = useState();
    const [selectedReport, setSelectedReport] = useState();
    const [stringifiedColumnDefs, setStringifiedColumnDefs] = useState();
    const [defaultColDef] = useState({ resizable: true, sortable: true, filter: true })
    const [newColumn, setNewColumn] = useState({field:'',headerName:'',...defaultColDef});
    const [gridApi, setGridApi] = useState();
    const [columnApi, setColumnApi] = useState();
    const [reportOptions] = useState([
        {value:'Active Candidates',label:'Active Candidates'},
        {value:'CandidateDefault',label:'CandidateDefault'},
        {value:'Custom Lists',label:'Custom Lists'},
        {value:'Employee Search',label:'Employee Search'},
        {value:'Job Orders',label:'Job Orders'},
        {value:'Lists',label:'Lists'},
        {value:'My Employees',label:'My Employees'},
        {value:'Recent Applicants',label:'Recent Applicants'},
        {value:'Recently Ended',label:'Recently Ended'},
    ]);
    const { cookies } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const { data: customColumns } = useFetchGet((selectedReport&&`${REACT_APP_API_URL}/Utilities/GetReportColumns?token=${cookies.get('customer').Token}&reportName=${selectedReport.value}&isDefault=true`),cookies)
    
    useEffect(()=>{
        if(customColumns&&customColumns.Return){
            let decodedColumns = JSON.parse(window.atob(customColumns.Return));
            setColumnDefs(decodedColumns);
            setStringifiedColumnDefs(JSON.stringify(decodedColumns))
        }
    },[customColumns])

    const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    };

    const handleReportChange = (e) => { 
        setSelectedReport(e)
    }

    const handleNewColumnValueChange = (e, fieldName) => { 
        let column = {...newColumn}
        column[fieldName] = e.target.value;
        setNewColumn(column)
    }

    const handleNewColumnSubmit = (e) => { 
        e.preventDefault(); 
        let newColumns = columnDefs
        newColumns.unshift(newColumn)
        setColumnDefs(newColumns)
        setStringifiedColumnDefs(JSON.stringify(newColumns))
        gridApi.setColumnDefs(newColumns)
        setNewColumn({field:'',headerName:'', ...defaultColDef})
    }

    const onGridReady = (params) => { 
        setGridApi(params.api)
        setColumnApi(params.columnApi)
     }

    const pushStringifiedColumnDefsToGrid = () => { 
        setColumnDefs(JSON.parse(stringifiedColumnDefs.split('\n').join('')))
     }
    
  return (
    <div>
        <Alert>
            This is a tool to edit the <b>default</b> column definitions for grids with column saving. Formatting is still managed in the codebase, so if you want to change the way a value is being rendered you'll need to update the codebase.
            <br/>
            In order to change the column, you'll need to know the field coming from the API and put that in the 'Field' box. This is case sensitive. HeaderName is the name of the header.
            <br/>
            Once you have the columns added to the grid and sized the way you want, simply click the <BsLayoutThreeColumns /> icon and select 'Save columns'
            <br/>
            This will update the <b>default</b> columns. Users will have to select the 'Show default columns' on their application in order to see the changes, unless the custom columns are deleted from the DB which would force everyone to see the defaults.
        </Alert>
        <Select options={reportOptions} onChange={(e)=>handleReportChange(e)}  className='margin-top' />
        <Form className='margin-bottom margin-top' onSubmit={handleNewColumnSubmit}>
            <Row>
                <Col sm={1}>
                    <Form.Label>Field:</Form.Label>
                </Col>
                <Col>
                    <FormControl value={newColumn.field} onChange={(e)=>handleNewColumnValueChange(e, 'field')} />
                </Col>
                <Col sm={1}>
                    <Form.Label>HeaderName:</Form.Label>
                </Col>
                <Col>
                    <FormControl value={newColumn.headerName} onChange={(e)=>handleNewColumnValueChange(e, 'headerName')} />
                </Col>
                <Col sm={2}>
                    <Button type='submit' className='full-width'>Add New Column</Button>
                </Col>
            </Row>
        </Form>
        <Row>
        {stringifiedColumnDefs?
        <>
        <Form.Control as='textarea' value={stringifiedColumnDefs.split('},{').join('},\n{')} onChange={(e)=>setStringifiedColumnDefs(e.target.value)} className='vh20'/>
        <Button onClick={()=>pushStringifiedColumnDefsToGrid()} className='margin-top margin-bottom'>Show changes in grid</Button>
        </>
        :''}
            <Col className='download-button-wrapper'>
              <ButtonGroup>
                <Button title='Download grid data' variant='secondary' onClick={() => onBtnExport()}><BsDownload/></Button>
                <ColumnSavingButtonGroup
                    customColumnName={selectedReport&&selectedReport.value}
                    columnApi={columnApi}
                    setColumnDefs={setColumnDefs}
                    columnDefs={columnDefs}
                    adminDefault={true}
                    otherColumnDefs={[]}
                />
              </ButtonGroup>
            </Col>   
        </Row>
        <div id="myGrid" className="ag-theme-alpine vh20">
              <AgGridReact
                rowData={[]}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
              ></AgGridReact>
          </div>
    </div>
  )
}

export default DefaultColumnEditing