import React from "react";
import UploadsHeader from "./Components/UploadHeader/UploadsHeader";
import GridController from "./Components/TimecardPage/GridController";
import ColumnHint from "./Components/ColumnHint";

export default function PayrollUploadsView({
  loaderParams,
  hoursTotals,
  exportParams,
  headerAddedParams,
  gridAddedParams
}) {

  return (
    <>
      <UploadsHeader
        loaderParams={loaderParams}
        exportParams={exportParams}
        headerAddedParams={headerAddedParams}
      />
      {!gridAddedParams.isResearchView && exportParams.selectedCustomer &&
        <ColumnHint loaderColumns={loaderParams.loaderColumns} />}
      <GridController
        rowData={headerAddedParams.rowData}
        selectedCustomer={exportParams.selectedCustomer}
        gridAddedParams={gridAddedParams}
        loaderColumns={loaderParams.loaderColumns}
        delayedRows={exportParams.delayedRows}
        matchRowData={exportParams.uploadData}
        mergeData={exportParams.mergeData}
        hoursTotals={hoursTotals}
      />
    </>
  )
}