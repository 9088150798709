import React, { useContext, useState, useEffect } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext'
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost'
import AddSRToOrder from './AddSRToOrder'
import useForceUpdate from 'Components/CustomComponents/CustomHooks/useForceUpdate'

function AddSRToOrderLogic({ show, setShow, jobOrderID }) {
  const { cookies } = useContext(CustomerContext)
  const { REACT_APP_API_URL } = process.env;
  const [branchIDs] = useState([]);
  const [SRIDs, setSRIDs] = useState();
  const [canPost, setCanPost] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: serviceReps } = useFetchPost(`${REACT_APP_API_URL}/Candidates/GetServiceReps?token=${cookies.get('customer').Token}`,cookies,branchIDs)
  const { status } = useFetchPost((canPost&&`${REACT_APP_API_URL}/Candidates/associate/PostServiceRepToOrder?token=${cookies.get('customer').Token}&OrderID=${jobOrderID}`),cookies,SRIDs)
  const forceUpdate = useForceUpdate();

  useEffect(()=>{
    if(status){
      forceUpdate()
      setCanPost(false)
      setLoading(false)
    }
    // eslint-disable-next-line
  },[status])

  const HandleSRChange = (e) => { 
    let newSRList = e.map(e=>e.value.SrIdent)
    setSRIDs(newSRList)
   }

  const HandleSubmit = (e) => { 
    setCanPost(true);
    setLoading(true);
   }

  return (
    <AddSRToOrder 
      show={show}
      setShow={setShow}
      jobOrderID={jobOrderID}
      serviceReps={serviceReps}
      setCanPost={setCanPost}
      status={status}
      HandleSRChange={HandleSRChange}
      loading={loading}
      HandleSubmit={HandleSubmit}
    ></AddSRToOrder>
  )
}

export default AddSRToOrderLogic