import React, { useContext } from 'react'
import Codes from './Codes'
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';

function CodesLogic({ defaultFormData }) {
    const { cookies, formData, setFormData } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const { data: orderTypes } = useFetchGet(`${REACT_APP_API_URL}/Candidates/getordertypes?token=${cookies.get('customer').Token}`,cookies)

    const HandleChange = (e, fieldName) => { 
        formData[fieldName] = e.value.OrderTypeId;
        setFormData(formData)
     }

  return (
      <Codes
        HandleChange={HandleChange}
        defaultFormData={defaultFormData}
        orderTypes={orderTypes}
      ></Codes>
  )
}

export default CodesLogic