import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import CandidatesGrid from './CandidatesGrid'

const CandidatesGridLogic = ({ 
    currentPageName, 
    rowData, 
    otherColumnDefs, 
    onGridReady, 
    customColumnName,
    aboveGridElements
},props) => {
    const [candidatePopupIsOpen, setCandidatePopupIsOpen] = useState(false);
    const [candidateStatusPopupIsOpen, setCandidateStatusPopupIsOpen] = useState(false);
    const [employeeData, setEmployeeData] = useState();
    const [jobOrderID, setJobOrderID] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [columnApi, setColumnApi] = useState();
    const [openAssignEmployee, setOpenAssignEmployee] = useState(false);
    const [openAssignCandidate, setOpenAssignCandidate] = useState();
    const {setCurrentPage} = useContext(CustomerContext);
    const [columnDefs, setColumnDefs] = useState();
    const [openedTab, setOpenedTab] = useState(1);
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
    })

    useEffect(()=>{
        if(currentPageName){
            setCurrentPage(currentPageName)
        }
    },[currentPageName,setCurrentPage])

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

    const HandleCellClick = (event) => { 
        setEmployeeData(event.data)
        if(event.data.OrderRequestID){
            setJobOrderID(event.data.OrderRequestID)
        }
        if(event.colDef.headerName==='Onboarding'){
            setCandidatePopupIsOpen(true);
            setOpenedTab(2);
        }else if(event.colDef.headerName==='Status'||event.colDef.headerName==='Status Description'){
            setCandidateStatusPopupIsOpen(true);
        }else if (event.colDef.field==='EmployeeID'){
            openInNewTab(`https://beyond.ontempworks.com/employee/${event.data.EmployeeID}`)
        }else if(event.colDef.headerName==='Order ID'){
            openInNewTab(`https://beyond.ontempworks.com/joborder/${event.data.OrderRequestID}`)
        }else{
            event.data.DateCreated = event.data.CreatedDate
            setCandidatePopupIsOpen(true)
            setOpenedTab(1);
        }
     }

     function HandleRowSelected(e){
        setSelectedRows(gridApi.getSelectedRows())
    }

    // function onGridColumnsChanged(params){
    //     if(!customColumns.Return){
    //         params.columnApi.autoSizeAllColumns(false);
    //     }
    // }

    function defaultOnGridReady(params){
        setGridApi(params.api)
        setColumnApi(params.columnApi)
        // if(!customColumns.Return){
        //     params.columnApi.autoSizeAllColumns(false);
        // }
    }

    function AssignCandidates(){
        setOpenAssignCandidate(true);
    }

    function DeselectAllRows(){
        gridApi.deselectAll()
    }

    const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    };

  return (
    <CandidatesGrid
        otherColumnDefs={otherColumnDefs}
        customColumnName={customColumnName}
        columnApi={columnApi}
        setColumnDefs={setColumnDefs}
        rowData={rowData}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        defaultOnGridReady={defaultOnGridReady}
        defaultColDef={defaultColDef}
        candidatePopupIsOpen={candidatePopupIsOpen}
        setCandidatePopupIsOpen={setCandidatePopupIsOpen}
        HandleCellClick={HandleCellClick}
        employeeData={employeeData}
        candidateStatusPopupIsOpen={candidateStatusPopupIsOpen}
        setCandidateStatusPopupIsOpen={setCandidateStatusPopupIsOpen}
        openAssignEmployee={openAssignEmployee}
        setOpenAssignEmployee={setOpenAssignEmployee}
        openAssignCandidate={openAssignCandidate}
        setOpenAssignCandidate={setOpenAssignCandidate}
        HandleRowSelected={HandleRowSelected}
        AssignCandidates={AssignCandidates}
        DeselectAllRows={DeselectAllRows}
        selectedRows={selectedRows}
        openedTab={openedTab}
        onBtnExport={onBtnExport}
        jobOrderID={jobOrderID}
        aboveGridElements={aboveGridElements}
    ></CandidatesGrid>
  )
}

export default CandidatesGridLogic;