import { useEffect, useState } from "react";
import LockBoxPrepView from "./LockboxPrepView";
import { GetBalancePaid, GetPaymentType, GetPostedPayments, GetTWInvoiceBalance } from "../ValueGetters/GetLockboxGridValues";
import { FormatUSD } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function LockboxPrep({headerExpanded, dataHold}) {
    const [cashReportData, setCashReportData] = useState();
    const [reportData, setReportData] = useState();
    const [reportRows, setReportRows] = useState();
    const [cashReportColDefs, setCashReportColDefs] = useState();
    const [defaultColDef] = useState ({
        sortable: true,
        resizable: true,
        filter: true,      
    });
    
    const [gridApi, setGridApi] = useState();
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
    const [selectedType, setSelectedType] = useState();
    
    const [researchSelection, setResearchSelection] = useState();

    useEffect(() => {
        if (cashReportData) {
            let reportBuildRows = cashReportData.map(
                row => ({
                    Posted: GetPostedPayments(row),
                    DepositDate: row[" Deposit Date"],
                    CustomerID: row["Customer ID"],
                    CustomerName: row["Customer Name"],
                    InvoiceNumber: row["Item #"],
                    PayAmount: row[" Net Credit"],
                    PayReferenceNumber: row[" Payment #"],
                    Description: row["Description"],
                    PaymentType: GetPaymentType(row),
                    RemainingBalance: row["Item Balance"] ? row["Item Balance"] : 0,
                    ReportDate: row[" Process Date"],
                    TWInvoiceBalance: GetTWInvoiceBalance(row, dataHold),
                    FullBalancePaid: GetBalancePaid(row, dataHold)
                })
            );
            
            setReportData(reportBuildRows);
        }
        // eslint-disable-next-line
    }, [cashReportData]);

    useEffect(()=>{
        if(reportRows){
            let payTotal = 0;

            reportRows.forEach(payment=>{
                payTotal += Number(payment.PayAmount);
            });

            let negString = payTotal < 0 ? (payTotal.toString()).substring(1) : 0;

            let bottomRowData = [{
                CustomerName: 'Grand Total',
                PayAmount: payTotal >= 0 ? FormatUSD(payTotal) : `- ${FormatUSD(negString)}`
            }];
            setPinnedBottomRowData(bottomRowData);
        }
    },[reportRows]);

    useEffect(()=> {
        if(reportData){
            setResearchSelection([
                { value: "All", label: "All", color: "#BDBDE7"},
                { value: "Posted Payment", label: `Posted Payment: Count: ${getTypeCount('Posted Payment')}`, color: "#BDE7E0"},
                { value: "200-Against previous chargeback", label: `200-Against previous chargeback: Count: ${getTypeCount('200-Against previous chargeback')}`, color: "#FFF5A5"},
                { value: "201-Client Request", label: `201-Client Request: Count: ${getTypeCount('201-Client Request')}`, color: "#FCEE88" },
                { value: "202-Non-factored Invoice (credit)", label: `202-Non-factored Invoice (credit): Count: ${getTypeCount('202-Non-factored Invoice (credit)')}`, color: "#FFF5A5" },
                { value: "600-See Attached Customer Remittance Advice", label: `600-See Attached Customer Remittance Advice: Count: ${getTypeCount('600-See Attached Customer Remittance Advice')}`, color: "#FCEE88"},        
                { value: "608-Auto Chargeback/Unresolved Dispute", label: `608-Auto Chargeback/Unresolved Dispute: Count: ${getTypeCount('608-Auto Chargeback/Unresolved Dispute')}`, color: "#FFF5A5"},
                { value: "OAP Applied", label: `OAP Applied: Count: ${getTypeCount('OAP Applied')}`, color: "#FCEE88" },
                { value: "OAP Created - 400-Letter to client (item not fou", label: `OAP Created - 400-Letter to client (item not found): Count: ${getTypeCount('OAP Created - 400-Letter to client (item not fou')}`, color: "#FFF5A5" },
                { value: "OAP Transferred - To other client", label: `OAP Transferred - To other client: Count: ${getTypeCount('OAP Transferred - To other client')}`, color: "#FCEE88"}
            ]);
        }
        // eslint-disable-next-line
    },[reportData])

    const getTypeCount = (value) => {
        let count = 0
        if(reportData && reportData.length){
            reportData.forEach(row => {
                if (row.Posted === value){
                    count ++;
                }
            });
        }
        return count;
    }

    const onFirstDataRendered = (params) => { 
        setGridApi(params.api);
        params.columnApi.autoSizeAllColumns();
      };

    const HandleExportPaid = (params) => {
        gridApi.exportDataAsCsv(params);
    };

    const HandleTypeSelection = (e) => {
        setSelectedType(e);
        let reportRows = e.value === "All" ? reportData : reportData.filter(row => row.Posted === e.value);
        setReportRows(reportRows);

        let reportCols = e.value === "All" ? 
            [
                {field: "Posted"},
                {field: "DepositDate"},
                {field: "CustomerID"},
                {field: "CustomerName"},
                {field: "InvoiceNumber"},
                {field: "PayAmount"},
                {field: "PayReferenceNumber"},
                {field: "Description"},
                {field: "PaymentType"},
                {field: "RemainingBalance"},
                {field: "ReportDate"},
                {field: "TWInvoiceBalance"},
                {field: "FullBalancePaid"}
            ]
            : e.value === "Posted Payment" ?
            [
                {field: "DepositDate", headerName: "Date"},
                {field: "CustomerID"},
                {field: "CustomerName"},
                {field: "InvoiceNumber"},
                {field: "PayReferenceNumber"},
                {field: "PaymentType"},
                {field: "PayAmount"}
            ]
            :
            [
                {field: "ReportDate", headerName: "Date"},
                {field: "DepositDate"},
                {field: "CustomerID"},
                {field: "CustomerName"},
                {field: "InvoiceNumber"},
                {field: "PayReferenceNumber"},
                {field: "PayAmount", headerName: "Sum of Payment Amount"}
            ];
        
        setCashReportColDefs(reportCols);
    };

    const customSelectStyles = {
        control: base => ({
            ...base,
            width: '500px'
        }),
        option: (base, {data}) => {
            const letterColor = data.color;
            return {
                ...base,
                backgroundColor: letterColor,
                color: "#000000"
            };
        },
    };

    return (
        <LockBoxPrepView
            headerExpanded={headerExpanded}
            onFirstDataRendered={onFirstDataRendered}
            reportRows={reportRows}
            setCashReportData={setCashReportData}
            cashReportColDefs={cashReportColDefs}
            defaultColDef={defaultColDef}

            HandleExportPaid={HandleExportPaid}
            researchSelection={researchSelection}
            HandleTypeSelection={HandleTypeSelection}
            selectedType={selectedType}
            customSelectStyles={customSelectStyles}
            pinnedBottomRowData={pinnedBottomRowData}
        />
    )
}