import React from 'react'
import { Button, Form} from 'react-bootstrap';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import SearchFiltersLogic from '../SearchFilters/SearchFiltersLogic';

function JobOrdersDashboard({ 
  setAddJobOrderIsOpen,
  postObj,
  setPostObj,
  openFilter,
  setOpenFilter,
  categories,
  rowData,
  defaultColDef,
  onNewColumnsLoaded,
  columnDefs,
  HandleCategoryChange,
  reportsLoading,
  onGridLoaded
 }) {

  const customSelectStyles = {
    control: base => ({
        ...base,
        width: '200px'
    })
  };

  return (
    <>
    <div className='header-card-auto'>
      <div className='three-column-div'>
        <h3>Orders Dashboard</h3>
        {categories&&<div className='button-div left-align'>
        <Form.Label className='label-gap'>Group by:</Form.Label>
        <Select 
          styles={customSelectStyles}
          options={categories.map(cat=>{return{value:cat,label:cat}})} 
          defaultValue={{label:categories[0],value:categories[0]}} 
          onChange={(e)=>HandleCategoryChange(e)} />
        </div>}
        <div>
          <Button className='label-gap' variant='secondary' onClick={(e)=>setAddJobOrderIsOpen(true)}>Add Job Order</Button>
          <Button variant='secondary' onClick={(e)=>setOpenFilter(!openFilter)}>{openFilter?'Hide Search':'Show Search'}</Button>
        </div>
      </div>
      <div className={openFilter?'margin-bottom':'hidden margin-bottom'}>
        <SearchFiltersLogic postObj={postObj} setPostObj={setPostObj} loading={reportsLoading} />
      </div>
    </div>

    <div className='card ats-card'>
      <div id="myGrid" className="ag-theme-alpine grid-height-mod">
          <AgGridReact
              defaultColDef={defaultColDef}
              rowData={rowData}
              onNewColumnsLoaded={onNewColumnsLoaded}
              columnDefs={columnDefs}
              onGridReady={onGridLoaded}
          ></AgGridReact>
      </div>
    </div>
    </>
  )
}

export default JobOrdersDashboard