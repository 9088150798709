import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import useFetchGet from '../Fetch/useFetchGet';
import CustomerContext from '../Context/CustomerContext';
import Placeholder from 'react-bootstrap/Placeholder';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

function ReactSelectFetchGet({
    url,
    mapFunction,
    value,
    onChange,
    isMulti, 
    className, 
    placeholder
}) {
    const { cookies } = useContext(CustomerContext);
    const { data, loading, error } = useFetchGet(url, cookies)
    const [options, setOptions] = useState();

    useEffect(()=>{
        if(data){
            setOptions(data.map(mapFunction))
        }
    },[data, setOptions, mapFunction])
  return (
    <>
    {loading?
    <Placeholder as={Form.Select} animation='border'/>
    :error?
    <Alert variant='warning'>There was an error, please refresh and try again</Alert>
    :
    <Select value={value} className={className} options={options} onChange={(e)=>onChange(e)} isMulti={isMulti} placeholder={placeholder} />
    }
    </>
  )
}

export default ReactSelectFetchGet