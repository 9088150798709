import '../../Payroll.css';
import { AgGridReact } from "ag-grid-react";
import { Button } from 'react-bootstrap';

export default function OAPNotesView({
    oapNotes,
    notesColDefs,
    defaultColDef,
    onFirstDataRendered,
    HandleAddRow,
    HandleSaveNotes,
    // HandleDeleteRow
}) {
    return (
        <>
            <div className="card oap-card">
                <div className='grid-header justify-left'>
                    <Button
                        className="card-header-button"
                        onClick={()=>HandleAddRow()}
                    >Note +</Button>
                    <Button
                        className="card-header-button"
                        onClick={()=>HandleSaveNotes()}
                    >Save New Selected</Button>
                </div>
                
                <div className="ag-theme-alpine ag-border-dark">
                    <AgGridReact
                        rowData={oapNotes}
                        onFirstDataRendered={onFirstDataRendered}
                        columnDefs={notesColDefs}
                        defaultColDef={defaultColDef}
                        rowSelection={'multiple'}
                        rowMultiSelectWithClick={true}
                    />
                </div>
            </div>
        </>
    )
}