import React from 'react'
import { Modal, Button, Row, Col, Spinner } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Badge from 'react-bootstrap/Badge';
import './CandidateInfoPopup.css'
import Onboarding from './TabItems/Onboarding/Onboarding'
import Statuses from './TabItems/Statuses/Statuses'
import Assignments from './TabItems/Assignments'
import Compliance from './TabItems/Compliance'
import Work from './TabItems/Work'
import Messages from './TabItems/Messages'
import ContactInformation from './TabItems/ContactInformation/ContactInformation'

function CandidateInfoPopup({ 
    onboarding,
    workHistory,
    messages,
    assignments,
    openInNewTab,
    openedTab,
    status,
    documents,
    HandleClose,
    candidateInfo,
    show,
    defaultComplianceActiveKey
}){

    return(
        <>
        <Modal onHide={HandleClose} show={show} size='xl'>
            {assignments?
            <>
            <Modal.Header closeButton>
                <Modal.Title>{candidateInfo.FirstName+' '+candidateInfo.LastName+' | '+candidateInfo.EmployeeID}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='vh80'>
                <Row>
                    <Col lg={4}>
                        <ContactInformation candidateInfo={candidateInfo} openInNewTab={openInNewTab} />
                    </Col>
                    <Col>
                    <Tabs defaultActiveKey={openedTab}>
                        <Tab eventKey="1" title={<div>Statuses<Badge bg="secondary">{status.length}</Badge></div>}>
                            <Statuses status={status} candidateInfo={candidateInfo} />
                        </Tab>

                        <Tab eventKey="2" title={<div>Onboarding <Badge bg="secondary">{onboarding.length}</Badge></div>}>
                            <Onboarding defaultActiveKey={defaultComplianceActiveKey} candidateInfo={candidateInfo} onboarding={onboarding} />
                        </Tab>
                        
                        <Tab eventKey="4" title={<div>Messages<Badge bg="secondary">{messages.length}</Badge></div>}>
                            {messages?
                                <Messages messages={messages} />
                            :
                                <h6>No Messages</h6>
                            }
                        </Tab>

                        <Tab eventKey="5" title={<div>Work<Badge bg="secondary">{workHistory.length}</Badge></div>}>
                            {workHistory?
                                <Work workHistory={workHistory} />
                            :
                                <h6>No work history</h6>
                            }
                        </Tab>

                        <Tab eventKey="3" title={<div>Assignments<Badge bg="secondary">{assignments.length}</Badge></div>}>
                            {assignments?
                                <Assignments assignments={assignments} openInNewTab={openInNewTab} />
                            :
                                <h6>No assignments</h6>
                            }
                        </Tab>

                        <Tab eventKey="6" title="Compliance">
                            <Compliance documents={documents} />
                        </Tab>

                    </Tabs>
                    </Col>
                    {/* <Col>  
                        <Accordion defaultActiveKey={`${openedTab}`}>
                            <Accordion.Item eventKey="Contact">
                                <Accordion.Header>Contact</Accordion.Header>
                                <Accordion.Body>
                                    <Contact candidateInfo={candidateInfo} />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Candidate Statuses <Badge bg="secondary">{status.length}</Badge></Accordion.Header>
                                <Accordion.Body className='no-padding status-wrapper'>
                                    <Statuses status={status} candidateInfo={candidateInfo} />
                                </Accordion.Body>
                            </Accordion.Item> 

                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Onboarding <Badge bg="secondary">{onboarding.length}</Badge></Accordion.Header>
                                <Accordion.Body>
                                    <Onboarding defaultActiveKey={defaultComplianceActiveKey} candidateInfo={candidateInfo} onboarding={onboarding} />
                                </Accordion.Body>
                            </Accordion.Item>  

                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Messages<Badge bg="secondary">{messages.length}</Badge></Accordion.Header>
                                <Accordion.Body className='status-wrapper no-padding'>
                                    {messages?
                                        <Messages messages={messages} />
                                    :
                                        <h6>No Messages</h6>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Work<Badge bg="secondary">{workHistory.length}</Badge></Accordion.Header>
                                <Accordion.Body className='no-padding'>
                                    {workHistory?
                                        <Work workHistory={workHistory} />
                                    :
                                        <h6>No work history</h6>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Assignments<Badge bg="secondary">{assignments.length}</Badge></Accordion.Header>
                                <Accordion.Body className='no-padding'>
                                    {assignments?
                                        <Assignments assignments={assignments} openInNewTab={openInNewTab} />
                                    :
                                        <h6>No assignments</h6>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Compliance</Accordion.Header>
                                <Accordion.Body>
                                    <Compliance documents={documents} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col> */}
                </Row>
            </Modal.Body>
            </>
            :
            <>
            <Modal.Header closeButton>
                {/* <Modal.Title>{candidateInfo.FirstName+' '+candidateInfo.LastName+' | '+candidateInfo.EmployeeID}</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <Spinner animation="border" />
            </Modal.Body>
            <Modal.Footer>
                <Button size='lg' variant="secondary" onClick={HandleClose}>
                    Close
                </Button>
            </Modal.Footer>
            </>
            }
        </Modal>
        </>
    )
}

export default CandidateInfoPopup;