import { AgGridReact } from "ag-grid-react";

export default function ErrorMergeGrid({
    delayedMergeRows,
    mergeCols,
    defaultColDef,
    rowHighlight,
    definedMismatchError
}){
    const onFirstDataRendered = (params) => { 
        params.columnApi.autoSizeAllColumns();
    };

    return (
        <>
            <div className="card oap-card">
                <div className="labelled-loader totals-mismatch-warning">
                    <h5 className="label-gap">Merged Data - </h5>
                    <p>{definedMismatchError}</p>
                </div>
                <div className="ag-theme-balham ag-border-dark">
                    <AgGridReact
                        rowData={delayedMergeRows}
                        onFirstDataRendered={onFirstDataRendered}
                        columnDefs={mergeCols}
                        defaultColDef={defaultColDef}
                        suppressFieldDotNotation={true}
                        rowClassRules={rowHighlight}
                    />
                </div>
            </div>
        </>
    )
}