import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import Button from 'react-bootstrap/Button'
import { BsDownload } from "react-icons/bs";
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner'
import ReactSelect from 'react-select';
import EmployeeLevelViewWarning from './EmployeeLevelViewWarning';
import './SB1162.css'

function SB1162View({
    data,
    endDate,
    setEndDate,
    defaultColDef,
    columnDefs,
    onGridReady,
    onBtnExport,
    customers,
    weekendDates,
    selectedCustomer,
    setSelectedCustomer,
    advancedData,
    advancedColumnDefs,
    activeKey,
    setActiveKey,
    HandleKeyChange,
    openPopup,
    setOpenPopup,
    loadingBasic,
    loadingAdvanced,
    branches,
    HandleBranchChange,
    selectedBranches,
    unformattedCustomers,
    overviewColumnDefs
}) {
  return (
    <>
        {openPopup&&<EmployeeLevelViewWarning show={openPopup} setShow={setOpenPopup} setActiveKey={setActiveKey} />} 
        <div className='header-card'>
            <div className='four-column-div'>
                <h3>SB1162 Dashboard</h3>
                <div className='double-select'>
                    <Form.Label className='label-gap'>Branches:</Form.Label>
                    {branches?
                    <ReactSelect isMulti options={branches} value={selectedBranches} onChange={(e)=>HandleBranchChange(e)} />
                    :
                    <Spinner animation='border' size='sm' />
                    }
                </div>
                <div className='double-select'>
                    <Form.Label className='label-gap'>Customer:</Form.Label>
                    {customers?
                    <ReactSelect options={customers} value={selectedCustomer} onChange={(e)=>setSelectedCustomer(e)} />
                    :
                    <Spinner animation='border' size='sm' />
                    }
                </div>
                <div className='double-select'>
                    <Form.Label className='label-gap'>Week:</Form.Label>
                    {weekendDates?
                        <ReactSelect className='label-gap' options={weekendDates} value={endDate} onChange={(e)=>setEndDate(e)} />
                    :
                        <Spinner animation='border' size='sm' />
                    }
                    <Button variant='secondary' onClick={() => onBtnExport()}>
                        {(loadingBasic||loadingAdvanced)?
                            <Spinner animation='border' size='sm' />
                        :
                            <BsDownload/>
                        }
                    </Button>
                </div>
            </div>
            <div className='left-align'>
                <Button
                    className='module-button'
                    onClick={()=>HandleKeyChange('compliance')}
                >Overview</Button>
                <Button
                    className='module-button'
                    onClick={()=>HandleKeyChange('basic')}
                >Standard Report</Button>
                <Button
                    className='module-button'
                    onClick={()=>HandleKeyChange('advanced')}
                >Employee Detail</Button>
            </div>
        </div>

        <div className='card oap-card'> 
            {(activeKey==='compliance')&&
            <div id="myGrid" className="ag-theme-alpine grid filter-grid">
                <AgGridReact
                    multiSortKey={'ctrl'}
                    suppressExcelExport={true}
                    popupParent={document.body}
                    defaultColDef={defaultColDef}
                    rowData={unformattedCustomers}
                    columnDefs={overviewColumnDefs}
                    onGridReady={onGridReady}
                ></AgGridReact>
            </div>
            }

            {(activeKey==='basic')&&
            <>
            {selectedCustomer?
            <div id="myGrid" className="ag-theme-alpine grid filter-grid">
                <AgGridReact
                    multiSortKey={'ctrl'}
                    suppressExcelExport={true}
                    popupParent={document.body}
                    defaultColDef={defaultColDef}
                    rowData={data}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                ></AgGridReact>
            </div>
            :
            <h3>Please select a customer</h3>
            }
            </>
            }

            {(activeKey==='advanced')&&
            <>
            {selectedCustomer?
            <div id="myGrid" className="ag-theme-alpine grid filter-grid">
                <AgGridReact
                    multiSortKey={'ctrl'}
                    suppressExcelExport={true}
                    popupParent={document.body}
                    defaultColDef={defaultColDef}
                    rowData={advancedData}
                    columnDefs={advancedColumnDefs}
                    onGridReady={onGridReady}
                ></AgGridReact>
            </div>
            :
            <h3>Please select a customer</h3>
            }
            </>
            }
        </div>
    </>
  )
}

export default SB1162View