import { FormatDecimalHundredths } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function CalculateTotals(
    employeeDefs,
    weeklyCalcs,
    tcTotalsSetters
){
    let calculatedTimecards = [];

    let caCalcTimecards = [];
    let calculatedCA = [];

    // let nvCalcTimecards = [];
    // let calculatedNV = [];

    let straightCalcTimecards = [];
    let calculatedStraight = [];

    let standardCalcTimecards = [];
    let calculatedStandard = [];

    weeklyCalcs.forEach(timecard=>{
        let foundDef = employeeDefs.find(def=> def.LookupID === timecard.IdNumber);
        if(foundDef!==undefined){
            timecard.AssignmentID = foundDef.AssignmentID;
            timecard.EmployeeID = foundDef.EmployeeID;
            timecard.WeekEndDay = foundDef.WeekEndDay;
            timecard.OTLaw = foundDef.otLaw;
            if(foundDef.otLaw === "CA"){caCalcTimecards.push(timecard);}
            // else if(foundDef.otLaw === "NV"){nvCalcTimecards.push(timecard);}
            else if(foundDef.otLaw === "Straight"){
                straightCalcTimecards.push(timecard);
            }
            else{standardCalcTimecards.push(timecard);}
        }
    });

    calculatedCA = caCalcTimecards ? CaliCalcTotals(caCalcTimecards) : [];
    // calculatedNV = nvCalcTimecards&&nvCalcTimecards.length ? 
    calculatedStraight = straightCalcTimecards ? StraightCalc(straightCalcTimecards) : [];
    calculatedStandard = standardCalcTimecards ? StandardCalc(standardCalcTimecards) : [];
    calculatedTimecards = calculatedCA.concat(calculatedStraight.concat(calculatedStandard));
    
    let reg = 0;
    let ot = 0;
    let dt = 0;
    let mp = 0;

    calculatedTimecards.forEach(timecard => {
        reg += timecard.Reg;
        ot += timecard.OT;
        dt += timecard.DT;
        mp += timecard.MP;
    });

    let totalHours = FormatDecimalHundredths(reg + ot + dt + mp);
    reg = FormatDecimalHundredths(reg);
    ot = FormatDecimalHundredths(ot);
    dt = FormatDecimalHundredths(dt);
    mp = FormatDecimalHundredths(mp);

    tcTotalsSetters.setTimecardHoursTotal(totalHours);
    tcTotalsSetters.setTimecardReg(reg);
    tcTotalsSetters.setTimecardOT(ot);
    tcTotalsSetters.setTimecardDT(dt);
    tcTotalsSetters.setTimecardMP(mp);

    return calculatedTimecards;
}

// calculates based on Fedaral OT Laws
function StandardCalc(weeklyTotals){
    let assignments = [...new Set(weeklyTotals.map(timecard=> timecard.IdNumber))];
    let calculatedHours = [];

    assignments.forEach(assignment => {
        let reg = 0;
        let ot = 0;
        let employee;
        let idType;
        let customerID;
        let employeeID;
        let assignmentId;
        let weDate;
    
        weeklyTotals.forEach((row)=>{
            if (row.IdNumber === assignment){
                reg += row.Reg;
                ot += row.OT;
                employee = row.EmployeeName;
                idType = row.IdType;
                customerID = row.CustomerID;
                employeeID = row.EmployeeID;
                assignmentId = row.AssignmentID;
                weDate = row.WeekEndDay;
            }
        })

        let calcReg = reg > 40 ? 40 : reg;
        let calcOt = reg > 40 ? ot + reg - 40 : ot;
        let assignmentHours = {
            EmployeeName: employee,
            LookupID: assignment,
            IdType: idType,
            CustomerID: customerID,
            EmployeeID: employeeID,
            AssignmentID: assignmentId,
            WeekEndDay: weDate,
            Reg: calcReg,
            OT: calcOt,
            DT: 0,
            MP: 0
        }
        if(assignment!==''){calculatedHours.push(assignmentHours);}
    });
    return calculatedHours;
};

// takes the existing totals and applies them straight across, without extra calculation
function StraightCalc(weeklyTotals){
    let assignments = [...new Set(weeklyTotals.map(timecard=> timecard.IdNumber))];
    let calculatedHours = [];

    assignments.forEach(assignment => {
        let reg = 0;
        let ot = 0;
        let dt = 0;
        let employee;
        let idType;
        let customerID;
        let employeeID;
        let assignmentId;
        let weDate;
    
        weeklyTotals.forEach((row)=>{
            if (row.IdNumber === assignment){
                reg += row.Reg;
                ot += row.OT;
                dt += row.DT ? row.DT : 0;
                employee = row.EmployeeName;
                idType = row.IdType;
                customerID = row.CustomerID;
                employeeID = row.EmployeeID;
                assignmentId = row.AssignmentID;
                weDate = row.WeekEndDay;
            }
        })

        let assignmentHours = {
            EmployeeName: employee,
            LookupID: assignment,
            IdType: idType,
            CustomerID: customerID,
            EmployeeID: employeeID,
            AssignmentID: assignmentId,
            WeekEndDay: weDate,
            Reg: reg,
            OT: ot,
            DT: dt,
            MP: 0
        }
        if(assignment!==''){calculatedHours.push(assignmentHours);}
    });
    return calculatedHours;
}

// calculates based on California OT Law
function CaliCalcTotals(weeklyTotals){
    let assignments = [...new Set(weeklyTotals.map(timecard=> timecard.IdNumber))];
    let calculatedHours = [];

    assignments.forEach(assignment => {
        let reg = 0;
        let ot = 0;
        let dt = 0;
        let mp = 0;
        let employee;
        let idType;
        let customerID;
        let employeeID;
        let assignmentId;
        let weDate;

        weeklyTotals.forEach((row)=>{
            if (row.IdNumber === assignment){
                reg += row.Reg;
                ot += row.OT;
                dt += row.DT ? row.DT : 0;
                mp += row.MP ? row.MP : 0;
                employee = row.EmployeeName;
                idType = row.IdType;
                customerID = row.CustomerID;
                employeeID = row.EmployeeID;
                assignmentId = row.AssignmentID;
                weDate = row.WeekEndDay;
            }
        });

        let calcReg = reg > 40 ? 40 : reg;
        let calcOt = reg > 60 ? 20 : reg > 40 ? ot + reg - 40 : ot;
        let calcDt = reg > 60 ? dt + reg - 60 : dt;

        let assignmentHours = {
            EmployeeName: employee,
            LookupID: assignment,
            IdType: idType,
            CustomerID: customerID,
            EmployeeID: employeeID,
            AssignmentID: assignmentId,
            WeekEndDay: weDate,
            Reg: calcReg,
            OT: calcOt,
            DT: calcDt,
            MP: mp
        };

        calculatedHours.push(assignmentHours);
    });
    return calculatedHours;
}