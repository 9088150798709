import React from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Assignments({ 
    assignments,
    openInNewTab
 }) {

  return (
    <Card className='status-wrapper'>
        {assignments.map((assignment,index)=>{
            return <Card key={'assignments'+index} className='padding margin-bottom'>
                        <Row>
                            <Col sm={9}>
                                <Row>
                                    <p><b>{assignment.CustomerName}</b> | {assignment.JobTitle}</p>
                                </Row>
                                <Row>
                                    <p><b>Start:</b> {assignment.StartDate.split('T')[0]} | <b>End:</b> {(assignment.EndDate?assignment.EndDate.split('T')[0]:'None')} | <b>Expected End:</b> {(assignment.EndDate?assignment.EndDate.split('T')[0]:'None')}</p>
                                </Row>
                                <Row>
                                    <p><b>Performance Code: </b>{assignment.PerfCode}</p>
                                </Row>
                            </Col>
                            <Col className='assignment-button-group'>
                                <Row>
                                    <Button onClick={()=>openInNewTab(`https://beyond.ontempworks.com/assignment/${assignment.AssignmentID}`)} className='margin-bottom' >Open Assignment</Button>
                                    <Button variant='secondary' onClick={()=>openInNewTab(`https://beyond.ontempworks.com/customer/${assignment.CustomerID}`)} >Open Customer</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
        })}
    </Card>
  )
}

export default Assignments