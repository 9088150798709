import { useContext, useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import UploadDocuments from './UploadDocuments';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';

function Documents({ employeeID }) {
    const { cookies } = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;
    const [selectedDocument, setSelectedDocument] = useState();
    const { data: documents, loading: loadingDocuments, error } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetEmployeeDocumentList?Token=${cookies.get('customer').Token}&EmployeeID=${employeeID}`, cookies)
    const { data: downloadableDocument } = useFetchGet((selectedDocument&&`${REACT_APP_API_URL}/utilities/downloademployeedoc?token=${cookies.get('customer').Token}&employeeID=${employeeID}&docid=${selectedDocument.DocId}`), cookies)
    const [showUploadDocumentPopup,setShowUploadDocumentPopup] = useState(false);

    useEffect(()=>{
        if(downloadableDocument){
            createAndDownloadBlobFile(base64ToArrayBuffer(downloadableDocument.EncodedDocObject), downloadableDocument.DocName, downloadableDocument.FileType)
        }
    // eslint-disable-next-line
    },[downloadableDocument])

    function base64ToArrayBuffer(base64) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map(( byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename, extension) {
        const blob = new Blob([body]);
        const fileName = `${filename}.${extension}`;
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    

  return (
    <div className='vh20'>
        {showUploadDocumentPopup&&<UploadDocuments show={showUploadDocumentPopup} setShow={setShowUploadDocumentPopup} employeeID={employeeID} />}
            
        <Button className='margin-bottom' onClick={()=>setShowUploadDocumentPopup(true)}>Upload Documents</Button>
        {loadingDocuments&&
            <Spinner animation='border' />
        }

        {(documents&&documents.length>0)?
            documents.map(document=>{
                return <Card className='margin-bottom'>
                            <Card.Body>
                                <Row>
                                    <Col><p><b>Document:</b></p> <p className='linked-cell' onClick={()=>setSelectedDocument(document)}>{document.DocName}</p></Col>
                                    <Col><p><b>Description:</b></p> <p>{document.DocDescription}</p></Col>
                                    <Col><p><b>Document Date:</b></p> <p>{document.DocumentDate&&document.DocumentDate.split('T')[0]}</p></Col>
                                    <Col><p><b>Expiration Date:</b></p> <p>{document.ExpirationDate&&document.ExpirationDate.split('T')[0]}</p></Col>
                                </Row>
                            </Card.Body>
                        </Card>
            })
        :
        error?
        <Alert variant='warning'>
            <p>There was an issue getting data, please try again</p>
            <p>{error}</p>
        </Alert>
        :
        <Alert>No document history</Alert>
        }
    </div>
  )
}

export default Documents