import WeekAndRowSelectionView from "./WeekAndRowSelectionView"

export default function WeekAndRowSelection({
    selectedWEDate,
    setSelectedWEDate,
    firstDataRow,
    setFirstDataRow,
    customSelectStyles
}){
    const weekEndingDates = [
        {value: "Friday", label: "Friday"},
        {value: "Saturday", label: "Saturday"},
        {value: "Sunday", label: "Sunday"}
    ];

    return(
        <>
        <WeekAndRowSelectionView
            weekEndingDates={weekEndingDates}
            selectedWEDate={selectedWEDate}
            setSelectedWEDate={setSelectedWEDate}
            firstDataRow={firstDataRow}
            setFirstDataRow={setFirstDataRow}
            customSelectStyles={customSelectStyles}
        />
        </>
    )
}