import React, { useContext, useState } from 'react'
import useFetch from 'Components/CustomComponents/Fetch/useFetch';
import Spinner from 'react-bootstrap/Spinner';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert } from 'react-bootstrap';

function ConfirmDeletePopup({
    show,
    setShow,
    labelID
}) {
    const [canPost, setCanPost] = useState(false);
    const { REACT_APP_API_URL } = process.env;
    const { cookies } = useContext(CustomerContext)
    const { status, loading } = useFetch((canPost&&`${REACT_APP_API_URL}/Candidates/DeleteCustomLabel?Token=${cookies.get('customer').Token}&LabelID=${labelID}`),'DELETE')

    return(
        <Modal show={show} onHide={setShow}>
            <Modal.Header closeButton>
                <h3>Confirm Delete</h3>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this list?</p>
                {(status&&status===400)?
                    <Alert variant='warning'>There was an issue, please try again</Alert>
                :
                 ''   
                }
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <Button variant='secondary' className='full-width' onClick={()=>setShow(false)}>Close</Button>
                    </Col>
                    <Col>
                    {(status&&status===200)?
                        <Alert>Successfully deleted the list</Alert>
                    :
                        <Button variant='danger' className='full-width' onClick={()=>setCanPost(true)}>{loading?<Spinner animation='border' size='sm' />:'Delete'}</Button>
                    }
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmDeletePopup