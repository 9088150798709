import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

export default function TextingGrid({
    rowData,
    columnDefs,
    setGridApi
}) {
    // eslint-disable-next-line
    const [defaultColDef] = useState({
        sortable: true,
        resizable: true,
        filter: true,       
    });

     function HandleClick(e){
        if(e.colDef.field==='EmployeeID'){
            openInNewTab('https://beyond.ontempworks.com/employee/'+e.data.EmployeeID)
        }else if(e.colDef.field==='AssignmentID'){
            openInNewTab('https://beyond.ontempworks.com/assignment/'+e.data.AssignmentID)
        }else if(e.colDef.field==='CellPhone'){
            window.open('tel:'+e.data.CellPhone)
        }else if(e.colDef.field==='HomePhone'){
            window.open('tel:'+e.data.HomePhone)
        }else if(e.colDef.field==='Email'){
            window.open('mailto:'+e.data.Email)
        }
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const onGridReady = (params) => { 
        // params.columnApi.autoSizeAllColumns();
        params.columnApi.autoSizeAllColumns();
        setGridApi(params.api)
     }

    const onFirstDataRendered = (params) => {
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns();
    }

  return (
    <>
      <div className='card oap-card'>
          <div id="myGrid" className="ag-theme-alpine">
              <AgGridReact
                  defaultColDef={defaultColDef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  onGridReady={onGridReady}
                  suppressExcelExport={true}
                  popupParent={document.body}
                  onCellClicked={HandleClick}
                  onFirstDataRendered={onFirstDataRendered}
              ></AgGridReact>
          </div>
      </div>
    </>
  )
}