import { GetDocCounts } from "../../CallApi";

export default function HandleCountSearch(
  e, setFormListData, cookies, setLoading, selectedDocType, selectedFormName
){
  e.preventDefault();
  setLoading(true);
  const formData = new FormData(e.currentTarget);
  const fileName = formData.get("searchString")?.toString();
  const docTypeId = selectedDocType ? selectedDocType.value.toString() : 0;
  const formName = selectedFormName ? selectedFormName.label.toString() : null;

  const searchString = fileName && fileName !== "" ? fileName : formName;
  console.log(searchString);

  const paramsFilled = searchString || docTypeId !== 0;

  if (paramsFilled) {
    GetDocCounts(cookies, searchString, docTypeId).then(forms => {
      setFormListData(forms);
      setLoading(false);
    });
  } else {
    setLoading(false);
    window.alert(`File Name, Form Name, or Doc Type must be included for search. Please fill out form correctly and try again.`, "Okay");
  }
}

