import { PatchOapData } from "./CallAPI";
import { GetAgingCustomer, GetInvoiceAmount, GetInvoiceBalance, GetRowID } from "./ValueGetters/GetReconcileGridValues";
import { GetMinimumOapBalance } from "./ValueGetters/GetUpdatedBalances";


export default function LoadMatched(
    oapRowData,
    selectedOapRowData,
    matchedInvoices,
    cookies,
    dataHold
) {

    let summedInvoices = [];
    matchedInvoices.forEach(invoice => {
        const myInvoicesObj = {
            ...selectedOapRowData[0],
            OAPRowID: 0
        };

        myInvoicesObj.PayAmount = Number(invoice.PayAmount);
        myInvoicesObj.InvoiceNumber = invoice.InvoiceNumber;

        let duplicateInvoice = summedInvoices.find(summed => summed.InvoiceNumber === invoice.InvoiceNumber)
        if (duplicateInvoice !== undefined) {
            duplicateInvoice.PayAmount += Number(invoice.PayAmount)
        } else {
            summedInvoices.push(myInvoicesObj);
        }
    });

    summedInvoices.forEach((invoice) => {

        invoice.OAPBalance = GetMinimumOapBalance(invoice, [...oapRowData, ...summedInvoices]); // in line only oap amount - pay amount
        invoice.InvoiceBalance = GetInvoiceBalance(invoice, dataHold);
        invoice.InvoiceAmount = GetInvoiceAmount(invoice, dataHold);
        invoice.AgingCustomer = GetAgingCustomer(invoice, dataHold);
        invoice.ROWID = GetRowID(invoice);

        PatchOapData(invoice, cookies);
    });
}
