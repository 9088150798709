import React, {useState, useContext, useEffect} from "react";
import useFetchGet from "Components/CustomComponents/Fetch/useFetchGet";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import Select from 'react-select';

export default function ContactList({
  id,
  emailViewParams
}){
  // connect to API to get contacts for customer 
  const { cookies } = useContext(CustomerContext);
  const { REACT_APP_API_URL } = process.env;
  const { data: contacts } = useFetchGet(`${REACT_APP_API_URL}/compliance/getcustomerinvoicecontact?token=${cookies.get('customer').Token}&customerId=${emailViewParams.selectedCustomerID}`, cookies);
  
  // builds the contacts to send to based on recipient type
  const [emailList, setEmailList] = useState();
  const [mailto, setMailto] = useState([]);
  const [mailNames, setMailNames] = useState([]);

  const abortController = new AbortController();

  //setting up the contacts dropdown
  useEffect(()=>{
    if (contacts) {
      // pushes a contact to each contact list -- change to AR when prod-ready
      contacts.push({
        CustomerID: 46978,
        FirstName: "AR",
        LastName: "Tester",
        Phone: "",
        Email: "jcampbell@employnet.com",
        Cellphone: "",
        OfficePhone: "",
        Title: "Test Account",
        ContactID: 24668,
        HierID: 68,
        IsInvoiceRecipient: 'true',
        FullName: "Jeff Test",
      });
      
      contacts.push({
        CustomerID: 46978,
        FirstName: "Accounts Receivables",
        LastName: "Employnet",
        Phone: "",
        Email: "ar@employnet.com",
        Cellphone: "",
        OfficePhone: "",
        Title: "Employnet AR",
        ContactID: 24767,
        HierID: 68,
        IsInvoiceRecipient: 'true',
        FullName: "Accounts Receivables Employnet",
      });

      let formattedEmails = contacts.map((email)=>{
        let disableValue;
        let emailLabel;
        
        if (email.Email===null){
          disableValue=true;
          emailLabel=email.Title + ": " + email.FullName + " *NO EMAIL";
        }else{
          disableValue=false;
          emailLabel=email.Title + ": " + email.FullName
        }

      return {value:email.Email,
        label:emailLabel,
        identity:email.ContactID,
        name:email.FullName,
        isDisabled:disableValue}
      });
      setEmailList(formattedEmails);
    };

    return ()=>{
      abortController.abort();
    };
    // eslint-disable-next-line
  },[contacts]);

  // function adds contacts to send to through Select component
  const HandleEmailChange = (id, e) => {
    let emailToAdd = e[e.length-1].value;
    let nameToAdd = e[e.length-1].name;
    let idToAdd = e[e.length-1].identity;
    let mailObj = {email: emailToAdd, name: nameToAdd, id: idToAdd}
    let mailArray = [...mailto];
    mailArray.unshift(mailObj)
    let addressArray = [...mailNames]
    addressArray[addressArray.length] = [`${emailToAdd}`]
    setMailto(mailArray);
    setMailNames(addressArray);

    if(id==="to"){
      emailViewParams.sendTo.current = mailArray;
      emailViewParams.namesTo.current = addressArray;
    } else if (id==="cc"){
      emailViewParams.sendCC.current = mailArray;
      emailViewParams.namesCC.current = addressArray;
    } else if (id==="bcc"){
      emailViewParams.sendBCC.current = mailArray;
      emailViewParams.namesBCC.current = addressArray;
    }
  };

  return (
    <div>
      <Select
        closeMenuOnSelect={false}
        options={emailList}
        placeholder='Click to add contacts'
        isMulti
        onChange={(e)=>HandleEmailChange(id, e)}
      />     
    </div>
  );
};