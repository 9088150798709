import React, { useContext, useState } from 'react'
import UpdateEverifyPopup from './UpdateEverifyPopup'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';

function UpdateEverifyPopupLogic({ show, setShow, candidateInfo }) {
    const [isApproved, setIsApproved] = useState();
    const [notes, setNotes] = useState();
    const [everify, setEverify] = useState();
    const [date, setDate] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const {REACT_APP_API_URL} = process.env;
    const {cookies} = useContext(CustomerContext)

    const HandleSubmit = (event) => { 
      let postObj = {
        EmployeeID: candidateInfo.EmployeeID,
        IsApproved: isApproved,
        Type: everify,
        Date: date+'T00:00:00.000',
        Notes: notes
      }
      if(ValidatePostObj()){
        setLoading(true);
        PostEverifyResponse(cookies.get('customer').Token, postObj);
      }
     }
    
     const ValidatePostObj = () => { 
      if(typeof isApproved === 'undefined'){
        setError('Please select a status')
        return false;
      }else if(!date){
        setError('Please add a date')
        return false;
      }else if(!everify){
        setError('Please select a drug test type')
        return false;
      }else{
        return true;
      }
   }

    const PostEverifyResponse = (token, postObj) => { 
      if(!cookies.get('customer').Token){
            window.location.reload();
        }
        fetch(`${REACT_APP_API_URL}/Compliance/PostEverifyResponse?token=${token}`,{
      method: 'POST',
      headers: {
          'Content-Type':'application/json'
      },
      body: JSON.stringify(postObj)
      })
      .then(res=>{
          if(res.status===200){
            setSubmitted(true)
            setLoading(false)
          }else if(res.status===401){
              cookies.remove('customer')
              window.location.reload()
          }else if(!res.ok){
              throw Error(res.statusText)
          }
      })
      .catch(error=>{
          setLoading(false);
          console.error(error)
          window.alert(error)
      })
     }

    const HandleChange = (event, fieldName) => { 
      setError(null);
      if(fieldName==='Everify'){
        setEverify(event.target.value)
      }else if(fieldName==='Date'){
        let newDate = event.target.value
        setDate(newDate)
      }else{
        setNotes(event.target.value)
      }
     }

  return (
    <UpdateEverifyPopup
      show={show}
      setShow={setShow}
      setNotes={setNotes}
      setIsApproved={setIsApproved}
      loading={loading}
      HandleSubmit={HandleSubmit}
      HandleChange={HandleChange}
      error={error}
      submitted={submitted}
    ></UpdateEverifyPopup>
  )
}

export default UpdateEverifyPopupLogic