export default function GetUpdatedMergeRows(matchRowData, mergeData, setMergeData) {
  let newRows = [];
  mergeData.forEach(row => {
    let employeeRow = matchRowData.find((matchRow) => matchRow.LookupID === row.IdNumber);
    if (employeeRow !== undefined) {
      let newRow = {
        'EmployeeName': row.EmployeeName ? row.EmployeeName : row.LastName + ', ' + row.FirstName,
        'AssignmentID': employeeRow.AssignmentID,
        'EmployeeID': employeeRow.EmployeeID,
        'Reg': employeeRow.Reg,
        'OT': employeeRow.OT,
        'DT': employeeRow.DT ? employeeRow.DT : 0,
        'MP': employeeRow.MP ? employeeRow.MP : 0,
        ...row
      };
      newRows.push(newRow);
    }
  });
  setMergeData(newRows);
}