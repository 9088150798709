import React, { useState } from 'react'
import TextingGrid from '../TextingGrid/TextingGrid';

export default function Birthdays({setGridApi, rowData}) {
  const [columnDefs] = useState([
    {field:'EmployeeID', headerName:'EE ID', cellClass:'linked-cell'},
    {headerName:'Message Sent', valueFormatter: (params)=>BeenContacted(params)},
    {headerName:'Can Recieve', valueFormatter: (params)=>CanRecieve(params)},
    {field:'FirstName'},
    {field:'LastName'},
    {field:'CalendarBirthDate',headerName:'Birthday', valueFormatter:(params)=>FormatDate(params)},
    {field:'CellPhone', cellClass:'linked-cell'},
    {field:'TextOk',headerName:'OK to Text', valueFormatter:(params)=>FormatYesNo(params)},
    {field:'TextDate',headerName:'Last Text Date', valueFormatter: (params)=>FormatDate(params)},
    {field:'Email', cellClass:'linked-cell'},
    {field:'EmailOk', headerName:'OK to Email', valueFormatter:(params)=>FormatYesNo(params)},
    {field:'EmailDate',headerName:'Last Email Date', valueFormatter: (params)=>FormatDate(params)}
  ]);

  const BeenContacted = (params) => { 
    if (params.data.EmailDate||params.data.TextDate){return 'Yes';}
    else {return 'No';}
  }

  const CanRecieve = (params) => { 
    if((params.data.Email&&params.data.EmailOk)||(params.data.CellPhone&&params.data.TextOk)){return 'Yes';}
    else {return 'No';}
  }

  const FormatDate = (params) => { 
    if(params.value) return params.value.split('T')[0]
  }

  const FormatYesNo = (params) => { 
    if(params.value===false){return 'No';}
    else if(params.value===true){return 'Yes';}
  }

  return (
    <TextingGrid
      rowData={rowData}
      columnDefs={columnDefs}
      setGridApi={setGridApi}
    />
  )
}