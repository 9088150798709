import React from 'react'
import { Col, Form,  Row } from 'react-bootstrap'
import ReactSelect from 'react-select';
import './Job.css'

function Job({ jobDescription, formattedJobTitles, HandleJobTitleChange, HandleDateChange, HandleJobDescChange, defaultFormData }) {
  return (
    <>
    <Form.Label>Job Title</Form.Label>
    {formattedJobTitles&&
    <ReactSelect options={formattedJobTitles} onChange={HandleJobTitleChange} defaultValue={(defaultFormData&&{label:defaultFormData.JobTitle})} />
    }
    
    <Form.Label>Job Description</Form.Label>
    <Form.Control defaultValue={jobDescription} as='textarea' className='margin-bottom text-area' onBlur={HandleJobDescChange}  />

    <Row>
      <Col>
        <Row><Form.Label>Expected Start Date<span className='red-text'>*</span></Form.Label></Row>
        <Row><Form.Control type='date' onChange={(e)=>HandleDateChange(e, 'StartDate')} /></Row>
      </Col>      
      <Col>
        <Row><Form.Label>Expected Assignment End Date<span className='red-text'>*</span></Form.Label></Row>
        <Row><Form.Control type='date' onChange={(e)=>HandleDateChange(e, 'AssignmentEnd')} /></Row>
      </Col>
    </Row>
    </>
  )
}

export default Job