export default function GetRepAssignment() {
    const repAssignments = [
        { "Customer": "Acro", "Rep": "Jeff" },
        { "Customer": "Adecco", "Rep": "Jeff" },
        { "Customer": "Agile", "Rep": "Jeff" },
        { "Customer": "Anserteam", "Rep": "Jeff" },
        { "Customer": "Sodexo", "Rep": "Jeff" },
        { "Customer": "UNITED-RENTALS ", "Rep": "Jeff" },
        { "Customer": "Careers", "Rep": "Jeff" },
        { "Customer": "Chesapeake", "Rep": "Jeff" },
        { "Customer": "Employnet", "Rep": "Jeff" },
        { "Customer": "Experis", "Rep": "Jeff" },
        { "Customer": "West Valley Staffing", "Rep": "Jeff" },
        { "Customer": "Wonderful", "Rep": "Jeff" },
        { "Customer": "KELLY", "Rep": "Jeff" },
        { "Customer": "Pitney", "Rep": "Jeff" },
        { "Customer": "Eastridge", "Rep": "Jeff" },
        { "Customer": "Ship Network", "Rep": "Jeff" },
        { "Customer": "Cepheid", "Rep": "Jeff" },
        { "Customer": "SIMPLEVMS", "Rep": "Jeff" },
        { "Customer": "Western Digital", "Rep": "Jeff" },
        { "Customer": "Duda", "Rep": "Jeff" },
        { "Customer": "Bill.com", "Rep": "Jeff" },
        { "Customer": "UNITED-RENTALS ", "Rep": "Jeff" },
        { "Customer": "Ulta", "Rep": "Jeff" },
        { "Customer": "Pacific Cheese", "Rep": "Jeff" },
        { "Customer": "NFI", "Rep": "Jeff" },
        { "Customer": "Ted Conference", "Rep": "Jeff" },
        { "Customer": "East Ridge Acres", "Rep": "Jeff" },
        { "Customer": "Annieglass", "Rep": "Jeff" },
        { "Customer": "Ocd Cleaning", "Rep": "Jeff" },
        { "Customer": "Pratt", "Rep": "Jeff" },
        { "Customer": "Barpellam", "Rep": "Jeff" },
        { "Customer": "One WorkPlace", "Rep": "Jeff" },
        { "Customer": "WorkForceLogiq", "Rep": "Jeff" },
        { "Customer": "APC Workforce Solutions", "Rep": "Jeff" },
        { "Customer": "Pro Unlimited", "Rep": "Jeff" },

        { "Customer": "Bender Warehouse", "Rep": "Yleanna" },
        { "Customer": "Bo Enterprises", "Rep": "Yleanna" },
        { "Customer": "California Water", "Rep": "Yleanna" },
        { "Customer": "Chase Online", "Rep": "Yleanna" },
        { "Customer": "Cleantec", "Rep": "Yleanna" },
        { "Customer": "Douglas Emmett", "Rep": "Yleanna" },
        { "Customer": "Driscoll", "Rep": "Yleanna" },
        { "Customer": "Duncan", "Rep": "Yleanna" },
        { "Customer": "ElringKlinger", "Rep": "Yleanna" },
        { "Customer": "Express Messenger", "Rep": "Yleanna" },
        { "Customer": "Fatte Albert`s", "Rep": "Yleanna" },
        { "Customer": "Gillig", "Rep": "Yleanna" },
        { "Customer": "GUSTO", "Rep": "Yleanna" },
        { "Customer": "Habitat For Humanity", "Rep": "Yleanna" },
        { "Customer": "Jo-Ann", "Rep": "Yleanna" },
        { "Customer": "Machabee", "Rep": "Yleanna" },
        { "Customer": "Noble House", "Rep": "Yleanna" },
        { "Customer": "PPG", "Rep": "Yleanna" },
        { "Customer": "Sheehan Genetics", "Rep": "Yleanna" },
        { "Customer": "Unknown", "Rep": "Yleanna" },
        { "Customer": "Western Milling", "Rep": "Yleanna" },
        { "Customer": "White Bottle", "Rep": "Yleanna" },
        { "Customer": "Bay Photo", "Rep": "Yleanna" },
        { "Customer": "Vf", "Rep": "Yleanna" },
        { "Customer": "Ineos", "Rep": "Yleanna" },
        { "Customer": "A-tech Concrete", "Rep": "Yleanna" },
        { "Customer": "Tforce", "Rep": "Yleanna" },
        { "Customer": "Bay Federal", "Rep": "Yleanna" },
        { "Customer": "Harris Spice", "Rep": "Yleanna" },
        { "Customer": "Nxedge", "Rep": "Yleanna" },
        { "Customer": "Shippers Warehouse", "Rep": "Yleanna" },
        { "Customer": "Jcs Marketing", "Rep": "Yleanna" },
        { "Customer": "Fox", "Rep": "Yleanna" },
        { "Customer": "Real Vision", "Rep": "Yleanna" },
        { "Customer": "Marquez", "Rep": "Yleanna" },
        { "Customer": "Joann", "Rep": "Yleanna" },
        { "Customer": "Chargepoint", "Rep": "Yleanna" },
        { "Customer": "Dcg", "Rep": "Yleanna" },
        { "Customer": "Cyberwolf", "Rep": "Yleanna" },
        { "Customer": "Inmusic", "Rep": "Yleanna" },
        { "Customer": "Tanimura", "Rep": "Yleanna" },
        { "Customer": "Cal West", "Rep": "Yleanna" },
        { "Customer": "Wawona", "Rep": "Yleanna" },
        { "Customer": "Rls Logistics", "Rep": "Yleanna" },
        { "Customer": "Cacique", "Rep": "Yleanna" },
        { "Customer": "Ontrac", "Rep": "Yleanna" },
        { "Customer": "ADVANCED PRINTED CIRCUIT TECHNOLOGY", "Rep": "Yleanna" },
        { "Customer": "Alpha Paw", "Rep": "Yleanna" },
        { "Customer": "Nordic Naturals", "Rep": "Yleanna" },
        { "Customer": "Kemper", "Rep": "Yleanna" },
        { "Customer": "Knowles", "Rep": "Yleanna" },
        { "Customer": "Asendia", "Rep": "Yleanna" },
        { "Customer": "Eurasia", "Rep": "Yleanna" },
        { "Customer": "Gt Ventures", "Rep": "Yleanna" },
        { "Customer": "Temco", "Rep": "Yleanna" },
        { "Customer": "Dairy Solutions", "Rep": "Yleanna" },
        { "Customer": "Growers Transplanting", "Rep": "Yleanna" },
        { "Customer": "Unisync", "Rep": "Yleanna" },
        { "Customer": "Fjc", "Rep": "Yleanna" },
        { "Customer": "Dcg", "Rep": "Yleanna" },
        { "Customer": "STITCH FIX", "Rep": "Yleanna" },
        { "Customer": "Armor Guys", "Rep": "Yleanna" },
        { "Customer": "GoboSource", "Rep": "Yleanna" },
        { "Customer": "Super Store", "Rep": "Yleanna" },
        { "Customer": "Parts Unlimited", "Rep": "Yleanna" },
        { "Customer": "D & H Distributing", "Rep": "Yleanna" },
        { "Customer": "Bossard", "Rep": "Yleanna" },
        { "Customer": "Housing Authority", "Rep": "Yleanna" },
        { "Customer": "IxSystems", "Rep": "Yleanna" },
        { "Customer": "Elite Spice", "Rep": "Yleanna" },
        { "Customer": "Saladino`s", "Rep": "Yleanna" },
        { "Customer": "Allen Tel", "Rep": "Yleanna" },
        { "Customer": "Destiny Packaging", "Rep": "Yleanna" },
        { "Customer": "Chapin", "Rep": "Yleanna" },
        { "Customer": "Apc Technologies", "Rep": "Yleanna" },
        { "Customer": "D`Arrigo", "Rep": "Yleanna" },
        { "Customer": "Bal Seal", "Rep": "Yleanna" },
        { "Customer": "Britevision", "Rep": "Yleanna" },
        { "Customer": "Considine", "Rep": "Yleanna" },
        { "Customer": "CSUMB", "Rep": "Yleanna" },
        { "Customer": "FM Industries", "Rep": "Yleanna" },
        { "Customer": "Terrasat Communications", "Rep": "Yleanna" },
        { "Customer": "Paisley Crafts", "Rep": "Yleanna" },
        { "Customer": "Nordic Ice", "Rep": "Yleanna" },
        { "Customer": "Hyve", "Rep": "Yleanna" },
    ];

    return repAssignments;
}