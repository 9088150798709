import * as XLSX from "xlsx";
import { CleanHeaders, GetOriginalTimecardCols, GetOriginalTimecardRows } from "./GetOrinigalTimecardData";
import { GetActiveRows, GetModifiedIDRows } from "./GetActiveGridData";
import { GetKeyNumbers, GetActiveMapHeaders, GetColumnMapObj } from "./GetColumnMapData";
import { GetMergeCols, GetMergeRows } from "./GetMergedGridData";

export default function LoadTimecard(
    e, 
    loaderParams
){
    const timecardFiles = e.target.files;
    const file = timecardFiles[0];
    const reader = new FileReader();
    let idMod = loaderParams.processDefinitions.idModifier;
    let rowIndex = loaderParams.firstDataRow;
    let timecardColumns = loaderParams.loaderColumns;
    
    let timecardHeaderLine = rowIndex -2;

    reader.onload = (evt) => {
        evt.preventDefault();

        const fileRead = evt.target.result;
        const workbook = XLSX.read(fileRead, { type: "binary" });
        const wsName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[wsName];

        const fileData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        let timecardHeaders = fileData[timecardHeaderLine];

        // sets the values for the original timecard grid
        let cleanedHeaders = CleanHeaders(timecardHeaders);
        let timecardColumnFields = GetOriginalTimecardCols(timecardHeaders);
        loaderParams.setOriginalTimecardCols(timecardColumnFields);
        let orignalTimecardData = GetOriginalTimecardRows(fileData, cleanedHeaders);
        loaderParams.setOriginalTimecardRows(orignalTimecardData);

        // sets the values for the active grid
        let activeRows = GetActiveRows(worksheet, rowIndex, timecardColumns);
        let activeRowData = idMod === undefined ? activeRows : GetModifiedIDRows(activeRows, idMod);
        loaderParams.setRowData(activeRowData);

        // sets the values for the column map
        // for each active column, display the active column header, and the timecard header
            // that corresponds to the active column: i.e., if active is Reg, timecard header 
            // may be Reg Hrs, need to save "Reg: Reg Hrs"
        let columnKeys = Object.keys(timecardColumns);
        let columnValues = Object.values(timecardColumns);
        let keyNumbers = GetKeyNumbers(columnKeys);
        let activeMapHeaders = GetActiveMapHeaders(keyNumbers, timecardHeaders);
        let colObj = GetColumnMapObj(columnValues, activeMapHeaders);
        loaderParams.setColumnMap(colObj);

        // sets the values for the Merged Grid
        let pushedMergedCols = GetMergeCols(columnValues, cleanedHeaders);
        loaderParams.setMergeCols(pushedMergedCols);
        let allMergedRows = GetMergeRows(timecardHeaderLine, orignalTimecardData, activeRowData);
        loaderParams.setMergeData(allMergedRows);
            
    }
    reader.readAsBinaryString(file);
}