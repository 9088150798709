import React from 'react'
import CandidatesGridLogic from '../CandidatesGridLogic'
import {Modal, Card, Spinner } from 'react-bootstrap'

const CandidatesGridModalLogic = ({ show, setShow, onRowSelected, rowData, otherColumnDefs, customColumnDefs, currentPageName, customColumnName }) => {
    return (
        <>
          <Modal show={show} onHide={setShow} dialogClassName="modal-90w">
            <Modal.Header closeButton>
                <h3>{currentPageName}</h3>
              </Modal.Header>
              {rowData?
              <>
                {rowData.length>0&&
                <Modal.Body>
                    <CandidatesGridLogic onRowSelected={onRowSelected} rowData={rowData} otherColumnDefs={otherColumnDefs} customColumnDefs={customColumnDefs} customColumnName={customColumnName} />
                </Modal.Body>
                }
              </>
              :
                <Spinner animation='border'/>
              }
              {(rowData&&rowData.length===0)&&
                <Modal.Body>
                    <Card className='text-center margin-top'>
                        <Card.Header>
                            <h3>No Employees here!</h3>
                        </Card.Header>
                        <Card.Body>
                            <p>Use the <a href="/ATS/Candidates/Search">Search</a> tool to find Employees</p>
                        </Card.Body>
                    </Card>
                </Modal.Body>
              }
          </Modal>
      </>
    )
}

export default  CandidatesGridModalLogic;