import { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { CustomerProvider } from 'Components/CustomComponents/Context/CustomerContext';
import { CookiesProvider } from 'react-cookie';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './App.css';

// top level imports
import CacheBuster from './CacheBuster';
import Layout from './Layout/Layout';
import PublicRoute from 'Components/CustomComponents/Auth/PublicRoute';
import PrivateRoute from 'Components/CustomComponents/Auth/PrivateRoute';
import LoginLogic from 'Components/CustomComponents/Login/LoginLogic';
import Privacy from 'Components/CustomComponents/Privacy/Privacy';
import FourZeroFour from './Layout/FourZeroFour';

// ATS pages
import JobOrdersDashboardLogic from 'Components/ATS/Orders/JobOrdersDashboard/JobOrdersDashboardLogic';
import EngagementHome from 'Components/ATS/Engagement/TextingLists/EngagementHome';
import JobOrdersLogic from 'Components/ATS/Orders/JobOrders/JobOrdersLogic';
import CandidaciesByBranchLogic from 'Components/ATS/Candidates/CandidaciesByBranch/CandidaciesByBranchLogic';
import CandidateDashboardLogic from 'Components/ATS/Candidates/CandidateDashboard/CandidateDashboardLogic'
import EmployeeSearch from 'Components/ATS/Candidates/EmployeeSearch/EmployeeSearch';
import CustomListsLogic from 'Components/ATS/Candidates/CustomLists/CustomListsLogic';

// Compliance pages
import NewDocStatus from 'Components/Compliance/NewDocStatus/NewDocStatus';
import SHT from 'Components/Compliance/SHT/SHT';
import Everify from 'Components/Compliance/Everify/Everify';
import Vendor from 'Components/Compliance/Vendor/Vendor';
import Covid from 'Components/Compliance/Covid/Covid';

// Payroll pages
import AssignemntsNotPaid from 'Components/Compliance/Payroll/Reports/AssignmentsNotPaid/AssignmentsNotPaid';
import InvoiceAging from 'Components/Compliance/Payroll/InvoiceAging/InvoiceAging';
import OAPHome from 'Components/Compliance/Payroll/OAPs/Pages/OAPHome';
import PayrollUploads from 'Components/Compliance/Payroll/PayrollUploads/PayrollUploads';

// Reorts pages
import SB1162 from 'Components/Reports/SB1162/SB1162';
import GMReport from 'Components/Reports/GM Report/GMReport';

// Admin pages
import AdminPage from 'Components/Admin/AdminPage';
import ReportSearch from 'Components/Admin/ReportSearch/ReportSearch';

class App extends Component {
  render(){
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
            return (
              <CookiesProvider>
                <CustomerProvider>
                  <BrowserRouter>
                    <Layout>  
                      <Routes>
                        <Route exact path='/' element={<PublicRoute><LoginLogic /></PublicRoute>} />
                        <Route path='/login' element={<PublicRoute><LoginLogic /></PublicRoute>} />
                        <Route path='/Privacy' element={<Privacy />} />
                        <Route path='/Dashboard' element={<PrivateRoute><JobOrdersDashboardLogic /></PrivateRoute>} />
                        
                        <Route path='/ATS/Engagement' element={<PrivateRoute><EngagementHome /></PrivateRoute>} />
                        <Route path='/ATS/Orders/Summary' element={<PrivateRoute><JobOrdersDashboardLogic /></PrivateRoute>} />
                        <Route path='/ATS/Orders/Detail' element={<PrivateRoute><JobOrdersLogic /></PrivateRoute>} />
                        <Route path='/ATS/Candidates' element={<PrivateRoute><CandidaciesByBranchLogic /></PrivateRoute>} />
                        <Route path='/ATS/Candidates/Dashboard' element={<PrivateRoute><CandidateDashboardLogic /></PrivateRoute>} />
                        <Route path='/ATS/Candidates/Search' element={<PrivateRoute><EmployeeSearch /></PrivateRoute>} />
                        <Route path='/ATS/Candidates/List' element={<PrivateRoute><CustomListsLogic /></PrivateRoute>} />

                        <Route path='/Compliance/Dashboard' element={<PrivateRoute><NewDocStatus /></PrivateRoute>} />
                        <Route path='/Compliance/SHT' element={<PrivateRoute><SHT /></PrivateRoute>} />
                        <Route path='/Compliance/Everify' element={<PrivateRoute><Everify /></PrivateRoute>} />
                        <Route path='/Compliance/Vendor' element={<PrivateRoute><Vendor /></PrivateRoute>} />
                        <Route path='/Compliance/Covid' element={<PrivateRoute><Covid /></PrivateRoute>} />
                        
                        <Route path='/Payroll/AssignmentsNotPaid' element={<PrivateRoute><AssignemntsNotPaid /></PrivateRoute>} />
                        <Route path='/Payroll/InvoiceAging' element={<PrivateRoute><InvoiceAging /></PrivateRoute>} />
                        <Route path='/Payroll/OAPs' element={<PrivateRoute><OAPHome /></PrivateRoute>} />
                        <Route path='/Payroll/PayrollUploads' element={<PrivateRoute><PayrollUploads /></PrivateRoute>} />

                        <Route path='/Reports/SB1162' element={<PrivateRoute><SB1162 /></PrivateRoute>} />
                        <Route path='/Reports/GMReport' element={<PrivateRoute><GMReport /></PrivateRoute>} />

                        <Route path='/Admin/Admin' element={<PrivateRoute><AdminPage /></PrivateRoute>} />
                        <Route path='/Admin/DocumentSearch' element={<PrivateRoute><ReportSearch /></PrivateRoute>} />

                        <Route path='*' element={<PrivateRoute><FourZeroFour /></PrivateRoute>} />
                      </Routes>
                    </Layout>
                  </BrowserRouter>
                </CustomerProvider>
              </CookiesProvider>
            )
        }}
      </CacheBuster>
    )
  }
}

export default App;
