import { useEffect, useRef } from "react";
import {FaCheckCircle} from "react-icons/fa";

export default function InstructionSheet({
    selectedCustomer,
    rowData,
    activeReviewed,
    matchRowData,
    sheetsExported
}){
    const instructionEnd = useRef();

    useEffect(()=>{
        if(sheetsExported){
            scrollToEnd();
        }
        // eslint-disable-next-line
    },[sheetsExported]);

    const scrollToEnd = () => {
        if(!instructionEnd.current) return;
        instructionEnd.current.scrollIntoView({behavior: 'smooth'})
    };

    return(
        <>
            <div className="card oap-card ">
                <h5>Payroll Upload Instructions</h5>
                <div className="instruction-list scroll-on-overflow-y overflow-override-x">
                    <div className= "card instructions-card">
                        <p className="instruction-row ">
                            <FaCheckCircle 
                                className={selectedCustomer ? 'inst-checked' : 'inst-unchecked'}
                            /> Select a customer from the dropdown above
                        </p>
                    </div>
                    <div className="card instructions-card">
                        <p className="instruction-row ">
                            <FaCheckCircle 
                                className={rowData && rowData.length ? 'inst-checked' : 'inst-unchecked'}
                            /> Upload the customer's timesheet file
                        </p>
                    </div>
                    <div className="card instructions-card">
                        <p className="instruction-row ">
                            <FaCheckCircle 
                                className={activeReviewed ? 'inst-checked' : 'inst-unchecked'}
                            /> Review the "Active Columns" grid.
                        </p>
                        <ul>
                            <li>Active columns are the select columns from the original spreadsheet which will be used
                                to calculate and assign hours for each employee.
                            </li>
                            <li>If the active columns don't look correct, click on the "Research" tab and review the "Column Mapping" box to verify that 
                                the Active Columns correspond correctly to the Timecard Columns.</li>
                            <li>More detail about the timecard will be provided in the grids below the "Column Mapping" box on the research screen.</li>
                            <li>You may click on the "Timecard" tab at any time to return to this screen and continue creating the upload.</li>
                        </ul>
                    </div>
                    <div className="card instructions-card">
                        <p className="instruction-row ">
                            <FaCheckCircle 
                                className={matchRowData && matchRowData.length ? 'inst-checked' : 'inst-unchecked'}
                            /> Push the "Calculate Hours" button.
                        </p>
                    </div>
                    <div className="card instructions-card">
                        <p className="instruction-row ">
                            <FaCheckCircle 
                                className={sheetsExported ? 'inst-checked' : 'inst-unchecked'}
                            /> Review the Calculated Totals grid
                        </p>
                        <ul>
                            <li>Mismatched employess will be highlighted in yellow.</li>
                            <li>Lookup data will be highlighted in blue. The remaining columns include the data that will be exported to the upload sheet if it is not a mismatch.</li>
                            <li>Hours totals will be displayed in the header toolbar.</li>
                            <li>Any errors found that are not explained by a mismatch will appear in an error box which will replace this instruction readout.</li>
                        </ul>
                    </div>
                    <div className="card instructions-card">
                        <p className="instruction-row ">
                            <FaCheckCircle 
                                className={sheetsExported ? 'inst-checked' : 'inst-unchecked'}
                            /> Push the "Export Sheets" button
                        </p>
                        <ul>
                            <li>This will download the customer's upload sheet, as well as a mismatch sheet if any mismatches were found.</li>
                            <li>If this is your first time exporting the sheets, you may see a popup prompting you to allow multiple downlads
                                at once. Select "Allow" in order to download both sheets.
                            </li>
                        </ul>
                    </div>
                    <div className={sheetsExported ? "card instructions-card green-background" : "no-display"}>
                        <h6 className="instruction-row ">
                            Use the "Calculated Totals" grid to help you verify that the totals in the mismatch and upload sheets match those in the orignal timesheet.
                        </h6>
                    </div>
                    <div 
                        className={sheetsExported ? "empty-height" : "no-display"}
                        ref={instructionEnd}
                    ></div>
                </div>
            </div>
        </>
    )
}