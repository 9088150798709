import { FormatDecimalHundredths } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function FindErroredLine(calculatedValues, auditData) {
  const matchError = calculatedValues.timecardHoursMatch;
  const mergeData = auditData.mergeData;
  const setDefinedError = auditData.setDefinedMismatchError;
  let foundLines = [];

  if (matchError === 'Raw totals do not match calculated totals') {

    let foundHoursMismatchLine = FindHoursMismatchLine(calculatedValues, mergeData);
    let foundDuplicateIDLines = FindDuplicateIDLines(mergeData);
    let fullError = 'Calculated totals do not match Timecard totals:';

    if (foundHoursMismatchLine !== undefined) {
      foundLines.push(foundHoursMismatchLine);
      fullError = fullError + ' Hours Mismatch Found.'
    }

    if (foundDuplicateIDLines && foundDuplicateIDLines.length) {
      foundDuplicateIDLines.forEach(duplicate => {
        foundLines.push(duplicate);
      });
      fullError = fullError + " Duplicate ID's Found.";
    };

    setDefinedError(fullError)
  } else {
    console.log(matchError);
    setDefinedError('Possible Database Error. Please reviews hours.')
  }
  return foundLines;
}

function FindHoursMismatchLine(calculatedValues, mergeData) {
  //const isMergeTotalCol = mergeData[0]['TotalHours'] ? true : false;
  let foundHoursMismatch;
  let foundRegDiff;
  let foundOTDiff;
  let foundDTDiff;
  let foundMPDiff;

  //let totalHoursDiff = FormatDecimalHundredths(Math.abs(calculatedValues.timecardHoursTotal - calculatedValues.mappingHoursTotal));
  let regHoursDiff = FormatDecimalHundredths(Math.abs(calculatedValues.timecardHoursReg - calculatedValues.mappingHoursReg));
  let otHoursDiff = FormatDecimalHundredths(Math.abs(calculatedValues.timecardHoursOT - calculatedValues.mappingHoursOT));
  let dtHoursDiff = FormatDecimalHundredths(Math.abs(calculatedValues.timecardHoursDT - calculatedValues.mappingHoursDT));
  let mpHoursDiff = FormatDecimalHundredths(Math.abs(calculatedValues.timecardHoursMP - calculatedValues.mappingHoursMP));

  // if(isMergeTotalCol){
  //     foundHoursMismatch = mergeData.find((line) => FormatDecimalHundredths(line.TotalHours) === totalHoursDiff);
  // } else {
  foundRegDiff = mergeData.find((line) => line.Reg === regHoursDiff);
  foundOTDiff = mergeData.find((line) => line.OT === otHoursDiff);
  foundDTDiff = mergeData.find((line) => line.DT === dtHoursDiff);
  foundMPDiff = mergeData.find((line) => line.MP === mpHoursDiff);

  foundHoursMismatch = foundRegDiff !== undefined ? foundRegDiff :
    foundOTDiff !== undefined ? foundOTDiff :
      foundDTDiff !== undefined ? foundDTDiff :
        foundMPDiff !== undefined ? foundMPDiff : undefined;
  //};
  return foundHoursMismatch;
}

function FindDuplicateIDLines(mergeData) {
  let foundDuplicateIDLines = [];
  let foundIDs = [];
  let foundNames = [];
  mergeData.forEach(line => {
    if (foundIDs.includes(line.IdNumber) && !foundNames.includes(line.EmployeeName)) {
      foundDuplicateIDLines.push(line);
      // loop through again to catch the original as well as the duplicate and add both to the array
      mergeData.forEach(row => {
        if (row.IdNumber === line.IdNumber && row.EmployeeName !== line.EmployeeName) {
          foundDuplicateIDLines.push(row);
        }
      })
    } else {
      foundIDs.push(line.IdNumber);
      foundNames.push(line.EmployeeName);
    }
  });

  return foundDuplicateIDLines;
}