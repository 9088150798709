import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { BsDownload, BsLayoutThreeColumns } from "react-icons/bs";
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import ReadOnlyJobOrderLogic from 'Components/ATS/Orders/JobOrderModals/ReadOnlyJobOrder/ReadOnlyJobOrderLogic';
import JobOrderStatusLogic from '../JobOrderModals/JobOrderStatusPopup/JobOrderStatusLogic';
import SearchFiltersLogic from '../SearchFilters/SearchFiltersLogic';
import JobOrderDetailsPopupLogic from '../JobOrderModals/JobOrderDetailsPopup/JobOrderDetailsPopupLogic';

function CustomerList({ 
  setAddJobOrderIsOpen,
  postObj,
  setPostObj,
  selectedJobOrderData,
  openOrderStatus,
  setOpenOrderStatus,
  openFilter,
  setOpenFilter,
  onBtnExport,
  setOrderReadOnlyIsOpen,
  orderReadOnlyIsOpen,
  orderID,
  setOpenJobOrderDetails,
  openJobOrderDetails,
  setOrderStatus,
  onCellClicked,
  rowData,
  defaultColDef,
  onGridReady,
  columnDefs,
  saveColumnOrder,
  getDefaults,
  loading,
  ordersLoading,
  onNewColumnsLoaded
}) {
  return (
    <>
    {orderReadOnlyIsOpen&&<ReadOnlyJobOrderLogic show={orderReadOnlyIsOpen} setShow={setOrderReadOnlyIsOpen} orderID={orderID} />}
    {openJobOrderDetails&&<JobOrderDetailsPopupLogic show={openJobOrderDetails} setShow={setOpenJobOrderDetails} orderID={orderID} />}
    {openOrderStatus&&<JobOrderStatusLogic jobOrder={selectedJobOrderData} show={openOrderStatus} setShow={setOpenOrderStatus} />}
    <div className='header-card-auto'>
      <div className='three-column-div'>
        <h3>Orders Detail</h3>
        <div>
          <Button className='label-gap' variant='secondary' onClick={(e)=>setAddJobOrderIsOpen(true)}>Add Job Order</Button>
          <Button variant='secondary' onClick={(e)=>setOpenFilter(!openFilter)}>{openFilter?'Hide Search':'Show Search'}</Button>
        </div>
        <div className='button-div'>
          <Dropdown>
            <Dropdown.Toggle className='margin-right' variant="secondary"><BsLayoutThreeColumns/></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item variant="secondary" onClick={() => saveColumnOrder()}>Save Columns</Dropdown.Item>
              <Dropdown.Item variant="secondary" onClick={() => getDefaults()}>{loading?<Spinner size='sm' animation='border' />:'Show Default Columns'}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button variant='secondary' onClick={() => onBtnExport()}><BsDownload/></Button>
        </div>
      </div>
      <div className={openFilter?'margin-bottom':'margin-bottom hidden'}>
        <SearchFiltersLogic loading={ordersLoading} postObj={postObj} setPostObj={setPostObj} setOrderStatus={setOrderStatus} />
      </div>
    </div>

    <div className='card ats-card'>
      <div id="myGrid" className="ag-theme-alpine candidate-modal-grid grid-height-mod">
          <AgGridReact
              defaultColDef={defaultColDef}
              rowData={rowData}
              onGridReady={onGridReady}
              columnDefs={columnDefs}
              onCellClicked={onCellClicked}
              suppressExcelExport={true}
              popupParent={document.body}
              onNewColumnsLoaded={onNewColumnsLoaded}
          ></AgGridReact>
      </div>
    </div>
    </>
  )
}

export default CustomerList