export function GetFirstDataRow(definitions, setFirstDataRow){
    setFirstDataRow(definitions.firstDataRow);
}

export function GetLoaderColumns(definitions, setLoaderColumns){
    setLoaderColumns(definitions.columns);
}

export function GetProcessDefinitions(definitions, setProcessDefinitions){
    let departmentMap = definitions.useDepartments && definitions.departments ? definitions.departments : {};

    setProcessDefinitions({
        processType: definitions.type.label,
        idType: definitions.identifier.label,
        otLaw: definitions.otLaw.value,
        customerID: definitions.customer,
        departments: departmentMap,
        idModifier: definitions.idModifier,
    });
}
