import { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";

export default function ErrorGrid({
    definedMismatchError,
    erroredRows,
    timecardColumns,
    defaultColDef,
    delayedMergeRows,
    erroredLines
}){
    const onFirstDataRendered = (params) => {
        params.columnApi.autoSizeAllColumns();
    };

    const rowHighlight = useMemo(()=>{
        if(delayedMergeRows&&delayedMergeRows.length){
            return{
                'mismatch-warn': (params)=>{
                    let isMismatchedRow = erroredLines.includes(params.data);
                    return isMismatchedRow;
                },
            };
        }
        // eslint-disable-next-line
    },[delayedMergeRows]);

    return(
        <>
            <div className="card oap-card">
                <h5 className="totals-mismatch-warning">{definedMismatchError}</h5>
                <div className="card  instructions-card scroll-on-overflow-y">
                    <div className="ag-theme-balham ag-border-dark">
                        <AgGridReact
                            rowData={erroredRows}
                            onFirstDataRendered={onFirstDataRendered}
                            columnDefs={timecardColumns}
                            defaultColDef={defaultColDef}
                            rowClassRules={rowHighlight}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}