import { Form } from "react-bootstrap";

export default function CommonIdentifiers({
    commonIdentifierSet
}){
    return (
     <>
        <div className="div-horizontal-margin flex-card-row center-align">
            <Form.Label className="label-gap">Employee Name</Form.Label>
            <Form.Control 
                type="text"
                className="input-text-modal-xs"
                value={commonIdentifierSet.employeeNameCol}
                onChange={(e)=>{commonIdentifierSet.setEmployeeNameCol(e.target.value)}}
            />
        </div>
        <div className="div-horizontal-margin flex-card-row center-align">
            <Form.Label className="label-gap">Last Name</Form.Label>
            <Form.Control 
                type="text"
                className="input-text-modal-xs"
                value={commonIdentifierSet.lastNameCol}
                onChange={(e)=>{commonIdentifierSet.setLastNameCol(e.target.value)}}
            />
        </div>
        <div className="div-horizontal-margin flex-card-row center-align">
            <Form.Label className="label-gap">First Name</Form.Label>
            <Form.Control 
                type="text"
                className="input-text-modal-xs"
                value={commonIdentifierSet.firstNameCol}
                onChange={(e)=>{commonIdentifierSet.setFirstNameCol(e.target.value)}}
            />
        </div>
        <div className="div-horizontal-margin flex-card-row center-align">
            <Form.Label className="label-gap">ID Number</Form.Label>
            <Form.Control 
                type="text"
                className="input-text-modal-xs"
                value={commonIdentifierSet.idCol}
                onChange={(e)=>{commonIdentifierSet.setIdCol(e.target.value)}}
            />
        </div>
        <div className="div-horizontal-margin flex-card-row center-align">
            <Form.Label className="label-gap">Department</Form.Label>
            <Form.Control 
                type="text"
                className="input-text-modal-xs"
                value={commonIdentifierSet.departmentIdentifierCol}
                onChange={(e)=>{commonIdentifierSet.setDepartmentIdentifierCol(e.target.value)}}
            />
        </div>
     </>
    )
}