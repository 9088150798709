import { useState, useEffect } from "react";
import PropTypes from 'prop-types';


const useFetchGet = (url, cookies, refreshTime) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const abortController = new AbortController();
  
    useEffect(() => {
      if(url){
        fetchData(0);
      }

      return ()=>{
        abortController.abort();
      } 
   // eslint-disable-next-line
    }, [url]);

    useEffect(()=>{
        if(refreshTime){
            const timer = setTimeout(() => {
              fetchData(0)
            }, refreshTime);
            return () => clearTimeout(timer);
        }
   // eslint-disable-next-line
    },[data])

    const fetchData = (count) => {
      setLoading(true)
      if(count<3){
        fetch(url,{ signal: abortController.signal })
        .then(res => {
          if(res.status===200){
            return res.json()
          }else if(res.status===401){
            cookies.remove('customer', {path:'/'})
            window.location.reload();
          }else if(res.status===503||res.status===500){
            setTimeout(() => {
              fetchData(count++)
            }, 1000);
            return [];
          }else if(!res.ok){
            res.text().then(text=>{
              if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                setTimeout(() => {
                  fetchData(count++)
                }, 1000);
              }else{
                throw Error(res.statusText)
              }
            })
          }
        })
        .then(data=>{
          setLoading(false);
          setData(data);
        })
        .catch(error => {
          setError(error);
        });
    };
  }
    return { error, loading, data, setData, fetchData };
  };

  useFetchGet.propTypes = {
    url: PropTypes.string.isRequired,
    cookies: PropTypes.object.isRequired,
    cookieName: PropTypes.string,
    refreshTime: PropTypes.number,
  }

  export default useFetchGet;