var count = 0;
const { REACT_APP_API_URL } = process.env;

function FetchAll(url, callType, cookies, data) {
  const abortController = new AbortController();

  return fetch(url, {
    signal: abortController.signal,
    method: callType,
    headers: { 'Content-Type': 'application/json' },
    body: callType === 'PATCH' || callType === 'POST' ? JSON.stringify(data) : null,
  })
    .then(result => {
      if (result.status === 200) {
        return result.text();
      } else if (result.status === 401) {
        cookies.remove('customer', { path: '/' })
        window.location.reload();
      } else if (result.status === 503 || result.status === 500) {
        setTimeout(() => {
          count++
          if (count < 3)
            RetryFetch(url, callType, cookies, data)
          else
            throw new Error('Data did not update')
        }, 1000)
      }
      else if (!result.ok) {
        result.text().then(text => {
          if (text === "Database 'Employnet' cannot be opened. It is in the middle of a restore.") {
            setTimeout(() => {
              count++
              if (count < 3)
                RetryFetch(url, callType, cookies, data)
              else
                throw Error(result.statusText)
            }, 5000)
          }
        })
      }
    })
    .then(returnData => {
      return (returnData ? JSON.parse(returnData) : {});
    })
    .catch(error => {
      console.error(error.message);
    });
};

function RetryFetch(url, callType, cookies, patchRowData) {
  FetchAll(url, callType, cookies, patchRowData)
};

export function GetActiveEmployees(id, idType, customerID, cookies) {
  const baseUrl = `${REACT_APP_API_URL}/payroll/findemployee?token=${cookies.get('customer').Token}&`
  let idURL = idType === 'Assignment ID' ? `assignmentid=${id}` :
    idType === 'Alternate Assignment ID' ? `altaNum=${id}&customerId=${customerID}` :
      idType === 'Alternate ID' && customerID !== null ? `altId=${id}&customerId=${customerID}` :
        idType === 'Alternate ID' ? `altId=${id}` :
          idType === 'Employee ID' ? `employeeId=${id}&customerId=${customerID}` :
            `VMSID=${id}`;

  const url = baseUrl + idURL;
  const activeEmployees = FetchAll(url, 'GET', cookies).then(response => {
    return response
  });
  return activeEmployees;
};

export function PushToOneDrive(uploadName, customerName, byteSheet, cookies) {
  const url = "https://prod-50.westus.logic.azure.com:443/workflows/b5134d382cad489c8ccd1e926259d52f/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=dXoxVCH8txgxPrqGpwaGMkedl4-mW3Y8cf8n65gBFXo";
  const path = `Uploads/${customerName}`;
  const fileName = uploadName;
  const postData = {
    "path": path,
    "fileName": fileName,
    "fileContent": byteSheet
  };
  FetchAll(url, 'POST', cookies, postData).then(response => {
    console.log(response);
  });
}

export function GetCustomerDepartments(customerID, cookies) {
  const url = `${REACT_APP_API_URL}/utilities/getcustomersdepartments?token=${cookies.get('customer').Token}&customerID=${customerID}`;
  const departments = FetchAll(url, 'POST', cookies).then(response => {
    return response;
  });
  return departments;
}

export function PushCustomerDefinition(customerID, definitionString, cookies) {
  const reportName = `payroll-uploads-${customerID}`;
  const url = `${REACT_APP_API_URL}/utilities/postreportcolumns?token=${cookies.get('customer').Token}&reportName=${reportName}&isDefault=true`;
  FetchAll(url, 'POST', cookies, definitionString);
}

export function GetTimecardCustomerDefinitions(timecardNames, cookies) {
  const url = `${REACT_APP_API_URL}/utilities/getreportcolumns?token=${cookies.get('customer').Token}&reportName=${timecardNames}&isDefault=true`;
  const customerDefinitions = FetchAll(url, 'GET', cookies).then(definition => {
    return definition;
  });
  return customerDefinitions;
}