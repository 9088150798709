import { GetDocSample } from "../CallApi";
import { binaryStringToBlob } from "blob-util";

export default function HandleViewDocSample(e, cookies){
  console.log(e);
  let docName = e.value;
  GetDocSample(cookies, docName).then(docObject => {
    let doc = docObject.EncodedBase64;
    let decodedDoc = atob(doc);
    let docBlob = binaryStringToBlob(decodedDoc, "application/pdf");
    console.log(docBlob);
    let docUrl = URL.createObjectURL(docBlob);
    console.log(docUrl);
    window.open(docUrl, '_blank', 'noopener,noreferrer');
  });
}