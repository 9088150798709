import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './MessageCodeForm.css'
import ReactSelectFetchGet from 'Components/CustomComponents/ReactSelectFetchGet/ReactSelectFetchGet';
import { Alert } from 'react-bootstrap';

function MessageCodeForm({
    selectedMessageCode,
    setSelectedMessageCode,
    note,
    setNote,
    handleSubmit,
    apiUrl,
    mapFunction,
    status,
    error,
    loading
}) {
  return (
    <form onSubmit={(e)=>handleSubmit(e)}>
        <Form.Label>Message Code<span className='red-text'>*</span></Form.Label>
        <ReactSelectFetchGet mapFunction={mapFunction} url={apiUrl} placeholder='Select message code...' onChange={(e)=>setSelectedMessageCode(e)} value={selectedMessageCode} className='z-index'/>

        <Form.Label>Message<span className='red-text'>*</span></Form.Label>
        <Form.Control
            className='margin-top'
            placeholder="Add a message..."
            value={note}
            onChange={(e)=>setNote(e.target.value)}
            />
        {/* I need both error and status!==200 because I get an error that there's no json coming back, even though the post was successful */}
        {(error&&status!==200)&&<Alert variant='warning'>There was an error, please try again</Alert>}
        {(status===200)&&<Alert>Success! Please allow up to 15 minutes for your changse to show</Alert>}
        <Button className='full-width margin-top' variant='success' type='submit'>{loading?<Spinner animation="border" role="status"></Spinner>:'Submit'}</Button>
    </form>
  )
}

export default MessageCodeForm