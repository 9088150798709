import GetDisplayTotals from './ValueGetters/GetDisplayTotals';

var count = 0;
const { REACT_APP_API_URL } = process.env;

function FetchAll(url, callType, cookies, patchRowData) {
    const abortController = new AbortController();
    
    return fetch(url, {
        signal: abortController.signal,
        method: callType,
        headers: { 'Content-Type': 'application/json' },
        body: callType === 'PATCH' ? JSON.stringify(patchRowData) : null,
    })
        .then(result => {
            if (result.status === 200) {
                return result.text();
            } else if (result.status === 401) {
                cookies.remove('customer', { path: '/' })
                window.location.reload();
            } else if (result.status === 503 || result.status === 500) {
                setTimeout(() => {
                    count++
                    if (count < 3)
                        RetryFetch(url, callType, cookies, patchRowData)
                    else
                        throw new Error('Data did not update')
                }, 1000)
            }
            else if (!result.ok) {
                result.text().then(text => {
                    if (text === "Database 'Employnet' cannot be opened. It is in the middle of a restore.") {
                        setTimeout(() => {
                            count++
                            if (count < 3)
                                RetryFetch(url, callType, cookies, patchRowData)
                            else
                                throw Error(result.statusText)
                        })
                    }
                })
            }
        })
        .then(returnData => {
            return (returnData ? JSON.parse(returnData) : {});
        })
        .catch(error => {
            console.error(error.message);
        });
};

function RetryFetch(url, callType, cookies, patchRowData) {
    FetchAll(url, callType, cookies, patchRowData)
};

export function DeleteRow(OAPRowID, cookies) {
    const url = `${REACT_APP_API_URL}/payroll/opadeleterow?token=${cookies.get('customer').Token}&OAPRowID=${OAPRowID}`;
    FetchAll(url, 'DELETE', cookies);
};

export function GetOapData(processingWeek, employer, cookies) {
    const url = `${REACT_APP_API_URL}/payroll/getoapdata?token=${cookies.get('customer').Token}&processedWeek=${processingWeek}&Employer=${employer}`;
    FetchAll(url, 'GET', cookies);
};


export function PatchOapData(patchRowData, cookies) {
    const url = `${REACT_APP_API_URL}/payroll/patchoapdata?token=${cookies.get('customer').Token}`;
    FetchAll(url, 'PATCH', cookies, patchRowData)
};

export function RefreshOapData(refreshObject) {
    const { processingWeek, employer, cookies, setReconcileRows, gridApi, selectedRep, setTotalInOaps, setTotalNotReconciled, setTotalReconciled } = refreshObject;
    
    const url = `${REACT_APP_API_URL}/payroll/getoapdata?token=${cookies.get('customer').Token}&processedWeek=${processingWeek}&Employer=${employer}`;

    const updatedRows = FetchAll(url, 'GET', cookies).then(response=>{
        return response
    }).then(newRows => {
        let repRows = selectedRep.value === "All" ? newRows : newRows.filter(row => row.RepName === selectedRep.value);
        setReconcileRows(repRows);
        GetDisplayTotals(newRows, setTotalInOaps, setTotalNotReconciled, setTotalReconciled);
        return newRows;
    }).then(
        gridApi.hideOverlay()
    );
    
    return updatedRows;
};

export function PostOapNote(payName, noteCustomer, email, noteText, cookies) {
    const url = `${REACT_APP_API_URL}/payroll/postoapnote?token=${cookies.get('customer').Token}&payName=${payName}&customer=${noteCustomer}&email=${email}&note=${noteText}`;
    FetchAll(url, 'POST', cookies);
};

export function SearchOapData(cookies, invoice, oapNumber, customer) {
    let searchParam = invoice !== null ? `InvoiceNumber=${invoice}` :
        oapNumber !== null ? `OAPNumber=${oapNumber}` : `CustomerName=${customer}`;
        
    const url = `${REACT_APP_API_URL}/payroll/searchoap?token=${cookies.get('customer').Token}&${searchParam}`;
    
    const searchRows = FetchAll(url, 'GET', cookies).then(response => {
        return response
    });
    
    return searchRows;
};

export function GetInvoiceAging(selectedCustomerName, cookies){
  let url = `${REACT_APP_API_URL}/payroll/getinvoiceagingdetail?token=${cookies.get('customer').Token}&customername=${selectedCustomerName}&pastdueindays=-50`;
  let invoices = FetchAll(url, 'GET', cookies).then(response =>{
    return response
  });
  return invoices;
}