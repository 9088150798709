import React, { useContext, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';

export default function LoginLoadingPage({ token, children }) {
  const [loggedIn, setLoggedIn] = useState(false);
  const { cookies } = useContext(CustomerContext)
  const { REACT_APP_API_URL } = process.env;
  const { data: audit } = useFetchGet(`${REACT_APP_API_URL}/Utilities/GetAudit?token=${token}`,cookies)

  useEffect(()=>{
    if(audit){
      cookies.set('customer', audit, {expires:new Date(audit.ExpirationDate), path:'/'})
      setLoggedIn(true)
    }
  },[audit, cookies])

  return (
    <>
      {loggedIn ? children : <Spinner animation='border' />}
    </>
  )
}