import React, {useState, useEffect, useContext} from "react";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import OAPSearchView from "./OAPSearchView";
import { SearchOapData } from "../CallAPI";
import { CustomComparator, FormatDate, FormatUSD, } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function OAPSearch() {
    const { cookies } = useContext(CustomerContext);;

    const searchParams = [
        { value: "Invoice", label: "Invoice Number" },
        { value: "OAP", label: "OAP Number" },
        { value: "Customer", label: "Customer" }
    ];

    const [searchLabel, setSearchLabel] = useState();
    const [searchValue, setSearchValue] = useState();
    const [searchInput, setSearchInput] = useState('');

    const [oapRowData, setOapRowData] = useState();
    const [oapColDefs, setOapColDefs] = useState();

    const [defaultColDef] = useState ({
        sortable: true,
        resizable: true,
        filter: true,
    });

    useEffect(() => {
        if (oapRowData) {
            setOapColDefs([
                { field: "OAPRowID", hide: true },
                {
                    field: "CustomerName",
                    editable: true,
                    valueParser: params => params.newValue,
                    sort: 'asc',
                    comparator: CustomComparator
                },
                {
                    field: "OAPNumber",
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    sort: 'asc'
                },
                { field: "DepositDate", valueFormatter: (data) => FormatDate(data) },
                { field: "ItemDate", valueFormatter: (data) => FormatDate(data) },
                { field: "OAPAmount", valueFormatter: (data) => FormatUSD(data) },
                { field: "InvoiceNumber", editable: true },
                {field: "PayAmount"},
                {field: "OAPBalance"},
                { field: "InvoiceBalance"},
                { field: 'InvoiceAmount'},
                { field: "AgingCustomer"},
                {
                    field: "ProcessingWeek",
                    sort: 'desc',
                    valueFormatter: (data) => FormatDate(data),
                    comparator: CustomComparator},
            ]);
        }
        // eslint-disable-next-line
    }, [oapRowData]);

    const onFirstDataRendered = (params) => {
        params.columnApi.autoSizeAllColumns();
    };

    const HandleSelectSearchParam = (e) => {
        setSearchLabel(e);
        setSearchValue(e.value);
    };

    const HandleSearchOAPs = () => {
        let searchInvoice = searchValue === "Invoice" ? searchInput : null;
        let searchOAP = searchValue === "OAP" ? searchInput : null;
        let searchCustomer = searchValue === "Customer" ? searchInput : null;

        SearchOapData(cookies, searchInvoice, searchOAP, searchCustomer).then(searchRows => {
            let filteredRows = [];
            searchRows.forEach(row => {
                let foundRow = filteredRows.find(found => found.InvoiceNumber === row.InvoiceNumber);
                var newerRowDate = foundRow !== undefined ? row.RunDate > foundRow.RunDate : false;
                if (newerRowDate) {
                    filteredRows.splice(filteredRows.indexOf(foundRow), 1, row);
                } else if (row.PayAmount !== 0) {
                    filteredRows.push(row);
                }
            });
            setOapRowData(filteredRows);
            return filteredRows;
        });
    };

    const customSelectStyles = {
        control: base => ({
            ...base,
            width: '200px'
        })
    };


    return (
        <>
            <OAPSearchView
                oapRowData={oapRowData}
                onFirstDataRendered={onFirstDataRendered}
                oapColDefs={oapColDefs}
                defaultColDef={defaultColDef}
                searchParams={searchParams}
                searchLabel={searchLabel}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                HandleSelectSearchParam={HandleSelectSearchParam}
                HandleSearchOAPs={HandleSearchOAPs}
                customSelectStyles={customSelectStyles}
            />
        </>
    )
}