import { AgGridReact } from "ag-grid-react";

export default function ActiveGrid({
  rowData,
  timecardColumns,
  defaultColDef,
  setGridApi,
}) {
  const onFirstDataRendered = (params) => {
    setGridApi(params.api)
    params.columnApi.autoSizeAllColumns();
  };

  return (
    <>
      <div className="card oap-card">
        <h5>Active Columns from Excel Spreadsheet</h5>
        <div className="ag-theme-balham ag-border-dark">
          <AgGridReact
            rowData={rowData}
            onFirstDataRendered={onFirstDataRendered}
            columnDefs={timecardColumns}
            defaultColDef={defaultColDef}
          />
        </div>
      </div>
    </>
  )
}