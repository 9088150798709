import React, { useState, useContext, useRef, useEffect } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import AddJobOrder from './AddJobOrder'

function AddJobOrderLogic() {
  const [generalNotes, setGeneralNotes] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [reqTitle, setReqTitle] = useState();
  const jobRequestTitle = useRef();
  const workSite = useRef();
  const pay = useRef();
  const [payError, setPayError] = useState();
  const job = useRef();
  const [jobError, setJobError] = useState();
  const codes = useRef();
  const [codesError, setCodesError] = useState();
  const [numberOfPositions, setNumberOfPositions] = useState('');
  const [confirmationPageIsOpen, setConfirmationPageIsOpen] = useState(false);
  const [jobOrderID, setJobOrderID] = useState();
  const { REACT_APP_API_URL } =process.env;
  const {
    cookies,
    formData,
    setFormData,
    addJobOrderIsOpen,
    setAddJobOrderIsOpen,
    defaultJobOrderID
  } = useContext(CustomerContext)
  const { data: defaultFormData } = useFetchGet((defaultJobOrderID?`${REACT_APP_API_URL}/Candidates/GetOrderRequestTemplate?token=${cookies.get('customer').Token}&orderId=${defaultJobOrderID}`:null),cookies)


  useEffect(()=>{
    if(defaultFormData){
      let newDepartment = {
        CustomerID: defaultFormData.CustomerID,
        CustomerName:defaultFormData.CustomerName,
        DepartmentName:defaultFormData.Department,
        WorkSite:defaultFormData.WorkSite,
      }
      formData.Department = newDepartment;
      setFormData(formData)
      setSelectedDepartment(newDepartment)
    }
  // eslint-disable-next-line
  },[defaultFormData])
  
  const HandleSelectChange = (event, fieldName) => { 
      formData[fieldName] = event.value
      setFormData(formData)
  }

  const HandleSubmit = (event) => { 
    setLoading(true);
    let canPost = true;
    event.preventDefault();
    let postObj = formData;
    if(!formData.PayType||!formData.PayRate){
        setPayError('Lifting Requirement, Pay Type, and Pay Rate are required')
        canPost=false;
        pay.current.scrollIntoView()
        setLoading(false)
    }else if(!formData.StartDate||!formData.AssignmentEnd){
        setJobError('Start and End date are required')
        canPost=false;
        job.current.scrollIntoView()
        setLoading(false)
    }else if(formData.WorkerCompCodeID==='NoDefaults'){
      setCodesError('You must set up a Worker Comp Code before you can create an Order. Please contact HQ to do so.')
      canPost=false
      setLoading(false)
    }
    if(canPost){
      PostJobOrder(BuildPostObj(postObj))
    }
   }

   const BuildPostObj = (postObj) => {
    postObj.SubmitterID = cookies.get('customer').UserID;
    postObj.CustomerID = formData.Department.CustomerID;
    postObj.CustomerName = formData.Department.CustomerName;
    postObj.DepartmentID = formData.Department.CustomerID;
    postObj.Department = formData.Department.DepartmentName;
    postObj.OrderTitle = reqTitle;
    postObj.totalPositions = numberOfPositions;
    if(formData.Shift){
      postObj.ShiftStartTime = formData.Shift.shiftstarttime;
      postObj.ShiftEndTime = formData.Shift.shiftendtime;
      postObj.ShiftID = formData.Shift.ShiftID;
      postObj.ShiftNotes = formData.Shift.shiftNotes;
      postObj.Monday = formData.Shift.monday;
      postObj.Tuesday = formData.Shift.tuesday;
      postObj.Wednesday = formData.Shift.wednesday;
      postObj.Thursday = formData.Shift.thursday;
      postObj.Friday = formData.Shift.friday;
      postObj.Saturday = formData.Shift.saturday;
      postObj.Sunday = formData.Shift.sunday;
      postObj.Shift = formData.Shift.shift;
    }
    if(formData.JobTitle){
      postObj.JobTitleID = formData.JobTitle.SkillCodeID;
      postObj.JobTitle = formData.JobTitle.SkillCodeDescription
    }
    if(generalNotes){
      postObj.Notes = generalNotes;
    }
    if(formData.PO){
      postObj.POID = formData.PO.PoID;
      postObj.PO = formData.PO.PONumber;
    }
    return postObj;
   }

   const PostJobOrder = (postObj) => { 
     fetch(`${REACT_APP_API_URL}/Candidates/PostJobOrder?token=${cookies.get('customer').Token}`,{
     method: 'POST',
     headers: {
         'Content-Type':'application/json'
     },
     body: JSON.stringify(postObj)
     })
     .then(res=>{
         if(res.status===200){
             return res.json()
         }else if(res.status===401){
          cookies.remove('customer', {path:'/'})
          window.location.reload();
          throw Error(res.statusText)
        }else if(res.status===503||res.status===500){
          setTimeout(() => {
            PostJobOrder(postObj)
          }, 1000);
        }else if(!res.ok){
          return res.text().then(text=>{
            if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
              setTimeout(() => {
                PostJobOrder(postObj)
              }, 1000);
            }else{
              throw Error(res.statusText)
            }
          })
        }
     })
     .then(data=>{
        PostToTW(postObj, data)  
     })
     .catch(error=>{
        setLoading(false);
        console.error(error)
        window.alert(error)
     })
    }

    const PostToTW = (postObj, reqID) => { 
      fetch(`${REACT_APP_API_URL}/Candidates/PostOrderToTW?token=${cookies.get('customer').Token}&orderRequestId=${reqID}&totalPositions=${numberOfPositions}&multiplierCodeID=${(postObj.MultiplierCodeID?postObj.MultiplierCodeID:0)}&workerCompCodeID=${(postObj.WorkerCompCodeID?postObj.WorkerCompCodeID:0)}&OrderTypeID=${postObj.OrderType?postObj.OrderType:1}`,{
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        }
        })
        .then(res=>{
            if(res.status===200){
                return res.json()
            }else if(res.status===401){
             cookies.remove('customer', {path:'/'})
             window.location.reload();
           }else if(res.status===503||res.status===500){
             setTimeout(() => {
               PostJobOrder(postObj)
             }, 1000);
           }else if(!res.ok){
             return res.text().then(text=>{
               if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                 setTimeout(() => {
                   PostJobOrder(postObj)
                 }, 1000);
               }else{
                 throw Error(res.statusText)
               }
             })
           }
        })
        .then(data=>{
          setJobOrderID(data)
          setConfirmationPageIsOpen(true)
          setLoading(false);      
       })
       .catch(error=>{
          setLoading(false);
          console.error(error)
          window.alert(error)
       })
     }

    const HandleNumberOfPositionsChange = (e) => { 
      let value = parseInt(e.target.value)
        if(Number.isInteger(value)){
          setNumberOfPositions(value)
        }else if(e.target.value===""){
          setNumberOfPositions("")
        }
     }

  return (
      <AddJobOrder
          HandleSelectChange={HandleSelectChange}
          selectedDepartment={selectedDepartment}
          setSelectedDepartment={setSelectedDepartment}
          HandleSubmit={HandleSubmit}
          setGeneralNotes={setGeneralNotes}
          setReqTitle={setReqTitle}
          jobRequestTitle={jobRequestTitle}
          loading={loading}
          show={addJobOrderIsOpen}
          setShow={setAddJobOrderIsOpen}
          workSite={workSite}
          job={job}
          jobError={jobError}
          pay={pay}
          payError={payError}
          codes={codes}
          codesError={codesError}
          HandleNumberOfPositionsChange={HandleNumberOfPositionsChange}
          numberOfPositions={numberOfPositions}
          confirmationPageIsOpen={confirmationPageIsOpen}
          setConfirmationPageIsOpen={setConfirmationPageIsOpen}
          jobOrderID={jobOrderID}
          setAddJobOrderIsOpen={setAddJobOrderIsOpen}  
          defaultFormData={defaultFormData}
      ></AddJobOrder>
  )
}

export default AddJobOrderLogic