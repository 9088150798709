export default function GetMatchRowCols(matchRowData, setMatchRowCols) {
  let processedKeys = Object.keys(matchRowData[0]);
  let processedCols = [];
  processedKeys.forEach(header => {
    let newField = header === "EmployeeName" || header === "LookupID" || header === "IdType" || header === "CustomerID" ?
      { field: header, cellStyle: { 'backgroundColor': 'var(--light-blue)', cellDataType: 'text' } } :
      header === "EmployeeID" || header === "AssignmentID" ? { field: header, cellDataType: 'text' } :
        { field: header };
    processedCols.push(newField);
  });
  setMatchRowCols(processedCols);
}