import React from "react";

export default function TableBuilder({
  selectedRowData,
  zeroToThirty,
  thirtyOneToSixty,
  sixtyOneToNinety,
  overNinety,
  balanceShown
}) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const FormatUSD = (params) => {
    return `${formatter.format(params)}`;
  };

  const FormatDate = (params) => {
    // return date without time stamp and 'T'
    let fixedDate = params.split('T')[0]
    return fixedDate
  };
  
  return (
    <table id="invoiceTable" border="1" cellPadding="5" cellSpacing="1" width="100%">
      <thead>
        <tr bgcolor='#8FAAC4'>
          <th>Client Name</th>
          <th>Department Name</th>
          <th>Payment Terms</th>
          <th>Invoice Number</th>
          <th>Invoice Date</th>
          {zeroToThirty > 0 ? <th>0 - 30</th> : null}
          {thirtyOneToSixty > 0 ? <th>31 - 60</th> : null}
          {sixtyOneToNinety > 0 ? <th>61 - 90</th> : null}
          {overNinety > 0 ? <th>90 +</th> : null}
          <th>Balance</th>
        </tr>
      </thead>
      <tbody>
      {selectedRowData.map((rowData) => {
      return (          
        <tr>
          <td>{rowData.customerName}</td>
          <td>{rowData.DepartmentName}</td>
          <td>{rowData.PaymentTerms}</td>
          <td>{rowData.invoiceNumber}</td>
          <td>{FormatDate(rowData.InvoiceDate)}</td>
          {zeroToThirty > 0  ? <td>{FormatUSD(rowData.Age_0_30)}</td> : null}
          {thirtyOneToSixty > 0 ? <td>{FormatUSD(rowData.Age_31_60)}</td> : null}
          {sixtyOneToNinety > 0 ? <td>{FormatUSD(rowData.Age_61_90)}</td> : null}
          {overNinety > 0 ? <td>{FormatUSD(rowData.Age_Ovr_90)}</td> : null}
          <td>{FormatUSD(rowData.BalanceAmount)}</td>
        </tr>
        );
      })}
        <tr bgcolor='#8FAAC4'>
          <td>Grand Total</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {zeroToThirty > 0  ? <td>{zeroToThirty}</td> : null}
          {thirtyOneToSixty > 0 ? <td>{thirtyOneToSixty}</td> : null}
          {sixtyOneToNinety > 0 ? <td>{sixtyOneToNinety}</td> : null}
          {overNinety > 0 ? <td>{overNinety}</td> : null}
          <td>{balanceShown}</td>
        </tr>
      </tbody>
    </table>
  );
};

