import React from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { Alert, Spinner, Button, Form, Modal, ModalFooter } from 'react-bootstrap'
import ReactSelect from 'react-select'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';


function DoNotCall({ show, setShow, candidateInfo }) {
    const {cookies} = useContext(CustomerContext)
    const [doNotCall, setDoNotCall] = useState();
    const [notes, setNotes] = useState('');
    const [canPost,setCanPost] = useState(false);
    const [error, setError] = useState();
    const {REACT_APP_API_URL} = process.env;
    const { status } = useFetchPost((canPost&&`${REACT_APP_API_URL}/Candidates/PostDoNotCall?token=${cookies.get('customer').Token}&EmployeeID=${candidateInfo.EmployeeID}&toggle=${doNotCall}&note=${notes}`),cookies,'');

    useEffect(()=>{
        if(status&&status!==200){
            setError('There was an issue, please try again')
        }
    },[status])
    const HandleSubmit = () => { 
        if(doNotCall===false||doNotCall){
            setCanPost(true)
        }else{
            setError('Please select a Looking For Work option')
        }
     }

  return (
    <Modal show={show} onHide={setShow}>
        <Modal.Header closeButton>
            <h5>{candidateInfo.FirstName+' '+candidateInfo.LastName+' | '+candidateInfo.EmployeeID}</h5>
        </Modal.Header>
        {(status===200)?
        <Modal.Body>
            <Alert>Successfully updated status, please allow up to 15 minutes for the changes to appear in this app</Alert>    
        </Modal.Body>
        :
        <Modal.Body>
            <Form.Label>Contact For Work<span style={{color:'red'}}>*</span></Form.Label>
            <ReactSelect options={[{value:false,label:'Contact'},{value:true,label:'Do not contact'}]} className='margin-bottom' onChange={(e)=>setDoNotCall(e.value)} />
            
            {error&&
                <Alert variant='warning' className='margin-bottom'>{error}</Alert>
            }

            <Form.Label>Notes</Form.Label>
            <Form.Control onChange={(e)=>setNotes(e.target.value)}/>
        </Modal.Body>
        }
        <ModalFooter>
            <Button disabled={(status===200)?true:false} onClick={()=>HandleSubmit()} variant='success'>{(canPost&&!status)?<Spinner animation='border'/>:'Submit'}</Button>
            <Button variant='secondary' onClick={()=>setShow(false)}>Close</Button>
        </ModalFooter>
    </Modal>
  )
}

export default DoNotCall