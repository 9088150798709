import React, { useState, useEffect, useContext } from "react";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import PayrollUploadsView from "./PayrollUploadsView";
import { GetFirstDataRow, GetLoaderColumns, GetProcessDefinitions } from "./PayrollUploadsGetters/GetLoaderParams";

export default function PayrollUploads() {
  const [customerDefinitionList, setCustomerDefinitionList] = useState();

  // will be used to create rules for loading customer info and needs to be accessible from anywhere
  const [loaderColumns, setLoaderColumns] = useState();
  const [rowData, setRowData] = useState();
  const [firstDataRow, setFirstDataRow] = useState();
  const [processDefinitions, setProcessDefinitions] = useState();
  const [originalTimecardRows, setOriginalTimecardRows] = useState();
  const [originalTimecardCols, setOriginalTimecardCols] = useState();
  const [columnMap, setColumnMap] = useState();
  const [mergeData, setMergeData] = useState();
  const [mergeCols, setMergeCols] = useState();

  const loaderParams = {
    loaderColumns: loaderColumns,
    setRowData: setRowData,
    firstDataRow: firstDataRow,
    processDefinitions: processDefinitions,
    setOriginalTimecardRows: setOriginalTimecardRows,
    setOriginalTimecardCols: setOriginalTimecardCols,
    setColumnMap: setColumnMap,
    setMergeData: setMergeData,
    setMergeCols: setMergeCols,
  };

  // hours totals, used in the grids, as well as the header
  const [timecardHoursTotal, setTimecardHoursTotal] = useState();
  const [mismatchHoursTotal, setMismatchHoursTotal] = useState();
  const [uploadHoursTotal, setUploadHoursTotal] = useState();
  const [mappingHoursTotal, setMappingHoursTotal] = useState();

  const [timecardReg, setTimecardReg] = useState();
  const [mismatchReg, setMismatchReg] = useState();
  const [uploadReg, setUploadReg] = useState();
  const [mappingHoursReg, setMappingHoursReg] = useState();

  const [timecardOT, setTimecardOT] = useState();
  const [mismatchOT, setMismatchOT] = useState();
  const [uploadOT, setUploadOT] = useState();
  const [mappingHoursOT, setMappingHoursOT] = useState();

  const [timecardDT, setTimecardDT] = useState();
  const [mismatchDT, setMismatchDT] = useState();
  const [uploadDT, setUploadDT] = useState();
  const [mappingHoursDT, setMappingHoursDT] = useState();

  const [timecardMP, setTimecardMP] = useState();
  const [mismatchMP, setMismatchMP] = useState();
  const [uploadMP, setUploadMP] = useState();
  const [mappingHoursMP, setMappingHoursMP] = useState();

  const hoursTotals = {
    timecardHoursTotal: timecardHoursTotal,
    setTimecardHoursTotal: setTimecardHoursTotal,
    mismatchHoursTotal: mismatchHoursTotal,
    setMismatchHoursTotal: setMismatchHoursTotal,
    uploadHoursTotal: uploadHoursTotal,
    setUploadHoursTotal: setUploadHoursTotal,
    mappingHoursTotal: mappingHoursTotal,
    setMappingHoursTotal: setMappingHoursTotal,

    timecardReg: timecardReg,
    setTimecardReg: setTimecardReg,
    mismatchReg: mismatchReg,
    setMismatchReg: setMismatchReg,
    uploadReg: uploadReg,
    setUploadReg: setUploadReg,
    mappingHoursReg: mappingHoursReg,
    setMappingHoursReg: setMappingHoursReg,

    timecardOT: timecardOT,
    setTimecardOT: setTimecardOT,
    mismatchOT: mismatchOT,
    setMismatchOT: setMismatchOT,
    uploadOT: uploadOT,
    setUploadOT: setUploadOT,
    mappingHoursOT: mappingHoursOT,
    setMappingHoursOT: setMappingHoursOT,

    timecardDT: timecardDT,
    setTimecardDT: setTimecardDT,
    mismatchDT: mismatchDT,
    setMismatchDT: setMismatchDT,
    uploadDT: uploadDT,
    setUploadDT: setUploadDT,
    mappingHoursDT: mappingHoursDT,
    setMappingHoursDT: setMappingHoursDT,

    timecardMP: timecardMP,
    setTimecardMP: setTimecardMP,
    mismatchMP: mismatchMP,
    setMismatchMP: setMismatchMP,
    uploadMP: uploadMP,
    setUploadMP: setUploadMP,
    mappingHoursMP: mappingHoursMP,
    setMappingHoursMP: setMappingHoursMP
  };

  // will be included in export (export params) and need to be accessible from anywhere
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [matchRowData, setMatchRowData] = useState();
  const [sheetsExported, setSheetsExported] = useState(false);
  const { cookies } = useContext(CustomerContext);
  const [delayedRows, setDelayedRows] = useState();

  let exportParams = {
    uploadData: matchRowData,
    selectedCustomer: selectedCustomer,
    setSheetsExported: setSheetsExported,
    cookies: cookies,
    mergeData: mergeData,
    delayedRows: delayedRows
  }

  // holds the remaining params needed by the header that need to be top level
  const [isResearchView, setIsResearchView] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [activeReviewed, setActiveReviewed] = useState(false);
  const [weeklyCalcs, setWeeklyCalcs] = useState();
  const [employeeDefs, setEmployeeDefs] = useState();
  const calculatedSums = {
    timecardHoursTotal: timecardHoursTotal,
    timecardReg: timecardReg,
    timecardOT: timecardOT,
    timecardDT: timecardDT,
    timecardMP: timecardMP
  };

  const headerAddedParams = {
    rowData: rowData,
    customerDefinitionList: customerDefinitionList,
    setCustomerDefinitionList: setCustomerDefinitionList,
    setSelectedCustomer: setSelectedCustomer,
    setIsResearchView: setIsResearchView,
    gridApi: gridApi,
    setActiveReviewed: setActiveReviewed,
    setWeeklyCalcs: setWeeklyCalcs,
    setEmployeeDefs: setEmployeeDefs,
    calculatedSums: calculatedSums
  };

  // holds the remaining params used by the grids that need top level access
  const gridAddedParams = {
    gridApi: gridApi,
    setGridApi: setGridApi,
    originalTimecardRows,
    originalTimecardCols,
    setMatchRowData: setMatchRowData,
    setDelayedRows: setDelayedRows,
    isResearchView: isResearchView,
    sheetsExported: sheetsExported,
    columnMap: columnMap,
    setMergeData: setMergeData,
    mergeCols: mergeCols,
    setMergeCols: setMergeCols,
    calculatedSums: calculatedSums,
    activeReviewed: activeReviewed,
    weeklyCalcs: weeklyCalcs,
    employeeDefs: employeeDefs,
    setSelectedCustomer: setSelectedCustomer
  };

  useEffect(() => {
    if (selectedCustomer) {
      let definitions = customerDefinitionList.find(def => def.customer === selectedCustomer.value);
      GetFirstDataRow(definitions, setFirstDataRow);
      GetLoaderColumns(definitions, setLoaderColumns);
      GetProcessDefinitions(definitions, setProcessDefinitions);
    }
    ResetTopValues();
    // eslint-disable-next-line
  }, [selectedCustomer]);

  const ResetTopValues = () => {
    setRowData([]);
    setMergeData();
    setMergeCols();

    setMatchRowData([]);
    setSheetsExported(false);

    setColumnMap();
    setDelayedRows();

    setActiveReviewed(false);
    HandleClearFile();
  }

  const HandleClearFile = () => {
    let uploader = document.getElementById("fileInput");
    uploader.value = '';
  }

  return (
    <>
      <PayrollUploadsView
        loaderParams={loaderParams}
        hoursTotals={hoursTotals}
        exportParams={exportParams}
        headerAddedParams={headerAddedParams}
        gridAddedParams={gridAddedParams}
      />
    </>
  )
}