import React from 'react'
import { Container, Modal } from 'react-bootstrap'
import { AgGridReact } from "ag-grid-react";

export default function CustomerInfoPopupView({
  contacts,
  contactsOnGridReady,
  contactsColDefs,
  contactsDefaultColDef,
  show,
  onHide
}){

  return(
    <div>
      <Modal dialogClassName="modal-70w" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <h3>Contact List</h3>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="ag-theme-balham">
              <AgGridReact
                suppressExcelExport={true}
                rowData={contacts}
                onGridReady={contactsOnGridReady}
                columnDefs={contactsColDefs}
                defaultColDef={contactsDefaultColDef}
              />
            </div> 
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}