import React, { useState, useEffect, useContext } from "react";
import InvoiceAgingView from "./InvoiceAgingView";
import { GetAgingBottomRow, GetAgingColDefs } from "./Grid/GetAgingGridValues";
import { GetCustomerEmailID, GetSelectedInvoices } from './Emailer/GetSelectedEmailData';
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import { GetInvoiceAging } from "../OAPs/CallAPI";

export default function InvoiceAging() {
  const { cookies } = useContext(CustomerContext);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedCustomerName, setSelectedCustomerName] = useState();

  const [rowData, setRowData] = useState();
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
  const [colDefs, setColDefs] = useState();

  const [gridApi, setGridApi] = useState();
  
  const [selectedRowData, setSelectedRowData] = useState();
  const [selectedCustomerID, setSelectedCustomerID] = useState();

  const [showMailer, setShowMailer] = useState(false);
  const [contactPopupIsOpen, setContactPopupIsOpen] = useState(false);

  const [defaultColDef] = useState({
    sortable: true,
    resizable: true,
    filter: true,
  });

  useEffect(()=>{
    if(selectedCustomerName){
      GetInvoiceAging(selectedCustomerName, cookies).then(rows =>{
        setRowData(rows);
      })
    }
    // eslint-disable-next-line
  },[selectedCustomerName])


  useEffect(() => {
    if (rowData){
      let colDefsPlaceholder = GetAgingColDefs(rowData);
      let pinnedPlaceholder = GetAgingBottomRow(rowData);
  
      setColDefs(colDefsPlaceholder);
      setPinnedBottomRowData(pinnedPlaceholder);
    }
  }, [rowData]);

  const onCustomerSelected = (e) => {
    setSelectedCustomer(e);
    setSelectedCustomerName(e.label);
  };

  const onFirstDataRendered = (params) => {
    setGridApi(params.api);
    params.api.autoSizeAllColumns();
  };

  const onFilterChanged = () => {
    let data = [];
    gridApi.forEachNodeAfterFilter(node => {
      data.push(node.data);
    })
    let pinnedRow = GetAgingBottomRow(data);
    setPinnedBottomRowData(pinnedRow);
  };

  const ExportToCSV = (params) => {
    gridApi.exportDataAsCsv(params);
  };

  // show contact data when a line is double-clicked
  const OpenInfoCard = (e) => {
    setSelectedCustomerID(e.data.CustomerID);
    setContactPopupIsOpen(true);
  };

  const EmailSelectedInvoices = (e) => {
    let invoicesSelected = GetSelectedInvoices(gridApi);
    setSelectedRowData(invoicesSelected);

    let customerEmailID = GetCustomerEmailID(gridApi);

    setSelectedCustomerID(customerEmailID);
    setShowMailer(true);
  };

  const gridParams = {
    rowData,
    onFirstDataRendered,
    colDefs,
    defaultColDef,
    pinnedBottomRowData
  };

  const gridFunctions = {
    onFilterChanged,
    onCustomerSelected,
    ExportToCSV,
    EmailSelectedInvoices,
    OpenInfoCard,
  }

  const componentParams = {
    selectedCustomer,
    contactPopupIsOpen,
    setContactPopupIsOpen,
    selectedCustomerID,
    selectedRowData,
    setSelectedRowData,
    showMailer,
    setShowMailer
  }

  return (
    <InvoiceAgingView  
      gridParams={gridParams}
      gridFunctions={gridFunctions}
      componentParams={componentParams}
    />
  );
};