import React from 'react'
import CustomerSearchLogic from './CustomerSearch/CustomerSearchLogic';
import './EmployeeSearch.css'
import BasicEmployeeSearchLogic from './BasicEmployeeSearch/BasicEmployeeSearchLogic';
import CandidatesGridLogic from '../CandidatesGrid/CandidatesGridLogic';

function EmployeeSearchView({
    advancedSearch,
    setAdvancedSearch,
    rowData,
    setRowData
}){
    return(
        <div>
            <div className='vertical-card-fit'>
            {advancedSearch?
                <CustomerSearchLogic setAdvancedSearch={setAdvancedSearch} setRowData={setRowData} />
                :
                <BasicEmployeeSearchLogic setAdvancedSearch={setAdvancedSearch} setRowData={setRowData} />
            }         
            </div>

            <CandidatesGridLogic currentPageName={'Employee Search'} rowData={rowData} otherColumnDefs={[]} />
        </div>
    )
}

export default EmployeeSearchView;