import React from 'react'
import Row from 'react-bootstrap/Row';

function Compliance({ documents }) {

  return (
    <>
    {(documents.CAState>0)&&
    <>
        <Row><p><b>SHT Compliance: </b>{documents.SHTCompliance}</p></Row>
        {documents.SHTDate&&
        <Row><p><b>SHT Date: </b>{documents.SHTDate.split('T')[0]}</p></Row>
        }
        <Row><p><b>Has last 24 months: </b>{documents.HasSHTLast24Months?'Yes':'No'}</p></Row>
    </>
    }
    <Row><p><b>I9 Compliance: </b>{documents.I9Compliance}</p></Row>
    {documents.I9Date&&
    <Row><p><b>I9 Date: </b>{documents.I9Date.split('T')[0]}</p></Row>
    }
    <Row><p><b>W4 Compliance: </b>{documents.W4Compliance}</p></Row>
    {documents.W4Date&&
    <Row><p><b>W4 Date: </b>{documents.W4Date.split('T')[0]}</p></Row>
    }
    <Row><p><b>Everified: </b>{documents.Everified}</p></Row>
    <Row><p><b>Background: </b>{documents.BackGroundCompliance}</p></Row>
    {documents.LastPayDate&&
    <Row><p><b>Last Pay Date: </b>{documents.LastPayDate.split('T')[0]}</p></Row>
    }
    </>
  )
}

export default Compliance