import { FormatDecimalHundredths } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function GetCalculatedValues(matchRowData, totalsSetters, tcTotals) {
  let upTotal = 0;
  let upReg = 0;
  let upOT = 0;
  let upDT = 0;
  let upMP = 0;

  let mmTotal = 0;
  let mmReg = 0;
  let mmOT = 0;
  let mmDT = 0;
  let mmMP = 0;

  matchRowData.forEach(row => {
    if (row.AssignmentID !== "Mismatch") {
      upReg += row.Reg;
      upOT += row.OT;
      upDT += row.DT;
      upMP += row.MP;
    } else {
      mmReg += row.Reg;
      mmOT += row.OT;
      mmDT += row.DT;
      mmMP += row.MP;
    }
  });
  upTotal = FormatDecimalHundredths(upReg + upOT + upDT + upMP);
  mmTotal = FormatDecimalHundredths(mmReg + mmOT + mmDT + mmMP);

  upReg = FormatDecimalHundredths(upReg);
  upOT = FormatDecimalHundredths(upOT);
  upDT = FormatDecimalHundredths(upDT);
  upMP = FormatDecimalHundredths(upMP);

  mmReg = FormatDecimalHundredths(mmReg);
  mmOT = FormatDecimalHundredths(mmOT);
  mmDT = FormatDecimalHundredths(mmDT);
  mmMP = FormatDecimalHundredths(mmMP);

  let calcReg = FormatDecimalHundredths(parseFloat(upReg) + parseFloat(mmReg));
  if (Math.abs(calcReg - tcTotals.timecardReg)>=0.1) { totalsSetters.setTimecardHoursMatch('Reg Hours Mismatch'); }

  let calcOT = FormatDecimalHundredths(parseFloat(upOT) + parseFloat(mmOT));
  if (Math.abs(calcOT - tcTotals.timecardOT)>=0.1) {totalsSetters.setTimecardHoursMatch('OT Hours Mismatch');}

  let calcDT = FormatDecimalHundredths(parseFloat(upDT) + parseFloat(mmDT));
  if (Math.abs(calcDT - tcTotals.timecardDT)>=0.1) { totalsSetters.setTimecardHoursMatch('DT Hours Mismatch'); }

  let calcMP = FormatDecimalHundredths(parseFloat(upMP) + parseFloat(mmMP));
  if (calcMP !== tcTotals.timecardMP) { totalsSetters.setTimecardHoursMatch('MP Hours Mismatch'); }

  if (tcTotals.mappingHoursTotal !== tcTotals.timecardHoursTotal) { totalsSetters.setTimecardHoursMatch('Raw totals do not match calculated totals'); }

  totalsSetters.setUploadHoursTotal(upTotal);
  totalsSetters.setUploadReg(upReg);
  totalsSetters.setUploadOT(upOT);
  totalsSetters.setUploadDT(upDT);
  totalsSetters.setUploadMP(upMP);

  totalsSetters.setMismatchHoursTotal(mmTotal);
  totalsSetters.setMismatchReg(mmReg);
  totalsSetters.setMismatchOT(mmOT);
  totalsSetters.setMismatchDT(mmDT);
  totalsSetters.setMismatchMP(mmMP);
};