import React, { useState, useEffect, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';
import CandidatesGridLogic from '../CandidatesGrid/CandidatesGridLogic';
import './CandidaciesByBranch.css';
import Select from 'react-select';
import { Col } from 'react-bootstrap';

function CandidaciesByBranchLogic({ 
    branchIDs, 
    SRIdent, 
    onlyActive 
}) {
    const [candidates, setCandidates] = useState();
    const [filteredCandidates, setFilteredCandidates] = useState();
    const [postObj, setPostObj] = useState();
    const [SRDropdownOptions, setSRDropdownOptions] = useState();
    const [statusDropdownOptions, setStatusDropdownOptions] = useState();
    const { cookies } = useContext(CustomerContext);
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const { data: rowData } = useFetchPost(`${REACT_APP_API_URL}/Candidates/SearchCandidates?token=${cookies.get('customer').Token}`, cookies, postObj);

    useEffect(()=>{
        let newPostObj = {
            BranchIDs: [...branchIDs],
            ServiceRepIDs: [SRIdent],
            isActive: onlyActive
            }
        setPostObj(newPostObj)
    },[branchIDs, SRIdent, onlyActive])

    useEffect(()=>{
        if(rowData){
            TransformCandidates(rowData)
            populateSRDropdownOptions(rowData)
            populateStatusDropdownOptions(rowData)
        }
    },[rowData])
    
    const TransformCandidates = (candidates) => { 
        let transformmedCandidates = candidates.map(candidate=>{
            candidate.FullName = candidate.FirstName+' '+candidate.LastName;
            candidate.Compliance = {
                Application: candidate.Application,
                Onboarding: candidate.OnBoarding,
                EVerify: candidate.EVerify,
                BackgroundCheck: candidate.Background,
                DrugTest: candidate.DrugTest
            }
            return candidate
        })
        setCandidates(transformmedCandidates)
        setFilteredCandidates(transformmedCandidates)
     }

    const populateSRDropdownOptions = (candidates) => { 
        let candidateDropdownOptions = [{value:'All',label:'All'}];
        candidates.forEach(candidate => {
            let option = {
                value:{
                    SRName: candidate.RepFullName,
                    SRID: candidate.SRIdent
                }, 
                label:candidate.RepFullName
            }
            let index = candidateDropdownOptions.findIndex(obj=>obj.value.SRID===option.value.SRID)
            if(index===-1){
                candidateDropdownOptions.push(option)
            }
        });
        setSRDropdownOptions(candidateDropdownOptions)
     }

     const handleSRChange = (e) => { 
        if(e.value==='All'){
            setFilteredCandidates(candidates)
        }else{
            let candidatesWithMatchingSR = []
            candidates.forEach(candidate=>{
                if(candidate.SRIdent===e.value.SRID){
                    candidatesWithMatchingSR.push(candidate)
                }
            })
            setFilteredCandidates(candidatesWithMatchingSR)
        }
     }

    const populateStatusDropdownOptions = (candidates) => { 
        let statusDropdownOptions = [{value:'All',label:'All'}];
        candidates.forEach(candidate => {
            let option = {
                value:{
                    StatusID: candidate.StatusID,
                    StatusDescription: candidate.LastStatus
                }, 
                label:candidate.StatusID
            }
            let index = statusDropdownOptions.findIndex(obj=>obj.value.StatusID===option.value.StatusID)
            if(index===-1){
                statusDropdownOptions.push(option)
            }
        });
        setStatusDropdownOptions(statusDropdownOptions)
     }

     const handleStatusChange = (e) => { 
        if(e.value==='All'){
            setFilteredCandidates(candidates)
        }else{
            let candidatesWithMatchingStatus = []
            candidates.forEach(candidate=>{
                if(candidate.StatusID===e.value.StatusID){
                    candidatesWithMatchingStatus.push(candidate)
                }
            })
            setFilteredCandidates(candidatesWithMatchingStatus)
        }
     }

     const customSelectStyles = {
        control: base => ({
            ...base,
            width: '300px'
        })
      };


    
  return (
        <CandidatesGridLogic 
            currentPageName={'Active Candidates'}  
            customColumnName={'Active Candidates'}
            otherColumnDefs={[]} 
            rowData={filteredCandidates} 
            aboveGridElements={
                <Row>
                    <Col>
                        <Select 
                            placeholder='Filter SR' 
                            options={SRDropdownOptions} 
                            onChange={(e)=>handleSRChange(e)} 
                            styles={customSelectStyles}
                        />
                    </Col>
                    <Col>
                        <Select 
                            placeholder='Filter Status' 
                            options={statusDropdownOptions}
                            onChange={(e)=>handleStatusChange(e)} 
                            styles={customSelectStyles}
                        />
                    </Col>
                </Row>
            } 
        />
  )
}

export default CandidaciesByBranchLogic