import React from "react";
import ColumnMap from "./ColumnMap";
import MergedGrid from "./MergedGrid";
import ErrorMergeGrid from "./ErrorMergeGrid";
import OriginalTimecardGrid from "./OriginalTimecardGrid";
import TotalsCompareTable from "./TotalsCompareTable";

export default function ResearchErrorsView({
    defaultColDef,
    delayedRows,
    delayedCols,

    mergeData,
    mergeCols,
    delayedMergeRows,

    calculatedSums,
    rawSums,
    mismatchSums,
    uploadSums,

    definedMismatchError,
    timecardHoursMatch,
    columnMap,
    rowHighlight
}){

    return (
        <>
            <div  className="two-col-even">
                <ColumnMap columnMap={columnMap}/>    
                <TotalsCompareTable 
                    timecardHoursMatch={timecardHoursMatch}
                    rawSums={rawSums}
                    calculatedSums={calculatedSums}
                    mismatchSums={mismatchSums}
                    uploadSums={uploadSums}
                />
            </div>
            {mergeData&&mergeData.length&&<MergedGrid 
                mergeData={mergeData}
                mergeCols={mergeCols}
                rowHighlight={rowHighlight}
                defaultColDef={defaultColDef}
            />}
            {delayedMergeRows&&delayedMergeRows.length&&<ErrorMergeGrid
                delayedMergeRows={delayedMergeRows}
                mergeCols={mergeCols}
                defaultColDef={defaultColDef}
                rowHighlight={rowHighlight}
                definedMismatchError={definedMismatchError}
            />}
            <OriginalTimecardGrid 
                delayedRows={delayedRows}
                delayedCols={delayedCols}
                defaultColDef={defaultColDef}
            />
        </>
    )
}