import React, { useState } from 'react';
import CandidateStatusPopup from './CandidateStatusPopup';

function CandidateStatusPopupLogic({ 
    show,
    setShow, 
    candidateInfo, 
    defaultRequestID 
}) {
    const [doNotCallPopup,setDoNotCallPopup] = useState(false);

    const handleClose = () => { 
        setShow(false)
    }
    
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

  return (
    <>
        {candidateInfo&&
            <CandidateStatusPopup
                show={show}
                candidateInfo={candidateInfo}
                defaultRequestID={defaultRequestID}
                handleClose={handleClose}
                openInNewTab={openInNewTab}
                doNotCallPopup={doNotCallPopup}
                setDoNotCallPopup={setDoNotCallPopup}
            ></CandidateStatusPopup>
        }
    </>
  )
}

export default CandidateStatusPopupLogic;