import { FormatDecimalHundredths } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export function ExportReconciled(oapRowData, formattedDate) {
    let exportArray = []; // holds all rows with an invoice number entered
    let csvContent = '';
    let blob;
    let objUrl;

    let exportArrayHeaders = [['OAPNumber', 'CustomerName', 'InvoiceNumber', 'PayAmount']];

    if (oapRowData) {
        // builds the array for the CSV file
        // exportArray.push(['OAPNumber', 'CustomerName', 'InvoiceNumber', 'PayAmount'])
        oapRowData.forEach((row) => {
            if (row.PayAmount&&(Number(row.PayAmount) !== 0)) {
                let customerToPush = row.CustomerName.replace(',', '');
                let invoice = row.InvoiceNumber !== 0 ? row.InvoiceNumber : 'leave as oap';
                let payAmountFormatted = FormatDecimalHundredths(row.PayAmount);
                    
                exportArray.push([
                    row.OAPNumber,
                    customerToPush,
                    invoice,
                    payAmountFormatted
                ]);
            }
        });
        exportArray.sort((a, b) => {
            return a[1].toUpperCase().localeCompare(b[1].toUpperCase()) || a[0].localeCompare(b[0])
        });

        let sortedArray = exportArrayHeaders.concat(exportArray);

        // get array in correct format for csv
        sortedArray.forEach(row => {
            csvContent += row.join(',') + '\n';
        });
        blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
        objUrl = URL.createObjectURL(blob);
    }
    // names and builds download
    const fileName = 'OAP Export ' + formattedDate;
    const downloader = document.createElement('a');
    document.body.appendChild(downloader);
    downloader.download = fileName;
    downloader.href = objUrl;
    downloader.click();
}

