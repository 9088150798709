import React from 'react'
import { Alert, Button, Form } from 'react-bootstrap'

function BasicEmployeeSearch({ setEmployeeID, error, setAdvancedSearch, isLoading, setFirstName, setLastName, HandleSubmit }) {
  return (
    <Form onSubmit={(e)=>HandleSubmit(e)}>
        <div className='four-column-div'>
            <Form.Control onChange={(e)=>setEmployeeID(e.target.value)} placeholder='Employee ID' />
            <Form.Control onChange={(e)=>setFirstName(e.target.value)} placeholder='First Name' />
            <Form.Control onChange={(e)=>setLastName(e.target.value)} placeholder='Last Name' />
            <div className='right-align'>
                <Button 
                    disabled={isLoading} 
                    type='submit' 
                    className='standard-button label-gap'>
                    {isLoading?'Searching...':'Search'}
                </Button>
                <Button 
                    className='standard-button' 
                    variant='secondary' 
                    onClick={()=>setAdvancedSearch(true)}>
                    Advanced Search
                </Button>
            </div>
        </div>
        {error&&<Alert variant='warning'>{error}</Alert>}
    </Form>
  )
}

export default BasicEmployeeSearch