import { AgGridReact } from "ag-grid-react";
import { Button } from "react-bootstrap";
import Select from "react-select";

export default function GMReportView({
    reportName,
    setReportName,
    defaultColDef,
    columnDefs,
    rowData
}){
    return(
        <>
        <div className="header-card">
            <div className="two-col-even">
                <h3>Budget Tracker</h3>
                <Select />
            </div>
            <div className="div-vertical-margin">
                <Button
                    className="module-button"
                    onClick={()=>{setReportName('Budget VS Actual')}}
                >Budget VS Actual</Button>
                <Button
                    className="module-button"
                    onClick={()=>{setReportName('4-Week Lookback')}}
                >4-Week Look Back</Button>
            </div>
        </div>
        <div className="card oap-card">
            <AgGridReact 
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowData={rowData}
            />
        </div>
        </>
    )
}