import React from 'react'
import { Row, Col, Modal, Form, Button, Alert, Spinner } from 'react-bootstrap'
import './AddCustomListPopup.css'
import AllBranchesMulti from 'Components/Compliance/Grid/AdvancedSearch/AllBranches/AllBranchesMulti';

function AddCustomListPopup({ 
    postObj,
    canPost, 
    status, 
    show, 
    HandleClose, 
    HandleChange, 
    HandleSubmit, 
    nameError, 
    descriptionError,
    defaultPostObj,
    selectedBranches,
    setSelectedBranches
}){

    return(
        <Modal show={show} onHide={(e)=>HandleClose(e)}>
            <Form onSubmit={(e)=>HandleSubmit(e)}>
                    <Modal.Header>
                        <h5>{defaultPostObj?'Edit':'Add'} Custom List</h5>
                    </Modal.Header>
                    {(status===200)?
                    <Modal.Body>
                        <h5>Successfully posted!</h5>
                    </Modal.Body>
                    :
                    <Modal.Body>
                        <Form.Label>Name<span className='red-text'>*</span></Form.Label>
                        <Form.Control value={postObj.LabelName} onChange={(e)=>HandleChange(e,'LabelName')}/>
                        {nameError?<Alert variant='warning' className='margin-top'>Name is required</Alert>:null}

                        <Form.Label>Description<span className='red-text'>*</span></Form.Label>
                        <Form.Control value={postObj.LabelDescription} onChange={(e)=>HandleChange(e,'LabelDescription')} />
                        {descriptionError?<Alert variant='warning' className='margin-top'>Description is required</Alert>:null}

                        <Form.Label>Branch <span className='red-text'>*</span></Form.Label>
                        {/* Gotta make a new all branches that handles isMulti */}
                        <AllBranchesMulti selectedBranches={selectedBranches} setSelectedBranches={setSelectedBranches} />

                    </Modal.Body>
                    }
                    <Modal.Footer>
                        <Row className='full-width'>
                            <Col>
                                <Button className='full-width' variant="secondary" onClick={(e)=>HandleClose(e)}>Close</Button>
                            </Col>
                            <Col>
                            {(status&&status===200)?
                                <Alert>Success!</Alert>
                            :
                                <Button className='full-width' variant="primary" type='submit'>
                                    {canPost?<Spinner animation='border' size='sm' />:'Save changes'}
                                </Button>
                            }
                            </Col>
                        </Row>
                    </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddCustomListPopup;