import React from 'react'
import { Modal, Form, Button, Spinner } from 'react-bootstrap'
import Select from 'react-select'

function AddBranchToOrder({
  show,
  setShow,
  jobOrderID,
  branches,
  HandleBranchChange,
  setCanPost,
  status,
  loading
}) {
  return (
    <>

    <Modal show={show} onHide={setShow}>
      <Modal.Header closeButton>
        <Modal.Title>Add Branch to Job Order</Modal.Title>
      </Modal.Header>
      {!status?    
      <Modal.Body>
        <Form.Label>Branches:</Form.Label>
            {branches&&
                <Select options={branches.map(branch=>{return{value:branch,label:branch.BranchName}})} isMulti onChange={(e)=>HandleBranchChange(e)} />
            }
        <Form.Label>Job Order ID:</Form.Label>
        <Form.Control disabled value={jobOrderID}></Form.Control>
      </Modal.Body>
      :
      <Modal.Body>
        {(status===200)?
        <Form.Label>Success!</Form.Label>
        :
        <Form.Label>Update failed, please try again.</Form.Label>
        }
      </Modal.Body>
      }
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>setShow(false)}>
          Close
        </Button>
        <Button variant="primary" disabled={status} onClick={()=>setCanPost(true)}>
          {loading?<Spinner animation='border'/>:'Save Changes'}
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default AddBranchToOrder