import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import AdvancedSearch from './AdvancedSearch';

function AdvancedSearchLogic({ currentGrid, setData, setLoading, customSelectStyles }){
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const [today] = useState(new Date().toJSON().split('T')[0]);
    const [postObj, setPostObj] = useState(
        {
            "OrderType": null,
            "PerformanceCode": null,
            "DateFilter": 'ActiveAsgWithinSpecifiedRange',
            "StartDate": (today+'T00:00:00.000Z'),
            "EndDate": (today+'T23:59:59.999Z'),
            "BranchName": null
        });
    const [dateFilterOptions] = useState([
        {value:null,label:'All',selected:true},
        {value:'StartDate',label:'Start Date'},
        {value:'EndDate',label:'End Date'},
        {value:'DateCreated',label:'Date Created'},
        {value:'ActiveAsgWithinSpecifiedRange',label:'Active Assignment Within Specified Range'}
]);
    const [orderTypeOptions] = useState([
        {value:null,label:'All',selected:true},
        {value:'BillBack',label:'Bill Back'},
        {value:'Payrolled',label:'Payrolled'},
        {value:'Temp',label:'Temp'},
        {value:'VendorTemp',label:'Vendor Temp'},
    ]);
    const [performanceCodeOptions, setPerformanceCodeOptions] = useState({value:null,label:'Getting codes...'});

    const {
        cookies,
        selectedBranch
    } = useContext(CustomerContext)
    const navigate = useNavigate();

    useEffect(()=>{
        GetPerformanceCodesFromDB();
    // eslint-disable-next-line
    },[])

    function GetPerformanceCodesFromDB(){
        if(!cookies.get('customer').Token){
            window.location.reload();
        }
        if(!cookies.get('customer').Token){
            window.location.reload();
        }
        fetch(`${REACT_APP_API_URL}/Compliance/GetPerformanceList?token=${cookies.get('customer').Token}`)
        .then(res=>{
            if(res.status===200){
                res.json().then(data=>{
                            TransformPerformanceCodes(data)
                        })
            }
        })
    }

    function TransformPerformanceCodes(data){
        let transformmedPerformanceCodes = data.map(code=>{
            return {value: code.Performance, label: code.Performance}
        })
        transformmedPerformanceCodes.unshift({value:null,label:'All',selected:true})
        setPerformanceCodeOptions(transformmedPerformanceCodes)
    }

    function HandleChange(e,fieldName){
        if(fieldName==='StartDate'){
            postObj.StartDate = (e.target.value+'T00:00:00.000Z')
            setPostObj(postObj)
        }else if(fieldName==='EndDate'){
            postObj.EndDate = (e.target.value+'T23:59:59.999Z')
            setPostObj(postObj)
        }else{
            postObj[fieldName] = e.value;
            setPostObj(postObj)
        }
    }

    function HandleSubmission(e){
        e.preventDefault();
        if(selectedBranch){
            postObj.BranchName = selectedBranch.BranchName;
        }else{
            postObj.BranchName = null;
        }
        setLoading({loading:true,message:'Requesting data...'});
        let url = GetDBUrl()
        if(!cookies.get('customer').Token){
            window.location.reload();
        }
        fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
        .then(res => {
            if(res.status===200){
                res.json().then(data=>{
                    setData(data)
                    setLoading({loading:false,message:''})
                })
            }else if(res.status===401){
                cookies.remove('customer',{path:'/'})
                navigate('/')
            }else{
                new Error(res)
            }
        })
        .catch(err=>{
            window.alert('There was an issue getting candidates, please check the console.')
            console.error(err)
        })
    }

    function GetDBUrl(){
        if(currentGrid==='COVID'){
           return `${REACT_APP_API_URL}/Compliance/GetCovidSearch?token=${cookies.get('customer').Token}`
        }else if(currentGrid==='SHT'){
            return `${REACT_APP_API_URL}/Compliance/GetSHTReportSearch?token=${cookies.get('customer').Token}`
        }else if(currentGrid==='DocStatus'){
            return `${REACT_APP_API_URL}/Compliance/GetDocStatusSearch?token=${cookies.get('customer').Token}`
        }else if (currentGrid==='Vendor'){
            return `${REACT_APP_API_URL}/Compliance/GetDocStatusVendors?token=${cookies.get('customer').Token}`
        }else if(currentGrid==='Everify'){
            return `${REACT_APP_API_URL}/Compliance/GetEmployeeVerifySearch?token=${cookies.get('customer').Token}`
        }
    }

    return(
        <AdvancedSearch
            dateFilterOptions={dateFilterOptions}
            orderTypeOptions={orderTypeOptions}
            performanceCodeOptions={performanceCodeOptions}
            HandleSubmission={HandleSubmission}
            HandleChange={HandleChange}
            today={today}
            postObj={postObj}
            customSelectStyles={customSelectStyles}
        ></AdvancedSearch>
    )
}

export default AdvancedSearchLogic;