import React from 'react'
import { Form, DropdownButton, ButtonGroup, Dropdown, Button, Spinner, Alert } from 'react-bootstrap'
import Select from 'react-select';
import AutoSearchSelectLogic from 'Components/CustomComponents/AutoSearchSelect/AutoSearchSelectLogic';
import './CustomerSearch.css';

function CustomerSearch({ 
    branches, 
    setAdvancedSearch, 
    defaultValues, 
    SetPreset, 
    OnSubmit, 
    HandleChange, 
    HandleOptionChange, 
    searching, 
    alert, 
    hireStatusOptions, 
    washedStatusOptions 
}){
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const customSelectStyles = {
        control: base => ({
            ...base,
            width: '300px'
        })
      };

    return(
        <Form className='candidate-search-form'>
            <div className='three-column-div div-vertical-margin left-margin wrap-overflow'>
                <div className='left-align'>
                    <p className='label-gap'>Branch:</p>
                    {branches?
                        <Select 
                            isMulti 
                            options={branches.map(branch=>{return {value:branch,label:branch.BranchName}})} 
                            defaultValue={defaultValues.Branches} 
                            onChange={(e)=>HandleOptionChange(e,'Branches')} 
                            styles={customSelectStyles}
                        />
                    : <Spinner animation='border' />}
                </div>
                <div className='left-align'>
                    <p className='label-gap'>Include candidates:</p>
                    <Select 
                        defaultValue={defaultValues.IsCandidate}  
                        options={[{value:true, label:'Yes'},{value:false, label:'No'}]} 
                        onChange={(e)=>HandleChange(e, 'IsCandidate')}
                        styles={customSelectStyles}
                    />
                </div>
                <div className='left-align'>
                    <p className='label-gap'>Interest Codes:</p>
                    <AutoSearchSelectLogic 
                        apiUrl={`${REACT_APP_API_URL}/Candidates/GetInterestCodes`} 
                        OnChange={(e)=>HandleOptionChange(e,'InterestCodes')} 
                        labelName={'InterestCode'}
                        customSelectStyles={customSelectStyles} 
                    />
                </div>
            </div>

            <div className='three-column-div div-vertical-margin left-margin'>
                <div className='left-align'>
                    <p className='label-gap'>Is Assigned:</p>
                    <Select 
                        defaultValue={defaultValues.IsAssigned} 
                        options={[{value:false,label:'No'},{value:true,label:'Yes'}]} 
                        onChange={(e)=>HandleChange(e,'IsAssigned')} 
                        styles={customSelectStyles}
                    />

                </div>
                <div className='left-align'>
                    <p className='label-gap'>Days since last paid:</p>
                    <Form.Control 
                        defaultValue={0} 
                        onChange={(e)=>HandleChange(e,'PayDateCount')} 
                        type="number"
                        className='input-text-modal-small'
                    />

                </div>
                <div className='left-align'>
                    <p className='label-gap'>Hire Status:</p>
                    <Select 
                        defaultValue={defaultValues.HireStatus} 
                        isMulti={true} 
                        onChange={(e)=>HandleOptionChange(e,'HireStatus')} 
                        options={hireStatusOptions} 
                        styles={customSelectStyles}
                    />

                </div>
            </div>
            
            <div className='three-column-div div-vertical-margin left-margin'>
                <div className='left-align'>
                    <p className='label-gap'>Washed Status:</p>
                    <Select 
                        defaultValue={defaultValues.WashStatus}    
                        isMulti={true} 
                        onChange={(e)=>HandleOptionChange(e,'WashStatus')} 
                        options={washedStatusOptions}
                        styles={customSelectStyles}
                    />
                </div>
                <div className='left-align'>
                    <p className='label-gap'>Zip:</p>
                    <AutoSearchSelectLogic 
                        apiUrl={`${REACT_APP_API_URL}/Candidates/GetCityZips`} 
                        OnChange={(e)=>HandleOptionChange(e,'Zip')} 
                        isMulti={false} 
                        labelName={'Zip'} 
                        customSelectStyles={customSelectStyles}
                    />

                </div>
                <div className='left-align'>
                    <p className='label-gap'>Distance:</p>
                    <Form.Control 
                        placeholder='Distance' 
                        onChange={(e)=>HandleChange(e,'Zip')} 
                        className='input-text-modal-small'
                    />
                </div>
            </div>
            <div className='two-col-even div-vertical-margin left-margin'>
                <div className='left-align'>
                    <p className='label-gap'>Employee Created Date Range:</p>
                    <Form.Control 
                        onChange={(e)=>HandleChange(e,'DateCreatedStart')} 
                        defaultValue={defaultValues.DateCreatedStart.split('T')[0]} 
                        type='date'
                        className='input-text-modal-small'
                    />
                    <Form.Control 
                        onChange={(e)=>HandleChange(e,'DateCreatedEnd')} 
                        defaultValue={defaultValues.DateCreatedEnd.split('T')[0]} 
                        type='date'
                        className='input-text-modal-small'
                    />
                </div>
                <div className='double-select'>
                    <DropdownButton 
                        variant='secondary' 
                        as={ButtonGroup} 
                        title={'Presets'}
                        className='label-gap standard-button'
                    >
                        <Dropdown.Item onClick={(e)=>SetPreset('Recent Applicants')}>Recent Applicants</Dropdown.Item>
                    </DropdownButton>
                    <Button 
                        variant='secondary' 
                        onClick={(e)=>setAdvancedSearch(false)}
                        className='label-gap standard-button'
                    >Basic Search</Button>
                    <Button 
                        disabled={searching} 
                        onClick={OnSubmit}
                        className='standard-button'
                    >{searching ?
                        <><Spinner as="span" animation="border" size="sm" variant="light" /></>
                        : 
                        'Search'
                    }</Button>
                    {alert?
                    <Alert variant={alert.variant}>{alert.message}</Alert>
                    :null}
                </div>
            </div>       
        </Form>
    )
}

export default CustomerSearch;