import { Button, Modal } from "react-bootstrap";
import CustomerInfoForm from "./CustomerDefs/CustomerInfoForm";
import CommonIdentifiers from "./ColumnMapping/CommonIdentifiers";
import TotalsByColumn from "./ColumnMapping/TotalsByColumn";
import TotalsByType from "./ColumnMapping/TotalsByType";
import PunchesInOut from "./ColumnMapping/PunchesInOut";
import PunchesByColumn from "./ColumnMapping/PunchesByColumn";
import PostCustomerDefinition from "../PostCustomerDefinition";

export default function AddCustomerView({
    cookies,
    show,
    onHide,
    formPage,
    setFormPage,
    customerIdentifiers,
    columnMapDefinition,
    GetColumnMapping,

    customerInfoFormSet,
    customerList,
    customSelectStyles,

    commonIdentifierSet,
    totalsByColumnSet,
    totalsByTypeSet,
    punchesInOutSet,
    punchesByColumnSet,

    processTypeDeterminent
}){
    return(
        <>
            <Modal dialogClassName="modal-70w" show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <h3>Add/Edit Customer Profile</h3>   
                </Modal.Header>
                {formPage===1&&<CustomerInfoForm
                    cookies={cookies}
                    customerInfoFormSet={customerInfoFormSet}
                    customSelectStyles={customSelectStyles}
                    customerList={customerList}
                />}
                {formPage===2&&
                <Modal.Body>
                    <div  className="vertical-card-fit">
                        <h5>Column Mapping</h5>
                        <div>
                            <div className="flex-card-row">
                                <CommonIdentifiers
                                    commonIdentifierSet={commonIdentifierSet}
                                />
                            </div>
                            <div className="flex-card-row">
                                {processTypeDeterminent==="Totals: By Column" && <TotalsByColumn
                                   totalsByColumnSet={totalsByColumnSet}
                                />}
                                {processTypeDeterminent==="Totals: By Type"&&<TotalsByType
                                    totalsByTypeSet={totalsByTypeSet}
                                />}
                                {processTypeDeterminent==="Punches: In/Out"&&<PunchesInOut
                                    punchesInOutSet={punchesInOutSet}
                                />}
                                {processTypeDeterminent==="Punches: By Column"&&<PunchesByColumn
                                    punchesByColumnSet={punchesByColumnSet}
                                />}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                }
                <Modal.Footer>
                    {formPage===1&&<Button className="aging-buttons" onClick={()=>setFormPage(2)}>Next</Button>}
                    {formPage===2&&<>
                        <Button 
                            className="aging-buttons"
                            onClick={()=>GetColumnMapping()}
                    >Save</Button>
                    <Button 
                            className="aging-buttons"
                            onClick={()=>{PostCustomerDefinition(customerIdentifiers, columnMapDefinition, cookies); onHide();}}
                    >Submit</Button>
                    </>}
                </Modal.Footer>
            </Modal>
        </>
    )
}