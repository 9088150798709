import ResearchErrors from "../Research/ResearchErrors";
import ActiveGrid from "../ActiveGrid";
import MatchGrid from "../MatchGrid";
import ErrorGrid from "../ErrorGrid";
import ErrorHelp from "../ErrorHelp";
import InstructionSheet from "../InstructionSheet";

export default function GridControllerView({
  gridViews,
  gridRowData,
  gridColumns,
  commonGridItems,
  getTimecardCSS,
  instructionHandlers,

  auditData,
  hoursTotals,
  researchAddedParams,

  delayedMergeRows,
  HandleRestart,
  HandleContinue
}) {
  return (
    <>
      {!gridViews.isResearchView && <div className={getTimecardCSS()}>

        {gridViews.showActiveGrid && <ActiveGrid
          rowData={gridRowData.rowData}
          timecardColumns={gridColumns.timecardColumns}
          defaultColDef={commonGridItems.defaultColDef}
          setGridApi={commonGridItems.setGridApi}
        />}
        {gridViews.showMatchGrid && <MatchGrid
          matchRowData={gridRowData.matchRowData}
          matchRowCols={gridColumns.matchRowCols}
          defaultColDef={commonGridItems.defaultColDef}
        />}
        {gridViews.showWelcomeCard && <InstructionSheet
          selectedCustomer={commonGridItems.selectedCustomer}
          rowData={gridRowData.rowData}
          activeReviewed={instructionHandlers.activeReviewed}
          matchRowData={gridRowData.matchRowData}
          sheetsExported={instructionHandlers.sheetsExported}
        />}
        {gridViews.showErrorGrid && <ErrorGrid
          definedMismatchError={auditData.definedMismatchError}
          erroredRows={gridRowData.erroredRows}
          timecardColumns={gridColumns.timecardColumns}
          defaultColDef={commonGridItems.defaultColDef}
          delayedMergeRows={gridRowData.delayedMergeRows}
          erroredLines={gridRowData.erroredLines}
        />}
        {!gridViews.showWelcomeCard && <ErrorHelp
          timecardHoursTotal={hoursTotals.timecardHoursTotal}
          mappingHoursTotal={hoursTotals.mappingHoursTotal}
          HandleRestart={HandleRestart}
          HandleContinue={HandleContinue}
        />}
      </div>}
      {gridViews.isResearchView &&
        <ResearchErrors
          defaultColDef={commonGridItems.defaultColDef}
          delayedRows={gridRowData.delayedRows}
          delayedCols={gridColumns.delayedCols}

          mergeData={gridRowData.mergeData}
          mergeCols={gridColumns.mergeCols}
          delayedMergeRows={delayedMergeRows}

          auditData={auditData}
          calculatedSums={instructionHandlers.calculatedSums}
          hoursTotals={hoursTotals}
          researchAddedParams={researchAddedParams}
          columnMap={instructionHandlers.columnMap}
        />
      }
    </>
  )
}