import { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";

export default function MatchGrid({
  matchRowData,
  matchRowCols,
  defaultColDef
}) {
  const onFirstDataRendered = (params) => {
    params.columnApi.autoSizeAllColumns();
  };

  const rowClassRules = useMemo(() => {
    if (matchRowData) {
      return {
        'mismatch-warn': 'data.AssignmentID === "Mismatch"'
      }
    }
  }, [matchRowData]);

  return (
    <>
      <div className="card oap-card">
        <h5>Calculated Totals</h5>
        <div className="ag-theme-balham ag-border-dark">
          <AgGridReact
            rowData={matchRowData}
            onFirstDataRendered={onFirstDataRendered}
            columnDefs={matchRowCols}
            defaultColDef={defaultColDef}
            rowClassRules={rowClassRules}
          />
        </div>
      </div>
    </>
  )
}