import React from "react";
import '../../Payroll.css';
import { AgGridReact } from "ag-grid-react";
import { Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import PasteInvoices from "../PasteInvoices";
import { BsDownload, BsArrowClockwise } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaBalanceScaleLeft } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import { BiPaste } from "react-icons/bi";
import { TiUpload } from "react-icons/ti";
import Select from "react-select";

export default function OAPReconcileView({
    oapColDefs,
    defaultColDef,
    onFirstDataRendered,
    onCounterChanged,
    HandleDuplicateRows,
    duplicationCounter,
    HandleDeleteRows,
    rowClassRules,
    showPasteInvoices,
    setShowPasteInvoices,
    OnPasteInvoices,
    selectedOap,
    selectedOapRowData,
    HandleExportProgress,
    HandleReconcileNegatives,
    HandleLoadMatched,
    dataHold,
    processingWeek,
    employer,
    HandleRefreshData,
    gridApi,
    selectedRep,
    repSelection,
    HandleRepSelection,
    customSelectStyles,
    reconcileRows,
    setReconcileRows,
    headerExpanded,
    onRowValueChanged
}) {
    return (
        <>
            {showPasteInvoices && <PasteInvoices
                reconcileRows={reconcileRows}
                setReconcileRows={setReconcileRows}
                selectedOap={selectedOap}
                selectedOapRowData={selectedOapRowData}
                showPasteInvoices={showPasteInvoices}
                setShowPasteInvoices={setShowPasteInvoices}
                dataHold={dataHold}
                processingWeek={processingWeek}
                employer={employer}
                gridApi={gridApi}
                selectedRep={selectedRep}
                HandleRefreshData={HandleRefreshData}
            />}
            <div className={headerExpanded ? "card oap-card resized-card" : "card oap-card tall-card"}>
                
                <div className="middle-card">
                    <div className="button-div">
                        <p>Select Rep:</p>                        
                        <Select
                            options={repSelection}
                            value={selectedRep}
                            defaultValue={{vale:'All', label:'All'}}
                            placeholder="Select Rep"
                            onChange={(e) => HandleRepSelection(e)}
                            styles={customSelectStyles}
                            className="totals-gap"
                        />
                        <div className="grouped-buttons">
                            <OverlayTrigger overlay={<Tooltip
                                style={{position:"fixed"}}
                            >Paste Invoices</Tooltip>}>
                                <Button
                                    onClick={(e) => OnPasteInvoices()}
                                    className="aging-buttons"
                                ><BiPaste/></Button>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip
                                style={{position:"fixed"}}
                            >Load Matched Invoices</Tooltip>} >
                                <Button
                                    onClick={()=>{HandleLoadMatched();HandleRefreshData()}}
                                    className="aging-buttons"
                                ><TiUpload /></Button>
                            </OverlayTrigger>
                        </div>

                        <div className="grouped-buttons">
                            <div className="grouped-input">
                                <div className="label-gap ">
                                    <Form.Control
                                        type="number"

                                        value={duplicationCounter}
                                        onChange={(e) => onCounterChanged(e)}
                                        
                                    />
                                </div>
                                <OverlayTrigger overlay={<Tooltip
                                    style={{position:"fixed"}}
                                >Duplicate Rows</Tooltip>} >
                                    <Button
                                        onClick={(e) => HandleDuplicateRows(e)}
                                        className="aging-buttons"
                                    ><MdContentCopy /></Button>
                                </OverlayTrigger>
                            </div>
                            <OverlayTrigger overlay={<Tooltip
                                style={{position:"fixed"}}
                            >Delete Selected</Tooltip>} >
                                <Button
                                    onClick={(e) => HandleDeleteRows(e)}
                                    className="aging-buttons red-background"
                                ><RiDeleteBin6Line/></Button>
                            </OverlayTrigger>
                        </div>
                        <div>
                            <OverlayTrigger overlay={<Tooltip
                                style={{position:"fixed"}}
                            >Balance Selected Invoices</Tooltip>} >
                                <Button
                                    onClick={()=>HandleReconcileNegatives()}
                                    className="aging-buttons"
                                ><FaBalanceScaleLeft /></Button>
                            </OverlayTrigger>   
                        </div>
                        <div>
                            <OverlayTrigger overlay={<Tooltip
                                style={{position:"fixed"}}
                            >Refresh Data</Tooltip>} >
                                <Button
                                    onClick={()=>HandleRefreshData()}
                                    className="aging-buttons green-background "
                                ><BsArrowClockwise /></Button>
                            </OverlayTrigger>  
                        </div>
                        <div className="grouped-buttons">
                            <OverlayTrigger overlay={<Tooltip
                                style={{position:"fixed"}}
                            >Export Progress</Tooltip>} >
                                <Button
                                    onClick={() => HandleExportProgress()}
                                    className="aging-buttons"
                                ><BsDownload/></Button>
                            </OverlayTrigger> 
                            
                        </div>
                    </div>
                </div>
                <div className="ag-theme-alpine ag-border-dark">
                    <AgGridReact
                        rowData={reconcileRows}
                        onFirstDataRendered={onFirstDataRendered}
                        columnDefs={oapColDefs}
                        defaultColDef={defaultColDef}
                        rowSelection={'multiple'}
                        rowClassRules={rowClassRules}
                        rowMultiSelectWithClick={true}
                        onRowValueChanged={onRowValueChanged}
                        editType={"fullRow"}
                    />
                </div>
            </div>
        </>
    )
}