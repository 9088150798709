import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import CandidateStatusFormLogic from './CandidateStatusForm/CandidateStatusFormLogic';
import MessageCodeFormLogic from '../../ContactInformation/MessageCodeForm/MessageCodeFormLogic';
import './CandidateStatusPopup.css'
import DoNotCall from '../../ContactInformation/DoNotCall/DoNotCall';
import openCellInNewTab from 'Components/CustomComponents/openCellInNewTab';

function CandidateStatusPopup({ 
    show, 
    candidateInfo,
    defaultRequestID,
    handleClose, 
    doNotCallPopup,
    setDoNotCallPopup,
    openInNewTab
 }) {
  return (
    <>
    {doNotCallPopup&&<DoNotCall show={doNotCallPopup} setShow={setDoNotCallPopup} candidateInfo={candidateInfo} />}
    <Modal onHide={handleClose} show={show} size="lg">
            <Modal.Header closeButton>
            <Modal.Title className='flex-row'>
              <h3>{candidateInfo.FirstName+' '+candidateInfo.LastName}</h3>
              <h3 onClick={(e)=>openInNewTab('https://beyond.ontempworks.com/employee/'+candidateInfo.EmployeeID)} className='linked-cell'>{candidateInfo.EmployeeID}</h3>
              <h3> | OrderID</h3>
              <h3 onClick={(e)=>openInNewTab('https://beyond.ontempworks.com/joborder/'+candidateInfo.OrderID)} className='linked-cell'> {candidateInfo.OrderID}</h3>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {doNotCallPopup&&<DoNotCall show={doNotCallPopup} setShow={setDoNotCallPopup} candidateInfo={candidateInfo} />}
              <div className='contact-information-horizontal margin-bottom'>        
                  {candidateInfo.DoNotCall&&
                  <Alert variant='warning'>Candidate is flagged for Do Not Call. Please check message codes to see when the Do Not Call flag was set before making contact.</Alert>}
                  <div className='contact-info-p-wrapper'>
                      <b>Phone: </b>
                      <p onClick={(e)=>openCellInNewTab('tel:'+candidateInfo.CellPhoneNumber,e)} className='linked-cell'>{candidateInfo.CellPhoneNumber}</p>
                  </div>
                  <div className='contact-info-p-wrapper'>
                  </div>
                  <div className='contact-info-p-wrapper'>
                      <b>Email: </b>
                      <p onClick={(e)=>openInNewTab('mailto:'+candidateInfo.EmailAddress)} className='linked-cell'>{candidateInfo.EmailAddress}</p>
                  </div>
                  <Button onClick={()=>setDoNotCallPopup(true)} variant={candidateInfo.DoNotCall?'success':'danger'} className='full-width'>{candidateInfo.DoNotCall?'Set Looking For Work':'Set Not Looking For Work'}</Button>
              </div>
                <Row>
                  <Col>
                    <CandidateStatusFormLogic
                        CandidateID={candidateInfo.CandidateID}
                        defaultRequestID={defaultRequestID}
                        OrderRequestID={candidateInfo.OrderID}
                        defaultStatusID={candidateInfo.StatusID}
                        />
                  </Col>
                  <Col>
                    <MessageCodeFormLogic employeeID={candidateInfo.EmployeeID} />
                  </Col>
                </Row>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default CandidateStatusPopup;