import '../../Payroll.css';
import { AgGridReact } from "ag-grid-react";
import { Button, Form } from 'react-bootstrap';
import LoadCashReport from '../FileHandling/LoadCashReport';
import Select from 'react-select';

export default function LockBoxPrepView({
    headerExpanded,
    onFirstDataRendered,
    reportRows,
    setCashReportData,
    cashReportColDefs,
    defaultColDef,

    HandleExportPaid,
    researchSelection,
    HandleTypeSelection,
    selectedType,
    customSelectStyles,
    pinnedBottomRowData
}) {
    return (
        <>
            <div className={headerExpanded ? "card oap-card resized-card" : "card oap-card tall-card"}>
                <div className='middle-card'>
                    <div className="labelled-loader">
                        <p className="label-gap">Load Cash Report:</p>
                        <Form.Group>
                            <Form.Control
                                type="file"
                                size="sm"
                                onChange={(e) => LoadCashReport(e, setCashReportData)}
                            />
                        </Form.Group>
                        <div className='totals-gap'>
                            <Select
                                options={researchSelection}
                                value={selectedType}
                                placeholder={"Select Results Type"}
                                onChange={(e)=>HandleTypeSelection(e)}
                                styles={customSelectStyles}
                            />
                        </div>
                        <Button
                            className='standard-button totals-gap'
                            onClick={()=>HandleExportPaid()}
                        >Export</Button>
                    </div>
                </div>
                <div className="ag-theme-alpine ag-border-dark">
                    <AgGridReact
                        rowData={reportRows}
                        columnDefs={cashReportColDefs}
                        defaultColDef={defaultColDef}
                        onFirstDataRendered={onFirstDataRendered}
                        pinnedBottomRowData={pinnedBottomRowData}
                    />
                </div>
            </div>
        </>
    )
}