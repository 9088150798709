import React, { useState, useEffect, useContext } from "react";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import ReportSearchView from "./ReportSearchView";
import { FormatDate } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";
import HandleViewDocSample from "./HandleViewDocSample";

export default function ReportSearch(){
  const { cookies } = useContext(CustomerContext);
  const [gridApi, setGridApi] = useState();
  const [formListData, setFormListData] = useState();
  const [formListCols, setFormListCols] = useState();
  const [gridName, setGridName] = useState("Document Search");
  const [loading, setLoading] = useState(false);
  const [defaultColDefs] = useState({
    sortable: true,
    resizable: true,
    filter: true,       
  });
  const [selectedDocType, setSelectedDocType] = useState();
  const [selectedDocChange, setSelectedDocChange] = useState();
  const [selectedFormName, setSelectedFormName] = useState();

  const nowDate = new Date().toLocaleDateString("en-CA");
  const lastMonth = new Date(new Date().setDate(new Date().getDate() - 30)).toLocaleDateString("en-CA");

  useEffect(()=>{
    if(formListData){
      if(gridName === "Document Search"){
        setFormListCols([
          {
            field: "DocDate", 
            checkboxSelection: true, 
            headerCheckboxSelection: true,
            valueFormatter: (data) => FormatDate(data)
          },
          {field: "DocId"},
          {field: "DocName", cellClass: 'linked-cell'},
          {field: "DocType"},
          {field: "DocTypeId"},
          {field: "EmployeeID", cellClass: 'linked-cell'},
          {field: "FileType"},
          {field: "FirstName"},
          {field: "LastName"}
        ]);
      }
      if(gridName === "Name Count"){
        setFormListCols([
          {field: "DocName"},
          {field: "DocCount"},
          {field: "MinDate", valueFormatter: (data) => FormatDate(data)},
          {field: "MaxDate", valueFormatter: (data) => FormatDate(data)}
        ]);
      }
    }
    // eslint-disable-next-line
  },[formListData, gridName]);

  
  // const prevWeek = nowDate.setDate(nowDate.getDate() -7);
  //const prevWeekDate = new Date(prevWeek.getFullYear(), prevWeek.getMonth(), prevWeek.getDate());
  
  const onFirstDataRendered = (params) => { 
    setGridApi(params.api);
    params.api.autoSizeAllColumns();
  };

  const HandleExportFormData = (params) => {
    gridApi.exportDataAsCsv(params);
  }

  const HandleSwitchTabs = (name) => {
    setGridName(name);
    ClearSearch();
  };

  const ClearSearch = () => {
    setSelectedDocType();
    setSelectedDocChange();
    setFormListCols();
    setFormListData();
    setSelectedFormName();
    console.log(selectedDocType);
    document.getElementById("searchForm").reset();
    document.getElementById("customName").reset();
  }

  const HandleClickDocLink = (e) => {
    if (e.colDef.field === "EmployeeID"){
      openInNewTab('https://beyond.ontempworks.com/employee/' + e.data.EmployeeID)
    } else if (e.colDef.field === "DocName"){
      HandleViewDocSample(e, cookies);
    }
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  };

  return (
    <>
      <ReportSearchView 
        formListData={formListData}
        setFormListData={setFormListData}
        formListCols={formListCols}
        defaultColDefs={defaultColDefs}
        cookies={cookies}
        onFirstDataRendered={onFirstDataRendered}
        HandleExportFormData={HandleExportFormData}
        gridApi={gridApi}
        gridName={gridName}
        HandleSwitchTabs={HandleSwitchTabs}
        loading={loading}
        setLoading={setLoading}
        selectedDocType={selectedDocType}
        setSelectedDocType={setSelectedDocType}
        selectedFormName={selectedFormName}
        setSelectedFormName={setSelectedFormName}
        HandleClickEmployeeId={HandleClickDocLink}
        ClearSearch={ClearSearch}
        selectedDocChange={selectedDocChange}
        setSelectedDocChange={setSelectedDocChange}
        todayDate={nowDate}
        prevDate={lastMonth}
      />
    </>
  )
}