import React, {useState, useEffect} from "react";
import GMReportView from "./GMReportView";

export default function GMReport(){


    const [reportName, setReportName] = useState('Budget VS Actual');
    const [columnDefs, setColumnDefs] = useState();
    const [rowData, setRowData] = useState();
    const [defaultColDef] = useState({
        sortable: true,
        resizable: true,
        filter: true,
    });

    useEffect(()=>{
        if(reportName&&reportName==='Budget VS Actual'){
            setColumnDefs([
                {field: "Branch"},
                {field: "Date"},
                {field: "SalesBudgetStatus"},
                {field: "GPBudgetStatus"},
                {field: "ActualSales"},
                {field: "SalesBudget"},
                {field: "ActualGPDollars"},
                {field: "GPBudget"}
            ]);
            setRowData(); //set to budget row data
        } else{
            setColumnDefs([
                {field: "Entity"},
                {field: "Branch"},
                {field: "CustomerName"},
                {field: "Hours"},
                {field: "Sales"},
                {field: "GPDollars"}
            ]);
            setRowData(); //set to 4 week loock back data see GetUpdatedMergeCols for nested headers
        }
    },[reportName]);

    return (
        <GMReportView
            reportName={reportName}
            setReportName={setReportName}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData}
        />
    )
}