import { Form } from "react-bootstrap";

export default function TotalsByType({
    totalsByTypeSet
}){
    return (
        <>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">Hours Type</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={totalsByTypeSet.hoursTypeCol}
                    onChange={(e)=>{totalsByTypeSet.setHoursTypeCol(e.target.value)}}
                />
            </div>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">Total Hours</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={totalsByTypeSet.totalHoursCol}
                    onChange={(e)=>{totalsByTypeSet.setTotalHoursCol(e.target.value)}}
                />
            </div>           
        </>
    )
}