import Form from 'react-bootstrap/Form'
import { useContext, useState, useEffect } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchDelete from 'Components/CustomComponents/Fetch/useFetchDelete';
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';

export default function CheckboxRenderer(props) {
    const {REACT_APP_API_URL} = process.env;
    const {cookies} = useContext(CustomerContext)
    const [value, setValue] = useState(props.data.IsRequired);
    const [canPost, setCanPost] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [postObj] = useState([props.data.FormID])
    const { status: postStatus, loading: postLoading } = useFetchPost((canPost&&`${REACT_APP_API_URL}/Utilities/PostCustomerRequiredDocuments?Token=${cookies.get('customer').Token}&CustomerID=${props.data.CustomerID}`),cookies.get('customer'), postObj)
    const { status: deleteStatus, loading: deleteLoading } = useFetchDelete((canDelete&&`${REACT_APP_API_URL}/Utilities/DeleteCustomerRequirementDoc?Token=${cookies.get('customer').Token}&CustomerID=${props.data.CustomerID}&FormID=${props.data.FormID}`),cookies.get('customer'))

    useEffect(()=>{
        if(postStatus){
            setCanPost(false)
        }else if(deleteStatus){
            setCanDelete(false)
        }
    },[postStatus, deleteStatus])

    const HandleValueChange = (checked) => { 
        if(checked){
            setValue(true)
            setCanPost(true)
        }else if(checked===false){
            setValue(false)
            setCanDelete(true)
        }
     }

    return (
        <span>
           <Form.Check
                type="switch"
                checked={value}
                onChange={(e)=>HandleValueChange(e.target.checked)}
                label={(deleteLoading||postLoading)?'Saving...':(postStatus===200||deleteStatus===200)?'Saved!':((postStatus&&postStatus!==200)||(deleteStatus&&deleteStatus!==200))&&'Error, try again'}
            />
        </span>
    );
}