var count = 0;
const { REACT_APP_API_URL } = process.env;

function FetchAll(url, callType, cookies, patchRowData) {
  const abortController = new AbortController();
  
  return fetch(url, {
    signal: abortController.signal,
    method: callType,
    headers: { 'Content-Type': 'application/json' },
    body: callType === 'PATCH' ? JSON.stringify(patchRowData) : null,
    })
    .then(result => {
      if (result.status === 200) {
        return result.text();
      } else if (result.status === 401) {
        cookies.remove('customer', { path: '/' })
        window.location.reload();
      } else if (result.status === 503 || result.status === 500) {
        setTimeout(() => {
          count++
          if (count < 3) { RetryFetch(url, callType, cookies, patchRowData) }
          else {throw new Error('Data did not update')}            
        }, 1000)
      }
      else if (!result.ok) {
        result.text().then(text => {
          if (text === "Database 'Employnet' cannot be opened. It is in the middle of a restore.") {
            setTimeout(() => {
              count++
              if (count < 3) { RetryFetch(url, callType, cookies, patchRowData) }
              else {throw Error(result.statusText)}
            })
          }
        })
      }
    })
    .then(returnData => {
      return (returnData ? JSON.parse(returnData) : {});
    })
    .catch(error => {
      console.error(error.message);
    });
};

function RetryFetch(url, callType, cookies, patchRowData) {
  FetchAll(url, callType, cookies, patchRowData)
};

export function GetDocs(cookies, searchString, startTime, endTime, docTypeId) {
  console.log(cookies);
  const url = docTypeId !== null ? `${REACT_APP_API_URL}/utilities/getemployeedocuments?token=${cookies.get('customer').Token}&search=${searchString}&startTime=${startTime}&endTime=${endTime}&DocTypeId=${docTypeId}` :
    `${REACT_APP_API_URL}/utilities/getemployeedocuments?token=${cookies.get('customer').Token}&search=${searchString}&startTime=${startTime}&endTime=${endTime}`;
  
    const formRows = FetchAll(url, 'GET', cookies).then(rows => {
    return rows
  });

  return formRows;
};

export function RenameForm(identifier, oldFormName, newFormName, docTypeId, cookies) {
  const url = `${REACT_APP_API_URL}/utilities/renameemployeedocument?aident=${identifier}&oldfilename=${oldFormName}&newfilename=${newFormName}&doctypeid=${docTypeId}`;
  let renamedForms = FetchAll(url, 'POST', cookies).then(forms => {
    return forms
  });

  return renamedForms;
}

export function GetDocCounts(cookies, searchString, docTypeId) {
  const url = `${REACT_APP_API_URL}/utilities/getdocumentcounts?token=${cookies.get('customer').Token}&search=${searchString}&doctypeid=${docTypeId}`
  let docCounts = FetchAll(url, 'GET', cookies).then(docs=>{
    return docs
  });

  return docCounts;
}

export function GetDocSample(cookies, docName) {
  const url = `${REACT_APP_API_URL}/utilities/getdocumentsample?token=${cookies.get('customer').Token}&docName=${docName}`;
  let docSample = FetchAll(url, 'GET', cookies).then(doc=>{
    return doc;
  });

  return docSample;
}

export function GetDocTypes(cookies){
  const url = `${REACT_APP_API_URL}/utilities/getdocumenttypes?token=${cookies.get('customer').Token}`;
  let docTypes = FetchAll(url, 'GET', cookies).then(types=>{
    return types;
  });

  return docTypes;
}

export function GetFormNames(cookies){
  const url = `${REACT_APP_API_URL}/utilities/getformlist?token=${cookies.get('customer').Token}`;
  
  let formNames = FetchAll(url, 'GET', cookies).then(names=>{
    return names;
  });
  return formNames;
}