import { FormatDecimalHundredths } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export function FindPaidInvoices(e, oapRowData, paidInvoices) {
    let rowData = e.data ? e.data : e;
    let currentPayAmount = rowData.PayAmount;

    let paidInvoice = oapRowData.find(oap => Number(oap.PayAmount) === Number(currentPayAmount) && Number(currentPayAmount) > 0);
    if (paidInvoice !== undefined) {
        paidInvoices.push(paidInvoice);
    };
}

export function GetMinimumInvoiceBalance(oapRow, oapRowData) {
    let paidInvoices = GetPaidInvoices(oapRowData);
    let rowData = oapRow.data ? oapRow.data : oapRow;
  
    let returnRow = paidInvoices.find(paid => paid.InvoiceNumber === rowData.InvoiceNumber)
    return returnRow.InvoiceBalance
}

function GetPaidInvoices(oapRowData) {
    // provides final invoice balances to GetMiniumumInvoiceBalance
    let paidInvoices = [];

    oapRowData.forEach(oap => {
        let foundInvoice = paidInvoices.find(found => found.InvoiceNumber === oap.InvoiceNumber && oap.PayAmount > 0);
        if (foundInvoice !== undefined) {
            let balance = Number(foundInvoice.InvoiceBalance) - Number(oap.PayAmount);
            foundInvoice.InvoiceBalance = balance;
        } else {
            paidInvoices.push(oap);
        };
    });

    return paidInvoices;
};

export function GetMinimumOapBalance(e, oapRowData) {
    let numberCatcher = [];
    let returnBalance = e.data ? e.data.OAPBalance : e.OAPBalance;
    let dataOapNumber = e.data ? e.data.OAPNumber : e.OAPNumber;

    let balance;

    oapRowData.forEach((row) => {
        let number = row.OAPNumber;
        let value = Number(row.OAPAmount);
        let payment = Number(row.PayAmount);

        let numberPicker = numberCatcher.find(oap => oap.OAP === number);
        if (numberPicker !== undefined) { // updates existing balance
            balance = FormatDecimalHundredths(numberPicker.Balance - payment);
            for (var item of numberCatcher) {
                if (item.OAP === numberPicker.OAP) {
                    item.Balance = balance;
                }
            }
        } else { // creates original balance
            balance = value - payment;
            numberCatcher.push({ 'OAP': number, 'Balance': balance });
        }                    
    }); 
    
    for (var oapRow of numberCatcher) { // updates balance in line with correct totals
        if (oapRow.OAP === dataOapNumber) {
            returnBalance = Number(oapRow.Balance);
        }
    }
    let finalOapBalance = FormatDecimalHundredths(returnBalance);
    return (finalOapBalance);
}