export default function GetUpdatedMergeCols(originalTimecardCols, matchRowData, setMergeCols) {
  let matchKeys = Object.keys(matchRowData[0]);
  //add EmployeeName to Match Keys as LastName + ", " + FirstName if it doesn't otherwise exist
  let idCols = [];
  let hoursCols = ['Reg', 'OT', 'DT', 'MP'];
  matchKeys.forEach(header => {
    if (header === "EmployeeName" || header === "AssignmentID" || header === "EmployeeID" || header === "LastName" || header === "FirstName") {
      let newField = {
        field: header,
        cellStyle: { 'backgroundColor': 'var(--light-blue)' },
        headerClass: 'medium-blue-background',
        pinned: 'left',
        cellDataType: 'text'
      };
      idCols.push(newField);
    }
  });
  hoursCols.forEach(col => {
    let hoursField = {
      field: col,
      cellStyle: { 'backgroundColor': 'var(--light-blue)' },
      headerClass: 'medium-blue-background',
      pinned: 'left'
    };
    idCols.push(hoursField);
  });

  let pushedMergedCols = [
    { headerName: 'Active Columns', headerClass: 'blue-background', children: idCols },
    { headerName: 'Original Timecard Columns', headerClass: 'tab-color', children: originalTimecardCols }
  ];

  setMergeCols(pushedMergedCols);
}