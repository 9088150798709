import React, { useEffect, useState, useContext } from 'react'
import { Form } from 'react-bootstrap';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import Select from 'react-select'

function ReqSelect({ defaultOption, isMulti, apiUrl, labelName, selectLabel, HandleChange, isRequired }) {
    const [options, setOptions] = useState();
    const [defaultSelectedOption, setDefaultSelectedOption] = useState()
    const {cookies} = useContext(CustomerContext)

    useEffect(()=>{
        GetOptionsFromDB(apiUrl)
    // eslint-disable-next-line
    },[apiUrl])
    const GetOptionsFromDB = (apiUrl) => { 
        fetch(apiUrl)
        .then(res=>{
            if(res.status===200){
                return res.json()
            }else if(res.status===204){
                return setOptions([{value:null, label:'No Options', isDisabled:true}])
            }else if(res.status===401){
                cookies.remove('customer', {path:'/'})
                window.location.reload();
              }else if(res.status===503||res.status===500){
                setTimeout(() => {
                  GetOptionsFromDB(apiUrl)
                }, 1000);
                return [];
              }else if(!res.ok){
                res.text().then(text=>{
                  if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                    setTimeout(() => {
                      GetOptionsFromDB(apiUrl)
                    }, 1000);
                  }else{
                    throw Error(res.statusText)
                  }
                })
              }
        })
        .then(data=>{
            if(data.data.length>0){
                TransformDataToOptions(data.data)
            }else{
                setOptions([{value:null, label:'No Options', isDisabled:true}])
            }
        })
        .catch(error=>{
            error.then(error=>{
                window.alert(error)
                console.error(apiUrl+error)
            })
        })
    }

    const TransformDataToOptions = (data) => { 
        let transformmedOptions = data.map(obj=>{
            if(defaultOption===obj[labelName]){
                setDefaultSelectedOption({value:obj,label:obj[labelName],isSelected:true})
            }
            return {value: obj, label: obj[labelName]}
        })  
        setOptions(transformmedOptions)
     }

  return (
      <>
      {options&&
      <div className='margin-bottom'>
        <Form.Label>{selectLabel}{isRequired&&<span className='red-text'>*</span>}</Form.Label>
        <Select defaultValue={defaultSelectedOption&&defaultSelectedOption} isMulti={isMulti?isMulti:false} options={options} onChange={HandleChange}></Select>
      </div>
      }
      </>
  )
}

export default ReqSelect