import React from 'react';
import { Form, Spinner } from 'react-bootstrap';
import ReqSelect from '../ReqSelect/ReqSelect';
import Select from 'react-select';
import CustomerTreeDropdown from 'Components/CustomComponents/CustomerTreeDropdown/CustomerTreeDropdown';

function Department({ 
  onMenuOpen, 
  branches, 
  defaultBranch, 
  handleBranchesChange, 
  HandleCustomerDepartmentChange, 
  formData,
  cookies,
  selectedDepartment,
  HandlePOChange,
  selectedPO,
  defaultFormData,
  branchID 
}) {
  const {REACT_APP_API_URL} = process.env;

  return (
      <>
        <Form.Label>Branches:</Form.Label>
        {(branches&&defaultBranch)?
        <Select onMenuOpen={()=>onMenuOpen('Branches')} defaultValue={defaultBranch} onChange={(e)=>handleBranchesChange(e)} options={branches.map((branch)=>{return{value:branch,label:branch.BranchName}})} />
        :
        <Spinner animation='border' />
        }

      {branchID&&
      <>
        <Form.Label>Customer/Department<span className='red-text'>*</span></Form.Label>
        {(selectedDepartment&&defaultFormData)?
          <CustomerTreeDropdown onMenuOpen={()=>onMenuOpen('Department')} defaultValue={{value:selectedDepartment,label:selectedDepartment.DepartmentName}} onChange={(e)=>HandleCustomerDepartmentChange(e)} defaultBranchIDs={branchID} />
          :
          <CustomerTreeDropdown onMenuOpen={()=>onMenuOpen('Department')} onChange={(e)=>HandleCustomerDepartmentChange(e)} defaultBranchIDs={branchID} />
        }
      </>}
      {selectedDepartment&&
        <ReqSelect defaultOption={defaultFormData&&defaultFormData.PO} HandleChange={(e)=>HandlePOChange(e)} selectLabel='POs' labelName='PONumber' apiUrl={`${REACT_APP_API_URL}/OrderRequest/getcustomerpo?token=${cookies.get('customer').Token}&customerid=${selectedDepartment.CustomerID}`} />
      }
      {selectedPO&&
      <>
      <Form.Label>PO Value</Form.Label>
      <Form.Control disabled value={selectedPO.POValue?selectedPO.POValue:'None'}></Form.Control>

      <Form.Label>PO Amount Used</Form.Label>
      <Form.Control disabled value={formData.PO.AmountUsed?formData.PO.AmountUsed:'0'}></Form.Control>

      <Form.Label>PO End Date</Form.Label>
      <Form.Control disabled value={selectedPO.POEndDate?selectedPO.POEndDate:'No End Date'}></Form.Control>
      </>
      }
      </>
  )
}

export default Department