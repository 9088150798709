import React from 'react'
import Card from 'react-bootstrap/Card';
import MessageCodeFormLogic from './MessageCodeForm/MessageCodeFormLogic';
import ContactInformationPanel from './ContactInformationPanel';

function ContactInformation({
    candidateInfo
}) {

return (
    <>
    <Card>
        <Card.Header>
            <h6>Contact Information</h6>
        </Card.Header>
        <Card.Body>
            <ContactInformationPanel candidateInfo={candidateInfo}/>
        </Card.Body>
    </Card>
    <Card className='margin-top'>
        <Card.Header>
            <h6>Add Message Code</h6>
        </Card.Header>
        <Card.Body>

            <MessageCodeFormLogic employeeID={candidateInfo.EmployeeID}/>

        </Card.Body>
    </Card>
    </>
  )
}

export default ContactInformation