import React, {useState, useEffect} from "react";
import { Alert, Button } from "react-bootstrap";

export default function EmailStatus({
    status,
    setShowMailer,
    setShowSend
}){
    const [message, setMessage] = useState();
    const [alertVariant, setAlertVariant] = useState();

    useEffect(() => {
        setShowSend(false);
        if(status===200){
            setMessage("Message Sent!");
            setAlertVariant("success");
        }else{
            setMessage("There was an error sending your message");
            setAlertVariant("danger");
        }
        // eslint-disable-next-line
    },[]);

    const HandleClick = (e) => {
        if(status===200){
            setShowMailer(false);
        }
        setShowSend(true);
    }
        
    return (
        <>
            {message &&
                <Alert variant={alertVariant}>
                    {message}
                    <Button className="emailer-button-gapped" onClick={() => HandleClick()}>Okay</Button>
                </Alert>
            }
        </>
    )
}