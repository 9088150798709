import React, { useState, useEffect } from 'react'
import { useContext } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext'
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';
import SB1162View from './SB1162View';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';

function SB1162() {
    const {cookies, setCurrentPage} = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;
    const [postObj] = useState({})
    const [endDate, setEndDate] = useState();
    const [selectedCustomer, setSelectedCustomer]= useState();
    const [branchIDs, setBranchIDs] = useState([cookies.get('customer').BranchID])
    const [customers, setCustomers]= useState();
    const [branches, setBranches] = useState();
    const [selectedBranches, setSelectedBranches] = useState();
    const [weekendDates, setWeekendDates] = useState();
    const [activeKey, setActiveKey] = useState("compliance");
    const [openPopup, setOpenPopup] = useState(false);
    const { data: unformattedBranches } = useFetchGet(`${REACT_APP_API_URL}/Compliance/GetBranches?Token=${cookies.get('customer').Token}`, cookies)
    const { data, loading: loadingBasic } = useFetchPost(((selectedCustomer&&endDate)&&`${REACT_APP_API_URL}/Reports/GetSB1162Grouped?Token=${cookies.get('customer').Token}&RootCustomerID=${selectedCustomer.value.CustomerId}&EndDate=${endDate.value}`),cookies,postObj)
    const { data: advancedData, loading: loadingAdvanced } = useFetchPost(((selectedCustomer&&endDate)&&`${REACT_APP_API_URL}/Reports/GetSB1162EmployeeLevel?Token=${cookies.get('customer').Token}&RootCustomerID=${selectedCustomer.value.CustomerId}&EndDate=${endDate.value}`),cookies,postObj)
    const { data: unformattedCustomers, status } = useFetchPost((branchIDs&&`${REACT_APP_API_URL}/Reports/GetSB1162Compliance?token=${cookies.get('customer').Token}`), cookies, branchIDs );
    const { data: unformattedWeekendDates } = useFetchGet(`${REACT_APP_API_URL}/Reports/GetSB1162Sundays?token=${cookies.get('customer').Token}`,cookies)
    const [gridApi, setGridApi] = useState();
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
    });
    const [columnDefs] = useState([
        {field:'EstablishmentName'},
        {field:'AddressLine1'},
        {field:'AddressLine2'},
        {field:'City'},
        {field:'State'},
        {field:'Zip'},
        {headerName:'NAICS Code'},
        {headerName:'Major Activity'},
        {field:'EmployeesAtEstablishment'},
        {headerName:'Was a California Pay Data Report filed for this establishment last year?'},
        {headerName:'Was an EEO-1 Report filed for this establishment last year?'},
        {headerName:"Is this establishment the employer's headquarters?"},
        {field:"LaborContractorName"},
        {field: "LaborContractorFEIN"},
        {field: 'SnapshotStartDate', headerName:"Snapshot - Begin Date", valueFormatter: ({value})=>FormatDate(value)},
        {field: 'SnapshotEndDate', headerName:"Snapshot - End Date", valueFormatter: ({value})=>FormatDate(value)},
        {field:'JobCategory'},
        {field:'RaceEthnicitySex'},
        {field:'Payband'},
        {field:'NumberOfEmployees'},
        {field:'MeanHourlyRate', valueFormatter: ({value})=>FormatFloat(value)},
        {field:'MedianHourlyRate', valueFormatter: ({value})=>FormatFloat(value)},
        {field:'TotalHours'},
        {headerName:"Row-Level Clarifying Remarks"},
    ]);
    const [advancedColumnDefs] = useState([
      {field: "EstablishmentName"},
      {field: "AddressLine1"},
      {field: "AddressLine2"},
      {field: "City"},
      {field: "State"},
      {field: "Zip"},
      {field: "EmployeesAtEstablishment"},
      {field: "LaborContractorName"},
      {field: "LaborContractorFEIN"},
      {field: "SnapshotStartDate", valueFormatter: ({value})=>FormatDate(value)},
      {field: "SnapshotEndDate", valueFormatter: ({value})=>FormatDate(value)},
      {field: "JobCategory"},
      {field: "RaceEthnicitySex"},
      {field: "Payband"},
      {field: "Gross", valueFormatter: ({value})=>FormatFloat(value)},
      {field: "ItemId"},
      {field: "PayRate", valueFormatter: ({value})=>FormatFloat(value)},
      {field: "TotalHours"}
  ])
  const [overviewColumnDefs] = useState([
    {headerName:'Sent', valueGetter: (params)=>FormatStatus(params), cellClass: params => StatusClass(params), sort: 'desc'},
    {field: "Required", valueFormatter: ({value}) => value?'Yes':'No'},
    {field: "CustomerName", headerName: 'Customer'},
    {field: "CustomerId", cellRenderer: params => LinkCell(params)},
    {field: "DepartmentName", headerName: 'Department'},
    {field: "BranchName", headerName: 'Branch'},
    {field: 'RepFullName', headerName: 'Sent By SR'},
    {field: "LatestReportSent", valueFormatter: ({value})=>FormatDate(value)},
    {field: "Message"},
  ])



    useEffect(()=>{
        setCurrentPage('SB1162') 
    // eslint-disable-next-line
      },[])

    useEffect(()=>{
      if(unformattedCustomers){
        if(unformattedCustomers.length>0){

          let uniqueRootCustomers = unformattedCustomers.map((cust)=>{
            return {value: cust, label: cust.CustomerName}
          })
          setSelectedCustomer(uniqueRootCustomers[0])
          setCustomers(uniqueRootCustomers)
        }else{
          setCustomers([])
        }
      }
      // eslint-disable-next-line
    },[unformattedCustomers])

    useEffect(()=>{
      if(unformattedWeekendDates){
        let formattedDates = unformattedWeekendDates.map(date=>{
          return {value:date.TheDate, label: date.DateValue}
        })
        setEndDate(formattedDates[1])
        setWeekendDates(formattedDates)
      }
      // eslint-disable-next-line
    },[unformattedWeekendDates])

    useEffect(()=>{
      if(unformattedBranches){
        let formattedBranches = unformattedBranches.map(branch=>{
          if(branch.BranchID===cookies.get('customer').BranchID){
            setSelectedBranches([{value: branch, label: branch.BranchName}])
            return {value: branch, label: branch.BranchName}
          }else{
            return {value: branch, label: branch.BranchName}
          }
        })
        setBranches(formattedBranches)
      }
      // eslint-disable-next-line
    },[unformattedBranches])

    const LinkCell = (params) => { 
      if(params.value){
        return <a href={`https://beyond.ontempworks.com/customer/${params.value}`} rel="noreferrer" target='_blank'>{params.value}</a>
      }
     }

    const StatusClass = (params) => { 
      if(params.data.Required){
        if(params.data.LatestReportSent){
          return 'overview-green'
        }else{
          return  'overview-orange'
        }
      }else{
        return 'overview-grey'
      }
     }

    const FormatStatus = (params) => { 
      if(params.data.Required){
        if(params.data.LatestReportSent){
          return 'Sent'
        }else{
          return 'Not Sent'
        }
      }else{
        return 'NA'
      }
     }

    const FormatFloat = (value) => { 
      if(value){
        return (Math.round(value * 100) / 100).toFixed(2);
      }else{
        return ''
      }
     }

    const FormatDate = (value) => { 
      if(value){
        return value.split('T')[0]
      }else{
        return ''
      }
     }

      const onGridReady = (params) => { 
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns({allColumns:true});
     }

     const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    };

    const HandleBranchChange = (params) => { 
      setCustomers(null)
      if(params.length>0){
        let newBranchIDs = params.map(obj=>obj.value.BranchID)
        setBranchIDs(newBranchIDs)
        setSelectedCustomer(null)
        setSelectedBranches(params)
      }else{
        setBranchIDs([])
        setSelectedCustomer(null)
        setSelectedBranches(null)
      }
     }

    const HandleKeyChange = (key) => { 
      if(key==='advanced'){
        setOpenPopup(true);
      }else{
        setActiveKey(key)
      }
     }


  return (
    <SB1162View
        data={data?data:[]}
        endDate={endDate}
        setEndDate={setEndDate}
        defaultColDef={defaultColDef} 
        columnDefs={columnDefs} 
        onGridReady={onGridReady}
        onBtnExport={onBtnExport}
        customers={customers}
        weekendDates={weekendDates}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        advancedData={advancedData?advancedData:[]}
        advancedColumnDefs={advancedColumnDefs}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        HandleKeyChange={HandleKeyChange}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        loadingBasic={loadingBasic}
        loadingAdvanced={loadingAdvanced}
        branches={branches}
        HandleBranchChange={HandleBranchChange}
        selectedBranches={selectedBranches}
        unformattedCustomers={unformattedCustomers}
        overviewColumnDefs={overviewColumnDefs}
        status={status}
    ></SB1162View>
  )
}

export default SB1162