import { FormatUSD } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function GetDisplayTotals(
    oapRowData,
    setTotalInOaps,
    setTotalNotReconciled,
    setTotalReconciled
) {
    let allOaps = [];
    let reconciledOaps = [];
    let notReconciled = [];

    oapRowData.forEach(oap => {
        let uniqueOapNumber = allOaps.find(customer => customer.OAPNumber === oap.OAPNumber);
        if (uniqueOapNumber === undefined) {
            allOaps.push(oap);
        }
    });

    reconciledOaps = allOaps.filter(oap => oap.OAPBalance <= 0 || oap.OAPBalance.isNaN);
    notReconciled = allOaps.filter(oap => oap.OAPBalance > 0);

    let totalOapSum = 0;
    let totalReconciledSum = 0;
    let totalNotReconciledSum = 0;

    allOaps.forEach(item => {
        totalOapSum += Number(item.OAPAmount);
        
    });

    reconciledOaps.forEach(item => {
        totalReconciledSum += Number(item.OAPAmount)
    });

    notReconciled.forEach(item => {
        totalNotReconciledSum += Number(item.OAPAmount);
    });


    setTotalInOaps(FormatUSD(totalOapSum));
    setTotalNotReconciled(FormatUSD(totalNotReconciledSum));
    setTotalReconciled(FormatUSD(totalReconciledSum));
}