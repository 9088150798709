import * as XLSX from "xlsx";
import NormalizeRemitColumns from "../InvoiceMatching/NormalizeRemitColumns";
import { FormatExcelSerialDate, FormatDecimalHundredths, FormatUSD, FormatNumberOnlyDate } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function LoadRemitFile(e, rowDataSetter, setRemitSum, customer) {
    let fileData = ReadRemitFile(e);

    setTimeout(() => {
        fileData.forEach(row => {
            NormalizeHeaders(row, customer);
        });

        var filteredData = fileData.filter(line => {
            return (
                line.Employee !== undefined &&
                line["Week Worked"] !== undefined &&
                line.Hours !== undefined
            );
        });

        filteredData.forEach(row => {
            FormatData(row, customer);
        })

        SumRemittance(filteredData, rowDataSetter, setRemitSum);
        
    }, 500);
};

function ReadRemitFile(e) {
    const allFiles = e.target.files;
    const file = allFiles[0];
    const reader = new FileReader();
    const returnData = [];

    reader.onload = (evt) => {
        evt.preventDefault();

        const fileRead = evt.target.result;
        const workbook = XLSX.read(fileRead, { type: "binary" });
        const wsName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[wsName];  
        const fileData = XLSX.utils.sheet_to_json(worksheet, { head: 1 });

        fileData.forEach(row => {
            returnData.push(row);
        });

    };
    reader.readAsBinaryString(file);
    return returnData;
};

function NormalizeHeaders(row, customer) {
    let normalizedColumns = NormalizeRemitColumns(customer);

    let employeeNameType = typeof row[normalizedColumns[0]];
    if (employeeNameType === "string") {
        row["Employee"] = row[normalizedColumns[0]];
    } else if (employeeNameType !== undefined) {
        row["Employee"] = row[normalizedColumns[0][1]] + " " + row[normalizedColumns[0][0]];
    } else {
        row["Employee"] = undefined;
    };

    row["ID"] = row[normalizedColumns[1]];

    row["Week Worked"] = row[normalizedColumns[2]];

    let hoursRowType = typeof row[normalizedColumns[3]];

    if (hoursRowType === "number") {
        row["Hours"] = row[normalizedColumns[3]];
    } else if (hoursRowType !== undefined) {
        let rowHoursArray = []
        normalizedColumns[3].forEach(hoursType => {
            rowHoursArray.push(row[hoursType]);
        });

        let hoursTotal = rowHoursArray.reduce((sumValue, addedValue) => sumValue + addedValue);
        row["Hours"] = hoursTotal;
    } else {
        row["Hours"] = undefined;
    };
    
    row["Paid Invoice Amount"] = row[normalizedColumns[4]];
};

function FormatData(row, customer) {

    let normalizedColumns = NormalizeRemitColumns(customer);
    let formattedEmployee = row.Employee.replaceAll(",", "");
    formattedEmployee = formattedEmployee.replace(/\s+/g, " ");
    formattedEmployee = formattedEmployee.replace(" - (VMS)", "");

    row.Employee = normalizedColumns[7] === "First Last" ? ReorderName(formattedEmployee)
    : formattedEmployee;

    parseFloat(row["Paid Invoice Amount"]);
    let formattedPayment = normalizedColumns[6] === "Calc Bill" ?
        row["Paid Invoice Amount"] - (row["Paid Invoice Amount"] * 0.01) :
        row["Paid Invoice Amount"];
    row["Paid Invoice Amount"] = formattedPayment;

    let formattedDate = normalizedColumns[5] === "Excel Date" ? FormatExcelSerialDate(row) :
    normalizedColumns[5] === "Numbers Date" ? FormatNumberOnlyDate(row["Week Worked"]) : row["Week Worked"];
    row["Week Worked"] = formattedDate;

    return row;
};

function SumRemittance(remitRowData, setRemitRowData, setRemitSum) {

    let sumData = [];

    remitRowData.forEach(line => {
        line.IsMismatched = false;
        let foundInSumData = sumData.find(item =>
            item["Week Worked"] === line["Week Worked"] &&
            item.Employee === line.Employee
        );
        if (foundInSumData) {
            foundInSumData.Hours += line.Hours;
            foundInSumData["Paid Invoice Amount"] += line["Paid Invoice Amount"];
        }
        else {
            sumData.push(line);
        }
    
    });

    let paySum = 0;

    sumData.forEach(item => {
        item["Paid Invoice Amount"] = FormatDecimalHundredths(item["Paid Invoice Amount"]);
        item.Hours = FormatDecimalHundredths(item.Hours);
        paySum += Number(item["Paid Invoice Amount"]);
    });

    setRemitRowData(sumData);
    setRemitSum(FormatUSD(paySum));
}

function ReorderName(employee) {
    let employeeNames = employee.split(" ");
    let reorderedName = employeeNames[1] + " " + employeeNames[0];
    return reorderedName;
};