export function MatchCustomers() {
    const matchCustomers = [
        { value: "Acro", label: "Acro" },
        { value: "Adecco", label: "Adecco" },
        { value: "Careers", label: "Careers USA"},
        { value: "Eastridge", label: "Eastridge"},
        { value: "Kelly", label: "Kelly Services" },
        { value: "Pitney", label: "Pitney Bowes" },
        { value: "Ulta", label: "Ulta"}
    ];

    return matchCustomers;
};