export default function NormalizeRemitColumns(customer) {
    var normalizedColumns

    switch (customer) {

        case "Acro":
            normalizedColumns = [
                "CLP Name",
                "Assignment ID",
                "Weekending Date",
                ["ST Hours", "OT Hours", "DT Hours"],
                "Payment Amount",
                "Excel Date", // not meant to change columns, but to distinguish date type to normalize data in date col
                "Bill Provided", // determines if provided total is same as pay total, or if calculation must be done to determine total
                "Last First" // name order

            ];
            break;
        
        case "Adecco":
            normalizedColumns = [
                "Employee",
                "Assignment Number",
                "Week Worked",
                "Hours",
                "Paid Invoice Amount",
                "Excel Date",
                "Bill Provided",
                "Last First"
            ];
            break;
        
        case "Careers":
            normalizedColumns = [
                "Staff Associate Name",
                "Job Number",
                "Weekending Date",
                ["Reg Hrs", "OTHrs"],
                "Sub Total",
                "Numbers Date",
                "Bill Provided",
                "Last First"
            ];
            break;
        
        case "Eastridge":
            normalizedColumns = [
                ["Worker First Name", "Worker Last Name"],
                "Vendor ID",
                "Week ending date",
                " Hours ",
                " Supplier Total ",
                "Excel Date",
                "Bill Provided",
                "Last First"
            ];
            break;
        
        case "Kelly":
            normalizedColumns = [
                "Name",
                "Voucher",
                "Week Ending Date",
                "Quantity",
                "Line Total",
                "Standard Date",
                "Bill Provided",
                "Last First"
            ];
            break;
        
        case "Pitney":
            normalizedColumns = [
                "Name",
                "Voucher",
                "Week Ending Date",
                "Quantity",
                "Line Total",
                "Standard Date",
                "Bill Provided",
                "Last First"
            ];
            break;
        
        case "Ulta":
            normalizedColumns = [
                "Worker",
                "IDColumn",
                "EndDate",
                "Hours",
                "GrandTotal",
                "Excel Date",
                "Calc Bill",
                "First Last"
            ];
            break;
        
        default:
            normalizedColumns = [
                "Employee",
                "ID",
                "Week Worked",
                "Hours",
                "Paid Invoice Amount",
                "Standard Date",
                "Bill Provided",
                "Last First"
            ];
            break;
    }

    return normalizedColumns;
};