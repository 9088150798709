import React, { useState, useEffect } from "react";
import useFetchGet from "Components/CustomComponents/Fetch/useFetchGet";
import UploadsHeaderView from "./UploadsHeaderView";
import AddCustomer from "../AddCustomer/AddCustomer";
import { GetDecodedDefList } from "../GetTimecardCustomers";
import { GetTimecardCustomers } from "../GetTimecardCustomers";
import GetWeeklyTotals from "../../HoursCalcs/GetWeeklyTotals";
import GetEmployeeDefs from "../../HoursCalcs/GetEmployeeDefs";

export default function UploadsHeader({
  loaderParams,
  exportParams,
  headerAddedParams
}) {
  const cookies = exportParams.cookies;
  const { REACT_APP_API_URL } = process.env;
  const { data: reportColumns } = useFetchGet(`${REACT_APP_API_URL}/utilities/getreportcolumnnames?token=${cookies.get('customer').Token}`);
  const { data: customers } = useFetchGet(`${REACT_APP_API_URL}/reports/getcustomerrootsfromtoken?token=${cookies.get('customer').Token}`, cookies);

  const [customerList, setCustomerList] = useState();
  const [addEditCustomer, setAddEditCustomer] = useState(false);

  const processingParams = {
    setActivReviewed: headerAddedParams.setActiveReviewed,
    gridApi: headerAddedParams.gridApi,
    processDefinitions: loaderParams.processDefinitions,
    rowData: headerAddedParams.rowData,
    cookies: cookies,
    setWeeklyCals: headerAddedParams.setWeeklyCalcs,
    setEmployeeDefs: headerAddedParams.setEmployeeDefs
  }

  useEffect(() => {
    if (reportColumns) {
      let payrollUploadRows = reportColumns.filter((reportName) => reportName.startsWith("payroll-uploads"));
      GetDecodedDefList(payrollUploadRows, cookies, headerAddedParams.setCustomerDefinitionList);
    }
    // eslint-disable-next-line
  }, [reportColumns]);

  useEffect(() => {
    if (headerAddedParams.customerDefinitionList) {
      let customerDropdown = GetTimecardCustomers(headerAddedParams.customerDefinitionList, customers);
      let sortedDropdow = customerDropdown.sort((a, b) => a.label - b.label ? 1 : b.label > a.label ? -1 : 0);
      setCustomerList(sortedDropdow);
    }
    // eslint-disable-next-line
  }, [headerAddedParams.customerDefinitionList]);

  // customer selection will trigger use effect on PayrollUploads. 
  // Top level to set top level data (firstDataRow, loaderColumns, processDefinitions)

  const HandleProcessTimecard = () => {
    headerAddedParams.setActiveReviewed(true);
    headerAddedParams.gridApi.showLoadingOverlay();
    let weeklyTotals = GetWeeklyTotals(loaderParams.processDefinitions, headerAddedParams.rowData);
    headerAddedParams.setWeeklyCalcs(weeklyTotals);
    GetEmployeeDefs(weeklyTotals, cookies, headerAddedParams.setEmployeeDefs);
  }

  return (
    <>
      <UploadsHeaderView
        customerList={customerList}
        selectedCustomer={exportParams.selectedCustomer}
        setSelectedCustomer={headerAddedParams.setSelectedCustomer}
        processingParams={processingParams}

        loaderParams={loaderParams}
        setIsResearchView={headerAddedParams.setIsResearchView}
        exportParams={exportParams}
        setAddEditCustomer={setAddEditCustomer}
        calculatedSums={headerAddedParams.calculatedSums}
        HandleProcessTimecard={HandleProcessTimecard}
      />
      {addEditCustomer && <AddCustomer
        setAddEditCustomer={setAddEditCustomer}
        customerList={customerList}
        customerDefinitionList={headerAddedParams.customerDefinitionList}
      />}
    </>
  )
}