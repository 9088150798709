//import { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomerSelection from "./CustomerSelection";
import ProcessingSelections from "./ProcessingSelections";
import EmployeeIdSelection from "./EmployeeIdSelection";
import WeekAndRowSelection from "./WeekAndRowSelection";

export default function CustomerInfoForm({
    cookies,
    customerInfoFormSet,
    customSelectStyles,
    customerList
}){
    return (
        <>
        <Modal.Body>
            <CustomerSelection
                cookies={cookies}
                customSelectStyles={customSelectStyles}
                isNewCustomer={customerInfoFormSet.isNewCustomer}
                setIsNewCustomer={customerInfoFormSet.setIsNewCustomer}
                selectedCustomer={customerInfoFormSet.selectedCustomer}
                setSelectedCustomer={customerInfoFormSet.setSelectedCustomer}
                useDepartments={customerInfoFormSet.useDepartments}
                setUseDepartments={customerInfoFormSet.setUseDepartments}
                customerID={customerInfoFormSet.customerID}
                setCustomerID={customerInfoFormSet.setCustomerID}
                displayName={customerInfoFormSet.displayName}
                setDisplayName={customerInfoFormSet.setDisplayName}
                customerList={customerList}
            />
            <ProcessingSelections
                selectedOtRule={customerInfoFormSet.selectedOtRule}
                setSelectedOtRule={customerInfoFormSet.setSelectedOtRule}
                selectedProcessType={customerInfoFormSet.selectedProcessType}
                setSelectedProcessType={customerInfoFormSet.setSelectedProcessType}
                setProcessTypeDeterminent={customerInfoFormSet.setProcessTypeDeterminent}
                customSelectStyles={customSelectStyles}
            />
           <EmployeeIdSelection
                selectedIdType={customerInfoFormSet.selectedIdType}
                setSelectedIdType={customerInfoFormSet.setSelectedIdType}
                idModifier={customerInfoFormSet.idModifier}
                setIdModifier={customerInfoFormSet.setIdModifier}
                customSelectStyles={customSelectStyles}
           />
           <WeekAndRowSelection
                selectedWEDate={customerInfoFormSet.selectedWEDate}
                setSelectedWEDate={customerInfoFormSet.setSelectedWEDate}
                firstDataRow={customerInfoFormSet.firstDataRow}
                setFirstDataRow={customerInfoFormSet.setFirstDataRow}
                customSelectStyles={customSelectStyles}
           />
        </Modal.Body>
        </>
    )
}