import React from 'react'
import { Button, Card, Modal } from 'react-bootstrap';

export default class PopupErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        hasError: false, 
        error: '', 
        errorInfo: '', 
        show: true, 
        setShow: () =>{
          window.location.reload(true);
          this.setState({show: !this.state.show})
        }
      };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      this.setState({error: error, errorInfo: errorInfo})
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <Modal show={this.state.show} onHide={this.state.setShow}>
            <Modal.Header>
              <h3>Something went wrong.</h3>
            </Modal.Header>
            <Modal.Body>
              <p>If you send the below info to your development team they will be very appreciative!</p>
              <p>I know you don't like errors, but errors help us fix things faster.</p>
              {(this.state.error&&this.state.errorInfo)&&
              <Card className='padding'>
                  <h4>Error info:</h4>
                  <p>Stack trace: {this.state.errorInfo.componentStack.split(' at ')[1]}</p>
                  <p>Error: {this.state.error.message}</p>
              </Card>
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.state.setShow}>Close</Button>
            </Modal.Footer>
          </Modal>
        
        );
      }
  
      return this.props.children; 
    }
  }