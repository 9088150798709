export function FormatUSD(data) {

  let dollarValue = data.value ? data.value : data ? data : 0;
  let dollarCheck = isNaN(dollarValue) ? 0 : dollarValue;

  const dollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(dollarCheck);
  
  return dollar
};

export function FormatNegUSD(input) {

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  
  let number = input.value ? input.value.replace(/[()-,]/g,'') : input.data ? input.data.OAPAmount.replace(/[()-,]/g,'') : 0;
  return `${formatter.format(number)}`;
};

export function FormatExcelSerialDate(data) {
  let date = data.data ? data.data["Week Worked"] : data["Week Worked"];

  var epochZeroDate = new Date(0);
  var timeZoneOffset = epochZeroDate.getTimezoneOffset();
  var jsDate = new Date(0, 0, date - 1, 0, -timeZoneOffset, 0);
  var jsonDate = jsDate.toJSON();
  var formattedDate = FormatDate(jsonDate);

  return formattedDate;
};

export function FormatDate(data) {
  let fixedDate = data.value ? data.value.split('T')[0] :
    data.data && data.data.customerName !== "Grand Total" ? data.split('T')[0] : 
    data && !data.data ? data.split('T')[0] : '';
    
  return fixedDate
};

export function FormatNumberOnlyDate(inputDate) {
  let dateString = inputDate.toString();
  var formattedDate = dateString.slice(0, 4) + "-" + dateString.slice(4, 6) + "-" + dateString.slice(6, 8);
  return formattedDate;
};

export function GetTotal (data, dataName){
  let total = Number(data.reduce((last, current) => last + current[dataName], 0)).toFixed(2)
  return total
};

export function FormatDecimalHundredths(data) {
  let decimalNumber = (Math.round(data * 100) / 100).toFixed(2);
  return decimalNumber;
}

export function CustomComparator(valueA, valueB) {
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
}; 