import React from 'react'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import Birthdays from './Birthdays/Birthdays';
import ReactSelect from 'react-select';
import '../../../Compliance/Payroll/Payroll.css';
import { BsDownload } from 'react-icons/bs';
import Spinner from 'react-bootstrap/Spinner';

export default function TextingListsView({
  branches,
  defaultBranch,
  HandleBranchChange,
  activeKey,
  setActiveKey,
  startDate,
  endDate,
  SetDate,
  rowData,
  loading,
  gridApi,
  setGridApi
}){
  const onBtnExport = () => {gridApi.exportDataAsCsv();}

  return (
    <>
      <div className='header-card'>
        <div className='three-column-div'>
          <h3>Engagement</h3>
          {branches&&
            <ReactSelect options={branches} defaultValue={defaultBranch} isMulti onChange={HandleBranchChange} />
          }
          <div className='two-col-head'>
            <Form.Control value={startDate.split('T')[0]} onChange={(e)=>SetDate(e,'start')} type='date' />
            <Form.Control value={endDate.split('T')[0]} onChange={(e)=>SetDate(e,'end')} type='date' />
          </div>
        </div>
        <div className="spaced">
          <Button className="module-button" onClick={()=>setActiveKey('birthdays')}>Birthdays</Button>
          <Button title='Download grid data' variant='secondary' onClick={() => onBtnExport()}><BsDownload/></Button> 
          {loading&&<Spinner animation='border' size='sm'/>}
        </div>
      </div>
      {activeKey==='birthdays'&&<Birthdays 
        setGridApi={setGridApi}
        rowData={rowData}    
      />}
    </>
  )
}