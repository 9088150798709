import React, { useEffect, useState, useContext } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import CustomLists from './CustomLists'
import { FaRegEdit } from 'react-icons/fa';


export default function CustomListsLogic() {
    const [addCustomListPopupIsOpen, setAddCustomListPopupIsOpen] = useState(false);
    const {
        cookies,
        setCurrentPage,
        selectedBranch,
    } = useContext(CustomerContext)
    const [editListDefaultPostObj, setEditListDefaultPostObj] = useState();
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const [columnDefs] = useState([
        {headerName:'Name',field:'LabelName'},
        {headerName:'Description',field:'LabelDescription'},
        {headerName:'Branch',field:'BranchName'},
        {headerName:'Service Rep',field:'ServiceRepName'},
        {headerName:'Edit', width: 50, cellRenderer: EditButton},
        // {headerName:'Delete', width: 50, cellRenderer: DeleteButton}
    ])
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
    })
    const [openCandidatesList,setOpenCandidatesList] = useState(false);
    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
    const [labelID, setLabelID] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [fetchLists, setFetchLists] = useState(true);
    const [filteredLists, setFilteredLists] = useState();
    const {data: customLists, setData: setCustomLists, isLoading: gettingData} = useFetchGet((fetchLists&&`${REACT_APP_API_URL}/Candidates/GetCustomLabels?token=${cookies.get('customer').Token}&labelcategoryid=1`),cookies) 
    const {data: candidates} = useFetchGet((labelID&&`${REACT_APP_API_URL}/Candidates/GetCandidatesFromCustomList?token=${cookies.get('customer').Token}&labelid=${labelID}`),cookies)
    
    useEffect(()=>{
        if(!customLists){
            setFetchLists(true)
        }else{
            setFetchLists(false)
        }
    },[customLists])

    useEffect(()=>{
        if(customLists&&selectedBranch?.BranchName){
            let filteredLists = customLists.filter((list) => list.BranchName===selectedBranch.BranchName);
            if(JSON.stringify(customLists)!==JSON.stringify(filteredLists)){
                setFilteredLists([...filteredLists])
            }
        }else if(customLists&&!selectedBranch?.BranchName){
            setFilteredLists(customLists)
        }
    },[customLists,selectedBranch])

    useEffect(()=>{
        setCurrentPage('Lists')
    // eslint-disable-next-line
    },[])

    const HandleCellClicked = (params) => { 
        if(params.colDef.headerName==='Edit'){
            setEditListDefaultPostObj(params.data)
            setAddCustomListPopupIsOpen(true)
        }else if(params.colDef.headerName==='Delete'){
            setShowConfirmDeletePopup(true)
            setSelectedRow(params)
        }else{
            setLabelID(params.data.LabelID);
            setOpenCandidatesList(true);
        }
     }  

    const handleCloseCustomListPopup = () => { 
        setEditListDefaultPostObj()
        setAddCustomListPopupIsOpen(false);
        setCustomLists(null)
    }

    const handleCloseConfirmDeletePopup = () => { 
        setShowConfirmDeletePopup(false)
        setCustomLists(null)
    }
    
    const CreateNewCustomList = (event) => { 
        setAddCustomListPopupIsOpen(true);
    }

     function onGridReady(params){
        params.api.sizeColumnsToFit();
    }

  return (
    <>
        <CustomLists
            HandleCellClicked={HandleCellClicked}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            filteredLists={filteredLists}
            onGridReady={onGridReady}
            CreateNewCustomList={CreateNewCustomList}
            addCustomListPopupIsOpen={addCustomListPopupIsOpen}
            handleCloseCustomListPopup={handleCloseCustomListPopup}
            gettingData={gettingData}
            setCustomLists={setCustomLists}
            openCandidatesList={openCandidatesList}
            setOpenCandidatesList={setOpenCandidatesList}
            candidates={candidates}
            editListDefaultPostObj={editListDefaultPostObj}
            showConfirmDeletePopup={showConfirmDeletePopup}
            handleCloseConfirmDeletePopup={handleCloseConfirmDeletePopup}
            selectedRow={selectedRow}
        ></CustomLists>
    </>
  )
}

// const DeleteButton = (params) => {
//     return(
//         <button className='delete-list-button'><FaTrashAlt /></button>
//     )
// }

const EditButton = (params) => { 
    if(params.data.LabelName==='Automated Signup'){
        return ''
    }else{
        return(
            <button className='edit-list-button'><FaRegEdit /></button>
        )
    }
 }
