import React, { useContext, useState, useEffect } from 'react'
import AddBranchToOrder from './AddBranchToOrder';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet'
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';
import useForceUpdate from 'Components/CustomComponents/CustomHooks/useForceUpdate';

function AddBranchToOrderLogic({ show, setShow, jobOrderID }) {
    const { cookies } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const [branchIDs, setBranchIDs] = useState();
    const [canPost, setCanPost] = useState(false);
    const [loading, setLoading] = useState(false);
    const { data: branches } = useFetchGet(`${REACT_APP_API_URL}/Candidates/GetBranchList?token=${cookies.get('customer').Token}`,cookies)
    const { status } = useFetchPost((canPost&&`${REACT_APP_API_URL}/Candidates/associate/PostBranchOrder?token=${cookies.get('customer').Token}&OrderID=${jobOrderID}`),cookies,branchIDs)
    const forceUpdate = useForceUpdate();

    useEffect(()=>{
      if(status){
        forceUpdate()
        setCanPost(false)
        setLoading(false)
      }
      // eslint-disable-next-line
    },[status])

    const HandleBranchChange = (e) => { 
      let newBranchIDs = e.map(e=>e.value.BranchID)
      setBranchIDs(newBranchIDs)
     }

  return (
    <AddBranchToOrder
        status={status}
        show={show}
        setShow={setShow}
        jobOrderID={jobOrderID}
        branches={branches}
        HandleBranchChange={HandleBranchChange}
        setCanPost={setCanPost}
        loading={loading}
    ></AddBranchToOrder>
  )
}

export default AddBranchToOrderLogic