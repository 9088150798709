import ProcessingSelectionsView from "./ProcessingSelectionsView";

export default function ProcessingSelections({
    selectedOtRule,
    setSelectedOtRule,
    selectedProcessType,
    setSelectedProcessType,
    setProcessTypeDeterminent,
    customSelectStyles
}){
    const overtimeRules = [
        {value: "Standard", label:"Federal OT Standard"},
        {value: "CA", label: "California Overtime"},
        {value: "Straight", label: "No Added Calculation"},
        // {value: "FourTen", label: "Four Ten"},
        {value: "State", label: "Use Employee State"}
    ];

    const processTypes = [
        {value: "ColTotals", label: "Totals: By Column"},
        {value: "ColTypes", label: "Totals: By Type"},
        {value: "PunchIns", label: "Punches: In/Out"},
        {value: "PunchCols", label: "Punches: By Column"}
    ];

    const HandleSelectProcessType = (e) =>{
        console.log(e);
        let process = e.label;
        setSelectedProcessType(e);
        setProcessTypeDeterminent(process);
    }

    return(
        <>
         <ProcessingSelectionsView
            overtimeRules={overtimeRules}
            selectedOtRule={selectedOtRule}
            setSelectedOtRule={setSelectedOtRule}

            processTypes={processTypes}
            selectedProcessType={selectedProcessType}
            HandleSelectProcessType={HandleSelectProcessType}

            customSelectStyles={customSelectStyles}
         />
        </>
    )
}