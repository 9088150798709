import { useEffect, useState, useContext } from "react";
import OAPNotesView from "./OAPNotesView";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import useFetchGet from "Components/CustomComponents/Fetch/useFetchGet";
import { PostOapNote } from "../CallAPI";


export default function OAPNotes() {
    const { REACT_APP_API_URL } = process.env;
    const { cookies } = useContext(CustomerContext);
    const { data: noteRows } = useFetchGet(`${REACT_APP_API_URL}/payroll/getoapnotes?token=${cookies.get('customer').Token}`, cookies);
     const [oapNotes,setOapNotes] = useState();
    const [gridApi, setGridApi] = useState();
    const [defaultColDef] = useState({
        sortable: true,
        resizable: true,
        filter: true,
        editable: true
    });

    const [notesColDefs, setNotesColDefs] = useState();

    useEffect(() => {
        if (noteRows && noteRows.length) {
            setOapNotes(noteRows);
        }
        //eslint-disable-next-line
    },[noteRows]);

    useEffect(() => {
        if (oapNotes) {
            setNotesColDefs([
                {
                    field: "PayName",
                    sort: 'asc',
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                },
                { field: "Customer" },
                { field: "Email" },
                { field: "Notes" }
            ]);
        };
        //eslint-disable-next-line
    }, [oapNotes]);

    const onFirstDataRendered = (params) => {
        setGridApi(params.api);
        params.columnApi.autoSizeAllColumns();
    };

    const HandleAddRow = () => {
        let updatedRows = [...oapNotes];
        let newNoteRow = { "PayName": "", "Customer": "", "Email": null, "Notes": null };
        updatedRows.push(newNoteRow);
        setOapNotes(updatedRows);
        gridApi.refreshCells();
    };

    const HandleSaveNotes = () => {
        let notesToSave = gridApi.getSelectedRows();
        notesToSave.forEach(note => {
            let payName = note.PayName;
            let noteCustomer = note.Customer;
            let email = note.Email;
            let noteText = note.Notes;

            PostOapNote(payName, noteCustomer, email, noteText, cookies);
        });
    };

    // const HandleDeleteRow = () => {
    //     const toDelete = gridApi.getSelectedRows();
    //     const removeRows = [...oapNotes];
    //     toDelete.forEach(row => {
    //         removeRows.splice(
    //             removeRows.indexOf(row), 1
    //         );
    //     });

    //     setOapNotes(removeRows);
    // };

    return (
        <>
            <OAPNotesView
                oapNotes={oapNotes}
                notesColDefs={notesColDefs}
                defaultColDef={defaultColDef}
                onFirstDataRendered={onFirstDataRendered}
                HandleAddRow={HandleAddRow}
                HandleSaveNotes={HandleSaveNotes}
                // HandleDeleteRow={HandleDeleteRow}
            />
        </>
    )
}