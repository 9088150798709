

export function GetPostedPayments(row){
    let credit = parseFloat(row[" Net Credit"]);
    let description = row["Description"];
    let payNumber = row[" Payment #"];

    let posted = (description === "Invoice Closed" && 
        payNumber !==  undefined &&
        credit > 0.1) || description === "Item Reduction" ? "Posted Payment" : description;
    
    return posted;
}

export function GetPaymentType(row){
    let posted = GetPostedPayments(row);
    let payType = posted === "Posted Payment" ? "Payment" : "";
    return payType;
}

export function GetTWInvoiceBalance(row, dataHold){

    let posted = GetPostedPayments(row);
    let twInvoice = dataHold.find(value => value.invoiceNumber === row["Item #"]);

    let invoiceBalance = posted !== "Posted Payment" ? "Non-payment Line Item" :
        twInvoice !== undefined ? twInvoice.BalanceAmount : "Not in Aging";
    
    return invoiceBalance;
}

export function GetBalancePaid(row, dataHold){
    let paymentType = GetPaymentType(row);
    let invoiceBalance = GetTWInvoiceBalance(row, dataHold);
    let balancePaid = (paymentType === "Payment" && invoiceBalance === row[" Net Credit"]) ? "Full Balance" :
        (paymentType === "Payment" && invoiceBalance !== row[" Net Credit"]) ? "Partial" : "Non-Payment";

    return balancePaid;
}