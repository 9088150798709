export function GetActiveRows(
    worksheet,
    rowIndex,
    timecardColumns
){
    let activeRows = [];
    while (worksheet['C' + rowIndex] || worksheet['B' + rowIndex] || worksheet['A' + rowIndex]) {
        let row = {};
        // eslint-disable-next-line
        Object.keys(timecardColumns).forEach((column) => {
            worksheet[column + rowIndex] ?
                row[timecardColumns[column]] = worksheet[column + rowIndex].v
                :
                row[timecardColumns[column]] = 0;
        });
        activeRows.push(row);
        rowIndex++;
    }
    return activeRows;
}

export function GetModifiedIDRows(activeRows, idMod){
    let modRows = [];

    activeRows.forEach(row=>{
        let rawID = row.IdNumber;
        let modifiedID = rawID.length > idMod ? rawID.substr(rawID.length - idMod) : rawID;
        row.IdNumber = modifiedID;
        modRows.push(row);
    });
    return modRows;
}