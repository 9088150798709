import { useContext } from 'react';
import Header from './Header';
import Footer from './Footer'
// import AddEmployeeForm from './AddEmployeeForm/AddEmployeeForm'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import AddJobOrderLogic from 'Components/ATS/Orders/JobOrderModals/AddJobOrder/AddJobOrderLogic.js';
import ErrorBoundary from 'Components/CustomComponents/Auth/ErrorBoundary';

export default function Layout ({children}) {
  const {addJobOrderIsOpen} = useContext(CustomerContext);
  
  return (
    <div>
      <Header />
        {addJobOrderIsOpen&&<AddJobOrderLogic />}
        {/* {cookies.get('customer')&& <AddEmployeeForm />} */}

        <div className='app-container'>
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </div>
      <Footer />
    </div>
  );
}