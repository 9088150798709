import AsyncSelect from 'react-select/async';
import { components } from 'react-select'

function AutoSearchSelect({ 
  apiUrl, 
  FetchCandidatesFromDB, 
  onMenuOpen, 
  HandleKeyDown, 
  options, 
  OnChange, 
  defaultValue, 
  isMulti,
  customSelectStyles
}){
    
    const NoOptionsMessage = props => {
        return (
          <components.NoOptionsMessage {...props}>
            <span className='type-to-search-option'>Type to search...</span> 
          </components.NoOptionsMessage>
        );
      };

    return(
        <AsyncSelect 
          components={{ NoOptionsMessage }} 
          loadOptions={(e)=>FetchCandidatesFromDB(e,apiUrl)} 
          onMenuOpen={onMenuOpen} placeholder='Search...' 
          defaultValue={defaultValue} 
          onChange={OnChange} 
          isMulti={(isMulti===false)?false:true} 
          options={options} 
          onKeyDown={HandleKeyDown} 
          styles={customSelectStyles}
        />
    )
}

export default AutoSearchSelect;