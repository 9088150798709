import React from "react";
import '../../Payroll.css';
import { Button } from 'react-bootstrap';
import { AgGridReact } from "ag-grid-react";
import Select from 'react-select';

export default function AssignmentsNotPaidView(props) {
  return (
    <>
      <div className="header-card">
      <h3>Assignments Not Paid</h3>
        <div className="spaced">
          <div className="double-select">  
            <p className="label-gap">Select One or More Branches to View Data:</p>
            <Select
              className="dropdown-styling"
              options={props.branchFilter}
              value={props.selectedBranch}
              isMulti
              placeholder='Select a Branch'
              onChange={(e) => props.HandleBranchChange(e)}
              styles={props.customSelectStyles} />
          </div>
          <Button className="header-export-button" onClick={()=>props.ExportToCSV()}>Export to CSV</Button>             
        </div>
      </div>
      <div className="card oap-card">
        {props.rowData ? 
          <div className="ag-theme-alpine ag-height-override">
            <AgGridReact
              multiSortKey={'ctrl'}
              suppressExcelExport={true}
              rowData={props.rowData}
              onFirstDataRendered={props.onFirstDataRendered}
              columnDefs={props.columnDefs}
              defaultColDef={props.defaultColDef}
              onCellClicked={props.onAssignmentClick}
            />
          </div> 
        :
          <h5 className="spaced">Please select a branch to view Assignments Not Paid</h5>
        }
      </div>
    </>
  )
}