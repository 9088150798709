import React, { useEffect, useState } from "react";
import GridControllerView from "./GridControllerView";
import GetTimecardColumns from "../../PayrollUploadsGetters/GetTimecardColumns";
import GetMappingHours from "../../PayrollUploadsGetters/GetMappingHours";
import CalculateTotals from "../../HoursCalcs/CalculateTotals";
import GetCalculatedValues from "../../PayrollUploadsGetters/GetCalculatedValues";
import GetMatchRowCols from "../../PayrollUploadsGetters/GetMatchRowCols";
import GetUpdatedMergeRows from "../../PayrollUploadsGetters/GetUpdatedMergeRows";
import GetUpdatedMergeCols from "../../PayrollUploadsGetters/GetUpdatedMergeCols";
import FindErroredLine from "../../PayrollUploadsGetters/FindErroredLine";
import GetErroredRows from "../../PayrollUploadsGetters/GetErroredRows";

export default function GridController({
  rowData,
  selectedCustomer,
  gridAddedParams,
  loaderColumns,
  delayedRows,
  matchRowData,
  mergeData,
  hoursTotals
}) {
  const [showActiveGrid, setShowActiveGrid] = useState(false);
  const [showMatchGrid, setShowMatchGrid] = useState(false);
  const [showErrorGrid, setShowErrorGrid] = useState(false);
  const [showWelcomeCard, setShowWelcomeCard] = useState(true);

  const gridViews = {
    isResearchView: gridAddedParams.isResearchView,
    showActiveGrid: showActiveGrid,
    showMatchGrid: showMatchGrid,
    showErrorGrid: showErrorGrid,
    showWelcomeCard: showWelcomeCard
  }

  const [erroredLines, setErroredLines] = useState();
  const [erroredRows, setErroredRows] = useState();
  const [delayedMergeRows, setDelayedMergeRows] = useState();

  const gridRowData = {
    rowData: rowData,
    delayedRows: delayedRows,
    matchRowData: matchRowData,
    erroredLines: erroredLines,
    erroredRows: erroredRows,
    delayedMergeRows: delayedMergeRows,
    mergeData: mergeData,
  }

  const [timecardColumns, setTimecardColumns] = useState();
  const [matchRowCols, setMatchRowCols] = useState();
  const [delayedCols, setDelayedCols] = useState();

  const gridColumns = {
    timecardColumns: timecardColumns,
    matchRowCols: matchRowCols,
    delayedCols: delayedCols,
    mergeCols: gridAddedParams.mergeCols
  }

  const [defaultColDef] = useState({
    sortable: true,
    resizable: true,
    filter: true,
  });

  const commonGridItems = {
    defaultColDef: defaultColDef,
    gridApi: gridAddedParams.gridApi,
    setGridApi: gridAddedParams.setGridApi,
    selectedCustomer: selectedCustomer
  }

  const calculatedSums = gridAddedParams.calculatedSums;
  const instructionHandlers = {
    activeReviewed: gridAddedParams.activeReviewed,
    sheetsExported: gridAddedParams.sheetsExported,
    calculatedSums: calculatedSums,
    setCalculatedSums: gridAddedParams.setCalculatedSums,
    columnMap: gridAddedParams.columnMap
  }

  const [definedMismatchError, setDefinedMismatchError] = useState();
  const auditData = {
    mergeData: mergeData,
    setMergeData: gridAddedParams.setMergeData,
    definedMismatchError: definedMismatchError,
    setDefinedMismatchError: setDefinedMismatchError
  };

  const [timecardHoursMatch, setTimecardHoursMatch] = useState('Match');
  const tcTotalsSetters = {
    setTimecardHoursTotal: hoursTotals.setTimecardHoursTotal,
    setTimecardReg: hoursTotals.setTimecardReg,
    setTimecardOT: hoursTotals.setTimecardOT,
    setTimecardDT: hoursTotals.setTimecardDT,
    setTimecardMP: hoursTotals.setTimecardMP
  };

  const researchAddedParams = {
    timecardHoursMatch: timecardHoursMatch,
    erroredLines: erroredLines,
    setErroredLines: setErroredLines,
    setMergeData: gridAddedParams.setMergeData,
    setDelayedMergeRows: setDelayedMergeRows,
    setErroredRows: setErroredRows,
    setShowMatchGrid: setShowMatchGrid,
    setShowErrorGrid: setShowErrorGrid,
    setShowWelcomeCard: setShowWelcomeCard
  }

  const calculatedValues = {
    ...hoursTotals,
    timecardHoursMatch: timecardHoursMatch
  };

  // resets all values when the selected customer changes
  useEffect(() => {
    setShowActiveGrid(false);
    setShowMatchGrid(false);
    setShowErrorGrid(false);
    setShowWelcomeCard(true);

    hoursTotals.setTimecardHoursTotal();
    hoursTotals.setTimecardReg();
    hoursTotals.setTimecardOT();
    hoursTotals.setTimecardDT();
    hoursTotals.setTimecardMP();

    hoursTotals.setUploadHoursTotal();
    hoursTotals.setUploadReg();
    hoursTotals.setUploadOT();
    hoursTotals.setUploadDT();
    hoursTotals.setUploadMP();

    hoursTotals.setMismatchHoursTotal();
    hoursTotals.setMismatchReg();
    hoursTotals.setMismatchOT();
    hoursTotals.setMismatchDT();
    hoursTotals.setMismatchMP();

    hoursTotals.setMappingHoursTotal();
    hoursTotals.setMappingHoursReg();
    hoursTotals.setMappingHoursOT();
    hoursTotals.setMappingHoursDT();
    hoursTotals.setMappingHoursMP();

    setDelayedCols();
    setDelayedMergeRows();
    setTimecardHoursMatch('Match');
    // eslint-disable-next-line
  }, [selectedCustomer]);

  // on customer selection from the header, loader columns and process definitions are set.
  // on load of timecard worksheet, rowData, originalTimecardRow, originalTimecardCols, columnMap, mergeData, and mergeCols are all set
  // the following use effect will kick in, setting the timecard columns for the first grid display (ActiveGrid)
  useEffect(() => {
    if (loaderColumns) {
      GetTimecardColumns(loaderColumns, setTimecardColumns);
    }
  }, [loaderColumns]);

  // delayed cols and rows build the initial grid for the research tab
  useEffect(() => {
    if (gridAddedParams.originalTimecardCols && gridAddedParams.originalTimecardCols.length) {
      setDelayedCols(gridAddedParams.originalTimecardCols);
      setShowActiveGrid(true);
    }
  }, [gridAddedParams.originalTimecardCols]);

  useEffect(() => {
    if (delayedCols && delayedCols.length) {
      gridAddedParams.setDelayedRows(gridAddedParams.originalTimecardRows);
    }
    // eslint-disable-next-line
  }, [delayedCols]);

  // mapping hours will be the summation of the hours coming from the original timecard
  // this may need to be revisited when processing punch data
  useEffect(() => {
    if (delayedRows) {
      let mappingHoursSetters = {
        setMappingHoursTotal: hoursTotals.setMappingHoursTotal,
        setMappingHoursReg: hoursTotals.setMappingHoursReg,
        setMappingHoursOT: hoursTotals.setMappingHoursOT,
        setMappingHoursDT: hoursTotals.setMappingHoursDT,
        setMappingHoursMP: hoursTotals.setMappingHoursMP
      };
      GetMappingHours(delayedRows, gridAddedParams.columnMap, mappingHoursSetters);
    }
    // eslint-disable-next-line
  }, [delayedRows]);

  //employeeDefs are created when the "Calculate Hours" button is press, triggering the 
  // HandleProcessTimecard function in UploadsHeader which will also set the weeklyCalcs
  useEffect(() => {
    if (gridAddedParams.employeeDefs) {
      let calculatedTotals = CalculateTotals(gridAddedParams.employeeDefs, gridAddedParams.weeklyCalcs, tcTotalsSetters);
      gridAddedParams.setMatchRowData(calculatedTotals);
    }
    // eslint-disable-next-line
  }, [gridAddedParams.employeeDefs]);

  useEffect(() => {
    if (matchRowData && matchRowData.length) {
      let totalsSetters = {
        setUploadHoursTotal: hoursTotals.setUploadHoursTotal,
        setUploadReg: hoursTotals.setUploadReg,
        setUploadOT: hoursTotals.setUploadOT,
        setUploadDT: hoursTotals.setUploadDT,
        setUploadMP: hoursTotals.setUploadMP,

        setMismatchHoursTotal: hoursTotals.setMismatchHoursTotal,
        setMismatchReg: hoursTotals.setMismatchReg,
        setMismatchOT: hoursTotals.setMismatchOT,
        setMismatchDT: hoursTotals.setMismatchDT,
        setMismatchMP: hoursTotals.setMismatchMP,

        setTimecardHoursMatch: setTimecardHoursMatch
      };
      let tcTotals = {
        ...calculatedSums,
        mappingHoursTotal: hoursTotals.mappingHoursTotal
      };
      GetCalculatedValues(matchRowData, totalsSetters, tcTotals);
      GetMatchRowCols(matchRowData, setMatchRowCols);
      GetUpdatedMergeCols(gridAddedParams.originalTimecardCols, matchRowData, gridAddedParams.setMergeCols);
      GetUpdatedMergeRows(matchRowData, mergeData, gridAddedParams.setMergeData);

      setShowActiveGrid(false);
      setShowMatchGrid(true);
    }
    // eslint-disable-next-line
  }, [matchRowData]);

  useEffect(() => {
    if (timecardHoursMatch !== 'Match') {
      let lineErrors = FindErroredLine(calculatedValues, auditData);
      setErroredLines(lineErrors);
    }
    // eslint-disable-next-line
  }, [timecardHoursMatch]);

  useEffect(() => {
    if (erroredLines) {
      let mergeLoadDelay = mergeData;
      setDelayedMergeRows(mergeLoadDelay);
      gridAddedParams.setMergeData();
      GetErroredRows(mergeLoadDelay, erroredLines, setErroredRows);
    }
    // eslint-disable-next-line
  }, [erroredLines]);

  useEffect(() => {
    if (erroredRows) {
      setShowMatchGrid(false);
      setShowErrorGrid(true);
      setShowWelcomeCard(false);
    }
    // eslint-disable-next-line
  }, [erroredRows]);

  const getTimecardCSS = () => {
    let cssClass = showErrorGrid || showMatchGrid ? "compare-deck" :
      showActiveGrid ? "cardHolder" : "";
    return cssClass;
  }

  const HandleContinue = () => {
    setShowErrorGrid(false);
    setShowWelcomeCard(true);
    setShowMatchGrid(true);
  }

  const HandleRestart = () => {
    gridAddedParams.setSelectedCustomer(null);
  };

  return (
    <>
      <GridControllerView
        gridViews={gridViews}
        gridRowData={gridRowData}
        gridColumns={gridColumns}
        commonGridItems={commonGridItems}
        getTimecardCSS={getTimecardCSS}
        instructionHandlers={instructionHandlers}

        auditData={auditData}
        hoursTotals={hoursTotals}
        researchAddedParams={researchAddedParams}

        delayedMergeRows={delayedMergeRows}
        HandleRestart={HandleRestart}
        HandleContinue={HandleContinue}
      />
    </>
  )
}

