import './Footer.css';
import Package from '../../package.json';

export default function Footer() {
  return (
    <div className='text-center text-lg-left footer' fixed="bottom">
      <div className='foot-row'>
        <div className='foot-col'>
          <a className="text-white" href='https://ewm.employnet.com/'>
            EWM.employnet.com
          </a>
        </div>
        <div className='foot-col'>
          <a className="text-white" href='/Privacy'>
              Privacy
          </a>
        </div>
        <div className='foot-col'>
          <p>App Version: {Package.version}</p>
        </div>
      </div>
      
      <div className='text-center p-3'>
        &copy; {new Date().getFullYear()} Copyright:{' - '}
        <p>Employnet Workforce Management</p>
      </div>
    </div>
  )
};