export default function CreateMessageString({
    selectedCustomerID,
    body,
    myTable,
    sendTo,
    sendCC,
    sendBCC,
    subject
}) {

    let toSendList = sendTo.current.map((contact) => {
        return {
            originType: 3,
            originId: contact.id,
            name: contact.name,
            address: contact.email
        }
    });

    let ccSendList = sendCC.current.length > 0 ?
        sendCC.current.map((contact) => {
        return {
            originType: 3,
            originId: contact.id,
            name: contact.name,
            address: contact.email
        }}) : [];
    
    let bccSendList = sendBCC.current.length > 0 ?
        sendBCC.current.map((contact) => {
        return {
            originType: 3,
            originId: contact.id,
            name: contact.name,
            address: contact.email
        }}) : [];

    // body text must be stringified to find all \n and replace with <br/>
    let textSetter = JSON.stringify(body);
    let quoteFixer = textSetter.replace(/["]+/g, '')
    let textBody = quoteFixer.replace(/(?:\\[n])+/g, "<br/><br/>") + "<br/><br/>"

    let message =
    {
        "to": toSendList,
        "cc": ccSendList,
        "bcc": bccSendList,
        "subject": subject,
        "format": 2,
        "body": textBody + myTable + '{{signature}}',
        "isTraceOn": true,
        "isTemplate": false,
        "logMessage": true,
        "messageActionId": 49,
        "linkedEntities": [{
            "originTypeId": 2,
            "originId": selectedCustomerID
        }],
    };    
    
    return message;
}