import React, { useState } from 'react'
import EmployeeSearchView from './EmployeeSearchView'

function EmployeeSearch(){
    const [rowData, setRowData] = useState([]);
    const [advancedSearch, setAdvancedSearch] = useState(false);

    return(
        <EmployeeSearchView
            advancedSearch={advancedSearch}
            setAdvancedSearch={setAdvancedSearch}
            rowData={rowData}
            setRowData={setRowData}
        ></EmployeeSearchView>
    )
}

export default EmployeeSearch;