import { Modal, Container, Col, Row, Button } from "react-bootstrap";
import { BsDownload, BsArrowClockwise } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaBalanceScaleLeft } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import { BiPaste } from "react-icons/bi";
import { TiUpload } from "react-icons/ti";
import React, {useEffect, useState } from "react";

export default function ModuleInfo({
    show,
    onHide,
    headerText
}) {
    const [infoHeader, setInfoHeader] = useState();
    useEffect(() => {
        if (headerText) {
            setInfoHeader(headerText);
        }
    }, [headerText]);

    return (
        <>
            <Modal dialogClassName="info-modal" className="module-info-modal" show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <h3>{infoHeader} info</h3>
                </Modal.Header>
                <Modal.Body className="modal-body-content">
                    <Container>
                        {infoHeader === "OAP Dashboard" && 
                            <Row>
                                <h4>Summary</h4>
                                <p>The OAP Dashboard is the main hub of the OAP tool. From this dashboard you see an overview of 
                                    all OAP activity. New and leftover OAP's are broken down by customer and displayed in a donut
                                    chart to the left. In the grid to the right, data is diplayed based on selections made using the
                                    OAP Totals buttons in the OAP Header Toolbar, or by selecting individual customer segments from
                                    the New OAPs By Customer or Leftover OAPs By Customer charts.


                                </p>
                            </Row>
                        }
                        {infoHeader === "Invoice Matching" && <>
                            <Row>
                                <h4>Summary</h4>
                                <p>The Match Invoices page allows users to load a customer's reconciliation file and find the invoice numbers
                                    and pay amounts that correspond with each line item in said file. This is accomplished by:
                                    <ol>
                                        <li>
                                            <p>Parsing the file and building a grid view of the important information from the reconciliation file</p>
                                        </li>
                                        <li>
                                            <p>Looking up values from the file in the Invoice Aging table, and returning a grid of like values
                                                for in Invoice Aging.
                                            </p>
                                        </li>
                                        <li>
                                            <p>Matching exact values between the reconciliation data and Invoice Aging data to create a grid of
                                                Invoice Numbers and Pay amounts.
                                            </p>
                                        </li>
                                        <li>
                                            <p>Reporting back any reconciliation data values that did not have a match.</p>
                                        </li>
                                    </ol>
                                    
                                    Users can then use the data collected to further scrutinize and edit the reconciliation data until the total
                                    amount paid in the reconciliation file has been accounted for. The user can then save the unique invoice
                                    numbers, and the sum of their pay amounts, for applying to the appropriate OAP Number in the Reconcile OAPs
                                    page, via the "Load Matched Invoices" button.
                                </p>
                            </Row>
                        </>}
                        {infoHeader === "Reconcile OAPs" &&<>
                            <Row>
                                <h4>Summary</h4>
                                <p>The Reconcile OAPs page is where users may enter in, balance, save and reconcile all OAPs for the week.
                                    Users may edit OAPs in various ways, including manual entry and row duplication, pasting invoice and 
                                    pay amounts, and loading matched invoices. While edits are save automatically, correct calculations may
                                    only show if the data has been refreshed. After editing, users may export their progress, and even export
                                    a pay file using the "Export Payments" button in the OAP Header Toolbar. The status of each OAP and Invoice
                                    is indicated by a color key for easy reference (see "Grid Color Keys" at the end of this info page).
                                </p>
                            </Row>
                            <Row>
                                <h4>Reconcile Toolbar</h4>
                                <p>The Reconcile Toolbar provides users with functionality above and beyond what is inherently available in the grid.
                                    This added functionality includes filtering by assigned rep, advanced editing capabilities, row deletion, balancing
                                    invoices, data refresh, and progress export.
                                </p>
                                <ul>
                                    <li>
                                        <p>The <span className="bolded-info-text">Select Rep</span> dropdown allows the user to narrow down the items displayed in the grid by showing only those customers
                                            who are assigned to the selected rep.
                                        </p>

                                    </li>
                                    <li>
                                        <p>The <span className="bolded-info-text">Paste Invoices</span> button ( <BiPaste /> ) allows the user to copy in invoice numbers
                                            and payment amounts from a secondary tool or email and paste them into a message box. When the user selects the "Load Invoices"
                                            button, the invoices that have been pasted into the message box will be applied to the OAP selected in the grid.
                                        </p>
                                    </li>
                                    <li>
                                        <p> The <span className="bolded-info-text">Load Matched Invoices</span> button ( <TiUpload /> ) allows the user to apply the invoices
                                            saved from the "Match Invoices" module to the selected OAP.
                                        </p>
                                    </li>
                                    <li>
                                        <p> The <span className="bolded-info-text">Duplicate Rows</span> button ( <MdContentCopy /> ) will create as many copies of the selected row
                                            as are indicated by the counter to the left. These copies will all have a "0" for the invoice number and Pay Amount
                                            columns by default, so that they may act as a blank copy of the selected OAP.
                                        </p> 
                                    </li>
                                    <li>
                                        <p>The <span className="bolded-info-text">Delete Selected</span> button ( <RiDeleteBin6Line /> ) will delete all selected rows
                                            from the table. <span className="bolded-info-text">CAUTION:</span> This will also delete the row from the database! Before selecting this button, be sure only those rows selected are the one
                                            which you wish to delete.
                                        </p>
                                    </li>
                                    <li>
                                        <p>The <span className="bolded-info-text">Balance Selected Invoices</span> button ( <FaBalanceScaleLeft /> ) will make the pay amount
                                            of each selected invoice equal to the invoice amount. This button is to adjust all invoice underpayments and 
                                            overpayments, and (upon refresh) will also update the OAP balance to reflect any balance adjustments.
                                        </p>
                                    </li>
                                    <li>
                                        <p>The <span className="bolded-info-text">Refresh Data</span> button ( <BsArrowClockwise /> ) will push all updates to the database, then
                                            pull the full table from the database and refresh the cells in the grid.
                                        </p>
                                    </li>
                                    <li>
                                        <p>The <span className="bolded-info-text">Export Progress</span> button ( <BsDownload /> ) will download a csv file copy of the data in
                                            the grid, allowing a user to have a hard copy of their OAP progress in the event of database failures or reporting needs.
                                        </p>
                                    </li>
                                </ul>
                            </Row>
                            <Row>
                                <h4>Column Descriptions</h4>
                                <ul>
                                    <li>
                                        <p><span className="bolded-info-text">Customer Name:</span> shows the name of the customer as it is shown in the Wells
                                            Fargo OAP File. This column is editable to allow for the sending of correct customer names with the pay file (i.e.,
                                            changing "Unknown Customer" to a customer in Tempworks or Pay Customer that matches the OAP)
                                        </p>
                                    </li>
                                    <li>
                                        <p><span className="bolded-info-text">Aging Customer:</span> shows the name of the customer as it is shown on the invoice record
                                            from Tempworks. If there is no invoice number provided, or the invoice number is "0", this will be blank. If an invoice number
                                            is entered, but that invoice is not found in the Invoice Aging data of Tempworks, this will read "Invoice Not In Aging".
                                        </p>
                                    </li>
                                    <li>
                                        <p><span className="bolded-info-text">OAP Number:</span> shows the OAP Number from Wells Fargo.</p>
                                    </li>
                                    <li>
                                        <p><span className="bolded-info-text">Deposit Date:</span> shows the date the funds resulting in the OAP were initially
                                            deposited into the Wells Fargo account.
                                        </p>
                                    </li>
                                    <li>
                                        <p><span className="bolded-info-text">OAP Amount:</span> shows the amount remaining to be reconciled on an OAP. This data
                                            is from Wells Fargo, and does not account for any payments or edits included in the OAP tool. Gray rows 
                                            indicate that this OAP Amount has been paritally reconciled by prior invoice payments and the OAP Amount is a leftover amount.
                                            White or light gray rows indicate that this OAP Amount has not been reconciled at all, and the OAP is new.
                                        </p>
                                    </li>
                                    <li>
                                        <p><span className="bolded-info-text">Invoice Number:</span> an input column to apply invoices to the OAP Number of the same row.
                                            Once an invoice number has been entered, the tool will look up that invoice in the in Invoice Aging table in Tempworks and 
                                            return the customer to which it belongs (if any), as well as the Invoice Amount (or total due).
                                        </p>
                                    </li>
                                    <li>
                                        <p><span className="bolded-info-text">Pay Amount:</span> an input column to apply the invoice payment to the OAP number of the same row.
                                            Once a Pay Amount has been entered, the tool will calculate how much of the Invoice Balance remains (Invoice Amount - Pay Amount), as
                                            well as the OAP Balance (OAP Amount - sum of all Pay Amounts attached to this same OAP Number).
                                        </p>
                                    </li>
                                    <li>
                                        <p><span className="bolded-info-text">OAP Balance:</span> the value of the OAP Amount minus all the Pay Amounts that have
                                            been applied to all rows with this same OAP Number.
                                        </p>
                                    </li>
                                    <li>
                                        <p><span className="bolded-info-text">Invoice Balance:</span> the value of the Invoice Amount minus the Pay Amount of the same row.</p>
                                    </li>
                                    <li>
                                        <p><span className="bolded-info-text">Invoice Amount:</span> the total balance of the Invoice as found in the Tempworks Invoice Aging table.</p>
                                    </li>
                                    <li>
                                        <p><span className="bolded-info-text">Match:</span> provides a button to move to the matching module with the Customer Name selected
                                            if there is a matching formula created for that customer.
                                        </p>
                                    </li>
                                </ul>
                            </Row>
                            <Row>
                                <h4>Grid Colors Key</h4>
                                
                                <Col>
                                    <h6 className="bolded-info-text">Row Colors</h6>
                                    <p className="row-overlay color-info">OAP or Invoice Balance</p>
                                    <p className="all-balances-zero color-info">All Balances Reconciled</p>
                                    <p className="mismatch-warn color-info">Invoice Number Not In Aging</p>
                                    <p className="white-background color-info">New OAP - No Invoice Attached</p>
                                    <p className="leftover-overlay color-info">Leftover OAP - No Invoice Attached</p>
                                </Col>
                                <Col>
                                    <h6 className="bolded-info-text">Column Colors</h6>
                                    <p className="negative-value color-info">Invoice Overpayment</p>
                                    <p className="invoice-with-balance color-info">Invoice Underpayment</p>
                                    <p className="oap-with-balance color-info">Positve OAP Balance</p>
                                    <p className="negative-value color-info">Negative OAP Balance</p>
                                </Col>
                            </Row></>}
                        {infoHeader === "OAP Notes" && <>
                            <Row>
                                <h4>Summary</h4>
                                <p>The OAP Notes page contains information to help users find and appropriately process remittance files.
                                    This may include finding the Customer Name that corresponds to the name from the Wells Fargo OAP, references
                                    to email addresses for the sender of the remittance, or notes to dictate where remittance can be found or how
                                    invoices should be posted.
                                </p>
                                <p>
                                    Users may add notes by hitting the <span className="bolded-info-text">Note +</span> button, then saving that
                                    note to the database for future reference by pressing the <span className="bolded-info-text">Save New Selectd</span> button.
                                </p>
                            </Row>
                        </>}
                        {infoHeader === "OAP Search" && <>
                            <h4>Summary</h4>
                            <p>The OAP Search page allows users to research OAPs and Invoices that have been processed through the OAP tool.
                                Users may select one of three options for searching, each of which will display different results.
                                <ol>
                                    <li><span className="bolded-info-text">Invoice Number</span> search will return all items that have been processed
                                        through the OAP Tool that include the entered invoice number.</li>
                                    <li><span className="bolded-info-text">OAP Number</span> search will return all of the invoices that have been applied
                                    to the OAP Number entered into the text box.</li>
                                    <li><span className="bolded-info-text">Customer</span> search will return all of the OAP's for a particular customer that
                                    have been processed through the OAP tool.</li>
                                </ol>
                            </p>
                        </>}
                        {infoHeader === "OAP Header" && <>
                            <Row>
                                <h4>Summary</h4>
                                <p>The OAP Header Toolbar includes buttons for navigation, exporting, info, switching companies (EWM & Employnet), 
                                    and loading new pay files. It also displays OAP totals for all OAPs, reconciled OAPs, and OAPs not reconciled.
                                    From the Dashboard page, these totals are displayed in buttons, which can be clicked to display a breakdown of 
                                    the associated OAPs for each category in the right hand grid. This header may also be minimized from any page
                                    when it is not needed, or to expand the view of the data below.
                                </p>
                            </Row>
                            <Row>
                                <h4>Loading New Files</h4>
                                <p>The <span className="bolded-info-text">Load OAP File</span> and <span className="bolded-info-text">Load Payments File</span> buttons
                                    allow the user to upload new data coming from Wells Fargo. When loading the OAP File, users should be uploading the export
                                    file from the <span className="bolded-info-text">"On Account Payments" </span> page of the Wells Fargo "Payments & OAPs" site. When loading the
                                    Payments File, users should be uploading the export file from the <span className="bolded-info-text">"Payments"</span> page of the 
                                    Wells Fargo "Payments & OAPs" site.
                                </p>
                                <p><span className="bolded-info-text">Important: </span>Both the OAP File and the Payments File must be loaded together in order to populate new data. Before uploading these files, a user should
                                    be sure that the Company selector toggle is set to the company that corresponds to file being uploaded (i.e., "Employnet" for
                                    "Employnet, Inc." files and "EWM" for "Clinistic Medical Staffing, Inc." files). Users should also be sure that the data that 
                                    is being displayed (if any) in the dashboard is saved, or can be overwritten without any negative impact to OAP Reconciliation
                                    workflows as loading new files will create new data, without carrying over any existing invoices or pay amounts.

                                </p>
                            </Row>
                            <Row>
                                <h4>Navigation</h4>
                                <p>Beneath the Page Header and file loading buttons are the navigation tabs (in gray). Navigation in the OAP tool is
                                    as simple as clicking on tab that corresponds to the action the user wishes to perform or data the user wishes to view, and the 
                                    user will be navigated to the correct page. For further information about each page, users may use the navigation in the footer
                                    of this information modal.                                    
                                </p>
                            </Row>
                            <Row>
                                <h4>Button Functions</h4>
                                <p>Besides navigation and file loading, the OAP Header Toolbar includes several buttons for added functionality
                                    <ul>
                                        <li>The <span className="bolded-info-text">Company Switch Toggle</span> is used to assign the correct company
                                            to the loaded OAP and Payment files. If there is data for both EWM and Employnet, users may also use this toggle to switch
                                            between views of the data for each company. A simple click allows users to switch their workflow from EWM to Employnet, and back.
                                        </li>
                                        <li>The <span className="bolded-info-text">Export Paymnets</span> button will download a CSV file to the users computer that includes
                                            all of the pay and invoice information for each OAP that has been fully reconciled. This CSV may then be used as an upload file to
                                            reconcile OAP on the Wells Fargo side.
                                        </li>
                                        <li>From the OAP Dashboard page, user may select any of the three <span className="bolded-info-text">OAP Totals</span> buttons: Totals,
                                            Reconciled, and Not Reconciled. Upon selection of one of these buttons, users will see the Dashboard grid on the right hand
                                            side of the screen change to show all of the OAP data that corresponds to button info. In this way, users may see a quick snapshot of 
                                            which OAPs are included in each category (All OAPs, Reconciled OAPs, and OAPs Not Reconciled).
                                        </li>
                                        <li>
                                            The <b>Leftover OAPs</b> button will show all OAPs that have leftover amounts after all invoices associated with the OAP have been paid. 
                                            A download button will also appear above this grid to save this data in a csv file.
                                        </li>
                                        <li>The <span className="bolded-info-text">Collapse Header</span> button will hide the OAP Header Toolbar items about the gray line
                                            that separtes the navigation items from the totals readouts. On the Reconcile OAPs page, this will also expand the grid below, allowing
                                            the user to see more of the data while they work. Upon clicking this button, users will be able to reverse this action by clicking the
                                            <span className="bolded-info-text"> Expand Header</span> button, which is displayed when the OAP Header Toolbar has been collapsed.
                                        </li>
                                        <li>The <span className="bolded-info-text">Info</span> button will open this modal, allowing users to see detailed information about every
                                            segment of the OAP Tool. This button will open the info for the page from which the user has selected the info button, and users may 
                                            view information of other pages by using the buttons in the footer of the info modal.
                                        </li>
                                    </ul>
                                </p>
                            </Row>
                        </>}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="aging-buttons tab-color"
                        onClick={() => setInfoHeader("OAP Dashboard")}
                    >Dashboard</Button>
                    <Button
                        className="aging-buttons tab-color"
                        onClick={() => setInfoHeader("Invoice Matching")}
                    >Match Invoices</Button>
                    <Button
                        className="aging-buttons tab-color"
                        onClick={() => setInfoHeader("Reconcile OAPs")}
                    >Reconcile OAPs</Button>
                    <Button
                        className="aging-buttons tab-color"
                        onClick={() => setInfoHeader("OAP Notes")}
                    >OAP Notes</Button>
                    <Button
                        className="aging-buttons tab-color"
                        onClick={() => setInfoHeader("OAP Search")}
                    >OAP Search</Button>
                    <Button
                        className="aging-buttons tab-color"
                        onClick={() => setInfoHeader("OAP Header")}
                    >OAP Header Toolbar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}