import React from 'react'
import { Form, Spinner } from 'react-bootstrap'
import Select from 'react-select'

function Codes({ orderTypes, HandleChange }) {
  return (
    <div>                
        <Form.Label>Order Type:<span className='red-text'>*</span></Form.Label>
        {orderTypes?
          <Select onChange={(e)=>HandleChange(e,'OrderType')} options={orderTypes.map(obj=>{return{value:obj,label:(obj.OrderType+' | '+obj.Description)}})} />
          :
          <Spinner animation='border' />
        }
    </div>
  )
}

export default Codes