import React, { useState, useContext, useEffect } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import WorkSite from './WorkSite'

function WorkSiteLogic({defaultFormData}) {
  const [contacts, setContacts] = useState();
  const {REACT_APP_API_URL} = process.env;
  const {
    cookies,
    formData,
    setFormData
  } = useContext(CustomerContext);

  useEffect(()=>{
    GetCustomerContacts((formData.Department.CustomerID?formData.Department.CustomerID:formData.DepartmentID))
// eslint-disable-next-line
  },[])

  const HandleChange = (event, fieldName) => { 
    if(fieldName==='WorkSite'){
      formData.WorkSite = event.value.AddressDesc;
      formData.WorkSiteID = event.value.AddrID;
      setFormData(formData);
    }else{
      formData[fieldName] = event.value.FullName;
      formData[`${fieldName}ID`] = event.value.ContactID;
      setFormData(formData);
    }
   }

  const GetCustomerContacts = (DepartmentID) => { 
      fetch(`${REACT_APP_API_URL}/OrderRequest/GetCustomerContacts?token=${cookies.get('customer').Token}&customerid=${DepartmentID}`)
      .then(res=>{
          if(res.status===200){
              return res.json()
          }else if(res.status===401){
            cookies.remove('customer', {path:'/'})
            window.location.reload();
          }else if(res.status===503||res.status===500){
            setTimeout(() => {
              GetCustomerContacts(DepartmentID)
            }, 1000);
            return [];
          }else if(!res.ok){
            res.text().then(text=>{
              if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                setTimeout(() => {
                  GetCustomerContacts(DepartmentID)
                }, 1000);
              }else{
                throw Error(res.statusText)
              }
            })
          }
      })
      .then(data=>{
          TransformContactsToOptions(data.data)
      })
      .catch(error=>{
          console.error(error)
          window.alert(error)
      })
   }

  const TransformContactsToOptions = (contacts) => { 
    let transformmedContacts = contacts.map(contact=>{
      return {value:contact, label:contact.FullName}
    })
    setContacts(transformmedContacts)
   }

  const FindDefaultValue = (defaultFormData, fieldName, options) => { 
    if(defaultFormData){
      let defaultValue = defaultFormData[fieldName]
      if(defaultValue){
        let defaultOption = options.find(option=>{
          return option.label === defaultValue
        })
        return defaultOption;
      }
      return null;
    }
  }
    
  return (
    <>
    {contacts&&
    <WorkSite
      apiUrl={REACT_APP_API_URL}
      cookies={cookies}
      formData={formData}
      contacts={contacts}
      HandleChange={HandleChange}
      defaultFormData={defaultFormData}
      FindDefaultValue={FindDefaultValue}
    ></WorkSite>
    }
    </>
  )
}

export default WorkSiteLogic