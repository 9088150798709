import { useMemo } from "react";
import ResearchErrorsView from "./ResearchErrorsView";

export default function ResearchErrors({
    defaultColDef,
    delayedRows,
    delayedCols,

    mergeData,
    mergeCols,
    delayedMergeRows,

    auditData,
    calculatedSums,
    hoursTotals,
    researchAddedParams,
    columnMap
}){
    //set up the calculated sums, map, and totals cards
    const rawSums = {
        mappingHoursTotal: hoursTotals.mappingHoursTotal,
        mappingHoursReg: hoursTotals.mappingHoursReg,
        mappingHoursOT: hoursTotals.mappingHoursOT,
        mappingHoursDT: hoursTotals.mappingHoursDT,
        mappingHoursMP: hoursTotals.mappingHoursMP
    };

    const mismatchSums = {
        mismatchHoursTotal: hoursTotals.mismatchHoursTotal,
        mismatchHoursReg: hoursTotals.mismatchReg,
        mismatchHoursOT: hoursTotals.mismatchOT,
        mismatchHoursDT: hoursTotals.mismatchDT,
        mismatchHoursMP: hoursTotals.mismatchMP
    };

    const uploadSums = {
        uploadHoursTotal: hoursTotals.uploadHoursTotal,
        uploadHoursReg: hoursTotals.uploadReg,
        uploadHoursOT: hoursTotals.uploadOT,
        uploadHoursDT: hoursTotals.uploadDT,
        uploadHoursMP: hoursTotals.uploadMP
    };

    const rowHighlight = useMemo((params)=>{
        if(delayedMergeRows&&delayedMergeRows.length&&researchAddedParams.erroredLines&&researchAddedParams.erroredLines.length){
            return{
                'mismatch-warn': (params)=>{
                    let isMismatchedRow = researchAddedParams.erroredLines.includes(params.data);
                    return isMismatchedRow;
                },
            };
        }
        // eslint-disable-next-line
    },[delayedMergeRows]);

    return(
        <ResearchErrorsView
            defaultColDef={defaultColDef}
            delayedRows={delayedRows}
            delayedCols={delayedCols}
            
            mergeData={mergeData}
            mergeCols={mergeCols}
            delayedMergeRows={delayedMergeRows}

            calculatedSums={calculatedSums}
            rawSums={rawSums}
            mismatchSums={mismatchSums}
            uploadSums={uploadSums}

            definedMismatchError={auditData.definedMismatchError}
            timecardHoursMatch={researchAddedParams.timecardHoursMatch}
            columnMap={columnMap}
            rowHighlight={rowHighlight}
        />
    )
}