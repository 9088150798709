import { GetActiveEmployees } from "../CallTimecardAPI";

export default function GetEmployeeDefs(weeklyTotals, cookies, setEmployeeDefs) {
  let activeEmployees = [];

  let lookupIds = [...new Set(weeklyTotals.map(timecard => timecard.IdNumber))];
  let idType = weeklyTotals[0].IdType;
  let customerID = weeklyTotals[0].CustomerID;
  let otLaw = weeklyTotals[0].OTLaw;

  lookupIds.forEach(id => {
    let employeeData = {};

    GetActiveEmployees(id, idType, customerID, cookies).then(employees => {
      if (employees && employees.length) {
        employeeData = {
          LookupID: id,
          IdType: idType,
          CustomerID: customerID,
          WeekEndDay: GetWeDate(employees[0].WeekEndDay),
          AssignmentID: employees[0].AssignmentID,
          EmployeeID: employees[0].EmployeeID,
          otLaw: otLaw === "State" ? employees[0].State : otLaw
        }
      } else {
        employeeData = {
          LookupID: id,
          IdType: idType,
          CustomerID: customerID,
          WeekEndDay: GetWeDate("Sunday"),
          AssignmentID: 'Mismatch',
          EmployeeID: 'Mismatch',
          otLaw: otLaw
        }
      }
      activeEmployees.push(employeeData);
      if (activeEmployees.length === lookupIds.length) {
        // log lengths to see if this can be effective, else, find a way to set match row data
        // when loop is over
        setEmployeeDefs(activeEmployees);
      }
    });
  });
}

function GetWeDate(day) {
  const FormatDate = (dayDate) => {
    let formattedDate = Date.parse(dayDate);
    let numDate = new Date(formattedDate);
    let stringDate = numDate.toLocaleDateString('en-US');
    return stringDate;
  }

  let dayVal = day === "Friday" ? 6 : day === "Saturday" ? 7 : 8;
  const rawDate = new Date().getDate() + (dayVal - new Date().getDay() - 1) - 7;
  const weekEndingDate = new Date();
  weekEndingDate.setDate(rawDate);
  const weDate = FormatDate(weekEndingDate);
  return weDate;
}