export function GetSelectedInvoices(gridApi) {
    const selectedData = gridApi.getSelectedRows();
    return selectedData;
}

export function isMultipleCustomer(selectedData) {
    let isMultiple = false;
    for (var i = 0; i < selectedData.length; i++){
      if (selectedData[i].CustomerID !== selectedData[0].CustomerID){
        window.alert("You have selected more than one customer!", "OK");
        isMultiple = true;
        break;
      }
    };
    return isMultiple;
}

export function GetCustomerEmailID(gridAPi) {
    
    let selectedData = GetSelectedInvoices(gridAPi);
    let isMultiple = isMultipleCustomer(selectedData);

    let selectedCustomerID = isMultiple ? null : selectedData[0].CustomerID;

    return selectedCustomerID;
}