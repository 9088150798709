export function GetKeyNumbers(columnKeys){
    let keyNumbers = [];
    // add 26 if key is multiple-letters (i.e., AA would be at 26, BA would be 52, etc.)
    // supports up to EZ
    columnKeys.forEach(key=>{
        let numberedKey = key.length ===1 ? key.charCodeAt(0)-65 :
            key[0] === "A" ? key.charCodeAt(1)-65 + 26 :
            key[0] === "B" ? key.charCodeAt(1)-65 + 52 :
            key[0] === "C" ? key.charCodeAt(1)-65 + 78 :
            key[0] === "D" ? key.charCodeAt(1)-65 + 104 :
            key.charCodeAt(1)-65 + 130;
        keyNumbers.push(numberedKey);
    });
    return keyNumbers;
}

export function GetActiveMapHeaders(keyNumbers, timecardHeaders){
    let activeMapHeaders = [];
    keyNumbers.forEach(keyNumber=>{
        let activeMapHeader = timecardHeaders[keyNumber];
        activeMapHeaders.push(activeMapHeader);
    });
    return activeMapHeaders;
}

export function GetColumnMapObj(columnValues, activeMapHeaders){
    let colObj = {}
    columnValues.forEach((custCol, tcCol)=>{
        // eslint-disable-next-line
        {colObj[custCol] = activeMapHeaders[tcCol]}
    });
    return colObj;
}