import React, { useContext, useEffect, useState } from 'react'
import CandidateInfoPopup from './CandidateInfoPopup';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';

function CandidateInfoPopupLogic({ 
  openedTab,
  show,
  setShow,
  candidateID,
  allCandidateInfo,
  defaultComplianceActiveKey 
}){
    const [status, setStatus] = useState();
    const [candidateInfo, setCandidateInfo] = useState();
    const [documents, setDocuments] = useState();
    const [assignments, setAssignments] = useState();
    const [messages, setMessages] = useState();
    const [workHistory, setWorkHistory] = useState();
    const [onboarding, setOnboarding] = useState();
    const {cookies} = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;
    const { data } = useFetchGet(`${REACT_APP_API_URL}/Candidates/GetCandidateDetails?token=${cookies.get('customer').Token}&EmployeeID=${candidateID}`,cookies)

    useEffect(()=>{
      if(data){
        setStatus(data.status)
        setDocuments(data.documents)
        setCandidateInfo({
          ...allCandidateInfo,
          ...data.candidate,
          interests: data.Interests.map(interest=>interest.Code)
        })
        setAssignments(data.history)
        setMessages(data.messages)
        setWorkHistory(data.work)
        setOnboarding(data.onboards)
      }
    // eslint-disable-next-line
    },[data])

    function HandleClose(){
        setShow(false)
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }
      
    return(
        <CandidateInfoPopup
            candidateInfo={candidateInfo}
            show={show}
            HandleClose={HandleClose}
            status={status}
            documents={documents}
            openedTab={openedTab}
            openInNewTab={openInNewTab}
            assignments={assignments}
            messages={messages}
            workHistory={workHistory}
            onboarding={onboarding}
            defaultComplianceActiveKey={defaultComplianceActiveKey}
        ></CandidateInfoPopup>
    )
}

export default CandidateInfoPopupLogic;