import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';
import { BsLayoutThreeColumns } from "react-icons/bs";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Spinner from 'react-bootstrap/Spinner';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import React, { useContext, useEffect, useState } from 'react'

function ColumnSavingButtonGroup({
    customColumnName,
    columnApi,
    setColumnDefs,
    otherColumnDefs,
    columnDefs,
    adminDefault
}) {
    const [isDefault, setIsDefault] = useState(adminDefault?true:false);
    const [encodedColumns, setEncodedColumns] = useState();
    const {cookies} = useContext(CustomerContext);
    const { REACT_APP_API_URL } = process.env;
    const { loading } = useFetchPost((encodedColumns&&`${REACT_APP_API_URL}/Utilities/PostReportColumns?token=${cookies.get('customer').Token}&reportName=${customColumnName}&isDefault=${isDefault}`),cookies,encodedColumns)
    const { data: customColumns } = useFetchGet((!columnDefs&&`${REACT_APP_API_URL}/Utilities/GetReportColumns?token=${cookies.get('customer').Token}&reportName=${customColumnName}&isDefault=${isDefault}`),cookies)

    useEffect(()=>{
        if(customColumns){
            if(customColumns.Return){
                let decodedColumns = JSON.parse(window.atob(customColumns.Return));
                decodedColumns.forEach(column=>{
                    if(column.field==='overview'){
                        column.cellRenderer = RenderOverview
                    }else if(column.headerName==='List'||column.headerName==='Candidate'||column.field==='Contacted'){
                        column.valueFormatter = transformBool
                    }else if(column.headerName==='Contact For Work'){
                        column.valueFormatter = TransformDoNotCall
                    }else if(column.field&&(column.field.includes('date')||column.field.includes('Date'))){
                        column.valueFormatter = TransformDate
                    }else if(column.field==='compliances'){
                        column.cellRenderer = RenderCompliance
                    }
                })
                setColumnDefs(decodedColumns);
            }else{
                setColumnDefs([
                    {headerName:'Select',checkboxSelection:true, suppressMovable:true},
                    {field:'EmployeeID', cellClass: 'linked-cell'},
                    {field:'FirstName'},
                    {field:'LastName'},
                    {headerName:'Overview ',field:'overview', cellRenderer: RenderOverview,headerTooltip: 'A=Assigned, C=Candidate, L=List, W=Contact For Work'},
                    {headerName:'Branch',field:'BranchName'},
                    {headerName:'Cell',field:'CellPhoneNumber'},
                    {headerName:'Email',field:'EmailAddress'},
                    {field:'City'},
                    {field:'Zip'},
                    {field:'Distance'},
                    {field:'Notes'},
                    {headerName:'Employee Created',field:'DateCreated',valueFormatter: TransformDate},
                    {field:'WashedStatus'},
                    {field:'LastPayDate',valueFormatter: TransformDate},
                    {field:'LastPayCount'},
                    {field:'LastStatus'},
                    {field:'JobTitle'},
                    {field:'CustomerName'},
                    {field:'OrderTitle'},
                    {field:'Assigned'},
                    {field:'Supervisor'},
                    {field:'Shift'},
                    {headerName:'List',field:'IsInList',valueFormatter:transformBool},
                    {headerName:'Candidate',field:'ImpliesActive',valueFormatter:transformBool},
                    {headerName:'Contact For Work',field:'DoNotCall',valueFormatter: TransformDoNotCall},
                    ...otherColumnDefs
                ]);        
            }
        }
    // eslint-disable-next-line
    },[customColumns])

    const RenderCompliance = (params) => {
        let compliance = [];
        let keys = Object.keys(params.data.Compliance)
        keys.forEach((key,index) => {
            let styledCompliance = StyleCompliance(key[0].toUpperCase(),params.data.Compliance[key],index,params.node.rowIndex)
            compliance.push(styledCompliance);
        });
        return <div className='compliance-group' key={'compliance'+params.node.rowIndex}>{compliance}</div>;
     }

     function TransformDate(date){
        if(date.value){
            return new Date(date.value).toLocaleDateString();

        }else{
            return 'None'
        }
    }

    const transformBool = (params) => { 
        if(params.value){
            return 'Yes'
        }else{
            return 'No'
        }
     }

    const TransformDoNotCall = (params) => { 
        if(params.value){
            return 'Do not contact'
        }else{
            return 'Contact'
        }
     }

     const RenderOverview = (params) => { 
        if(params.node){
            let overview = [];
            let keys = [{letter: 'A', value:((params.data.Assigned==='Assigned')?1:null)}, {letter: 'C', value:(params.data.ImpliesActive?1:null)}, {letter: 'L',value:(params.data.IsInList?1:null)}, {letter:'W',value:(params.data.DoNotCall?3:1)}]
            keys.forEach((key, index)=>{
                let styledOverview = StyleCompliance(key.letter, key.value, index, params.node.rowIndex, params)
                overview.push(styledOverview)
            })
            return <div className='compliance-group' key={'overview-'+params.node.rowIndex}>{overview}</div>;;
        }
     }

     const StyleCompliance = (complianceName, complianceProgress, index, parentIndex, params) => { 
        if(complianceProgress===0){
            return <p className='compliance compliance-in-progress' key={'compliance-nested-in-progress'+index+parentIndex}>{complianceName}</p>
        }else if(complianceProgress===1){
            return <p className='compliance compliance-complete' key={'compliance-nested-complete'+index+parentIndex}>{complianceName}</p>
        }else if(complianceProgress===2){
            return <p className='compliance compliance-not-needed' key={'compliance-nested-not-needed'+index+parentIndex}>{complianceName}</p>
        }else if(complianceProgress===3){
            return <p className='compliance compliance-warning' key={'compliance-nested-warning'+index+parentIndex}>{complianceName}</p> 
        }else if(!complianceProgress){
            return <p className='compliance compliance-not-started' key={'compliance-nested-not-started'+index+parentIndex}>{complianceName}</p>
        }
     }

    const saveColumnOrder = () => { 
        setIsDefault(adminDefault?true:false)
        let columns = columnApi.getAllGridColumns()
        columns = columns.map(column => {
            let userDefinedColDef = columnDefs[columnDefs.findIndex((element)=>element.field===column.colDef.field)];
            return {...column.colDef,...userDefinedColDef, width:column.actualWidth}
        })
        setColumnDefs(columns)
        let string = JSON.stringify(columns)
        let encoded = window.btoa(string)
        setEncodedColumns(encoded)
     }

    const getDefaults = () => { 
        setColumnDefs(null)
        setIsDefault(true)
     }

  return (
    <>
        <DropdownButton as={ButtonGroup} title={loading?<Spinner size='sm' animation='border' />:<BsLayoutThreeColumns/>} className='margin-right' variant="secondary">
        <Dropdown.Item variant="secondary" onClick={() => saveColumnOrder()}>Save Columns</Dropdown.Item>
        <Dropdown.Item variant="secondary" onClick={() => getDefaults()}>{loading?<Spinner size='sm' animation='border' />:'Show Default Columns'}</Dropdown.Item>
        </DropdownButton>
    </>
  )
}

export default ColumnSavingButtonGroup