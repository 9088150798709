import { useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import './Header.css';
import { CustomerContext } from 'Components/CustomComponents/Context/CustomerContext';
import { Header } from 'employnet-react-components';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';

export default function NavMenu () {
  const {cookies, currentPage} = useContext(CustomerContext);
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;
  const { data: menuOptions } = useFetchGet((cookies.get('customer')&&`${REACT_APP_API_URL}/Utilities/getmenu?token=${cookies.get('customer').Token}&appname=Infinity`),cookies)

  const Logout = () => {
    RemoveAuth().then(()=>{
      navigate('/login')
    });
  };

  const RemoveAuth = async function(){
    cookies.remove('customer', { path:'/' })
    return;
  };

  return (
    <Header className='navbar-container' appName='Infinity' currentPage={currentPage} loggedInUser={cookies.get('customer')} HandleLogout={Logout} MenuOptions={menuOptions&&menuOptions.Tree}  />
  );
}