import React from 'react'
import { Modal, Tabs, Tab, Spinner, Card } from 'react-bootstrap'
import CandidatesGridLogic from 'Components/ATS/Candidates/CandidatesGrid/CandidatesGridLogic'
import ReadOnlyJobOrderLogic from '../ReadOnlyJobOrder/ReadOnlyJobOrderLogic'
import openInNewTab from '../../../../CustomComponents/openInNewTab'
import './JobOrderDetailsPopup.css'

function JobOrderDetailsPopup({
    candidateData,
    jobOrderData,
    show,
    setShow,
    activeKey,
    setActiveKey
    }) {
  return (
    <>
    <Modal show={show} onHide={setShow} dialogClassName="modal-90w">
        <Modal.Header closeButton>
            {jobOrderData?
            <div className='job-order-detail-header'>
                <h3 onClick={()=>openInNewTab(`https://beyond.ontempworks.com/joborder/${jobOrderData.OrderID}`)} className='linked-cell'>{jobOrderData.OrderID}</h3>
                <h3>{jobOrderData.CustomerName}</h3>
            </div>
            :
            <Spinner animation='border' />}
        </Modal.Header>
        <Modal.Body>
            <Tabs activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                <Tab eventKey="candidates" title="Candidates">
                {candidateData?
                        <>
                        {candidateData.length>0?
                        <CandidatesGridLogic rowData={candidateData} otherColumnDefs={[]} customColumnName='Active Candidates' />
                        :
                        <Card className='text-center margin-top'>
                            <Card.Header>
                                <h3>No Candidates here!</h3>
                            </Card.Header>
                            <Card.Body>
                                <p>Use the <a href="/ATS/Candidates/Search">Search</a> tool to find Employees</p>
                            </Card.Body>
                        </Card>
                        }
                        </>
                        :
                        <Spinner animation='border'/>
                        }
                </Tab>
                <Tab eventKey="jobDetails" title="Job Order Details">
                    {jobOrderData&&<ReadOnlyJobOrderLogic orderID={jobOrderData.OrderID} />}
                </Tab>
            </Tabs>
        </Modal.Body>
    </Modal>
    
    </>
  )
}

export default JobOrderDetailsPopup