import React from "react";
import { Modal, Form, Container, Button } from "react-bootstrap";

export default function PasteInvoicesView({
    selectedOap,
    invoicePasteBody,
    setInovoicePasteBody,
    showPasteInvoices,
    handleClose,
    onInvoicePush
    
}) {
    
    return (
        <div>
            <Modal dialogClassName="modal-70w" show={showPasteInvoices} onHide={handleClose}>
            <Modal.Header closeButton>
                    <h3>Paste Invoices for {selectedOap}</h3>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form.Control
                            as='textarea'
                            value={invoicePasteBody}
                            rows={10}
                            placeholder="Paste Invoices and Amounts from excel. Make sure the two columns are side by side and are in number or general format."
                            onChange={(e)=>setInovoicePasteBody(e.target.value)}
                        />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="link-button-next"  onClick={(e)=> onInvoicePush(e)}>Load Invoices</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}