import React from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import AddCustomListPopupLogic from 'Components/ATS/Candidates/CustomLists/CustomListsLogic.js';
import { AgGridReact } from 'ag-grid-react';

function AssignEmployee({ 
  columnDefs,
  defaultColDef,
  loading, 
  handleRowSelected, 
  HandleSubmit, 
  show, 
  HandleClose, 
  lists, 
  addCustomListOpen, 
  setAddCustomListOpen, 
}) {
  return (
    <>  
    {addCustomListOpen&&<AddCustomListPopupLogic show={addCustomListOpen} setShow={setAddCustomListOpen} />}
    <Modal show={show} onHide={HandleClose} size='lg'>
        <Modal.Header closeButton>
            <Modal.Title>Add to List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Label>Custom List</Form.Label>
            <div id="myGrid" className="ag-theme-alpine vh40">
              <AgGridReact
                rowData={lists}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowSelection='multiple'
                onRowSelected={handleRowSelected}
              />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={HandleClose}>
              Close
            </Button>
            <Button disabled={loading} variant="primary" onClick={HandleSubmit}>
              {loading? 'Saving...' : 'Save Changes'}
            </Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}

export default AssignEmployee