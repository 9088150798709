import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";


const EditNumberCell = forwardRef((props, ref) => {
    const [value, setValue] = useState(parseInt(props.value));
    const refInput= useRef(null);

    useEffect(() => {
        // focus on the input
        refInput.current.focus();
    }, []);
 
    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return Number(value);
            },
 
            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false;
            },
 
            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd() {
                // our editor will reject any null value
                return !Number(value);
            }
        };
    });

    const handleOnChange = (e) => { 
        if(!isNaN(e)){
            setValue(e)
        }
     }
 
    return (
        <input ref={refInput}
               value={value}
               onChange={event => handleOnChange(event.target.value)}
               style={{width: "100%"}}
        />
    );
 });

 export default EditNumberCell;
 