import React, { useState, useEffect, useContext } from 'react'
import AutoSearchSelect from './AutoSearchSelect';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';

function AutoSearchSelectLogic({ 
    onMenuOpen, 
    apiUrl, 
    OnChange, 
    labelName, 
    defaultValue, 
    isMulti, 
    defaultSearchParam,
    customSelectStyles
}){
    const [searchParam, setSearchParam] = useState((defaultSearchParam?defaultSearchParam:''));
    const [options, setOptions] = useState();
    const {
        cookies
    } = useContext(CustomerContext)

    useEffect(()=>{
        if(defaultValue){
            defaultValue.isSelected=true;
            setOptions([defaultValue])
        }
    },[defaultValue])

    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
          if(searchParam!==''){
              FetchCandidatesFromDB(searchParam, apiUrl)
          }
      }, 1500)
  
      return () => clearTimeout(delayDebounceFn)
// eslint-disable-next-line
    }, [searchParam])

    function FetchCandidatesFromDB(searchParam, apiUrl){
        if(cookies.get('customer')==null){
            window.location.reload(false);
        }
        return fetch(`${apiUrl}?token=${cookies.get('customer').Token}&search=${searchParam}`)
        .then(res=>{
            if(res.status===200){
                return res.json()
              }else if(res.status===401){
                cookies.remove('customer', {path:'/'})
                window.location.reload();
              }else if(res.status===503||res.status===500){
                setTimeout(() => {
                  FetchCandidatesFromDB()
                }, 1000);
                return [];
              }else if(!res.ok){
                res.text().then(text=>{
                  if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                    setTimeout(() => {
                      FetchCandidatesFromDB()
                    }, 1000);
                  }else{
                    throw Error(res.statusText)
                  }
                })
              }
        })
        .then(data=>{
            setSearchParam('')
            return TransformOptions(data)
        })
    }

    function TransformOptions(data){
        if(data.length>0){
            let transformedOptions = data.map(obj=>{
                if(labelName==='Zip'){
                    return {value:obj,label:`${obj.City} - ${obj.Zip}`}
                }else if(labelName==='CustomerName'){
                    return {value:obj,label:`${obj.DepartmentName} | ${obj.CustomerName}`}
                }else if(labelName){
                    return {value:obj,label:obj[labelName]}
                }else{
                    return {value:obj,label:`${obj.DepartmentName}, ${obj.City}, ${obj.CustomerName}, ${obj.Zip}`}
                }
            })
            setOptions(transformedOptions)
            return transformedOptions
        }else{
            setOptions([{value:'',label:"No Results for '"+searchParam+"'", isDisabled:true}])
            return [{value:'',label:"No Results for '"+searchParam+"'", isDisabled:true}]
        }
    }

    function HandleKeyDown(e){
            if(e.code==="Backspace"){
                setSearchParam((searchParam.slice(0, -1)))
            }else if((e.key.length===1)&&CheckAlphaNumeric(e.code)){
                setSearchParam(searchParam+e.key)
            }
    }

    function CheckAlphaNumeric(string){
        let regex = /^[a-z0-9]+$/i;
        return regex.test(string)
    }

    return(
    <>
        {(options||!defaultValue)&&
        <AutoSearchSelect   
            apiUrl={apiUrl}
            FetchCandidatesFromDB={FetchCandidatesFromDB}
            setSearchParam={setSearchParam}
            HandleKeyDown={HandleKeyDown}
            options={options}
            OnChange={OnChange}
            isMulti={isMulti}
            onMenuOpen={onMenuOpen}
            defaultValue={defaultValue}
            customSelectStyles={customSelectStyles} 
        ></AutoSearchSelect>
        }
    </>
    )
}

export default AutoSearchSelectLogic;