import { useState, useContext, useEffect } from "react";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import AddCustomerView from "./AddCustomerView";

export default function AddCustomer({
    setAddEditCustomer,
    customerList,
    customerDefinitionList
}){
    const { cookies } = useContext(CustomerContext);
    const [formPage, setFormPage] = useState(1);
    const [useDepartments, setUseDepartments] = useState(false);

    const [customerID, setCustomerID] = useState();
    const [displayName, setDisplayName] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [isNewCustomer, setIsNewCustomer] = useState(true);

    const [selectedOtRule, setSelectedOtRule] = useState();    
    const [selectedProcessType, setSelectedProcessType] = useState();
    const [processTypeDeterminent, setProcessTypeDeterminent] = useState();

    const [selectedIdType, setSelectedIdType] = useState();
    const [idModifier, setIdModifier] = useState();

    const [selectedWEDate, setSelectedWEDate] = useState(); 
    const [firstDataRow, setFirstDataRow] = useState(2);

    const customerIdentifiers = {
        timecardCustomerID: customerID,
        customerName: displayName,
        useDepartments: useDepartments,
        otLaw: selectedOtRule,
        processType: selectedProcessType,
        idType: selectedIdType,
        timecardIdModifier: idModifier,
        weekEndingDay: selectedWEDate,
        startReadRow: firstDataRow
    };

    const customerInfoFormSet = {
        isNewCustomer: isNewCustomer,
        setIsNewCustomer: setIsNewCustomer,
        selectedCustomer: selectedCustomer,
        setSelectedCustomer: setSelectedCustomer,

        useDepartments: useDepartments,
        setUseDepartments: setUseDepartments,

        customerID: customerID,
        setCustomerID: setCustomerID,
        displayName: displayName,
        setDisplayName: setDisplayName,

        selectedOtRule: selectedOtRule,
        setSelectedOtRule: setSelectedOtRule,
        selectedProcessType: selectedProcessType,
        setSelectedProcessType: setSelectedProcessType,
        setProcessTypeDeterminent: setProcessTypeDeterminent,

        selectedIdType: selectedIdType,
        setSelectedIdType: setSelectedIdType,
        idModifier: idModifier,
        setIdModifier: setIdModifier,

        selectedWEDate: selectedWEDate,
        setSelectedWEDate: setSelectedWEDate,
        firstDataRow: firstDataRow,
        setFirstDataRow: setFirstDataRow
    }

    const [employeeNameCol, setEmployeeNameCol] = useState();
    const [lastNameCol, setLastNameCol] = useState();
    const [firstNameCol, setFirstNameCol] = useState();
    const [idCol, setIdCol] = useState();
    const [departmentIdentifierCol, setDepartmentIdentifierCol] = useState();

    const [regCol, setRegCol] = useState();
    const [otCol, setOtCol] = useState();
    const [dtCol, setDtCol] = useState();
    const [mpCol, setMpCol] = useState();

    const totalsByColumnCols = [
        employeeNameCol,
        lastNameCol,
        firstNameCol,
        idCol,
        departmentIdentifierCol,
        regCol,
        otCol,
        dtCol,
        mpCol];
    
    const totalsByColumnHeaders = [
        "EmployeeName",
        "LastName",
        "FirstName",
        "IdNumber",
        "Department",
        "Reg",
        "OT",
        "DT",
        "MP"
    ];

    const [hoursTypeCol, setHoursTypeCol] = useState();
    const [totalHoursCol, setTotalHoursCol] = useState();
    const totalsByTypeCols = [
        employeeNameCol,
        lastNameCol,
        firstNameCol,
        idCol,
        departmentIdentifierCol,
        hoursTypeCol,
        totalHoursCol];

    const totalsByTypeHeaders = [
        "EmployeeName",
        "LastName",
        "FirstName",
        "IdNumber",
        "Department",
        "HoursType",
        "TotalHours"
    ];

    const [punchDateCol, setPunchDateCol] = useState();
    const [inTypeCol, setInTypeCol] = useState();
    const [inPunchCol, setInPunchCol] = useState();
    const [outTypeCol, setOutTypeCol] = useState();
    const [outPunchCol, setOutPunchCol] = useState();
    const [punchDayCol, setPunchDayCol] = useState();

    const punchesInOutCols = [
        employeeNameCol,
        lastNameCol,
        firstNameCol,
        idCol,
        departmentIdentifierCol,
        punchDateCol,
        inTypeCol,
        inPunchCol,
        outTypeCol,
        outPunchCol,
        punchDayCol];

    const punchesInOutHeaders = [
        "EmployeeName",
        "LastName",
        "FirstName",
        "IdNumber",
        "Department",
        "PunchDate",
        "InType",
        "InPunch",
        "OutType",
        "OutPunch",
        "PunchDay"
    ];

    const [inOneCol, setInOneCol] = useState();
    const [outOneCol, setOutOneCol] = useState();
    const [inTwoCol, setInTwoCol] = useState();
    const [outTwoCol, setOutTwoCol] = useState();
    const [inThreeCol, setInThreeCol] = useState();
    const [outThreeCol, setOutThreeCol] = useState();
       
    const punchesByColumnCols = [
        employeeNameCol,
        lastNameCol,
        firstNameCol,
        idCol,
        departmentIdentifierCol,
        punchDateCol,
        inOneCol,
        outOneCol,
        inTwoCol,
        outTwoCol,
        inThreeCol,
        outThreeCol];

    const punchesByColumnHeaders = [
        "EmployeeName",
        "LastName",
        "FirstName",
        "IdNumber",
        "Department",
        "PunchDate",
        "InOne",
        "OutOne",
        "InTwo",
        "OutTwo",
        "InThree",
        "OutThree"
    ];

    const [columnMapDefinition, setColumnMapDefinition] = useState();
    
    const customSelectStyles = {
        control: base => ({
            ...base,
            width: '300px'
        }),
    };

    useEffect(()=>{
        if(selectedCustomer&&!isNewCustomer){
            let definitions = customerDefinitionList.find(def => def.customer === selectedCustomer.value);
            console.log(definitions);
            setSelectedOtRule(definitions.otLaw);
            setSelectedProcessType(definitions.type);
            setSelectedIdType(definitions.identifier);
            if(definitions.idModifier){
                setIdModifier(definitions.idModifier);
            };
            setSelectedWEDate(definitions.weDate);
            setFirstDataRow(definitions.firstDataRow);
            setUseDepartments(definitions.useDepartments);
            setProcessTypeDeterminent(definitions.type.label);
        }
        // eslint-disable-next-line
    },[selectedCustomer]);

    const showAddModal = () => {
        setAddEditCustomer(true);
    }

    const hideAddModal = () => {
        setAddEditCustomer(false);
    }

    const GetColumnMapping = () => {
        let mappingObj = {}

        let headerSelectionGetter = processTypeDeterminent === "Totals: By Column" ? totalsByColumnHeaders
        : processTypeDeterminent === "Totals: By Type" ? totalsByTypeHeaders
        : processTypeDeterminent === "Punches: In/Out" ? punchesInOutHeaders
        : processTypeDeterminent === "Punches: By Column" ? punchesByColumnHeaders
        : null;

        let columnSelectionGetter = processTypeDeterminent === "Totals: By Column" ? totalsByColumnCols
            : processTypeDeterminent === "Totals: By Type" ? totalsByTypeCols
            : processTypeDeterminent === "Punches: In/Out" ? punchesInOutCols
            : processTypeDeterminent === "Punches: By Column" ? punchesByColumnCols
            : null;
 
        columnSelectionGetter.forEach((key, i) => {
            if(key!==undefined){
                mappingObj[key] = headerSelectionGetter[i]
            }
        });
        let mappingValues = Object.values(mappingObj);
        
        if (mappingValues.includes('IdNumber')){
            setColumnMapDefinition(mappingObj);
        } else {
            window.alert("Column mapping must include a value for Id Number");
        }
    }

    const commonIdentifierSet = {
        employeeNameCol: employeeNameCol,
        setEmployeeNameCol: setEmployeeNameCol,
        lastNameCol: lastNameCol,
        setLastNameCol: setLastNameCol,
        firstNameCol: firstNameCol,
        setFirstNameCol: setFirstNameCol,
        idCol: idCol,
        setIdCol: setIdCol,
        departmentIdentifierCol: departmentIdentifierCol,
        setDepartmentIdentifierCol
    }

    const totalsByColumnSet = {
        regCol: regCol,
        setRegCol: setRegCol,
        otCol: otCol,
        setOtCol: setOtCol,
        dtCol: dtCol,
        setDtCol: setDtCol,
        mpCol: mpCol,
        setMpCol: setMpCol
    };

    const totalsByTypeSet = {
        hoursTypeCol: hoursTypeCol,
        setHoursTypeCol: setHoursTypeCol,
        totalHoursCol: totalHoursCol,
        setTotalHoursCol: setTotalHoursCol
    };

    const punchesInOutSet = {
        punchDateCol: punchDateCol,
        setPunchDateCol: setPunchDateCol,
        inTypeCol: inTypeCol,
        setInTypeCol: setInTypeCol,
        inPunchCol: inPunchCol,
        setInPunchCol: setInPunchCol,
        
        outTypeCol: outTypeCol,
        setOutTypeCol: setOutTypeCol,
        outPunchCol: outPunchCol,
        setOutPunchCol: setOutPunchCol,
        punchDayCol: punchDayCol,
        setPunchDayCol: setPunchDayCol
    }

    const punchesByColumnSet = {
        punchDateCol: punchDateCol,
        setPunchDateCol: setPunchDateCol,
        inOneCol: inOneCol,
        setInOneCol: setInOneCol,
        outOneCol: outOneCol,
        setOutOneCol: setOutOneCol,

        inTwoCol: inTwoCol,
        setInTwoCol: setInTwoCol,
        outTwoCol: outTwoCol,
        setOutTwoCol: setOutTwoCol,

        inThreeCol: inThreeCol,
        setInThreeCol: setInThreeCol,
        outThreeCol: outThreeCol,
        setOutThreeCol: setOutThreeCol
    };

    

    return (
        <AddCustomerView
            cookies={cookies}
            show={showAddModal}
            onHide={hideAddModal}
            formPage={formPage}
            setFormPage={setFormPage}
            customerIdentifiers={customerIdentifiers}
            columnMapDefinition={columnMapDefinition}
            GetColumnMapping={GetColumnMapping}

            customerInfoFormSet={customerInfoFormSet}
            customerList={customerList}
            customSelectStyles={customSelectStyles}

            commonIdentifierSet={commonIdentifierSet}
            totalsByColumnSet={totalsByColumnSet}
            totalsByTypeSet={totalsByTypeSet}
            punchesInOutSet={punchesInOutSet}
            punchesByColumnSet={punchesByColumnSet}

            processTypeDeterminent={processTypeDeterminent}
        />
    )
}