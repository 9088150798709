import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap'
import Select from 'react-select';
import AllBranchesLogic from './AllBranches/AllBranchesLogic';

function AdvancedSearch({ 
    today, 
    HandleChange, 
    HandleSubmission, 
    orderTypeOptions, 
    performanceCodeOptions, 
    dateFilterOptions,
    customSelectStyles
}){
    const customSelectStylesSmall = {
        control: base => ({
            ...base,
            width: '200px'
        }),
    };

    return(
        <>
            <Form onSubmit={HandleSubmission}>
                <Row className='two-col-even margin-top'>
                    <Col>
                        <div className='left-align margin-bottom'>
                            <p className='label-gap'>Branch:</p>
                            <AllBranchesLogic customSelectStyles={customSelectStyles}/>
                        </div>
                        <div className='left-align margin-bottom'>
                            <p className='label-gap'>Date Filter:</p>
                            <Select onChange={(e)=>HandleChange(e,'DateFilter')} 
                                name='Date Filter' 
                                defaultValue={{value:'ActiveAsgWithinSpecifiedRange',label:'Active Assignment Within Specified Range'}} 
                                options={dateFilterOptions}
                                styles={customSelectStyles}
                            />
                        </div>
                        
                    </Col>
                    <Col>
                        <div className='two-col-even'>
                            <div className='left-align margin-bottom'>
                                <p className='label-gap'>Order Type:</p>
                                <Select 
                                    onChange={(e)=>HandleChange(e,'OrderType')} 
                                    name='Order Type' 
                                    options={orderTypeOptions} 
                                    defaultValue={orderTypeOptions[0]}
                                    styles={customSelectStylesSmall}
                                />
                            </div>
                            <div className='left-align margin-bottom'>
                                <p className='label-gap'>Performance Code:</p>
                                <Select 
                                    defaultValue={{value:null,label:'All'}} 
                                    onChange={(e)=>HandleChange(e,'PerformanceCode')} 
                                    name='Performance Code' 
                                    options={performanceCodeOptions}
                                    styles={customSelectStylesSmall}
                                />
                            </div>
                        </div>
                        <div className='two-col-even'>
                            <div className='left-align'>
                                <p className='label-gap element-width'>Start Date:</p>
                                <Form.Control 
                                    onChange={(e)=>HandleChange(e,'StartDate')} 
                                    defaultValue={today} 
                                    name='Start Date' type='date'
                                    className='element-width'
                                />
                            </div>
                            <div className='left-align'>
                                <p className='label-gap'>End Date:</p>
                                <Form.Control 
                                    onChange={(e)=>HandleChange(e,'EndDate')} 
                                    defaultValue={today} 
                                    name='End Date' type='date'
                                    className='element-width label-gap'    
                                />                     
                                <Button type='submit' className="standard-button">Search</Button>
                            </div>
                        </div>          
                    </Col> 
                </Row>
            </Form>
        </>
    )
}

export default AdvancedSearch;