import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet'
import React, { useContext, useEffect, useState } from 'react'
import { Row, Alert, Col, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AgGridReact } from 'ag-grid-react';
import CheckboxRenderer from './CheckboxRenderer';
import Select from 'react-select';
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';

function CustomerRequiredForms() {
    const {cookies} = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const [customerOptions, setCustomerOptions] = useState();
    const [customerPostObj] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const { data: unformattedCustomers, loading: loadingCustomers } = useFetchPost(`${REACT_APP_API_URL}/Candidates/GetCustomers?Token=${cookies.get('customer').Token}`, cookies, customerPostObj)
    const { data: required, setData: setRequired } = useFetchGet((selectedCustomer&&`${REACT_APP_API_URL}/Utilities/GetCustomerRequiredDocuments?Token=${cookies.get('customer').Token}&CustomerID=${selectedCustomer.value.CustomerID}`), cookies)
    const [defaultColDef] = useState({
      resizable: true,
      sortable: true,
      filter: true,
    })
    const [columnDefs] = useState([
        {field: 'description'},
        {headerName: 'Is Required', cellRenderer: CheckboxRenderer },
    ])

    useEffect(()=>{
      if(unformattedCustomers){
          let formattedCustomers = unformattedCustomers.map(cust=>{
              return {value: cust, label: (cust.CustomerName+' | '+cust.DepartmentName+' | '+cust.CustomerID)}
          })
          setCustomerOptions(formattedCustomers)
      }
  },[unformattedCustomers])

    useEffect(()=>{
      if(selectedCustomer){
        setRequired(undefined)
      }
    },[selectedCustomer, setRequired])

  return (
    <Row>
      <Col>
        <Form.Label>Customer:</Form.Label>
        {loadingCustomers?
          <Spinner animation='border' size='sm'/>
        :
          <Select options={customerOptions} onChange={(e)=>setSelectedCustomer(e)} value={selectedCustomer} />
        }
      </Col>
      {selectedCustomer?
      <>
      <h6 className='margin-top'>Current Forms</h6>
      <div id="myGrid" className="ag-theme-alpine required-docs-grid">
          <AgGridReact
            rowData={required}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
          ></AgGridReact>
      </div>
      </>
      :
      <Alert className='margin-top'>Please select a customer</Alert>
      }
    </Row>
  )
}

export default CustomerRequiredForms;