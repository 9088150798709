import { useContext } from 'react';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import { Navigate, useSearchParams } from 'react-router-dom';
import LoginLoadingPage from './LoginLoadingPage';

export default function PrivateRoute({ children }) {
  const {cookies} = useContext(CustomerContext);
  const [searchParams] = useSearchParams();
  const authed = (cookies.get('customer')==null?(searchParams.get('token')?'token':'neither'):'cookies');

  switch(authed){
    case 'cookies':
      return children
    case 'token':
        return <LoginLoadingPage token={searchParams.get('token')}>{children}</LoginLoadingPage> 
    case 'neither':
      return <Navigate to='/login' replace />
    default:
  }
}