import React, { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext'
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet'
import JobOrderDetailsPopup from './JobOrderDetailsPopup'

function JobOrderDetailsPopupLogic({ 
    orderID,
    defaultActiveKey,
    show,
    setShow 
    }) {
    const [activeKey, setActiveKey] = useState(defaultActiveKey?defaultActiveKey:'candidates')
    const { cookies } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const [candidatesWithCompliance, setCandidatesWithCompliance] = useState();
    const { data: candidateData } = useFetchGet((orderID&&`${REACT_APP_API_URL}/Candidates/GetCandidatesByOrderID?token=${cookies.get('customer').Token}&orderid=${orderID}&isactive=${true}`),cookies)

    useEffect(()=>{
      if(candidateData){
        TransformCandidates(candidateData.candidates)
      }
    },[candidateData])

    const TransformCandidates = (candidates) => { 
      let transformmedCandidates = candidates.map(candidate=>{
          candidate.FullName = candidate.FirstName+' '+candidate.LastName;
          candidate.Compliance = {
              Application: candidate.Application,
              Onboarding: candidate.OnBoarding,
              EVerify: candidate.EVerify,
              BackgroundCheck: candidate.Background,
              DrugTest: candidate.DrugTest
          }
          return candidate
      })
      setCandidatesWithCompliance(transformmedCandidates)
   }

  return (
    <JobOrderDetailsPopup 
        candidateData={candidatesWithCompliance}
        jobOrderData={candidateData&&candidateData.order}
        show={show}
        setShow={setShow}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
    ></JobOrderDetailsPopup>
  )
}

export default JobOrderDetailsPopupLogic