import { Form } from "react-bootstrap"

export default function TotalsByColumn({
    totalsByColumnSet
}){

    return(
        <>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">Reg</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={totalsByColumnSet.regCol}
                    onChange={(e)=>{totalsByColumnSet.setRegCol(e.target.value)}}
                />
            </div>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">OT</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={totalsByColumnSet.otCol}
                    onChange={(e)=>{totalsByColumnSet.setOtCol(e.target.value)}}
                />
            </div>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">DT</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={totalsByColumnSet.dtCol}
                    onChange={(e)=>{totalsByColumnSet.setDtCol(e.target.value)}}
                />
            </div>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">MP</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={totalsByColumnSet.mpCol}
                    onChange={(e)=>{totalsByColumnSet.setMpCol(e.target.value)}}
                />
            </div>
        </>
    )
}