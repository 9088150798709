import { Button } from "react-bootstrap";
import { FormatDecimalHundredths } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function ErrorHelp({
    timecardHoursTotal,
    mappingHoursTotal,
    HandleRestart,
    HandleContinue
}){
    return(
        <>
            <div  className="card oap-card scroll-on-overflow-y disable-scroll-x">
                <h5>Error Help</h5>
                <div className="card instructions-card">
                    <div className="flex-card-row">
                        <p className="tc-error-calced">Calculated Total: {timecardHoursTotal}</p>
                        <p className="tc-error-tc">Timecard Total: {mappingHoursTotal}</p>
                        <p className="tc-error-diff">Difference: {FormatDecimalHundredths(mappingHoursTotal - timecardHoursTotal)}</p>
                    </div>
                </div>
                <div className="card instructions-card">
                    <p>
                        The totals above and the grid to the left are intended to help you to diagnose the error, but may or may not point out the source of the problem. 
                        Further information to assist you with this error may be found in the research tab. Please review the information carefully and make any needed corrections. 
                    </p>
                </div>
                <div className="card instructions-card">
                    <p>
                        If you have reviewed the discrepancies and would like to return to the beginning, please push the "Restart" button below.
                        You may also select a new customer from the dropdown to begin working on another timesheet.
                    </p>
                </div>
                <div className="card instructions-card">
                    <p>
                        If you have reviewed the discrepancies and would like to continue creating an upload without making
                        corrections to this timesheet, please push the "Continue" button below.
                    </p>
                </div>
                <div className="card instructions-card flex-card-row light-blue-background center-all">
                    <Button 
                        className="module-button white-background"
                        onClick={()=>HandleRestart()}
                    >Restart</Button>
                    <Button
                        className="module-button white-background"
                        onClick={()=>HandleContinue()}
                    >Continue</Button>
                </div>
            </div>
        </>
    )
}