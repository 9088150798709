import React, { useEffect, useContext, useState } from 'react'
import JobOrdersDashboard from './JobOrdersDashboard'
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';

function JobOrdersDashboardLogic() {
    const { cookies, setCurrentPage, setAddJobOrderIsOpen } = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;
    const [postObj, setPostObj] = useState();
    const [categories, setCategories] = useState();
    const [openFilter, setOpenFilter] = useState(true);
    const [groupBy, setGroupBy] = useState();
    const [rowData, setRowData] = useState();
    const { data: reports, loading: reportsLoading } = useFetchPost((postObj&&`${REACT_APP_API_URL}/Candidates/GetDashboardReports?token=${cookies.get('customer').Token}`),cookies,postObj)
    const [defaultColDef] = useState({ resizable: true, sortable: true, filter: true });
    const [columnDefs, setColumnDefs] = useState()

    useEffect(()=>{
        setCurrentPage('Orders Summary')
    // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(reports){
            setCategories(reports.Categories)
            // I default the category to the first in the list, this sets the data to the default category
            setRowData((groupBy?reports[groupBy].data:reports[reports.Categories[0]].data))
            setColumnDefs([
                {headerName: (groupBy?groupBy:reports.Categories[0]),field:'CategoryValue'},
                {headerName:'Orders',field:'OrderCount'},
                {headerName:'Required',field:'Opportunities'},
                {headerName:'Candidates',field:'CandidateCount'},
                {headerName:'Total Fills',field:'TotalFills'},
                {headerName:'Unfilled',field:'Unfilled'},
                {headerName:'Fill Ratio',field:'FillRatio', cellRenderer: cellRenderer},
                {headerName:'Time to fill',field:'TimeToFill'}
            ])
        }
    // eslint-disable-next-line
    },[reports])

    const cellRenderer = (params) => {
        return <span><p>{(params.value*100).toFixed(2)}%</p></span>
    }

    const onNewColumnsLoaded = (params) => { 
        params.api.sizeColumnsToFit()
     }

    const onGridLoaded = (params) => {
        params.api.sizeColumnsToFit()
    }

    const HandleCategoryChange = (e) => { 
        let newGroupBy = e.value;
        setGroupBy(newGroupBy)
        setRowData(reports[newGroupBy].data)
        setColumnDefs([
            {headerName: newGroupBy,field:'CategoryValue'},
            {headerName:'Orders',field:'OrderCount'},
            {headerName:'Required',field:'Opportunities'},
            {headerName:'Candidates',field:'CandidateCount'},
            {headerName:'Total Fills',field:'TotalFills'},
            {headerName:'Unfilled',field:'Unfilled'},
            {headerName:'Fill Ratio',field:'FillRatio', cellRenderer: cellRenderer},
            {headerName:'Time to fill',field:'TimeToFill'}
        ])
     }

  return (
    <JobOrdersDashboard
        postObj={postObj}
        setPostObj={setPostObj}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        categories={categories}
        rowData={rowData}
        onNewColumnsLoaded={onNewColumnsLoaded}
        HandleCategoryChange={HandleCategoryChange}        
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        setAddJobOrderIsOpen={setAddJobOrderIsOpen}
        reportsLoading={reportsLoading}
        onGridLoaded={onGridLoaded}
    ></JobOrdersDashboard>
  )
}

export default JobOrdersDashboardLogic