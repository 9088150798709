import EChartsReact from "echarts-for-react";
import { FormatUSD } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function PieChart({
    newOapList,
    leftoverOapList,
    setPieCustomer,
    setNewDisplayArray,
    setOpenAllOaps,
    setOpenReconciled,
    setOpenNotReconciled,
    setOpenCustomerGrid
}) {
    

    let newDisplayRows = [];

    for (var i = 0; i < newOapList.length; i++) {
        let dupCheckNumber = newOapList[i].OAPNumber;
        let foundDupOap = newDisplayRows.find(oapNum => oapNum.OAPNumber === dupCheckNumber);
        if (foundDupOap)
            continue;
            
        newDisplayRows.push(newOapList[i]);
    };

    var newByCustomer = []

    newDisplayRows.forEach(item => {
        let repeatedCustomer = newByCustomer.find(customer => customer.name === item.CustomerName);
        if (repeatedCustomer === undefined) {
            newByCustomer.push({ "name": item.CustomerName, "value": item.OAPAmount });
        }
        else {
            repeatedCustomer.value += item.OAPAmount
        }
    });

    let newTotal = 0;
    for (var k = 0; k < newDisplayRows.length; k++){
        let newAmount = Math.abs(newDisplayRows[k].OAPAmount);
        newTotal += newAmount;
    };

    newTotal = (Math.round(newTotal * 100)) / 100;
    const formattedNewTotal = FormatUSD(newTotal);

    var leftoverByCustomer = []

    leftoverOapList.forEach(item => {
        let repeatedCustomer = leftoverByCustomer.find(customer => customer.name === item.CustomerName);
        if (repeatedCustomer === undefined) {
            leftoverByCustomer.push({ "name": item.CustomerName, "value": item.OAPAmount });
        }
        else {
            repeatedCustomer.value += item.OAPAmount
        }
    });
    
    
    let leftoverTotal = 0;

    for (var j = 0; j < leftoverOapList.length; j++){
        let leftoverAmount = Math.abs(leftoverOapList[j].OAPAmount);
        leftoverTotal += leftoverAmount;
    };

    leftoverTotal = (Math.round(leftoverTotal * 100)) / 100;
    const formattedLeftoverTotal = FormatUSD(leftoverTotal);


    const newOapsOption = {
        title: {
            text: "New OAPs By Customer",
            subtext: `Total: ${formattedNewTotal}`,
            textStyle: {
                fontSize: 16,
                align: 'center'
            },
            left: 'left'
        },
        tooltip: {
            trigger: 'item',
            valueFormatter: (value) => FormatUSD(value)
        },
        series: [
            {
                name: 'New OAP Amount',
                type: 'pie',
                selectedMode: 'single',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: .5
                },
                label: {
                    show: false,
                    position: 'center'
                  },
                data: newByCustomer,
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 26,
                        fontWeight: 'bold'
                      }
                },
                labelLine: {
                    show: false
                  }
            }
        ]
    };

    const onPieClick = (e) => {
        let customerName = (e.data.name).toString()
        setPieCustomer(customerName);
        setOpenAllOaps(false);
        setOpenReconciled(false);
        setOpenNotReconciled(false);
        setOpenCustomerGrid(true);
    };

    const onEvents = {
        click: onPieClick,
    };

    // /////////////////////////////////////////////////
    const leftoverOapsOption = {
        title: {
            text: "Leftover OAPs By Customer",
            subtext: `Total: ${formattedLeftoverTotal}`,
            textStyle: {
                fontSize: 16,
                align: 'center'
            },
            left: 'left'
        },
        tooltip: {
            trigger: 'item',
            valueFormatter: (value) => FormatUSD(value)
        },
        series: [
            {
                name: 'Leftover OAP Amount',
                type: 'pie',
                selectedMode: 'single',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: .5
                },
                label: {
                    show: false,
                    position: 'center'
                  },
                data: leftoverByCustomer,
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 26,
                        fontWeight: 'bold'
                      }
                },
                labelLine: {
                    show: false
                  }
            }
        ]
    };

    const onLeftoverPieClick = (e) => {
        let customerName = (e.data.name).toString()
        setPieCustomer(customerName);
        setPieCustomer(customerName);
        setOpenAllOaps(false);
        setOpenReconciled(false);
        setOpenNotReconciled(false);
        setOpenCustomerGrid(true);
    };

    const onLeftoverEvents = {
        click: onLeftoverPieClick,
    };


    return (
        <>
            <div className="card oap-card">
                <EChartsReact
                    style={{
                        width: "100%",
                        height: "250%"
                    }}
                    option={newOapsOption}
                    onEvents={onEvents}
                />
                <EChartsReact
                    style={{
                        width: "100%",
                        height: "250%"
                    }}
                    option={leftoverOapsOption}
                    onEvents={onLeftoverEvents}
                />
            </div>            
        </>
    );
}