import { FormatDecimalHundredths } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";
import GetRepAssignment from "./GetRepAssignment";

export function GetAgingCustomer(e, dataHold) {
    var invoice = e.InvoiceNumber ? (e.InvoiceNumber).toString().trim() : "0";
    
    if (dataHold && dataHold.length) {
        let value = dataHold.find(obj =>obj.invoiceNumber.trim()===invoice);
        
        if (value) {
            return (value.customerName);
        }else{
            return 'Invoice Not Found';
        }
    }
};

export function GetInvoiceAmount(e, dataHold) {
    var invoice = e.InvoiceNumber ? e.InvoiceNumber.toString() : "0";

    if (invoice !== "0") {
        let foundInvoice = dataHold.find(inv => inv.invoiceNumber === invoice);

        let amountBalance = foundInvoice !== undefined && foundInvoice.BalanceAmount ? foundInvoice.BalanceAmount : 0;
        return amountBalance;
    }
    else
        return 0;
}

export function GetInvoiceBalance(oap, dataHold) {
    var invoice = oap.InvoiceNumber ? oap.InvoiceNumber.toString() : 0;
     
    if (invoice !== "0") {
        let value = dataHold.find(obj => (obj.invoiceNumber === invoice))
        if (value) {
            let balance = (Math.round((value.BalanceAmount - oap.PayAmount) * 100)) / 100;
            return (balance);
        }
        else {
            oap.AgingCustomer = "Invoice Not Found";
            return 0;
        }
    }
    else
        return 0;
};

export function GetOapBalance(oap, oapBalanceTracker) {

    let foundOap = oapBalanceTracker.find(item => item.OAPNumber === oap.OAPNumber);
    let payAmount = oap.PayAmount ? oap.PayAmount : 0;
    
    if (foundOap) {
        foundOap.OAPBalance = FormatDecimalHundredths(Number(foundOap.OAPBalance) - Number(payAmount));
    }
    else {
        oapBalanceTracker.push(
            {
                "OAPNumber": oap.OAPNumber,
                "OAPBalance": FormatDecimalHundredths(Number(oap.OAPAmount) - Number(payAmount)),
            }
        );
    }

    let currentOap = oapBalanceTracker.find(item => item.OAPNumber === oap.OAPNumber);
    let returnBalance = Number(currentOap.OAPBalance);

    return (returnBalance)
}

export function GetRowID(e) {
    let rowID =  e.data ? e.data.OAPNumber.slice(3) + e.data.InvoiceNumber : e.OAPNumber.slice(3) + e.InvoiceNumber;
    return rowID;
};

export function GetAssignedRep(row) {

    let repAssignments = GetRepAssignment();
    let rowCustomer = row.CustomerName.toLowerCase();

    let foundRow = repAssignments.find(assignment => rowCustomer.startsWith(assignment.Customer.toLocaleLowerCase()));
    let assignedRep = foundRow !== undefined ? foundRow.Rep : "Unassigned";

    return assignedRep;
};