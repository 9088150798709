import React from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import { useContext, useState } from 'react'
import { Badge, Card, Row, Accordion, Button, Spinner } from 'react-bootstrap'
import UpdateBackgroundPopupLogic from '../../UpdateBackgroundPopup copy/UpdateBackgroundPopupLogic'
import UpdateDrugTestPopupLogic from '../../UpdateDrugTestPopup/UpdateDrugTestPopupLogic'
import UpdateEverifyPopupLogic from '../../UpdateEverifyPopup/UpdateEverifyPopupLogic'
import Documents from './Documents';

function Onboarding({ 
    candidateInfo, 
    onboarding, 
    defaultActiveKey
}) {
    const [openBackgroundPopup, setOpenBackgroundPopup] = useState(false);
    const [openDrugTestPopup, setOpenDrugTestPopup] = useState(false);
    const [openEverifyPopup, setOpenEverifyPopup] = useState(false);
    const [activeKey, setActiveKey] = useState(defaultActiveKey?defaultActiveKey:'');
    const { cookies } = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;
    const { data: employeeDetails } = useFetchGet(`${REACT_APP_API_URL}/Compliance/GetEmployeeDetail?Token=${cookies.get('customer').Token}&EmployeeID=${candidateInfo.EmployeeID}`,cookies)

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

  return (
    <div  className='status-wrapper'>
        {openDrugTestPopup&&<UpdateDrugTestPopupLogic candidateInfo={candidateInfo} show={openDrugTestPopup} setShow={setOpenDrugTestPopup} />}
        {openBackgroundPopup&&<UpdateBackgroundPopupLogic formInfo={employeeDetails.backgroundStatus} employeeID={candidateInfo.EmployeeID} show={openBackgroundPopup} setShow={setOpenBackgroundPopup} />}
        {openEverifyPopup&&<UpdateEverifyPopupLogic candidateInfo={candidateInfo} show={openEverifyPopup} setShow={setOpenEverifyPopup} />}
        {(employeeDetails&&candidateInfo)?
            <Accordion
            activeKey={activeKey}
            onSelect={(k)=>setActiveKey(k)} 
             >
                <Accordion.Item eventKey="10">
                    <Accordion.Header>Application/Onboarding Workflows <Badge bg="secondary">{onboarding.length}</Badge></Accordion.Header>
                    <Accordion.Body>
                        {(onboarding&&onboarding.length>0)?
                            <Card>
                                {onboarding.map((onboard,index)=>{
                                        return <Card key={index} className='padding margin-bottom'>
                                                    <Row>
                                                            <h6>{onboard.WorkFlowName} | {onboard.StepStatus}</h6>
                                                            <p>Date Assigned: {onboard.DateCreated.split('T')[0]} - Date Completed: {onboard.DateCompleted?onboard.DateCompleted.split('T')[0]:'Not Completed'}</p>
                                                    </Row>
                                                </Card>
                                })}
                            </Card>
                            :
                            <h6 align='center'>No onboarding</h6>
                            }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                    <Accordion.Header>E-Verified</Accordion.Header>
                    <Accordion.Body>
                        <Row><p>Everified Status: <b>{employeeDetails.everify?employeeDetails.everify.IntepretedStatus:'None'}</b></p></Row>
                        <Button onClick={(e)=>setOpenEverifyPopup(true)}>Enter E-Verify</Button>
                        {(candidateInfo.Everified==='Yes')&&
                        <>
                            <Row><p>Case Status: <b>{employeeDetails.CaseStatus}</b></p></Row>
                            <Row><p>Case Eligibility Statement: <b>{employeeDetails.CaseEligibilityStatement}</b></p></Row>
                        </>
                        }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                    <Accordion.Header>Background</Accordion.Header>
                    <Accordion.Body>
                        {/* 
                        1. NA -> show nothing
                        2. Not Run -> Beyond link & approve/reject
                        3. Review < 30days -> link to bg & Approve/reject
                        4. Review > 30days -> link to g2 login & Approve/Reject
                        5. Completed -> link to people g2 & Approve/Reject
                        */}
                        <Row><p>Background Status: <b>{employeeDetails.backgroundStatus?employeeDetails.backgroundStatus.StatusMessage:'None'}</b></p></Row>
                        {employeeDetails.backgroundStatus&&
                        <>
                            {employeeDetails.backgroundStatus.ReportLocation?
                                <Row className='margin-bottom'><Button onClick={(e)=>openInNewTab(employeeDetails.backgroundStatus.ReportLocation)}>Open Background Report</Button></Row>
                                :
                                <>
                                {(employeeDetails.backgroundStatus.DateCreated)&&
                                    <Row><p>This background check is more than 30 days old, so you will need to visit the Accusource website to view it.</p><Button className='margin-bottom' onClick={(e)=>openInNewTab('https://access.peopleg2.com/')}>Log in to Accusource</Button></Row>
                                }
                                {(employeeDetails.backgroundStatus.StatusMessage==='Not Run')&&
                                    <Row><Button className='margin-bottom' onClick={()=>openInNewTab(`https://beyond.ontempworks.com/employee/${candidateInfo.EmployeeID}/backgroundchecks`)} >Run BG in Beyond</Button></Row>
                                }
                                </>
                            }
                            <Row><Button className='margin-bottom' onClick={(e)=>setOpenBackgroundPopup(true)}>Approve/Reject Background</Button></Row>
                        </>
                        }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                    <Accordion.Header>Drug Test</Accordion.Header>
                    <Accordion.Body>
                        <Row><p>Drug Test Status: <b>{employeeDetails.drugTest?employeeDetails.drugTest.DrugCompliance:'None'}</b></p></Row>
                        <Button onClick={(e)=>setOpenDrugTestPopup(true)}>Enter Drug Test</Button>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='14'>
                    <Accordion.Header>Documents</Accordion.Header>
                    <Accordion.Body>
                        <Documents employeeID={candidateInfo.EmployeeID}/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        :
        <Spinner animation='border' />
        }
    </div>
  )
}

export default Onboarding