import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import Pay from './Pay'

function PayLogic({ defaultFormData }) {
  const [shifts, setShifts] = useState();
  const [selectedShift, setSelectedShift] = useState();
  const {
    cookies,
    formData,
    setFormData
  } = useContext(CustomerContext)
  const {REACT_APP_API_URL} = process.env;
  const [multiplierCodes, setMultiplierCodes] = useState([]);
  const { data: compCodes, setData: setCompCodes } = useFetchGet(`${REACT_APP_API_URL}/OrderRequest/GetCustomerWorkerCompCodes?token=${cookies.get('customer').Token}&customerid=${formData.Department.CustomerID}`,cookies)
  const { data: unformattedMultiplierCodes } = useFetchGet((formData.Department&&`${REACT_APP_API_URL}/OrderRequest/getcustomermultipliers?token=${cookies.get('customer').Token}&customerid=${formData.Department.CustomerID}`),cookies)


  useEffect(()=>{
    if(unformattedMultiplierCodes){
      if(unformattedMultiplierCodes.data.length===0){
        setMultiplierCodes([{multiplierCodeId:0,multiplierCode:'No defaults. Set up manually in TempWorks.'}])
      }else{
        setMultiplierCodes(unformattedMultiplierCodes.data)
      }
    }
  // eslint-disable-next-line
  },[unformattedMultiplierCodes])

  useEffect(()=>{
    if(compCodes){
      if(compCodes.length===0){
        setCompCodes([{WorkerCompCodeID:'NoDefaults',WorkerCompCode:'No defaults',Description:'Set up manually in TempWorks.'}])
      }else{
        setCompCodes(compCodes)
      }
    }
  // eslint-disable-next-line
  },[compCodes])

  useEffect(()=>{
    GetCustomerShifts(formData.Department.CustomerID)
  // eslint-disable-next-line
  },[formData])

  const GetCustomerShifts = (DepartmentID) => { 
    fetch(`${REACT_APP_API_URL}/OrderRequest/GetCustomerShifts?token=${cookies.get('customer').Token}&customerid=${DepartmentID}`)
    .then(res=>{
        if(res.status===200){
            return res.json()
        }else if(res.status===401){
          cookies.remove('customer', {path:'/'});
          window.location.reload();
        }else if(res.status===503||res.status===500){
          setTimeout(() => {
            GetCustomerShifts(DepartmentID)
          }, 1000);
          return [];
        }else if(!res.ok){
          res.text().then(text=>{
            if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
              setTimeout(() => {
                GetCustomerShifts(DepartmentID)
              }, 1000);
            }else{
              throw Error(res.statusText)
            }
          })
        }
    })
    .then(data=>{
      if(data.data.length>0){
        TransformShiftsIntoOptions(data.data)
      }else{
        setShifts([{value:'',label:'No Shifts'}])
      }
    })
    .catch(error=>{
        console.error(error)
        window.alert(error)
    })
   }

  const TransformShiftsIntoOptions = (shifts) => { 
      let transformmedShifts = shifts.map(shift=>{
        return {value:shift,label:shift.shift}
      })
      setShifts(transformmedShifts)
   }

  const HandleShiftChange = (event) => { 
    setSelectedShift(event.value)
    formData.Shift = event.value;
    setFormData(formData);
   }

  const onMenuOpen = (event) => { 
    setSelectedShift(null)
   }

   const HandlePayRateChange = (event) => { 
      formData.PayRate = event.target.value;
      setFormData(formData)
    }
  
  const HandleSelectChange = (event, fieldName) => { 
    if(fieldName==='WorkerCompCodeID'){
      formData[fieldName] = event.value.WorkerCompCodeid;
      setFormData(formData)
    }else if(fieldName==='MultiplierCodeID'){
      formData[fieldName] = event.value.MultiplierCodeID;
      setFormData(formData)
    }else if(fieldName==='OrderType'){
      formData[fieldName] = event.value.OrderTypeId;
      setFormData(formData)
    }else{  
      formData[fieldName] = event.value;
      setFormData(formData)
    }
   }

  return (
    <>
    {shifts&&
    <Pay
      shifts={shifts}
      formData={formData}
      HandleShiftChange={HandleShiftChange}
      selectedShift={selectedShift}
      onMenuOpen={onMenuOpen}
      HandleSelectChange={HandleSelectChange}
      HandlePayRateChange={HandlePayRateChange}
      defaultFormData={defaultFormData}
      multiplierCodes={multiplierCodes}
      compCodes={compCodes}
    ></Pay>
    }
    </>
  )
}

export default PayLogic