import React from 'react'
import { Form, Modal, Button, Spinner } from 'react-bootstrap'
import Select from 'react-select'

function AddSRToOrder({ 
  show,
  setShow,
  jobOrderID,
  serviceReps,
  status,
  HandleSRChange,
  loading,
  HandleSubmit,
}) {
  return (
    <>
    <Modal show={show} onHide={setShow}>
      <Modal.Header closeButton>
        <Modal.Title>Add SR to Job Order</Modal.Title>
      </Modal.Header>
      {!status?    
      <Modal.Body>
        <Form.Label>Service Reps:</Form.Label>
        {serviceReps&&
          <Select isMulti options={serviceReps.map(SR=>{return{value:SR,label:SR.RepFullName}})} onChange={(e)=>HandleSRChange(e)} />
        }
        <Form.Label>Job Order ID:</Form.Label>
        <Form.Control disabled value={jobOrderID}></Form.Control>
      </Modal.Body>
      :
      <Modal.Body>
        {(status===200)?
        <Form.Label>Success!</Form.Label>
        :
        <Form.Label>Update failed, please try again.</Form.Label>
        }
      </Modal.Body>
      }
        <Modal.Footer>
        <Button variant="secondary" onClick={()=>setShow(false)}>
          Close
        </Button>
        <Button variant="primary" disabled={status} onClick={()=>HandleSubmit(true)}>
          {loading?<Spinner animation='border'/>:'Save Changes'}
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}

export default AddSRToOrder