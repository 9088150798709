import { useState, useEffect, useContext } from "react";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import useFetchGet from "Components/CustomComponents/Fetch/useFetchGet";

export default function GetCustomerList() {
    const { cookies } = useContext(CustomerContext);
    const { REACT_APP_API_URL } = process.env;
    const { data: customerList } = useFetchGet(`${REACT_APP_API_URL}/payroll/getcustomersfromaging?token=${cookies.get('customer').Token}`, cookies);

    const [customers, setCustomers] = useState();

    useEffect(() => {
        if (customerList) {
          let filteredData = Object.values(
            customerList.reduce((c, e) => {
              if (!c[e.customerName]) c[e.customerName] = e;
              return c;
            }, {})
          );
          let formattedCustomers = filteredData.sort().map((customer) => {
            return { value: customer, label: customer.customerName }
          });
          setCustomers(formattedCustomers);
        }
        // eslint-disable-next-line
    }, [customerList]);
    
    return customers;
}