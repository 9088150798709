import {TiArrowRightThick} from "react-icons/ti";

export default function ColumnMap({columnMap}){
    let pairLines = [];

    pairLines.push(
        <div className="three-col-bsb">
                <h5 className="button-div blue-background tc-compare-header">Active Column</h5>
                <div></div>
                <h5 className="button-div tab-color tc-compare">Timecard Column</h5>
            </div>
    )

    for(const columnPair in columnMap) {
        pairLines.push(
            <div className="three-col-bsb">

                <h6 className="blue-background tc-compare">{columnPair}</h6>
                <div className="center-all"><TiArrowRightThick /></div>
                <h6 className="tab-color tc-compare">{columnMap[columnPair]}</h6>
            </div>
        )
    };
    return (
        <>
            <div className="card vertical-card-fit">
                <div className="highlighted-box">
                    <h5 className="white-header-label">Column Mapping</h5>
                    {pairLines}    
                </div>
            </div> 
        </>
    )
}