export function GetOapTypes(oapData, payData) {
    let newOapPayments = [];
    let leftoverPayments = [];

    for (var oap = 0; oap < oapData.length; oap++){

        if ((oapData[oap].OAPNumber).startsWith("Total"))
            break;
        
        if (!(oapData[oap].OAPNumber).startsWith("OAP"))
            continue;
        
        let oapNumber = oapData[oap].OAPNumber;
        let payNumber = oapNumber.slice(3);

        let foundOap = payData.find(payment => payment.PayNumber === payNumber);

        let oapAmount = parseFloat(oapData[oap].OAPAmount);
        let payAmount = foundOap !== undefined ? parseFloat(foundOap.CheckAmount) : 0;

        if (payAmount === oapAmount) {
            newOapPayments.push(oapData[oap]);
        } else {
            leftoverPayments.push(oapData[oap]);
        }
    }
    
    return { newOapPayments, leftoverPayments };
};