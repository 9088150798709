import React, { useState, useContext, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import useFetchGet from "Components/CustomComponents/Fetch/useFetchGet";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import DocumentForm from "./DocumentForm";
import Button from "react-bootstrap/Button";
import { BsDownload } from "react-icons/bs";

function AllRequiredForms() {
  const { cookies } = useContext(CustomerContext);
  const { REACT_APP_API_URL } = process.env;
  const { data } = useFetchGet(
    `${REACT_APP_API_URL}/utilities/GetFormList?Token=${
      cookies.get("customer").Token
    }`,
    cookies
  );
  const [formListData, setFormListData] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [formData, setFormData] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState();
  const [groupOptions, setGroupOptions] = useState();
  const [gridApi, setGridApi] = useState();
  const [defaultColDef] = useState({
    resizable: true,
    sortable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    width: 125,
    filter: true,
  });

  useEffect(() => {
    if (data) {
      let formattedData = data.map((form) => {
        if (form.BeginUseDate) {
          form.BeginUseDate = formatDate(form.BeginUseDate);
        }
        if (form.EndUseDate) {
          form.EndUseDate = formatDate(form.EndUseDate);
        }
        if (form.CustomerNames && form.CustomerNames.length > 0) {
          form.CustomerNames = formatCustomerList(form.CustomerNames);
        }
        return form;
      });
      setFormListData(formattedData);
    }
  }, [data]);

  function formatDate(data) {
    let fixedDate = data ? data.split("T")[0] : "";
    return fixedDate;
  }

  function formatCustomerList(data) {
    let stringifiedCustomerList = "";
    if (data.length) {
      data.forEach((obj, index) => {
        if (index === 0) {
          stringifiedCustomerList += obj.CustomerName + " | " + obj.CustomerID;
        } else {
          stringifiedCustomerList +=
            ",  " + obj.CustomerName + " | " + obj.CustomerID;
        }
      });
    }
    return stringifiedCustomerList;
  }
  const [columnDefs] = useState([
    { field: "FormName", headerName: "Form" },
    { field: "StateRestriction", headerName: "State Requirement" },
    { field: "BeginUseDate" },
    { field: "EndUseDate" },
    { field: "Version" },
    { field: "FormCat", headerName: "Form Category" },
    { field: "FormGroup", headerName: "Form Group" },
    { field: "isCustomerForm", headerName: "Is Customer Form" },
    { field: "isHRCenterSurvey", headerName: "Is HR Center Survey" },
    { field: "isActive", headerName: "Active" },
    { field: "Entity" },
    { field: "Language" },
    { field: "CustomerNames" },
    { field: "DocType" },
  ]);

  const onCellClicked = (params) => {
    GetGroupAndCategoryOptions();
    setFormData(params.data);
    setOpenPopup(true);
  };

  const handleAddNewDocument = () => {
    GetGroupAndCategoryOptions();
    setFormData(null);
    setOpenPopup(true);
  };

  const GetGroupAndCategoryOptions = () => {
    let categories = [];
    let groups = [];
    data.forEach((row) => {
      if (row.FormCat !== "") {
        let newCategoryOption = { value: row.FormCat, label: row.FormCat };
        if (!categories.find((obj) => obj.value === newCategoryOption.value)) {
          categories.push(newCategoryOption);
        }
      }
      if (row.FormGroup !== "") {
        let newGroupOption = { value: row.FormGroup, label: row.FormGroup };
        if (!groups.find((obj) => obj.value === newGroupOption.value)) {
          groups.push(newGroupOption);
        }
      }
    });
    setCategoryOptions(categories);
    setGroupOptions(groups);
  };

  const onFirstDataRendered = (params) => {
    setGridApi(params.api);
    params.columnApi.autoSizeAllColumns();
  };

  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };

  return (
    <>
      {openPopup && (
        <DocumentForm
          show={openPopup}
          setShow={setOpenPopup}
          formData={formData}
          categoryOptions={categoryOptions}
          groupOptions={groupOptions}
        />
      )}
      <Button onClick={() => handleAddNewDocument()}>Add New Form</Button>
      <Button variant="secondary" onClick={() => onBtnExport()}>
        <BsDownload />
      </Button>
      <div id="myGrid" className="ag-theme-alpine required-docs-grid">
        <AgGridReact
          rowData={formListData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellClicked={onCellClicked}
          onFirstDataRendered={onFirstDataRendered}
        ></AgGridReact>
      </div>
    </>
  );
}

export default AllRequiredForms;
