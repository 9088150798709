import * as XLSX from "xlsx";
import { FormatDecimalHundredths } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";
import { PushToOneDrive } from "../CallTimecardAPI";

export default function ExportUploadXL(exportParams){
    let uploadArray = [];
    let mismatchArray = [];
    let weDate;
    let formattedDate;

    exportParams.uploadData.forEach(row=>{
        let employeeHours;
        let regPayCode = 'Reg';
        let mpPayCode = 'Meal Penalty';
        weDate = row.WeekEndDay;
        let rawDate = new Date(weDate);
        let isoDate = rawDate.toISOString();
        let splitDate = isoDate.split('T');
        formattedDate = splitDate[0];

        let tcReg = FormatDecimalHundredths(row.Reg);
        let tcOT = FormatDecimalHundredths(row.OT);
        let tcDT = FormatDecimalHundredths(row.DT);
        let tcMP = row.MP;

        if(row.AssignmentID!=="Mismatch"){
            employeeHours = {
                'Week Ending Date': weDate,
                'Pay Code': regPayCode,
                'Assignment ID': row.AssignmentID,
                'Employee ID': row.EmployeeID,
                'Reg Hours': tcReg,
                'OT Hours': tcOT,
                'DT Hours': tcDT,
                'Units': '',
                'Unit Pay Rate': '',
                'Unit Bill Rate': '',
                'Exp Reim Adj Pay Amount': '',
                'Exp Reim Adj Bill Amount': '',
                'Exp Reim Adj Invoice Text': '',
                'Per Diem Adj Pay Amount': '',
                'Per Diem Adj Bill Amount': '',
                'Car Allowance Pay': '',
                'Car Allowance Bill': '',
                'Per Diem Adj Invoice Text': '',
                'Invoice Text': '',
                'Cost Center': '',
                'Vend Inv Number': ''
            }
            uploadArray.push(employeeHours);
        }
        //creating a second row for meal penalties in the upload file
        if(row.AssignmentID!=="Mismatch"&&row.MP!==0){
            employeeHours = {
                'Week Ending Date': weDate,
                'Pay Code': mpPayCode,
                'Assignment ID': row.AssignmentID,
                'Employee ID': row.EmployeeID,
                'Reg Hours': tcMP,
                'OT Hours': 0,
                'DT Hours': 0,
                'Units': '',
                'Unit Pay Rate': '',
                'Unit Bill Rate': '',
                'Exp Reim Adj Pay Amount': '',
                'Exp Reim Adj Bill Amount': '',
                'Exp Reim Adj Invoice Text': '',
                'Per Diem Adj Pay Amount': '',
                'Per Diem Adj Bill Amount': '',
                'Car Allowance Pay': '',
                'Car Allowance Bill': '',
                'Per Diem Adj Invoice Text': '',
                'Invoice Text': '',
                'Cost Center': '',
                'Vend Inv Number': ''
            }
            uploadArray.push(employeeHours);
        }
        if(row.AssignmentID==="Mismatch"){
            let mismatchRow = {
                'Employee': row.EmployeeName,
                'Identifier': row.LookupID,
                'Id Type': row.IdType,
                'Customer ID': row.CustomerID,
                'Reg Hours': tcReg,
                'OT Hours': tcOT,
                'DT Hours': tcDT,
                'MP Hours': tcMP,
            };
            mismatchArray.push(mismatchRow);
        }
    });

    let uploadName = `${exportParams.selectedCustomer.label} ${formattedDate} Uploads.xlsx`;
    let uploadNameCSV = `${exportParams.selectedCustomer.label} ${formattedDate} Uploads.csv`;
    let workBook = XLSX.utils.book_new();
    let workSheet = XLSX.utils.json_to_sheet(uploadArray);
    //let newLineDelimeter = String.fromCharCode(10,13);
    let csvSheet = XLSX.utils.sheet_to_csv(workSheet);

    for(let col of [4,5,6]){
        FormatColumn(workSheet, col)
    };

    XLSX.utils.book_append_sheet(workBook, workSheet, "uploads");
    XLSX.writeFile(workBook, uploadName);

    const baseFileString = window.btoa(unescape(encodeURIComponent(csvSheet)));

    console.log(baseFileString);

    let workPapersName = `${exportParams.selectedCustomer.label} ${formattedDate} Work Papers.csv`;
    let auditSheet = XLSX.utils.json_to_sheet(exportParams.mergeData);
    let auditCSV = XLSX.utils.sheet_to_csv(auditSheet);
    const auditSheetFileString = window.btoa(unescape(encodeURIComponent(auditCSV)));

    let originalExportName = `${exportParams.selectedCustomer.label} ${formattedDate} Original.csv`;
    let originalSheet = XLSX.utils.json_to_sheet(exportParams.delayedRows);
    let originalCSV = XLSX.utils.sheet_to_csv(originalSheet);
    const originalSheetFileString = window.btoa(unescape(encodeURIComponent(originalCSV)));

    PushToOneDrive(uploadNameCSV, exportParams.selectedCustomer.label, baseFileString, exportParams.cookies);
    PushToOneDrive(workPapersName, exportParams.selectedCustomer.label, auditSheetFileString, exportParams.cookies);
    PushToOneDrive(originalExportName, exportParams.selectedCustomer.label, originalSheetFileString, exportParams.cookies);

    if(mismatchArray&&mismatchArray.length){
        let mmName = `${exportParams.selectedCustomer.label} ${weDate} Mismatches.xlsx`;
        let mmBook = XLSX.utils.book_new();
        let mmSheet = XLSX.utils.json_to_sheet(mismatchArray);

        for(let col of [4,5,6]){
            FormatColumn(mmSheet, col)
        };

        XLSX.utils.book_append_sheet(mmBook, mmSheet, "Mismatches");
        XLSX.writeFile(mmBook, mmName);
    }

    exportParams.setSheetsExported(true);
}

function FormatColumn(worksheet, col) {
    const range = XLSX.utils.decode_range(worksheet['!ref'])
    // note: range.s.r + 1 skips the header row
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      const ref = XLSX.utils.encode_cell({ r: row, c: col })
      if (worksheet[ref]) {
        worksheet[ref].z = "0.00";
        worksheet[ref].t = 'n';
      }
    }
}