import * as XLSX from "xlsx";

export function LoadOAPFiles(e, worksheetColumns, setRowData, rowIndex, oapLoadData) {
    const allFiles = e.target.files;
    const file = allFiles[0];
    const reader = new FileReader();
    let pushFileRows = [];

    reader.onload = (evt) => {
        evt.preventDefault();

        const fileRead = evt.target.result;
        const workbook = XLSX.read(fileRead, { type: "binary" });
        const wsName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[wsName];

        while (worksheet['A' + rowIndex]) {
            let row = {};
            // eslint-disable-next-line
            Object.keys(worksheetColumns).forEach((column) => {
                worksheet[column + rowIndex] ?
                    row[worksheetColumns[column]] = worksheet[column + rowIndex].w
                    :
                    row[worksheetColumns[column]] = 0;
            });
            pushFileRows.push(row);
            rowIndex++;
        }
        
        let allRows = [];
        pushFileRows.forEach(row => {
            if (row.OAPAmount) {
                row.OAPAmount = (row.OAPAmount).replace(/[(,)]/g, "");
                
                oapLoadData.forEach(dataOap => {
                    if (dataOap.OAPNumber === row.OAPNumber && Number(dataOap.PayAmount) !== 0 && Number(dataOap.OAPAmount) === Number(row.OAPAmount)) {
                        allRows.push(dataOap);
                    }
                });

                let inAllRows = allRows.find(allRow => allRow.OAPNumber === row.OAPNumber);
                if (inAllRows === undefined) {
                    allRows.push(row);
                };
            }
            else if (row.CheckAmount) {
                row.CheckAmount = (row.CheckAmount).replace(/[(,)]/g, "");
                allRows.push(row);
            };
        });
        setRowData(allRows);
    };
    reader.readAsBinaryString(file);
};