import React, { useState } from 'react';
import Cookies from 'universal-cookie';

export const CustomerContext = React.createContext();

export function CustomerProvider({ children }){

    const cookies = new Cookies();
    const [user, setUser] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [currentPage, setCurrentPage] = useState();
    const [addCandidateOpen, setAddCandidateOpen] = useState();
    const [addJobOrderIsOpen, setAddJobOrderIsOpen] = useState(false);
    const [defaultJobOrderID, setDefaultJobOrderID] = useState();
    const [formData, setFormData] = useState({
        "OrderRequestID": 0,
        "CustomerName": null,
        "CustomerID": 0,
        "Requestor": "",
        "Department": null,
        "WorkSite": null,
        "WorkSiteID": 0,
        "ReportTo": null,
        "SupervisorNumber": null,
        "SupervisorEmail": null,
        "AlternateApprover1": null,
        "AlternateApprover2": null,
        "HRCoordinator": null,
        "JobTitle": null,
        "JobDescription": null,
        "LiftingRequirement": false,
        "CostCenter": null,
        "ProjectCode": null,
        "PO": null,
        "PayRate": null,
        "MultiplierCode": null,
        "BillRate": null,
        "PayType": "Hourly",
        "CPE": 'false',
        "Schedule": null,
        "OTCalculation": null,
        "Shift": null,
        "ShiftNotes": null,
        "Monday": false,
        "Tuesday": false,
        "Wednesday": false,
        "Thursday": false,
        "Friday": false,
        "Saturday": false,
        "Sunday": false,
        "StartDate": null,
        "AssignmentEnd": null,
        "Notes": null,
        "JobOrderID": 0,
        "Supervisor": null,
        "RequestApprover": null,
        "JobTitleID": 0,
        "ShiftID": null,
        "POID": 0,
        "RequestorEmail": null,
        "SubmitterEmail": null,
        "ApproverEmail": null,
        "MultiplierCodeId": 0,
        "SupervisorID": 0,
        "RequestApprover1ID": 0,
        "RequestApprover2ID": 0,
        "ApproverID": 0,
        "HRCoordinatorID": 0,
        "AlternateApprover1ID": 0,
        "AlternateApprover2ID": 0,
        "RequestorID": 0,
        "ReportToID": 0,
        "SubmitterID": 0,
        "DepartmentID": 0,
        "SRIdent": 0,
        "WorkerCompCodeID": 0,
        "OrderTitle":''
      });

    return(
        <CustomerContext.Provider value={
            {   
                user,
                setUser,
                cookies,
                selectedBranch,
                setSelectedBranch,
                currentPage,
                setCurrentPage,
                addCandidateOpen,
                setAddCandidateOpen,
                formData,
                setFormData,
                addJobOrderIsOpen,
                setAddJobOrderIsOpen,
                defaultJobOrderID,
                setDefaultJobOrderID
            }
        }>
        {children}
    </CustomerContext.Provider>
)
}

export default CustomerContext;