import { AgGridReact } from "ag-grid-react";
import { BsDownload } from "react-icons/bs";
import { Tooltip, OverlayTrigger, Button, Spinner } from 'react-bootstrap';
import HandleReportSearch from "./SearchFunctions/HandleReportSearch";
import HandleCountSearch from "./SearchFunctions/HandleCountSearch";
import '../../Compliance/Payroll/Payroll.css';
import DocNameChage from "./DocNameChange";
import DocTypesDropdown from "./componets/DocTypesDropdown";
import FormNamesDropdown from "./componets/FormNamesDropdown";

export default function ReportSearchView({
  formListData,
  setFormListData,
  formListCols,
  defaultColDefs,
  cookies,
  onFirstDataRendered,
  HandleExportFormData,
  gridApi,
  gridName,
  HandleSwitchTabs,
  loading,
  setLoading,
  selectedDocType,
  setSelectedDocType,
  selectedFormName,
  setSelectedFormName,
  HandleClickEmployeeId,
  ClearSearch,
  selectedDocChange,
  setSelectedDocChange,
  todayDate,
  prevDate
}){

  return (
    <>
    <div className="top-card">
      <div className="margin-top">
        <h3>{gridName === "Document Search" ? "Document Search" : "Name Count"}</h3>
        <form className="search-row" id="searchForm"
          onSubmit={gridName === "Document Search" ? 
            (e)=>HandleReportSearch(e, setFormListData, selectedDocType, cookies, setLoading) :
            (e)=>HandleCountSearch(e, setFormListData, cookies, setLoading, selectedDocType, selectedFormName)
            }>
          <div className="instruction-row">
            <input className="date-input silver-border" type="text" placeholder="File Name" name="searchString" id="searchString"/>
            {gridName !== "Document Search" && 
              <FormNamesDropdown
                cookies={cookies}
                selectedFormName={selectedFormName}
                setSelectedFormName={setSelectedFormName}
              />
            }
            <DocTypesDropdown
              cookies={cookies}
              selectedDocType={selectedDocType}
              setSelectedDocType={setSelectedDocType}
            />
          </div>
          {gridName === "Document Search" &&
          <div className="date-box">
            <label className="date-input" >Date Created Range:</label>
            <input className="date-input silver-border date-select" type="date" name="rangeStart" defaultValue={prevDate}/> 
            <input className="date-input silver-border date-select" type="date" name="rangeEnd" defaultValue={todayDate}/>
          </div>}
          <Button className="standard-button" type="submit" disabled={loading}>
            {loading&&<Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />}
            {!loading&&<span>Search</span>}
          </Button>
          <Button className="standard-button div-horizontal-margin"
            onClick={()=>ClearSearch()}
          >Clear Search</Button>
        </form>
      </div>
      {gridName !== "Document Search"&&<div></div>}
      {gridName === "Document Search"&&<div>
        <div></div>
        <div className="search-row name-change date-select">
          <form className="search-row" id="customName" onSubmit={(e)=>DocNameChage(e, gridApi, cookies, selectedFormName, selectedDocChange)}> 
            <label>Change Selected: </label>
            <input className="date-input silver-border" type="text" placeholder="custom name" name="customName" />
            <FormNamesDropdown
              cookies={cookies}
              selectedFormName={selectedFormName}
              setSelectedFormName={setSelectedFormName}
            />
            <DocTypesDropdown
              cookies={cookies}
              selectedDocType={selectedDocChange}
              setSelectedDocType={setSelectedDocChange}
            />
            <Button className="standard-button div-horizontal-margin" type="submit">Submit</Button>
          </form>
        </div>
      </div>}
      <div className="search-row">
        <Button onClick={()=>HandleSwitchTabs("Document Search")} className="module-button">Document Search</Button>
        <Button onClick={()=>HandleSwitchTabs("Name Count")} className="module-button">Name Count</Button>
      </div>
    </div>
    {formListCols&&
    <div className="card oap-card">
      <div className="right-align-row row-gapper">
        <OverlayTrigger overlay={<Tooltip
          style={{position:"fixed"}}
          >ExportData</Tooltip>} >
            <Button className="standard-button" onClick={()=>HandleExportFormData()}><BsDownload/></Button>
          </OverlayTrigger>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={formListData}
          columnDefs={formListCols}
          defaultColDef={defaultColDefs}
          rowSelection={'multiple'}
          pagination={true}
          paginationPageSize={20}
          onFirstDataRendered={onFirstDataRendered}
          onCellClicked={HandleClickEmployeeId}
        />
      </div>
    </div>}
    </>
  )
}