import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';

function CandidateStatusForm({
  candidateRejectOptions,
  companyRejectReasons, 
  showCompanyRejectOptions, 
  showCandidateRejectOptions, 
  formError, 
  candidateStatusOptions, 
  handleChange, 
  handleSubmit, 
  attemptingToSubmit, 
  error,
  selectedCandidateStatus,
  status
}) {
    return (
      <>
          <div>
          {error?
              <div>
                  <h3>There was a problem, please try again</h3>
                  <p>Error:{error}</p>
              </div>
            :
            <Form>
                <Form.Label>Candidate Status<span className='red-text'>*</span></Form.Label>
                {candidateStatusOptions?
                    <Select value={selectedCandidateStatus} options={candidateStatusOptions} onChange={(e)=>handleChange(e, 'statuscodeid')} />
                :
                    <Spinner animation='border' size='sm' />
                }

                {showCandidateRejectOptions&&
                <>
                <Form.Label>Reason<span className='red-text'>*</span></Form.Label>
                <Select options={candidateRejectOptions.map(status=>{return{value:status,label:(status.Status+' | '+status.Description)}})} onChange={(e)=>handleChange(e, 'statuscodeid')} />
                </>
                }
                {showCompanyRejectOptions&&
                <>
                <Form.Label>Reason<span className='red-text'>*</span></Form.Label>
                <Select options={companyRejectReasons.map(status=>{return{value:status,label:(status.Status+' | '+status.Description)}})} onChange={(e)=>handleChange(e, 'statuscodeid')} />
                </>
                }
                {/* <Form.Label>Job Order<span className='red-text'>*</span></Form.Label>
                <Select isDisabled defaultValue={defaultOption} options={jobOrders} onChange={(e)=>handleChange(e, 'orderrequestid')} /> */}

                <Form.Label>Notes</Form.Label>
                <Form.Control onChange={(e)=>handleChange(e.target.value, 'notes')} className='margin-bottom' />
                
                {formError&&
                <Alert variant='warning'>{formError}</Alert>
                }
                
                {(status===200)&&
                <Alert>Success! Please allow up to 15 minutes for your changse to show</Alert>
                }

                <Button className='full-width' variant='success' onClick={handleSubmit}>{attemptingToSubmit?<Spinner animation="border" role="status"></Spinner>:'Submit'}</Button>
            </Form>
            }
          </div>
      </>
    )
}

export default CandidateStatusForm