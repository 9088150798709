import "./FourZeroFour.css";

export default function NoMatch() {
  return(
    <div className="not-found">
      <h1>404 Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <p>
        If you feel you have found this page in error, email us at 
        <a href="mailto: ewminfo@employnet.com"> ewminfo@employnet.com</a>
      </p>
    </div>
  )
}