import React, { useContext, useState } from 'react'
import BasicEmployeeSearch from './BasicEmployeeSearch'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext'
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import { useEffect } from 'react';

function BasicEmployeeSearchLogic({ setRowData, setAdvancedSearch }) {
    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [employeeID, setEmployeeID] = useState(0);
    const [error, setError] = useState();
    const [fetchUrl, setFetchUrl] = useState();
    const {cookies} = useContext(CustomerContext)
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const {data: rowData} = useFetchGet((fetchUrl&&fetchUrl),cookies)

    useEffect(()=>{
      if(rowData){
        setIsLoading(false);
        setRowData(rowData);
      }
      // eslint-disable-next-line
    },[rowData])

    useEffect(()=>{
      if(firstName||lastName||employeeID){
        setError(null)
      }
      // eslint-disable-next-line
    },[firstName,lastName,employeeID])

    const HandleSubmit = (e) => { 
      e.preventDefault();
      setError(null)
      setIsLoading(true);
      let params = '';
      if(firstName){
        params = `&firstName=${firstName}`
      }
      if(lastName){
        params = params + `&lastName=${lastName}`
      }
      if(employeeID){
        params = params + `&EmployeeID=${employeeID}`
      }
      if(ValidatePostObj()){
        setRowData(null)
        setFetchUrl(`${REACT_APP_API_URL}/Candidates/GetEmployeeByName?token=${cookies.get('customer').Token}${params}`)
      }
     }

    const ValidatePostObj = () => { 
      if(!firstName&&!lastName&&!employeeID){
        setError('Please fill in one of the fields to search')
        setIsLoading(false)
        return false
      }else if(isNaN(employeeID)){
        setError('Employee ID can only contain numbers')
        setIsLoading(false);
        return false
      }else{
        return true
      }
     }
  
  return (
    <BasicEmployeeSearch
        setFirstName={setFirstName}
        setLastName={setLastName}
        HandleSubmit={HandleSubmit}
        isLoading={isLoading}
        setAdvancedSearch={setAdvancedSearch}
        error={error}
        setEmployeeID={setEmployeeID}
    ></BasicEmployeeSearch>
  )
}

export default BasicEmployeeSearchLogic