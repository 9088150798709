import React, { useContext, useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Row from 'react-bootstrap/Row'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext'
import './AdminPage.css'
import CustomerRequiredForms from './CustomerRequiredForms/CustomerRequiredForms'
import AllRequiredForms from './AllRequiredForms/AllRequiredForms'
import DefaultColumnEditing from './DefaultColumnEditing.js/DefaultColumnEditing'

function AdminPage(){
    const { setCurrentPage } = useContext(CustomerContext)
    const [activeKey,setActiveKey] = useState()

    useEffect(()=>{
        setCurrentPage('Admin')
    },[setCurrentPage])

    return (
    <Row>
        <h2>Administrator Settings</h2>
        <Accordion activeKey={activeKey} onSelect={(k)=>setActiveKey(k)} >
            <Accordion.Item eventKey="CustomerRequiredForms">
                <Accordion.Header>Customer Required Forms</Accordion.Header>
                <Accordion.Body>
                    {activeKey==='CustomerRequiredForms'&&
                        <CustomerRequiredForms />
                    }
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="AllRequiredForms">
                <Accordion.Header>All Required Forms</Accordion.Header>
                <Accordion.Body>
                    {activeKey==='AllRequiredForms'&&
                        <AllRequiredForms />
                    }
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='DefaultColumnEditing'>
                <Accordion.Header>Edit Default Columns</Accordion.Header>
                <Accordion.Body>
                    {activeKey==='DefaultColumnEditing'&&
                        <DefaultColumnEditing />
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </Row>
    )
}

export default AdminPage