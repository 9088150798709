import { Form } from "react-bootstrap";

export default function PunchesByColumn({
    punchesByColumnSet
}){
    return(
        <>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">Punch Date</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesByColumnSet.punchDateCol}
                    onChange={(e)=>{punchesByColumnSet.setPunchDateCol(e.target.value)}}
                />
            </div>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">In One</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesByColumnSet.inOneCol}
                    onChange={(e)=>{punchesByColumnSet.setInOneCol(e.target.value)}}
                />
            </div><div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">Out One</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesByColumnSet.outOneCol}
                    onChange={(e)=>{punchesByColumnSet.setOutOneCol(e.target.value)}}
                />
            </div>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">In Two</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesByColumnSet.inTwoCol}
                    onChange={(e)=>{punchesByColumnSet.setInTwoCol(e.target.value)}}
                />
            </div><div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">Out Two</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesByColumnSet.outTwoCol}
                    onChange={(e)=>{punchesByColumnSet.setOutTwoCol(e.target.value)}}
                />
            </div>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">In Three</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesByColumnSet.inThreeCol}
                    onChange={(e)=>{punchesByColumnSet.setInThreeCol(e.target.value)}}
                />
            </div>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">Out Three</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesByColumnSet.outThreeCol}
                    onChange={(e)=>{punchesByColumnSet.setOutThreeCol(e.target.value)}}
                />
            </div>
        </>
    )
}