import { useState, useEffect } from "react";
import Select from "react-select";
import { GetFormNames } from "Components/Admin/CallApi";


export default function FormNamesDropdown({
  cookies,
  selectedFormName,
  setSelectedFormName
}){
  const [formNames, setFormNames] = useState();

  useEffect(()=>{
    GetFormNames(cookies).then(formNames=>{
      let formNamesList = [];
      formNames.forEach(name=>{
        let nameItem = {
          value: name.FormID,
          label: name.FormName
        };
        formNamesList.push(nameItem);
      });
      setFormNames(formNamesList);
    })
    // eslint-disable-next-line
  },[]);

  const customSelectStyles = {
    control: base => ({
      ...base,
      width: '400px',
      margin: '0px 5px 0px 5px'
    })
  };

  return(
    <>
    <Select
      options={formNames}
      value={selectedFormName || ''}
      onChange={(e)=>setSelectedFormName(e)}
      placeholder='Select a Form Name'
      styles={customSelectStyles}
    />
    </>
  )
}