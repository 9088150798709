import React, { useContext, useState, useEffect } from 'react'
import Department from './Department'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';

function DepartmentLogic({ selectedDepartment, setSelectedDepartment, defaultFormData }) {
  const [selectedPO, setSelectedPO] = useState();
  const {
    cookies,
    formData, 
    setFormData
  } = useContext(CustomerContext)
  const {REACT_APP_API_URL} = process.env;
  const [branchID, setBranchID] = useState([cookies.get('customer').BranchID]);
  const [defaultBranch, setDefaultBranch] = useState();
  const { data: branches } = useFetchGet((!defaultBranch&&`${REACT_APP_API_URL}/Candidates/GetBranchList?token=${cookies.get('customer').Token}`),cookies)

  useEffect(()=>{
    if(branches){
        branches.forEach(branch=>{
            if(branch.BranchName===cookies.get('customer').BranchName){
                setDefaultBranch([{value:branch,label:branch.BranchName}])
            }
        })
    }
  },[branches, cookies])

  const HandlePOChange = (event) => {
      setSelectedPO(event.value) 
      formData.PO = event.value;
      setFormData(formData);
   }

   const HandleCustomerDepartmentChange = (event) => { 
      formData.Department = event.value;
      setFormData(formData);
      setSelectedDepartment(event.value);
    }

  const onMenuOpen = (e) => { 
    if(e==='Branches'){
      setBranchID(null)
      setSelectedDepartment(null)
    }else{
      setSelectedDepartment(null)
    }
   }

  const handleBranchesChange = (e) => {
    setBranchID([e.value.BranchID])
 }
  
    
  return (
    <Department
      branchID={branchID}
      branches={branches}
      defaultBranch={defaultBranch}
      handleBranchesChange={handleBranchesChange}
      selectedDepartment={selectedDepartment}
      formData={formData}
      cookies={cookies}
      HandlePOChange={HandlePOChange}
      HandleCustomerDepartmentChange={HandleCustomerDepartmentChange}
      selectedPO={selectedPO}
      onMenuOpen={onMenuOpen}
      defaultFormData={defaultFormData}
    ></Department>
  )
}

export default DepartmentLogic