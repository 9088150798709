import React, { useState, useEffect, useMemo, useContext } from "react";
import OAPReconcileView from "./OAPReconcileView";
import EditNumberCell from "Components/CustomComponents/CustomHooks/useEditNumberCell";
import { FormatDate, FormatUSD, CustomComparator } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";
import LoadMatched from "../LoadMatched";
import { GetAgingCustomer, GetInvoiceAmount, GetInvoiceBalance, GetRowID } from "../ValueGetters/GetReconcileGridValues";
import { GetMinimumOapBalance } from "../ValueGetters/GetUpdatedBalances";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import { DeleteRow, PatchOapData, RefreshOapData } from "../CallAPI";

export default function OAPReconcile({
    dataHold,
    processingWeek,
    employer,

    oapRowData,

    setTotalInOaps,
    setTotalReconciled,
    setTotalNotReconciled,

    matchedInvoices,

    headerExpanded
}) {  
    const { cookies } = useContext(CustomerContext);
    const [gridApi, setGridApi] = useState();
    const [duplicationCounter, setDuplicationCounter] = useState(0); // for creating multiple duplicates
    
    const [showPasteInvoices, setShowPasteInvoices] = useState();
    const [selectedOap, setSelectedOap] = useState();
    const [selectedOapRowData, setSelectedOapRowData] = useState();

    const [frameworkComponents] = useState({
        editNumberCell: EditNumberCell
    });

    const [oapColDefs, setOapColDefs] = useState();
    const [defaultColDef] = useState ({
        sortable: true,
        resizable: true,
        filter: true,      
    });

    const [reconcileRows, setReconcileRows] = useState();

    const repSelection = [
        { value: "Jeff", label: "Jeff" },
        { value: "Yleanna", label: "Yleanna" },
        { value: "Unassigned", label: "Unassigned" },
        { value: "All", label: "All"}
    ];

    const [selectedRep, setSelectedRep] = useState({ value: "All", label: "All" });
    const [refreshObject, setRefreshObject] = useState();

    useEffect(()=>{
        const setTimeoutInstance = setTimeout(() => {
            if(refreshObject){
                RefreshOapData(refreshObject)
            }
        }, 1000)

        return () => {
            clearTimeout(setTimeoutInstance)
        }
    },[refreshObject])
    

    useEffect(() => {
        if (oapRowData) {
            setReconcileRows(oapRowData)
        }
    }, [oapRowData]);

    useEffect(() => {
        if (reconcileRows) {
            setOapColDefs([
                {
                    field: "RepName",
                    hide: true,
                },
                { field: "OAPRowID", hide: true },
                
                { field: "Reconciled", hide: true },
                {
                    field: "CustomerName",
                    editable: true,
                    valueParser: params => params.newValue,
                    sort: 'asc',
                    comparator: CustomComparator
                },
                { field: "AgingCustomer"},
                {
                    field: "OAPNumber",
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    sort: 'asc'
                },
                { field: "DepositDate", valueFormatter: (data) => FormatDate(data) },
                { field: "OAPAmount", valueFormatter: (data) => FormatUSD(data) },
                { field: "InvoiceNumber", editable: true },
                {
                    field: "PayAmount",
                    editable: true,
                    cellClassRules: { 'pay-amount-highlight': 'x !== null'}
                },
                {
                    field: "OAPBalance",
                    cellClassRules: {
                        'negative-value': 'x < 0',
                        'oap-with-balance': 'x > 0'
                    },
                },
                {
                    field: "InvoiceBalance",
                    cellClassRules: {
                        'negative-value': 'x < 0',
                        'invoice-with-balance': 'x > 0',
                    },
                },
                { field: 'InvoiceAmount'},
                
                { field: "ROWID", cellRenderer: GetRowID, hide: true },
                { field: "ProcessingWeek", hide: true },
                { field: "Employer", hide: true },
                { field: "IsLeftOver", hide: true }
            ]);
        }
        // eslint-disable-next-line
    }, [reconcileRows]);

    const onFirstDataRendered = (params) => { 
        setGridApi(params.api);
        params.columnApi.autoSizeAllColumns();
      };

    const onRowValueChanged = (e) => {
        let changedOap = e.data.OAPNumber;
        HandleUpdateOap(changedOap);
    }
    
    const rowClassRules = useMemo(() => {
        if (reconcileRows) {
            return {
                'all-balances-zero': '(data.OAPBalance === 0) && (data.InvoiceBalance === 0)',
                'row-overlay': 'data.InvoiceNumber && data.InvoiceNumber !== null && (data.OAPBalance !== 0 || data.InvoiceBalance !== 0)',
                'leftover-overlay': 'data.IsLeftOver',
                'mismatch-warn': 'data.PayAmount !== 0 && data.InvoiceAmount === 0'
            };
        };
        // eslint-disable-next-line
    }, [reconcileRows]);

    const HandleCounterChange = (e) => {
        setDuplicationCounter(e.target.value);
    }

    const HandleDuplicateRows = () => {
        
        const selectedData = gridApi.getSelectedRows();
        let editingOap = selectedData[0].OAPNumber;
        for (var d = 0; d < duplicationCounter; d++){
            for (var i = 0; i < selectedData.length; i++){
                let newRow = {
                    ...selectedData[0],
                    OAPRowID: 0,
                    InvoiceNumber: 0,
                    PayAmount: 0,
                    InvoiceBalance: 0,
                    InvoiceAmount: 0,
                    AgingCustomer: null,
                    ROWID: selectedData[0].OAPNumber.slice(3)
                };

                PatchOapData(newRow, cookies);
            }
        }
        HandleUpdateOap(editingOap);
    }

    const HandleDeleteRows = (e) => {
        const toDelete = gridApi.getSelectedRows();
        //create a list of OAP Numbers for the oaps that need to be deleted, then "HandleUpdateOap" for each
        const removeRows = [...reconcileRows]
        toDelete.forEach(row => {
            removeRows.splice(
                removeRows.indexOf(row), 1
            )
            DeleteRow(row.OAPRowID, cookies);
        });
        removeRows.forEach((invoice) => {
            invoice.OAPBalance = GetMinimumOapBalance(invoice, removeRows); // in line only oap amount - pay amount
            invoice.InvoiceBalance = GetInvoiceBalance(invoice, dataHold);
            invoice.InvoiceAmount = GetInvoiceAmount(invoice, dataHold);
            invoice.AgingCustomer = GetAgingCustomer(invoice, dataHold);
            invoice.ROWID = GetRowID(invoice);
            PatchOapData(invoice, cookies);
        });
        HandleRefreshData();
    }

    const HandlePasteInvoices = (e) => {
        const selectedRow = gridApi.getSelectedRows();
        setSelectedOapRowData(selectedRow);
        setSelectedOap(selectedRow[0].OAPNumber);
        setShowPasteInvoices(true);
    }

    const HandleLoadMatched = () => {
        const selectedOapRowData = gridApi.getSelectedRows();
        LoadMatched(reconcileRows, selectedOapRowData, matchedInvoices, cookies, dataHold, HandleRefreshData);
    }

    const HandleReconcileNegatives = () => {
        let selectedRows = gridApi.getSelectedRows();
        let updatedOap = selectedRows[0].OAPNumber;
        selectedRows.forEach(row => {
            row.PayAmount = row.InvoiceAmount;
            row.OAPBalance = GetMinimumOapBalance(row, reconcileRows);
            row.InvoiceBalance = GetInvoiceBalance(row, dataHold);
            PatchOapData(row, cookies);
        });
        HandleUpdateOap(updatedOap);
    };

    const HandleUpdateOap = (oap) => {
        gridApi.showLoadingOverlay();
        reconcileRows.forEach(row => {
            if (oap&&row.OAPNumber === oap) { 
                row.OAPBalance = GetMinimumOapBalance(row, reconcileRows);
                row.InvoiceBalance = GetInvoiceBalance(row, dataHold);
                row.InvoiceAmount = GetInvoiceAmount(row, dataHold);
                row.AgingCustomer = GetAgingCustomer(row, dataHold);
                row.ROWID = GetRowID(row);
                PatchOapData(row, cookies); 
            };
        });
        setRefreshObject({processingWeek, employer, cookies, setReconcileRows, gridApi, selectedRep, setTotalInOaps, setTotalNotReconciled, setTotalReconciled}) 
    }

    const HandleRefreshData = () => {
        gridApi.showLoadingOverlay();
        setRefreshObject({processingWeek, employer, cookies, setReconcileRows, gridApi, selectedRep, setTotalInOaps, setTotalNotReconciled, setTotalReconciled}) 
    }

    const HandleExportProgress = (params) => {
        gridApi.exportDataAsCsv(params);
    };

    const HandleRepSelection = (e) => {
        setSelectedRep(e);

        let repRows = e.value === "All" ? oapRowData : oapRowData.filter(row => row.RepName === e.value);
        setReconcileRows(repRows); // need to add a data view instead of manipulating oapRowData's state
    };

    const customSelectStyles = {
        control: base => ({
            ...base,
            width: '200px'
        })
    };

    return (
        <OAPReconcileView
            reconcileRows={reconcileRows}
            setReconcileRows={setReconcileRows}
            oapColDefs={oapColDefs}
            defaultColDef={defaultColDef}
            onFirstDataRendered={onFirstDataRendered}
            frameworkComponents={frameworkComponents}
            onCounterChanged={HandleCounterChange}
            HandleDuplicateRows={HandleDuplicateRows}
            duplicationCounter={duplicationCounter}
            HandleDeleteRows={HandleDeleteRows}
            rowClassRules={rowClassRules}
            showPasteInvoices={showPasteInvoices}
            setShowPasteInvoices={setShowPasteInvoices}
            OnPasteInvoices={HandlePasteInvoices}
            selectedOap={selectedOap}
            selectedOapRowData={selectedOapRowData}
            HandleExportProgress={HandleExportProgress}
            HandleReconcileNegatives={HandleReconcileNegatives}
            HandleLoadMatched={HandleLoadMatched}
            dataHold={dataHold}
            gridApi={gridApi}
            processingWeek={processingWeek}
            employer={employer}
            HandleRefreshData={HandleRefreshData}
            selectedRep={selectedRep}
            repSelection={repSelection}
            HandleRepSelection={HandleRepSelection}
            customSelectStyles={customSelectStyles}
            headerExpanded={headerExpanded}
            onRowValueChanged={onRowValueChanged}
        />
    )
}