import { AgGridReact } from "ag-grid-react";

export default function MergedGrid({
    mergeData,
    mergeCols,
    rowHighlight,
    defaultColDef
}){
    const onFirstDataRendered = (params) => { 
        params.columnApi.autoSizeAllColumns();
    };

    return (
        <>
            <div className="card oap-card">
                <h5>Merged Data</h5>
                <div className="ag-theme-balham ag-border-dark">
                    <AgGridReact
                        rowData={mergeData}
                        onFirstDataRendered={onFirstDataRendered}
                        columnDefs={mergeCols}
                        defaultColDef={defaultColDef}
                        suppressFieldDotNotation={true}
                        rowClassRules={rowHighlight}
                    />
                </div>
            </div>
        </>
    )
}