import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import CandidateInfoPopupLogic from './CandidateInfoPopup/CandidateInfoPopupLogic'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import AssignCandidatePopupLogic from './AssignCandidates/AssignCandidatePopupLogic'
import AssignEmployeeLogic from './AssignEmployee/AssignEmployeeLogic'
import CandidateStatusPopupLogic from './CandidateInfoPopup/TabItems/Statuses/CandidateStatusPopup/CandidateStatusPopupLogic';
import PopupErrorBoundary from 'Components/Compliance/Grid/PopupErrorBoundary/PopupErrorBoundary';
import ColumnSavingButtonGroup from './CandidateInfoPopup/ColumnSavingButtonGroup';
import { BsDownload } from 'react-icons/bs';

const CandidatesGrid = ({ 
  onBtnExport,
  customColumnName,
  columnApi,
  setColumnDefs,
  defaultOnGridReady,
  onGridColumnsChanged,
  DeselectAllRows,
  openedTab,
  jobOrderID,
  openAssignEmployee,
  setOpenAssignEmployee,
  openAssignCandidate,
  setOpenAssignCandidate,
  onGridReady,
  HandleRowSelected,
  selectedRows,
  defaultColDef,
  rowData,
  columnDefs,
  HandleCellClick,
  candidatePopupIsOpen,
  setCandidatePopupIsOpen,
  employeeData,
  candidateStatusPopupIsOpen,
  setCandidateStatusPopupIsOpen,
  aboveGridElements,
  otherColumnDefs
}) => {

  return (
      <>
        {candidateStatusPopupIsOpen&&<PopupErrorBoundary><CandidateStatusPopupLogic show={candidateStatusPopupIsOpen} setShow={setCandidateStatusPopupIsOpen} candidateInfo={employeeData} defaultRequestID={jobOrderID} /></PopupErrorBoundary>}
        {candidatePopupIsOpen&&<PopupErrorBoundary><CandidateInfoPopupLogic openedTab={openedTab} defaultJobOrderID={jobOrderID} allCandidateInfo={employeeData} candidateID={employeeData.EmployeeID} setShow={setCandidatePopupIsOpen} show={candidatePopupIsOpen}/></PopupErrorBoundary>}
        {openAssignCandidate&&<PopupErrorBoundary><AssignCandidatePopupLogic candidates={selectedRows} show={openAssignCandidate} setShow={setOpenAssignCandidate} /></PopupErrorBoundary>}
        {openAssignEmployee&&<PopupErrorBoundary><AssignEmployeeLogic candidates={selectedRows} show={openAssignEmployee} setShow={setOpenAssignEmployee} /></PopupErrorBoundary>}
        {rowData?
        <>
          <Row>
            <Col sm={aboveGridElements?3:6}>
              {(selectedRows.length>0)&&
              <ButtonGroup className="add-candidate-button">
                <Button className='border-right' onClick={(e)=>setOpenAssignCandidate(true)}>Add to Job Order</Button>
                <Button onClick={(e)=>setOpenAssignEmployee(true)}>Add to List</Button>
                <Button onClick={()=>DeselectAllRows()}>Deselect All Rows</Button>
              </ButtonGroup>
              }
            </Col>
            
          </Row>
          <div className='card oap-card'>
            <div className='right-align'>
              {aboveGridElements&&<div className='label-gap'>{aboveGridElements}</div>}
              <ButtonGroup className='download-button-wrapper margin-bottom'>
                <Button title='Download grid data' variant='secondary' onClick={() => onBtnExport()}><BsDownload/></Button>
                <ColumnSavingButtonGroup
                    customColumnName={customColumnName}
                    columnApi={columnApi}
                    setColumnDefs={setColumnDefs}
                    otherColumnDefs={otherColumnDefs}
                    columnDefs={columnDefs}
                />
              </ButtonGroup>
            </div>
   
            {columnDefs?
            <div id="myGrid" className="ag-theme-alpine grid">
                <AgGridReact
                    defaultColDef={defaultColDef}
                    rowData={rowData}
                    onGridReady={onGridReady?onGridReady:defaultOnGridReady}
                    onCellClicked={HandleCellClick}
                    columnDefs={columnDefs}
                    checkboxSelection={true}
                    rowMultiSelectWithClick={true}
                    rowSelection='multiple'
                    onRowSelected={HandleRowSelected}
                    onGridColumnsChanged={onGridColumnsChanged}
                    suppressRowClickSelection={true}
                    tooltipShowDelay={0}
                ></AgGridReact>
            </div>
            :
            <Spinner animation='border'/>
            }
          </div>
        </>
        :
        <Spinner animation="border" role="status" />
        }
    </>
  )
}

export default CandidatesGrid;