import { AgGridReact } from "ag-grid-react";

export default function OriginalTimecardGrid({
    delayedRows,
    delayedCols,
    defaultColDef
}){
    const onFirstDataRendered = (params) => { 
        params.columnApi.autoSizeAllColumns();
    };

    return (
        <>
            <div className="card oap-card">
                <h5 >Original Timecard Data</h5>
                <div className="ag-theme-balham ag-border-dark">
                    <AgGridReact
                        rowData={delayedRows}
                        onFirstDataRendered={onFirstDataRendered}
                        columnDefs={delayedCols}
                        defaultColDef={defaultColDef}
                        suppressFieldDotNotation={true}
                    />
                </div>
            </div>
        </>
    )
}