import React from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';

function BuzzOffer({ EmployeeID, OrderID, show, setShow }) {
    const { cookies } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const [canSubmit, setCanSubmit] = useState(false);
    // Not ready to implement yet
    // eslint-disable-next-line
    const { status } = useFetchPost((canSubmit&&`${REACT_APP_API_URL}/Candidates/PostJobOffer?Token=${cookies.get('customer').Token}&EmployeeID=${EmployeeID}&OrderID=${OrderID}`),cookies)

    const handleClose = () => { 
        setCanSubmit(false)
        setShow(false)
     }
  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Send Job Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Label>Employee ID</Form.Label>
            <Form.Control disabled value={EmployeeID} />

            <Form.Label>Job Order ID</Form.Label>
            <Form.Control disabled value={OrderID} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="success" onClick={()=>setCanSubmit(true)}>
                Send Job Offer
            </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default BuzzOffer