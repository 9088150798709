import React from 'react';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Tooltip from './Tooltip';
import CandidaciesByBranchLogic from '../CandidaciesByBranch/CandidaciesByBranchLogic';
import CandidatesGridLogic from '../CandidatesGrid/CandidatesGridLogic';
import '../CandidaciesByBranch/CandidaciesByBranch.css';

function CandidateDashboard({ 
    endingAssignments,
    recentApplicants,
    needReassigned,
    activeKey,
    setKey,
    branches,
    defaultBranch,
    HandleBranchChange,
    branchIDs,
    openTooltip,
    setOpenTooltip,
    onlyActive,
    setOnlyActive,
    myEmployees, 
    serviceReps,
    HandleSRChange,
    SRIdent
}){
    return(
        <>
        <div className='header-card'>
            {openTooltip&&<Tooltip show={openTooltip} setShow={setOpenTooltip} />}
            <div className='four-column-div'>
                <h3>Candidates Dashboard</h3>
                {(activeKey==='needsReassigned'||activeKey==='endingAssignments'||activeKey==='recentApplicants'||activeKey==='candidates')&&
                    branches&&<Select options={branches} defaultValue={defaultBranch} isMulti onChange={HandleBranchChange} />
                }
                {(activeKey==='myEmployees'||activeKey==='candidates')&&
                    (serviceReps&&serviceReps.length>0)&&<Select options={serviceReps} onChange={(e)=>HandleSRChange(e)} />
                }
                <div className='right-align'>
                    {(activeKey==='candidates')&&
                        <Form.Check type='switch' label='Only Active' checked={onlyActive} onChange={(e)=>setOnlyActive(e.target.checked)} className='label-gap'/>
                    }
                    <Button title='Candidate tabs, onboarding, and overview explained' variant="secondary" onClick={()=>setOpenTooltip(true)}>?</Button>
                </div>
            </div>
            <div>
                <Button
                    className='module-button'
                    onClick={()=>setKey('candidates')}
                >Candidates</Button>
                <Button
                    className='module-button'
                    onClick={()=>setKey('myEmployees')}
                >My Employees
                    {(myEmployees&&myEmployees.length>0)&&<Badge>{myEmployees.length}</Badge>}
                </Button>
                <Button
                    className='module-button'
                    onClick={()=>setKey('recentApplicants')}
                >Recent Applicants
                    {(recentApplicants&&recentApplicants.length>0)&&<Badge bg="danger">{recentApplicants.length}</Badge>}
                </Button>
                <Button
                    className='module-button'
                    onClick={()=>setKey('endingAssignments')}
                >Ending Assignments
                    {(endingAssignments&&endingAssignments.length>0)&&<Badge bg="danger">{endingAssignments.length}</Badge>}
                </Button>
                <Button
                    className='module-button'
                    onClick={()=>setKey('needsReassigned')}
                >Recently Ended Assignments
                    {(needReassigned&&needReassigned.length>0)&&<Badge bg="danger">{needReassigned.length}</Badge>}
                </Button>
            </div>
        </div>
        {activeKey==='candidates'&&<CandidaciesByBranchLogic branchIDs={branchIDs} SRIdent={SRIdent} onlyActive={onlyActive} />}
        {activeKey==='myEmployees'&&<CandidatesGridLogic currentPageName='My Employees' rowData={myEmployees} otherColumnDefs={[]} />}
        {activeKey==='recentApplicants'&&<CandidatesGridLogic currentPageName='Recent Applicants' rowData={recentApplicants&&recentApplicants} otherColumnDefs={[]} />}
        {activeKey==='endingAssignments'&&<CandidatesGridLogic currentPageName='Ending Assignments' rowData={(endingAssignments&&endingAssignments)} otherColumnDefs={[]} />}
        {activeKey==='needsReassigned'&&<CandidatesGridLogic currentPageName='Recently Ended' rowData={needReassigned} otherColumnDefs={[]} />}
        </>
    )
}

export default CandidateDashboard;