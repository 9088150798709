import React, { useContext, useState } from 'react';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import ReadyOnlyJobOrder from './ReadOnlyJobOrder';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';

function ReadOnlyJobOrderLogic({ orderID }) {
    const [openAddSR, setOpenAddSR] = useState(false);
    const [openAddBranch, setOpenAddBranch] = useState(false);
    const { REACT_APP_API_URL } = process.env;
    const {cookies} = useContext(CustomerContext)
    const {data: currentSubmission } = useFetchGet(`${REACT_APP_API_URL}/Candidates/GetTWOrderByID?token=${cookies.get('customer').Token}&OrderID=${orderID}`,cookies)
    const { data: SRs, fetchData: fetchSRs } = useFetchGet(`${REACT_APP_API_URL}/Candidates/Associate/GetServiceRepsByOrder?token=${cookies.get('customer').Token}&OrderID=${orderID}`,cookies)
    const { data: Branches, fetchData: fetchBranches } = useFetchGet(`${REACT_APP_API_URL}/Candidates/Associate/GetBranchesPerOrder?token=${cookies.get('customer').Token}&OrderID=${orderID}`,cookies)
    
    const mapData = (data, valueName) => { 
      if(data.length>0){
        let returnString = ''
        data.forEach((data,index,array) => {
          if(index===(array.length-1)){
            returnString += data[valueName]
          }else{
            returnString += (data[valueName]+', ')
          }
        })
        return returnString
      }else{
        return '--'
      }
     }

    const handleSRClose = () =>{
      setOpenAddSR(false);
      fetchSRs(0)
    }

    const handleBranchClose = () => {
      setOpenAddBranch(false);
      fetchBranches(0)
    }
    
  return (
    <>
    {currentSubmission&&
        <ReadyOnlyJobOrder
            currentSubmission={currentSubmission}
            SRs={SRs}
            Branches={Branches}
            mapData={mapData}
            openAddSR={openAddSR}
            setOpenAddSR={setOpenAddSR}
            openAddBranch={openAddBranch}
            setOpenAddBranch={setOpenAddBranch}
            orderID={orderID}
            handleSRClose={handleSRClose}
            handleBranchClose={handleBranchClose}
        ></ReadyOnlyJobOrder>
    }
    </>
  )
}

export default ReadOnlyJobOrderLogic