import React, { useContext, useEffect, useState } from 'react'
import CustomerList from './JobOrders'
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';

function CustomerListLogic() {
    const { cookies, setCurrentPage, setAddJobOrderIsOpen } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const [postObj, setPostObj] = useState();
    const [openJobOrderDetails, setOpenJobOrderDetails] = useState(false);
    const [orderID, setOrderID] = useState(0);
    const [gridApi, setGridApi] = useState();
    const [columnApi, setColumnApi] = useState();
    const [candidates, setCandidates] = useState();
    const [encodedColumns, setEncodedColumns] = useState();
    const [columnDefs, setColumnDefs] = useState();
    const [isDefault, setIsDefault] = useState(false);
    const [orderReadOnlyIsOpen, setOrderReadOnlyIsOpen] = useState(false);
    const { data: candidateData } = useFetchGet((orderID&&`${REACT_APP_API_URL}/Candidates/GetCandidatesByOrderID?token=${cookies.get('customer').Token}&orderid=${orderID}&isactive=${true}`),cookies)
    const { data, setData: setTWOrders, loading: ordersLoading } = useFetchPost((postObj&&`${REACT_APP_API_URL}/Candidates/GetTWOrders?token=${cookies.get('customer').Token}`),cookies,postObj)
    const { data: customColumns } = useFetchGet((!columnDefs&&`${REACT_APP_API_URL}/Utilities/GetReportColumns?token=${cookies.get('customer').Token}&reportName=Orders&isDefault=${isDefault}`),cookies)
    const { loading } = useFetchPost((encodedColumns&&`${REACT_APP_API_URL}/Utilities/PostReportColumns?token=${cookies.get('customer').Token}&reportName=Orders`),cookies,encodedColumns)
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
    });
    const [openFilter, setOpenFilter] = useState(true);
    const [openOrderStatus, setOpenOrderStatus] = useState(false);
    const [selectedJobOrderData, setSelectedJobOrderData] = useState();

    const cellRenderer = (params) => {
        return <p>{(params.value*100).toFixed(2)}%</p>
    }

    useEffect(()=>{
        if(postObj&&data){
            setTWOrders(null)
        }
    // eslint-disable-next-line
    },[postObj])

    useEffect(()=>{
        if(customColumns){
            if(customColumns.Return){
                let decodedColumns = JSON.parse(window.atob(customColumns.Return));
                setColumnDefs(decodedColumns);
            }else{
                setColumnDefs([
                    {headerName:'Order ID',field:'OrderID',cellClass:'linked-cell'},
                    {headerName:'Job Title',field:'PublicJobTitle'},
                    {headerName:'Request Name',field:'OrderTitle'},
                    {headerName:'TW Order Status',field:'OrderStatus'},
                    {headerName:'Publicly Visible',field:'DoNotPostToWeb', valueFormatter: (params)=>params.value?'No':'Yes'},
                    {headerName:'Open Order Age',field:'OpenOrderAge'},
                    {headerName:'Candidates',field:'CandidateCount'},
                    {headerName:'Required',field:'Required'},
                    {headerName:'Filled',field:'FilledAssignments'},
                    {headerName:'Unfilled',field:'UnfilledAssignments'},
                    {headerName:'Order Type',field:'OrderTypeDesc'},
                    {headerName:'Customer',field:'CustomerName'},
                    {headerName:'Created Type',field:'CreatedType'},
                    {headerName:'Created By',field:'CreatedBy'},
                    {headerName:'Taken By',field:'RepFullName'},
                    {headerName:'Branch',field:'BranchName'},
                    {headerName:'Percent Filled',field:'PercentFilled', cellRenderer: cellRenderer },
                    {headerName:'EEO Class',field:'EEOClass'},
                    {headerName:'Job Type',field:'EEOCategory'},
                    {headerName:'Customer ID',field:'CustomerID'},
                    {headerName:'Last Assignment',field:'LastAssignment',valueFormatter: (date)=>{return TransformDate(date)}},
                    {headerName:'Shift',field:'shift'},
                    {headerName:'City',field:'City'},
                    {headerName:'State',field:'State'},
                    {headerName:'WorkSite',field:'Worksite'},
                    {headerName:'Order Created Date',field:'DateTimeOrderTaken',valueFormatter: (date)=>{return TransformDate(date)}},
                    {headerName:'Time to Fill',field:'AvgTimetoFill'},
                    {headerName:'Job Description',field:'JobDescription'}
                ]);
            
        }}
    // eslint-disable-next-line
    },[customColumns])

    const saveColumnOrder = () => { 
        setIsDefault(false)
        let columns = columnApi.getAllGridColumns()
        columns = columns.map(column => {
            let userDefinedColDef = columnDefs[columnDefs.findIndex((element)=>element.field===column.colDef.field)];
            return {...column.colDef,...userDefinedColDef, width:column.actualWidth}
        })
        let string = JSON.stringify(columns)
        let encoded = window.btoa(string)
        setEncodedColumns(encoded)
     }

     const getDefaults = () => { 
        setColumnDefs(null)
        setIsDefault(true)
     }

    useEffect(()=>{
        if(candidateData){
            setCandidates(candidateData.candidates)
        }
    // eslint-disable-next-line
    },[candidateData])

    useEffect(()=>{
        setCurrentPage('Orders Detail')
    // eslint-disable-next-line
    },[])


    function TransformDate(date){
        if(date.value){
            return new Date(date.value).toLocaleDateString();

        }else{
            return 'None'
        }
    }

    function onGridReady(params){
        params.columnApi.autoSizeAllColumns();
        setGridApi(params.api)
        setColumnApi(params.columnApi)
    }

    const onCellClicked = (params) => { 
        setSelectedJobOrderData(params.data)
        setOrderID(params.data.OrderID)
        if(params.colDef.headerName==='Order ID'){
            openInNewTab(`https://beyond.ontempworks.com/joborder/${params.data.OrderID}`)
        }else if(params.colDef.headerName==='TW Order Status'){
            setOpenOrderStatus(true);
        }else{
            setOpenJobOrderDetails(true);
        }
     }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

    const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    };

    const onNewColumnsLoaded = (params) => { 
        params.columnApi.autoSizeAllColumns()
     }

  return (
    <CustomerList
        postObj={postObj}
        setPostObj={setPostObj}
        rowData={data}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        onCellClicked={onCellClicked}
        apiUrl={REACT_APP_API_URL}
        openJobOrderDetails={openJobOrderDetails}
        candidates={candidates}
        setAddJobOrderIsOpen={setAddJobOrderIsOpen}
        orderReadOnlyIsOpen={orderReadOnlyIsOpen}
        setOrderReadOnlyIsOpen={setOrderReadOnlyIsOpen}
        orderID={orderID}
        onBtnExport={onBtnExport}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        openOrderStatus={openOrderStatus}
        setOpenOrderStatus={setOpenOrderStatus}
        selectedJobOrderData={selectedJobOrderData}
        setOpenJobOrderDetails={setOpenJobOrderDetails}
        saveColumnOrder={saveColumnOrder}
        getDefaults={getDefaults}
        loading={loading}
        ordersLoading={ordersLoading}
        onNewColumnsLoaded={onNewColumnsLoaded}
        ></CustomerList>
  )
}

export default CustomerListLogic