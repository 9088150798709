import React, { useState, useContext, useEffect } from 'react'
import CandidateDashboard from './CandidateDashboard'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import useForceUpdate from 'Components/CustomComponents/CustomHooks/useForceUpdate';

function CandidateDashboardLogic(){
    const { cookies, setCurrentPage } = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;
    const [activeKey, setKey] = useState('candidates')
    const [SRIdent, setSRIdent] = useState(0);
    const [serviceReps, setServiceReps] = useState();
    const [branchIDs, setBranchIDs] = useState([cookies.get('customer').BranchID])
    const [recentApplicantsPostObj, setRecentApplicantsPostObj] = useState({Branches: [{BranchName:cookies.get('customer').BranchName, BranchID:cookies.get('customer').BranchID}],IsAssigned: false, HireStatus: [],Zip: null,DateCreatedStart: new Date(new Date().setDate(new Date().getDate() - 7)).toJSON().split('T')[0]+"T00:00:00.000Z",DateCreatedEnd: new Date().toJSON().split('T')[0]+"T23:59:59.999Z",WashStatus: [{WashedStatusID: 127,WashedStatus: "Web Applicant"}]})
    const { data } = useFetchGet(`${REACT_APP_API_URL}/Candidates/GetBranchList?token=${cookies.get('customer').Token}`, cookies)
    const { data: endingAssignments, setData: setEndingAssignements } = useFetchPost((activeKey==='endingAssignments')&&`${REACT_APP_API_URL}/Candidates/EmployeesWithEndingAssignments?token=${cookies.get('customer').Token}`,cookies, branchIDs)
    const { data: recentApplicants, setData: setRecentApplicants } = useFetchPost((activeKey==='recentApplicants')&&`${REACT_APP_API_URL}/Candidates/EmployeeSearch?token=${cookies.get('customer').Token}`,cookies,recentApplicantsPostObj)
    const { data: needReassigned, setData: setNeedsReassigned } = useFetchPost((activeKey==='needsReassigned')&&`${REACT_APP_API_URL}/Candidates/EmployeesWithRecentEndingAssignments?token=${cookies.get('customer').Token}`,cookies,branchIDs)
    const { data: lists, setData: setLists } = useFetchGet((activeKey==='lists')&&`${REACT_APP_API_URL}/Candidates/GetServiceRepEmployees?token=${cookies.get('customer').Token}&SRIdent=${SRIdent}`,cookies)
    const { data: myEmployees, setData: setMyEmployees } = useFetchGet((activeKey==='myEmployees')&&`${REACT_APP_API_URL}/Candidates/GetCandidatesPerSR?token=${cookies.get('customer').Token}&SRIdent=${SRIdent}`,cookies)
    const { data: rawServiceReps } = useFetchPost(`${REACT_APP_API_URL}/Candidates/GetServiceReps?token=${cookies.get('customer').Token}`,cookies,branchIDs)
    const [branches, setBranches] = useState();
    const [defaultBranch, setDefaultBranch] = useState();
    const [openTooltip, setOpenTooltip] = useState(false);
    const [onlyActive, setOnlyActive] = useState(true);
    const forceUpdate = useForceUpdate()

    useEffect(()=>{
        if(rawServiceReps){
            let serviceReps = rawServiceReps.map(SR=>{return{value:SR,label:SR.RepFullName}})
            serviceReps.unshift({value:{SrIdent:0},label:'None'})
            setServiceReps(serviceReps)
        }
    // eslint-disable-next-line
    },[rawServiceReps])

    useEffect(()=>{
        if(activeKey){
            forceUpdate()
        }
    // eslint-disable-next-line
    },[activeKey])

    useEffect(()=>{
        const TransformData = (data) => { 
            let newArray = data.map(obj=>{
                if(obj.BranchName===cookies.get('customer').BranchName){
                    setDefaultBranch([{value:obj,label:obj.BranchName}])
                }
                return {value: obj, label: obj.BranchName}
            })
            setBranches(newArray)
         }
        if(!branches&&data){
            TransformData(data)
        }
    // eslint-disable-next-line
    },[data])

    useEffect(()=>{
        setCurrentPage('Candidate Management')
// eslint-disable-next-line
    },[])

    const HandleBranchChange = (e) => { 
        setEndingAssignements(null)
        setRecentApplicants(null)
        setNeedsReassigned(null)
        if(e.length>0){
            let IDs = e.map(obj=>{
                return obj.value.BranchID
            })
            let branchObjects = e.map(obj=>{
                return obj.value
            })
            let newRecent = {...recentApplicantsPostObj}
            newRecent.Branches = branchObjects;
            setBranchIDs(IDs)
            setRecentApplicantsPostObj(newRecent)
        }else{
            let newRecent = {...recentApplicantsPostObj}
            newRecent.Branches = []
            setRecentApplicantsPostObj(newRecent)
            setBranchIDs([])
        }
            forceUpdate()
     }

    const HandleSRChange = (e) => { 
        setLists(null)
        setMyEmployees(null)
        setSRIdent(e.value.SrIdent)
        forceUpdate()
     }

    return(
        <CandidateDashboard
            endingAssignments={endingAssignments}
            recentApplicants={recentApplicants}
            needReassigned={needReassigned}
            activeKey={activeKey}
            setKey={setKey}
            branches={branches}
            defaultBranch={defaultBranch}
            HandleBranchChange={HandleBranchChange}
            branchIDs={branchIDs}
            openTooltip={openTooltip}
            setOpenTooltip={setOpenTooltip}
            onlyActive={onlyActive}
            setOnlyActive={setOnlyActive}
            lists={lists}
            myEmployees={myEmployees}
            serviceReps={serviceReps}
            HandleSRChange={HandleSRChange}
            SRIdent={SRIdent}
        ></CandidateDashboard>
    )
}

export default CandidateDashboardLogic;