import React, { useContext, useState } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import GridLogic from '../Grid/GridLogic'

function SHT(){
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const {
        cookies
    } = useContext(CustomerContext)
    const [defaultColDefs] = useState([
        {headerName:'Employee ID', field:'EmployeeID', cellClass:'linked-cell'},
        {headerName:'Assignment ID',field:'AssignmentID', cellClass:'linked-cell'},
        {headerName:'SHT Compliance', field:'SHTCompliance'},
        {headerName:'SHT Date',field:'SHTDate',valueFormatter: (date)=>{return TransformDate(date)}},
        {headerName:'Days On Assignment',field:'DaysOnAssginment'},
        {headerName:'Hours on Assignment',field:'TotalHours'},
        {headerName:'Last Pay Date', field:'LastPayDate', valueFormatter: (date)=>{return TransformDate(date)}},
        {headerName:'First Name', field:'FirstName'},
        {headerName:'Last Name', field:'LastName'},
        {headerName:'Email',field:'EmployeeEmail', cellClass:'linked-cell'},
        {headerName:'Cell Phone', field:'EmployeeCellphone', cellClass:'linked-cell'},
        {headerName:'Home Phone', field:'EmployeeHomephone', cellClass:'linked-cell'},
        {headerName:'Customer',field:'CustName'},
        {headerName:'Department',field:'DeptName'},
        {headerName:'Branch',field:'BranchName'},
        {headerName:'Supervisor',field:'Supervisor'},
        {headerName:'Shift',field:'Shift'}
    ]);
    const [currentGrid] = useState('SHT');
    const [currentPage] = useState('SHT');
    const [apiUrl] = useState(`${REACT_APP_API_URL}/Compliance/GetSHTByBranchName?token=${cookies.get('customer').Token}`);
    const [customerColumnDefs] = useState([
        {headerName:'Employee ID', field:'EmployeeID', cellClass:'linked-cell'},
        {headerName:'First Name', field:'FirstName'},
        {headerName:'Last Name', field:'LastName'},
        {headerName:'SHT Date',field:'SHTDate',valueFormatter: (date)=>{return TransformDate(date)}},
        {headerName:'Days On Assignment',field:'DaysOnAssginment'},
        {headerName:'Hours on Assignment',field:'TotalHours'},
        {headerName:'SHT Compliance', field:'SHTCompliance'},
        {headerName:'Customer',field:'CustName'},
        {headerName:'Department',field:'DeptName'},
        {headerName:'Branch',field:'BranchName'},
        {headerName:'Supervisor',field:'Supervisor'},
        {headerName:'Shift',field:'Shift'}
    ]);
    const [show] = useState(false);

    function TransformDate(date){
        if(date.value){
            return date.value.split('T')[0];
        }else{
            return 'None'
        }
    }

    function HandleClick(e){
        if(e.colDef.field==='EmployeeID'){
            openInNewTab('https://beyond.ontempworks.com/employee/'+e.data.EmployeeID)
        }else if(e.colDef.field==='AssignmentID'){
            openInNewTab('https://beyond.ontempworks.com/assignment/'+e.data.AssignmentID)
        }else if(e.colDef.field==='EmployeeCellphone'){
            window.open('tel:'+e.data.EmployeeCellphone)
        }else if(e.colDef.field==='EmployeeHomephone'){
            window.open('tel:'+e.data.EmployeeHomephone)
        }else if(e.colDef.field==='EmployeeEmail'){
            window.open('mailto:'+e.data.EmployeeEmail)
        }
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    function SortData(data){
        let filteredData = [];
        data.forEach(candidate=>{
            if(candidate.SHTCompliance==='No'){
                filteredData.push(candidate)
            }
        })
        return filteredData
    }

    return(
        <GridLogic
            popupInfo={{
                show:show
            }}
            currentPage={currentPage}
            currentGrid={currentGrid}
            apiUrl={apiUrl}
            defaultColDefs={defaultColDefs}
            customerColumnDefs={customerColumnDefs}
            HandleClick={HandleClick}
            SortData={SortData}
            show={show}
        ></GridLogic>
    )
}

export default SHT;