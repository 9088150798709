import React from "react";
import {Form, Button, Col, Row, InputGroup, Container, Modal} from "react-bootstrap"
import TableBuilder from "./TableBuilder/TableBuilder";
import ContactList from "../../../../CustomComponents/TempworksEmailer/ContactList";
import EmailStatus from "../../../../CustomComponents/TempworksEmailer/EmailStatus";

export default function EmailerView({
  emailViewParams,
  emailTableParams,
  status
}){
  return(
    <div>
      <Modal dialogClassName="modal-70w" show={emailTableParams.show} onHide={emailTableParams.onHide}>
      <Modal.Header closeButton>
          <h3>Compose Email</h3>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="no-display">
              <TableBuilder
                selectedRowData={emailTableParams.selectedRowData}
                zeroToThirty={emailTableParams.zeroToThirty}
                thirtyOneToSixty={emailTableParams.thirtyOneToSixty}
                sixtyOneToNinety={emailTableParams.sixtyOneToNinety}
                overNinety={emailTableParams.overNinety}
                balanceShown={emailTableParams.balanceShown}
              />
            </div>
            <Form>
              <Row className="row-gapper">
                <Col xs="auto">
                    <p className="contact-type-text">TO:</p>
                </Col>
                <Col>
                  <ContactList
                    id="to"
                    emailViewParams={emailViewParams}
                  />
                </Col>
              </Row>
              <Row className="row-gapper">
                <Col xs="auto">
                  <p className="contact-type-text">CC:</p>
                </Col>
                <Col>
                  <ContactList
                    id="cc"
                    emailViewParams={emailViewParams}
                  />
                </Col>
              </Row>
              <Row className="row-gapper">
                  <Col xs="auto">
                      <p className="contact-type-text">BCC:</p>
                  </Col>
                  <Col>
                    <ContactList
                      id="bcc"
                      emailViewParams={emailViewParams}
                    />
                  </Col>
              </Row>                        
              <Row className="row-gapper">
              <InputGroup>
                  <InputGroup.Text>Subject:</InputGroup.Text>
                  <Form.Control type="text" value={emailViewParams.subject} onChange={(e)=>emailViewParams.setSubject(e.target.value)}/>
              </InputGroup>
              </Row>
              <Row className="row-gapper">
                  <Col>
                      <Form.Control as="textarea" value={emailViewParams.body} rows={8} placeholder="compose message here" onChange={(e)=>emailViewParams.setBody(e.target.value)}/>
                  </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Switch id="showTable" label="Display Table" onChange={()=>emailTableParams.HandleShowTable()}/>
                  {emailTableParams.tableisVisible &&
                    <TableBuilder
                      selectedRowData={emailTableParams.selectedRowData}
                      zeroToThirty={emailTableParams.zeroToThirty}
                      thirtyOneToSixty={emailTableParams.thirtyOneToSixty}
                      sixtyOneToNinety={emailTableParams.sixtyOneToNinety}
                      overNinety={emailTableParams.overNinety}
                      balanceShown={emailTableParams.balanceShown}
                    />}
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {emailViewParams.showSend&&
            <Button className="emailer-button-gapped" 
              variant="success" onClick={()=>emailViewParams.HandleSend()}>Send
            </Button>}
          {emailViewParams.status&&<EmailStatus
            status={status}
            setShowMailer={emailViewParams.setShowMailer}
            setShowSend={emailViewParams.setShowSend}
          />}
        </Modal.Footer>
      </Modal>
    </div>
  );
};