import { useState, useEffect } from "react";
import PropTypes from 'prop-types';


const useFetchPatch = (url, cookies, postObj) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState();
    const abortController = new AbortController();

    useEffect(()=>{
      if(!cookies){
        window.location.reload()
      }
  // eslint-disable-next-line
    },[])

    useEffect(() => {
      if(url){
        fetchData(0);
      }

      return ()=>{
        abortController.abort();
      } 
   // eslint-disable-next-line
    }, [url,postObj]);

    const fetchData = (count) => {
      setLoading(true);
      if(count<3){
        fetch(url,{
            signal: abortController.signal,
            method: 'PATCH',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(postObj)
        })
        .then(res => {
          setStatus(res.status)
          if(res.status===200){
            setStatus(200)
            return res.json()
          }else if(res.status===400){
            res.text().then(text=>{
              if(text==='The wait operation timed out.'){
                setTimeout(() => {
                  fetchData(count++)
                }, 1000);
              }
            })
          }else if(res.status===204){
            setStatus(204)
            setData(null)
            setLoading(false)
          }else if(res.status===401){
            cookies.remove('customer', {path:'/'})
            window.location.reload();
          }else if(res.status===503||res.status===500){
            setTimeout(() => {
              fetchData(count++)
            }, 1000);
            return [];
          }else if(!res.ok){
            res.text().then(text=>{
              if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                setTimeout(() => {
                  fetchData(count++)
                }, 1000);
              }else{
                setStatus(res.status)
                throw Error(res.statusText)
              }
            })
          }
        })
        .then(data=>{
          setLoading(false);
          setData(data);
        })
        .catch(error => {
          setLoading(false)
          setError(error);
        });
      };
    }
    return { error, loading, data, setData, status };
  };

  useFetchPatch.propTypes = {
    url: PropTypes.string.isRequired,
    cookies: PropTypes.object.isRequired,
  }

  export default useFetchPatch;