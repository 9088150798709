import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Alert, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import AllBranchesLogic from './AdvancedSearch/AllBranches/AllBranchesLogic'
import { BsDownload } from "react-icons/bs";
import AdvancedSearchLogic from './AdvancedSearch/AndvancedSearchLogic';
import NewStatusPopup from './StatusPopup/NewStatusPopup';
import CandidateInfoPopupLogic from 'Components/ATS/Candidates/CandidatesGrid/CandidateInfoPopup/CandidateInfoPopupLogic';
import PopupErrorBoundary from './PopupErrorBoundary/PopupErrorBoundary'

function Grid({ 
    onNewColumnsLoaded,
    candidateInfo,
    infoPopupIsOpen,
    setInfoPopupIsOpen,
    OpenInfoPopup,
    vaxStatusPopup,
    FilterAll,
    FilterCustomer,
    FilterToBeCalled,
    defaultColDef,
    OnGridReady,
    columnDefs,
    loading,
    setLoading,
    ChangeView,
    setGridData,
    advancedView,
    gridData,
    onBtnExport,
    currentGrid,
    popupInfo,
    defaultComplianceActiveKey,
    customSelectStyles
}){
    
    return(
        <>
            {infoPopupIsOpen&&<PopupErrorBoundary>
                    <CandidateInfoPopupLogic 
                        defaultComplianceActiveKey={defaultComplianceActiveKey} 
                        openedTab={'2'} 
                        candidateID={candidateInfo.employeeid?candidateInfo.employeeid:candidateInfo.EmployeeID} 
                        allCandidateInfo={candidateInfo}  
                        show={infoPopupIsOpen} 
                        setShow={setInfoPopupIsOpen} />
            </PopupErrorBoundary>}
            <NewStatusPopup open={vaxStatusPopup} popupInfo={popupInfo} /> 

            <div className={advancedView ? 'compliance-advanced-card' : 'header-card-auto'}>
                <div className='three-column-div'>
                    <h3>Compliance Dashboard</h3>
                    {!advancedView&&<AllBranchesLogic customSelectStyles={customSelectStyles} />}
                    <div>
                        {loading.loading ?
                            <div className="r2-c3 filter-messages grid-center">
                                <Alert className='full-width' variant="warning">{loading.message}</Alert>
                            </div>
                        :
                            <div className='r2-c3 filter-messages grid-center'>
                                <Alert className='full-width' variant="info">{gridData.length} total assignments</Alert>
                            </div>
                        }
                    </div>
                </div>
                  
                <div>
                    {advancedView ?
                        <AdvancedSearchLogic 
                            currentGrid={currentGrid} 
                            setLoading={setLoading} 
                            setData={setGridData}
                            customSelectStyles={customSelectStyles}
                        />
                        :
                        <div className="r2-c1 filter-branches grid-center">   
                    </div>
                    }
                </div>

                <div className='two-col-head'>
                    <div className='two-col-even'>
                        <ToggleButtonGroup className='full-width' type="radio" name="filters" defaultValue={1}>
                            <ToggleButton id="tbg-radio-1" onClick={FilterAll} className="module-button" value={1}>All</ToggleButton>
                            <ToggleButton id="tbg-radio-2" onClick={FilterToBeCalled} className="module-button" value={2}>To-Do</ToggleButton>
                            <ToggleButton id="tbg-radio-3" onClick={FilterCustomer} className="module-button" value={3}>Customer</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div className='instruction-row right-align'>
                        {advancedView ? 
                            <Button variant='secondary' className='label-gap' onClick={ChangeView}>Basic Search</Button>
                            :
                            <Button variant='secondary' className='label-gap' onClick={ChangeView}>Advanced Search</Button>
                        }
                        <Button variant='secondary' onClick={() => onBtnExport()}><BsDownload/>
                        </Button>
                    </div>
                </div>
                
            </div>
            <div className={advancedView ? 'card ats-card' : 'card oap-card'}>
                <div id="myGrid" className="ag-theme-alpine grid filter-grid">
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        rowData={gridData}
                        onCellClicked={OpenInfoPopup}
                        columnDefs={columnDefs}
                        onGridReady={OnGridReady}
                        suppressExcelExport={true}
                        popupParent={document.body}
                        onNewColumnsLoaded={onNewColumnsLoaded}
                    />
                </div>
            </div>
        </>
    )
}

export default Grid;