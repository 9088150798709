import { useState, useEffect } from "react";
import useFetchGet from "Components/CustomComponents/Fetch/useFetchGet";
import { GetCustomerDepartments } from "../../../CallTimecardAPI";
import CustomerSelectionView from "./CustomerSelectionView";

export default function CustomerSelection({
    cookies,
    customSelectStyles,
    isNewCustomer,
    setIsNewCustomer,
    selectedCustomer,
    setSelectedCustomer,
    useDepartments,
    setUseDepartments,
    customerID,
    setCustomerID,
    displayName,
    setDisplayName,
    customerList,
}){
    const { REACT_APP_API_URL } = process.env;
    const { data } = useFetchGet(`${REACT_APP_API_URL}/reports/getcustomerrootsfromtoken?token=${cookies.get('customer').Token}`, cookies);

    const [newOrEdit, setNewOrEdit] = useState("New");

    const [TwCustomerList, setTwCustomerList] = useState();

    const [departmentList, setDepartmentList] = useState();
    const [selectedDepartment, setSelectedDepartment] = useState();
    const [departmentDepth, setDepartmentDepth] = useState(0);

    const [customNameField, setCustomNameField] = useState(false);
    const [customToggleLabel, setCustomerToggleLabel] = useState("Tempworks");

    const [useDepartmentsName, setUseDepartmentsName] = useState("False")

    useEffect(()=>{
        if(data&&data.length){
            let customers = [];
            data.forEach(customer=>{
                let addCustomer = {value: customer.CustomerID, label: customer.CustomerName}
                customers.push(addCustomer);
            });
            setTwCustomerList(customers);
        }
    },[data]);

    useEffect(()=>{
        if(customerID){
            let allDepartments = [];
            GetCustomerDepartments(customerID, cookies).then(departments => {
                let treeChildren = departments.customerTrees;
                treeChildren.forEach(child=>{
                    let newID = child.CustomerID;
                    let deparmentName = child.DepartmentName;
                    allDepartments.push({
                        value: newID, label: deparmentName
                    });
                });
                if(departmentDepth===0){
                    setDepartmentList(allDepartments);
                    setDepartmentDepth(1);
                };
            });
        }
        // eslint-disable-next-line
    },[customerID]);

    const customerSelections = {
        customerDropdown: isNewCustomer ? TwCustomerList : customerList,
        customerSelection: selectedCustomer,
        customerIdentifier: customerID,
        displayName: displayName,

        departmentDropdown: departmentList,
        departmentSelection: selectedDepartment,
    };

    const HandleNewOrEdit = () => {
        if(isNewCustomer){
            setIsNewCustomer(false);
            setNewOrEdit("Edit");
        } else {
            setIsNewCustomer(true);
            setNewOrEdit("New");
        }
    }

    const HandleUseDepartments = () => {
        if(useDepartments){
            setUseDepartments(false);
            setUseDepartmentsName("False");
        } else {
            setUseDepartments(true);
            setUseDepartmentsName("True");
        }
        // will trigger call to add department mapping once endpoint is complete
    }

    const HandleUseCustomerName = () => {
        let nameField = customNameField ? false : true;
        let toggleLabel = nameField ? "Custom" : "Tempworks";
        setCustomerToggleLabel(toggleLabel);
        setCustomNameField(nameField);
    }

    const HandleSelectCustomer = (e) => {
        console.log(e);
        setSelectedCustomer(e);
        let customerIdentifier = e.value ? e.value : e;
        setCustomerID(customerIdentifier);
        setDisplayName(e.label);
    };

    const HandleSelectDepartment = (e) => {
        console.log(e);
        setSelectedDepartment(e);
        setCustomerID(e.value);
        setDepartmentDepth(2);
        setDisplayName(displayName + ' - ' + e.label);
    };
 
    return(
        <>
            <CustomerSelectionView
                newOrEdit={newOrEdit}
                isNewCustomer={isNewCustomer}
                HandleNewOrEdit={HandleNewOrEdit}
                
                customToggleLabel={customToggleLabel}
                customNameField={customNameField}
                useDepartmentsName={useDepartmentsName}
                HandleUseDepartments={HandleUseDepartments}

                customerSelections={customerSelections}
                customSelectStyles={customSelectStyles}

                HandleUseCustomerName={HandleUseCustomerName}
                HandleSelectCustomer={HandleSelectCustomer}
                HandleSelectDepartment={HandleSelectDepartment}
            />
        </>
    )
}