import { AgGridReact } from "ag-grid-react";
import { useState, useEffect } from 'react';
import { FormatUSD } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export default function CustomerNewOAPGrid({
    pieCustomer,
    newOapsArray
}) {
    const [gridApi, setGridApi] = useState();
    const [newOapColDefs, setNewOapColDefs] = useState();
    const [newOapRowData, setNewOapRowData] = useState();
    const [defaultColDef] = useState({
        sortable: true,
        resizable: true,
        filter: true,
    });

    useEffect(() => {
        if (pieCustomer) {
            let oapsArray = newOapsArray.filter(customer => customer.CustomerName === pieCustomer);
            setNewOapRowData(oapsArray);
        }
        // eslint-disable-next-line
    }, [pieCustomer]);

    useEffect(() => {
        if (newOapRowData) {
            setNewOapColDefs([
                {
                    field: "CustomerName",
                    valueParser: params => params.newValue
                },
                {
                    field: "OAPAmount",
                    sort: 'desc',
                    valueFormatter: (data) => FormatUSD(data)
                },
                {
                    field: "OAPNumber",
                }
            ]);
        }
        // eslint-disable-next-line
    }, [pieCustomer, newOapRowData]);

    const onFirstDataRendered = (params) => { 
        setGridApi(params.api);
        params.columnApi.autoSizeAllColumns();
    };
    
    return (
        <>
            <h6>New OAPs By Customer</h6>
            <div className="ag-theme-alpine">
                <AgGridReact
                    rowData={newOapRowData}
                    columnDefs={newOapColDefs}
                    defaultColDef={defaultColDef}
                    onFirstDataRendered={onFirstDataRendered}
                    gridApi={gridApi}
                />
            </div>
        </>
    );
}