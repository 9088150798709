export default function ColumnHint({loaderColumns}){
    let loaderPairs = [];

    for(const columnPair in loaderColumns){
        loaderPairs.push(
            <div className="tc-error-calced instruction-row">
                <p className="label-gap">{columnPair}:</p>
                <p>{loaderColumns[columnPair]}</p>
            </div>
        )
    }

    return(
        <div className="card matching-card div-vertical-padding">
            <h5>Expected Columns:</h5>
            <div className="instruction-row">
                {loaderPairs}
            </div>
        </div>
    )
}