import React from 'react'
import { Accordion, Modal, Button, Card } from 'react-bootstrap'

function Tooltip({ show, setShow }) {
  return (
    <Modal show={show} onHide={setShow}>
      <Modal.Header closeButton>
        <Modal.Title>Dashboard Tabs Explained</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Search Definitions</Accordion.Header>
            <Accordion.Body>
            <h5>Candidates</h5>
            <p>These are employees who are Candidates on a Job Order.</p>
            
            <h5>List Employees</h5>
            <p>These are any employees assigned to a list.</p>

            <h5>My Employees</h5>
            <p>These are any employees that you personally have created.</p>

            <h5>Ending Assignments</h5>
            <p>Employees who Assignments are ending in the next 14 days.</p>

            <h5>Recent Applicants</h5>
            <p>Employees who have applied in the last 7 days and have a status of WebApplicant.</p>

            <h5>Recently Ended Assignments</h5>
            <p>Employees who are off assignment, Hire Status of Eligible and Active, have a Washed Status of Familiar, and ended their last assignment no more than 30 days ago.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Onboarding Color Explanation</Accordion.Header>
            <Accordion.Body>
            <p><b>White:</b> Has not been started yet</p>
            <p><b>Yellow:</b> In progress</p>
            <p><b>Green:</b> Completed</p>
            <p><b>Grey:</b> Not Necessary</p>
              {/* <Accordion defaultActiveKey='3'>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Application</Accordion.Header>
                  <Accordion.Body>
                    <p><b>White</b></p>
                    <p><b>Yellow</b></p>
                    <p><b>Green</b></p>
                    <p><b>Grey</b></p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Onboarding</Accordion.Header>
                  <Accordion.Body>
                    <p><b>White</b></p>
                    <p><b>Yellow</b></p>
                    <p><b>Green</b></p>
                    <p><b>Grey</b></p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Everify</Accordion.Header>
                  <Accordion.Body>
                    <p><b>White</b></p>
                    <p><b>Yellow</b></p>
                    <p><b>Green</b></p>
                    <p><b>Grey</b></p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>Background</Accordion.Header>
                  <Accordion.Body>
                    <p><b>White</b></p>
                    <p><b>Yellow</b></p>
                    <p><b>Green</b></p>
                    <p><b>Grey</b></p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>Drug</Accordion.Header>
                  <Accordion.Body>
                    <p><b>White</b></p>
                    <p><b>Yellow</b></p>
                    <p><b>Green</b></p>
                    <p><b>Grey</b></p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Release Notes</Accordion.Header>
            <Accordion.Body>
              <Card className='padding release-notes'>
                <h4>Release 0.0.5</h4>
                <ul>
                  <li>Added SB1162 Report</li>
                </ul>

                <h4>Release 0.0.4</h4>
                <ul>
                  <li>Added Service Rep filter on the Candidate Dashboard</li>
                  <li>Added savable columns to the Orders page</li>
                  <li>Added a 'Search' button in the Orders Dashboard and Orders page so users can select search criteria before searching. Previously, the search would auto-search every time a value was changed.</li>
                </ul>

                <h4>Release 0.0.3</h4>
                <ul>
                  <li>Users may now change the status of Candidates being added to a Job Order when adding them via the 'Add to Job Order' menu</li>
                  <li>New tab 'My Employees' added to Candidate Dashboard, tab shows all employees that were added by the logged in Service Rep. Users may look up other Service Reps employees via the dropdown on top of the page.</li>
                  <li>Updated Candidate Status Popup:
                    <ul>
                      <li>New view for Candidate Statuses. They are now grouped by Job Order and put into an Accordion view, making them easier to navigate through.</li>
                      <li>In each Job Order Accordion option there are new 'Update status' and 'Buzz Offer' buttons</li>
                      <li>Added the Everify, Background, and Drug menus from the Compliance app. Users may now manually add Everify, Background, or Drug Test information.</li>
                    </ul>
                  </li>
                  <li>Added new Job Order Details popup, when clicking on a specific Job Order users will be able to view Candidates on a Job Order and a brief overview of the Job Order details.</li>
                  <li>Added ability to share the Job Order with other Branches or Service Reps. To do so, navigate to the Orders page, click on an Order to bring open the Job Order Details popup, click on the 'Job Order Details' tab and scroll down till you see the 'Add SR To Order' and 'Add Branch To Order' buttons.</li>  
                </ul>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={(e)=>setShow(false)} variant="secondary">Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Tooltip