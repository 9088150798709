import React, { useEffect, useState } from "react";
import OAPHomeView from "./OAPHomeView";
import { LoadOAPFiles } from "../FileHandling/LoadOAPFiles";
import { GetOapTypes } from "../FileHandling/GetOapTypes";
import useFetchGet from "Components/CustomComponents/Fetch/useFetchGet";
import { useContext } from "react";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import { GetOapBalance, GetInvoiceBalance, GetInvoiceAmount, GetAgingCustomer, GetAssignedRep } from "../ValueGetters/GetReconcileGridValues";
import useFetchPost from "Components/CustomComponents/Fetch/useFetchPost";
import GetDisplayTotals from "../ValueGetters/GetDisplayTotals";
import { GetOapData } from "../CallAPI";
import { GetMinimumOapBalance } from "../ValueGetters/GetUpdatedBalances";

export default function OAPHome() {
    const [employer, setEmployer] = useState("Employnet");
    const [totalInOaps, setTotalInOaps] = useState();
    const [totalReconciled, setTotalReconciled] = useState();
    const [totalNotReconciled, setTotalNotReconciled] = useState();

    const [openAllOaps, setOpenAllOaps] = useState(true);
    const [openReconciled, setOpenReconciled] = useState(false);
    const [openNotReconciled, setOpenNotReconciled] = useState(false);
    const [openCustomerGrid, setOpenCustomerGrid] = useState(false);
    const [openLeftoverOaps, setOpenLeftoverOaps] = useState(false);

    const [pieCustomer, setPieCustomer] = useState();
    const [newDisplayArray, setNewDisplayArray] = useState();
    
    const nowDate = new Date();
    const todayDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate());
    const formattedDate = todayDate.toISOString().split('T')[0];

    const processingWeekDate = new Date(todayDate.setDate(
        todayDate.getDate() + ((7 - todayDate.getDay() + 6) % 7 || 7)));
    
    const processingWeek = processingWeekDate.toISOString();
    //const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
    const runDate = (new Date(Date.now())).toISOString().slice(0, -1);
    const [localRunDate, setLocalRunDate] = useState();

    const [headerText, setHeaderText] = useState("OAP Start");
    const [oapData, setOapData] = useState();
    const [newOapList, setNewOapList] = useState();
    const [leftoverOapList, setLeftoverOapList] = useState();

    const [payData, setPayData] = useState();

    const [oapRowData, setOapRowData] = useState();

    const [canPost, setCanPost] = useState(false);

    const [openDashboard, setOpenDashboard] = useState(false);
    const [openInvoiceMatch, setOpenInvoiceMatch] = useState(false);
    const [openReconcile, setOpenReconcile] = useState(false);
    const [openNotes, setOpenNotes] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [openLockboxPrep, setOpenLockboxPrep] = useState(false);

    const [headerExpanded, setHeaderExpanded] = useState(true);
    const [showInfo, setShowInfo] = useState(false);

    const [matchCustomer, setMatchCustomer] = useState();
    const [matchedInvoices, setMatchedInvoices] = useState();

    const { REACT_APP_API_URL } = process.env;
    const { cookies } = useContext(CustomerContext);
    const { data: oapLoadData } = useFetchGet(`${REACT_APP_API_URL}/payroll/getoapdata?token=${cookies.get('customer').Token}&processedWeek=${processingWeek}&Employer=${employer}`)
    const { data: dataHold } = useFetchGet(`${REACT_APP_API_URL}/payroll/getinvoiceaging?token=${cookies.get('customer').Token}`, cookies);
    // eslint-disable-next-line
    const { status } = useFetchPost(canPost&&`${REACT_APP_API_URL}/payroll/postoapdata?token=${cookies.get('customer').Token}`, cookies, oapRowData)

    const OAPColumns = {
        A: "OAPNumber",
        C: "DepositDate",
        D: "ItemDate",
        E: "OAPAmount",
        H: "CustomerName",
        I: "CustomerID"
    };

    const PayFileColumns = {
        A: "PayNumber",
        D: "DepositDate",
        F: "CheckAmount",
        H: "CustomerName",
        I: "CustomerID"
    };

    var oapBalanceTracker = [];

    useEffect(() => {
        HandleLoadDashboard();
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (oapLoadData&&oapLoadData.length) {
            var leftoverOaps = [];
            var newOaps = [];

            oapLoadData.forEach(oap => {
                if (oap.IsLeftOver === true)
                    leftoverOaps.push(oap);
                else
                    newOaps.push(oap);
            });

            setNewOapList(newOaps);
            setLeftoverOapList(leftoverOaps);
            setOapRowData(oapLoadData);
            let pulledRunDate = oapLoadData[0].RunDate + 'Z';
            let localDate = new Date(pulledRunDate);
            let dateString = localDate.toString();
            let dateStringSplit = dateString.split(' ');
            let dateSplice = dateStringSplit.slice(1,5);
            let dateSpliceJoin = dateSplice.join(' ');

            console.log(dateSpliceJoin);
            console.log(dateString);
            setLocalRunDate(dateSpliceJoin);
        }
        // eslint-disable-next-line 
    }, [oapLoadData]);

    useEffect(() => {
        if (oapData && payData) {
            let newOaps = payData ? GetOapTypes(oapData, payData).newOapPayments : GetOapTypes(oapData).newOapPayments;
            setNewOapList(newOaps);
            let leftoverOaps = payData ? GetOapTypes(oapData, payData).leftoverPayments : GetOapTypes(oapData).leftoverPayments;
            setLeftoverOapList(leftoverOaps);
            
            let allOapsList = newOaps.concat(leftoverOaps);
            let allOapRows = allOapsList.map(
                oap => ({
                    ...oap,
                    InvoiceNumber: oap.InvoiceNumber&&oap.InvoiceNumber !== 0? oap.InvoiceNumber : 0,
                    PayAmount: oap.PayAmount&&oap.PayAmount !== 0 ? oap.PayAmount : 0,
                    OAPBalance: GetOapBalance(oap, oapBalanceTracker),
                    InvoiceAmount: GetInvoiceAmount(oap, dataHold),
                    InvoiceBalance: GetInvoiceBalance(oap, dataHold),
                    Reconciled: oap.Reconciled === true ? true : false,
                    InvoiceMathcing: null,
                    AgingCustomer: GetAgingCustomer(oap, dataHold),
                    ROWID: oap.OAPNumber.slice(3) + oap.InvoiceNumber,
                    ProcessingWeek: processingWeek,
                    OAPRowID: oap.OAPRowID && oap.OAPRowID !== 0 ? oap.OAPRowID : 0,
                    Employer: employer,
                    RunDate: runDate,
                    IsLeftOver: leftoverOaps.find(leftover => leftover.OAPNumber === oap.OAPNumber) !== undefined ? true : false,
                    RepName: GetAssignedRep(oap)
                }));

            allOapRows.forEach(row=> {
                row.OAPBalance = GetMinimumOapBalance(row, allOapRows);
            });

            setOapRowData(allOapRows);
            setCanPost(true);
        }
        // eslint-disable-next-line
    }, [payData]);
    
    useEffect(() => {
        if (oapRowData) {
            GetDisplayTotals(oapRowData, setTotalInOaps, setTotalNotReconciled, setTotalReconciled);
        }
        // eslint-disable-next-line
    }, [oapRowData])

    useEffect(() => {
        if (newOapList && newOapList.length) {
            let newDisplayRows = [];

            for (var i = 0; i < newOapList.length; i++) {
                let dupCheckNumber = newOapList[i].OAPNumber;
                let foundDupOap = newDisplayRows.find(oapNum => oapNum.OAPNumber === dupCheckNumber);
                if (foundDupOap)
                    continue;
                    
                newDisplayRows.push(newOapList[i]);
            };
            setNewDisplayArray(newDisplayRows);
        }
    }, [newOapList]);
    
    const HandleLoadOAPs = (e) => {
        LoadOAPFiles(e, OAPColumns, setOapData, 7, oapLoadData)
    };

    const HandleLoadPayFile = (e) => {
        LoadOAPFiles(e, PayFileColumns, setPayData, 7, oapLoadData);
    };

    const HandleLoadDashboard = () => {
        setOpenReconcile(false);
        setOpenInvoiceMatch(false);
        setOpenDashboard(true);
        setOpenNotes(false);
        setHeaderText("OAP Dashboard");
        setOpenSearch(false);
        setOpenLockboxPrep(false);
    };

    const HandleLoadInvoiceMatch = () => {
        setOpenDashboard(false);
        setOpenReconcile(false);
        setOpenInvoiceMatch(true);
        setOpenNotes(false);
        setHeaderText("Invoice Matching");
        setOpenSearch(false);
        setOpenLockboxPrep(false);
    };

    const HandleLoadReconcile = () => {
        setOpenDashboard(false);
        setOpenInvoiceMatch(false);
        setOpenReconcile(true);
        setOpenNotes(false);
        setHeaderText("Reconcile OAPs");
        setOpenSearch(false);
        setOpenLockboxPrep(false);
    };

    const HandleLoadNotes = () => {
        setOpenDashboard(false);
        setOpenInvoiceMatch(false);
        setOpenReconcile(false);
        setOpenNotes(true);
        setHeaderText("OAP Notes");
        setOpenSearch(false);
        setOpenLockboxPrep(false);
    }

    const HandleLoadSearch = () => {
        setOpenDashboard(false);
        setOpenInvoiceMatch(false);
        setOpenReconcile(false);
        setOpenNotes(false);
        setHeaderText("OAP Search");
        setOpenSearch(true);
        setOpenLockboxPrep(false);
    }

    const HandleLoadLockboxPrep = () => {
        setOpenDashboard(false);
        setOpenInvoiceMatch(false);
        setOpenReconcile(false);
        setOpenNotes(false);
        setHeaderText("Lockbox Prep");
        setOpenSearch(false);
        setOpenLockboxPrep(true);
    }

    const HandleEmployerSwitch = () => {
        if (employer === "Employnet") {
            setEmployer("EWM");
            GetOapData(processingWeek, "EWM", cookies);
        }
        else if (employer === "EWM") {
            setEmployer("Employnet");
            GetOapData(processingWeek, "Employnet", cookies);
        }
    };

    const HandleShowInfo = () => {
        setShowInfo(true);
    };

    const HandleHideInfo = () => {
        setShowInfo(false);
    };

    return (
        <>
            <OAPHomeView
                headerText={headerText}
                processingWeek={processingWeek}
                localRunDate={localRunDate}

                HandleLoadOAPs={HandleLoadOAPs}
                HandleLoadPayFile={HandleLoadPayFile}

                HandleLoadDashboard={HandleLoadDashboard}
                HandLoadInvoiceMatch={HandleLoadInvoiceMatch}
                HandleLoadReconcile={HandleLoadReconcile}
                HandleLoadNotes={HandleLoadNotes}
                HandleLoadSearch={HandleLoadSearch}
                HandleLoadLockboxPrep={HandleLoadLockboxPrep}

                HandleEmployerSwitch={HandleEmployerSwitch}
                employer={employer}

                totalInOaps={totalInOaps}
                setTotalInOaps={setTotalInOaps}
                totalReconciled={totalReconciled}
                setTotalReconciled={setTotalReconciled}
                totalNotReconciled={totalNotReconciled}
                setTotalNotReconciled={setTotalNotReconciled}
                
                oapRowData={oapRowData}
                setOapRowData={setOapRowData}

                openDashboard={openDashboard}
                newOapList={newOapList}
                leftoverOapList={leftoverOapList}
                
                openReconcile={openReconcile}
                setOpenReconcile={setOpenReconcile}
                formattedDate={formattedDate}
                dataHold={dataHold}

                openNotes={openNotes}
                openSearch={openSearch}
                openLockboxPrep={openLockboxPrep}

                openInvoiceMatch={openInvoiceMatch}
                setOpenInvoiceMatch={setOpenInvoiceMatch}
                matchCustomer={matchCustomer}
                setMatchCustomer={setMatchCustomer}
                matchedInvoices={matchedInvoices}
                setMatchedInvoices={setMatchedInvoices}
                
                openAllOaps={openAllOaps}
                setOpenAllOaps={setOpenAllOaps}
                openReconciled={openReconciled}
                setOpenReconciled={setOpenReconciled}
                openNotReconciled={openNotReconciled}
                setOpenNotReconciled={setOpenNotReconciled}
                openCustomerGrid={openCustomerGrid}
                setOpenCustomerGrid={setOpenCustomerGrid}
                openLeftoverOaps={openLeftoverOaps}
                setOpenLeftoverOaps={setOpenLeftoverOaps}

                pieCustomer={pieCustomer}
                setPieCustomer={setPieCustomer}
                newDisplayArray={newDisplayArray}

                headerExpanded={headerExpanded}
                setHeaderExpanded={setHeaderExpanded}

                showInfo={showInfo}
                HandleShowInfo={HandleShowInfo}
                HandleHideInfo={HandleHideInfo}
            />
        </>
    )
}