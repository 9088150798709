import React from 'react';
import { Accordion, Card, Col, Form, Row, Button } from 'react-bootstrap';
import AddBranchToOrderLogic from './AddBranchToOrder/AddBranchToOrderLogic';
import AddSRToOrderLogic from './AddSRToOrder/AddSRToOrderLogic'

function ReadyOnlyJobOrder({ 
    currentSubmission,
    SRs,
    Branches,
    mapData,
    openAddSR,
    setOpenAddSR,
    openAddBranch,
    setOpenAddBranch,
    orderID,
    handleSRClose,
    handleBranchClose
 }) {
    const USDFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
  return (
    <>
    {openAddSR&&<AddSRToOrderLogic show={openAddSR} setShow={handleSRClose} jobOrderID={orderID} />}
    {openAddBranch&&<AddBranchToOrderLogic show={openAddBranch} setShow={handleBranchClose} jobOrderID={orderID} />}
    <Form>
        <Form.Group>
            <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Details</Accordion.Header>
                    <Accordion.Body>
                        <Card className="padding candidate-card childrenMargin margin-bottom">
                            <Row>
                                <Col>
                                    <p><b>Request Title: </b>{currentSubmission.OrderTitle?currentSubmission.OrderTitle:'--'}</p>
                                </Col>
                                <Col>
                                    <p><b>Work Site: </b>
                                    {currentSubmission.Worksite?currentSubmission.Worksite:'--'}</p>
                                </Col>
                                <Col>
                                    <p><b>Job Title: </b>
                                    {currentSubmission.PublicJobTitle?currentSubmission.PublicJobTitle:'--'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p><b>Job Order Type: </b>
                                    {currentSubmission.OrderTypeDesc?currentSubmission.OrderTypeDesc:'--'}</p>
                                </Col>
                                <Col>
                                    <p><b>Positions Required: </b>
                                    {currentSubmission.Required?currentSubmission.Required:'--'}</p>
                                </Col>
                                <Col>
                                    <p><b>Positions Filled: </b>
                                    {currentSubmission.FilledAssignments?currentSubmission.FilledAssignments:'--'}</p>
                                </Col>   
                            </Row>
                            <Row>
                                <Col>
                                    <p><b>Start Date: </b>
                                    {currentSubmission.StartDate ? currentSubmission.StartDate.split('T')[0] : '--'}</p>
                                </Col>
                                <Col>
                                    <p><b>Expected End Date: </b>
                                    {currentSubmission.EstOrderEnd ? currentSubmission.EstOrderEnd.split('T')[0] : '--'}</p>
                                </Col>
                                <Col>
                                    <p><b>Shift: </b>
                                    {currentSubmission.shift?currentSubmission.shift:'--'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p><b>Publicly Visible: </b>{(currentSubmission.DoNotPostToWeb===false)?'Yes':<>{currentSubmission.DoNotPostToWeb&&'No'}</>}</p>
                                </Col>
                                <Col>
                                    <p><b>Time Clock: </b>{(currentSubmission.TimeClock===false)?'No':<>{currentSubmission.TimeClock&&'Yes'}</>}</p>
                                </Col>
                                <Col>
                                    <p><b>Multiplier Code: </b>{currentSubmission.MultiplierCode?currentSubmission.MultiplierCode:'--'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p><b>Pay Rate: </b>{currentSubmission.PayRate?USDFormatter.format(currentSubmission.PayRate):'--'}</p>
                                </Col>
                                <Col>
                                    <p><b>Bill Rate: </b>{currentSubmission.BillRate?USDFormatter.format(currentSubmission.BillRate):'--'}</p>
                                </Col>
                                <Col>
                                    <p><b>Overtime Plan: </b>{currentSubmission.OverTimePlan?currentSubmission.OverTimePlan:'--'}</p>
                                </Col>
                            </Row>
                            <Row className='margin-bottom'>
                                <p><b>Job Description: </b></p>
                                <div className='read-only-job-description'>
                                    <p>{currentSubmission.JobDescription?currentSubmission.JobDescription:'--'}</p>
                                </div>
                            </Row>
                            <Row>
                                <Col>
                                    <p><b>Taken By SR: </b>{currentSubmission.RepFullName?currentSubmission.RepFullName:'--'}</p>
                                </Col>
                                <Col>
                                    <p><b>Additional SRs: </b>
                                    {SRs&&mapData(SRs, 'RepFullName')}
                                    </p>
                                </Col>
                                <Col className='align-flex-end'>
                                    <Button onClick={()=>setOpenAddSR(true)}>Add SR To Order</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p><b>Branch: </b>
                                    {currentSubmission.BranchName?currentSubmission.BranchName:'--'}</p>
                                </Col>
                                <Col>
                                    <p><b>Additional Branches: </b>
                                    {Branches&&mapData(Branches, 'BranchName')}
                                    </p>
                                </Col>
                                <Col className='align-flex-end'>
                                    <Button onClick={()=>setOpenAddBranch(true)}>Add Branch To Order</Button>                                
                                </Col>
                            </Row>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>Contact Roles</Accordion.Header>
                    <Accordion.Body>
                        <h3>Coming soon!</h3>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header>Messages</Accordion.Header>
                    <Accordion.Body>
                    <h3>Coming soon!</h3>

                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header>Assignments</Accordion.Header>
                    <Accordion.Body>
                        <h3>Coming soon!</h3>

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Form.Group>
    </Form>
    </>
  )
}

export default ReadyOnlyJobOrder;