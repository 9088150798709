import { FormatDate, FormatUSD, GetTotal } from "Components/CustomComponents/ValueFormatters/GridValueFormatters";

export function GetAgingBottomRow(rowData) {
    
  const pinnedBottomRowData =
    [{
      customerName: 'Grand Total',
      BalanceAmount: `${GetTotal(rowData, 'BalanceAmount')}`,
      Age_0_30: `${GetTotal(rowData, 'Age_0_30')}`,
      Age_31_60: `${GetTotal(rowData, 'Age_31_60')}`,
      Age_61_90: `${GetTotal(rowData, 'Age_61_90')}`,
      Age_Ovr_90: `${GetTotal(rowData, 'Age_Ovr_90')}`,
    }];

    return pinnedBottomRowData;
}

export function GetAgingColDefs(rowData) {
  
  let zeroThirtyCheck = false;
  let thirtySixtyCheck = false;
  let sixtyNinetyCheck = false;
  let overNinetyCheck = false;

  if (Number(`${GetTotal(rowData, 'Age_0_30')}`) === 0) {
      zeroThirtyCheck = true
  }
  if (Number(`${GetTotal(rowData, 'Age_31_60')}`) === 0) {
      thirtySixtyCheck = true
  }
  if (Number(`${GetTotal(rowData, 'Age_61_90')}`) === 0) {
      sixtyNinetyCheck = true
  }
  if (Number(`${GetTotal(rowData, 'Age_Ovr_90')}`) === 0) {
      overNinetyCheck = true
  }
  const colDefs = [
    { field: 'CompanyName', headerName: 'Company', hide: true },
    { field: 'customerName', headerName: 'Client Name', checkboxSelection: true, headerCheckboxSelection: true },
    { field: 'CustomerID', headerName: 'Customer ID', hide: true },
    { field: 'DepartmentName', headerName: 'Department' },
    { field: 'PaymentTerms' },
    { field: 'invoiceNumber' },
    { field: 'InvoiceDate', valueFormatter: (data) => FormatDate(data) },
    { field: 'PastDueInDays', hide: true },
    { field: 'Age_0_30', hide: zeroThirtyCheck, headerName: '0-30', valueFormatter: (data) => FormatUSD(data) },
    { field: 'Age_31_60', hide: thirtySixtyCheck, headerName: '31-60', valueFormatter: (data) => FormatUSD(data) },
    { field: 'Age_61_90', hide: sixtyNinetyCheck, headerName: '61-90', valueFormatter: (data) => FormatUSD(data) },
    { field: 'Age_Ovr_90', hide: overNinetyCheck, headerName: '91 +', valueFormatter: (data) => FormatUSD(data) },
    {
      field: 'BalanceAmount',
      valueFormatter: (data) => FormatUSD(data),
      cellEditor: 'editNumberCell',
      editable: true
    },
  ];
    
    return colDefs;
}