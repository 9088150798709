import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";

export default function OAPSearchView({
    oapRowData,
    onFirstDataRendered,
    oapColDefs,
    defaultColDef,
    searchParams,
    searchLabel,
    searchInput,
    setSearchInput,
    HandleSelectSearchParam,
    HandleSearchOAPs,
    customSelectStyles
}) {
    return (
        <>
            <div className="card oap-card resized-card">
                <div className="middle-card justify-left">
                    <div className="button-div">
                        <Select
                            options={searchParams}
                            value={searchLabel}
                            placeholder="Search By"
                            onChange={(e) => HandleSelectSearchParam(e)}
                            styles={customSelectStyles}
                        />
                        <Form.Control
                            type="text"
                            className="totals-gap"
                            value={searchInput}
                            onChange={(e)=>setSearchInput(e.target.value)}
                        />
                        <Button
                            onClick={() => HandleSearchOAPs()}
                            className="aging-buttons"
                        >Search</Button>
                    </div>
                </div>
                {oapRowData&&<div className="ag-theme-alpine ag-border-dark">
                    <AgGridReact
                        rowData={oapRowData}
                        onFirstDataRendered={onFirstDataRendered}
                        columnDefs={oapColDefs}
                        defaultColDef={defaultColDef}
                    />
                </div>}
            </div>
        </>
    )
}