import { GetTimecardCustomerDefinitions } from "../CallTimecardAPI";

export function GetTimecardCustomers(customerDefinitionList, customers) {
  let customerDropdownList = []
  customerDefinitionList.forEach(definition => {
    let foundCustomer = customers.find(id => id.CustomerID === definition.customer);
    if (foundCustomer !== undefined) {
      customerDropdownList.push({
        value: foundCustomer.CustomerID, label: foundCustomer.CustomerName
      });
    }
    else {
      customerDropdownList.push({
        value: definition.customer, label: definition.customerName
      })
    }
  });
  return customerDropdownList;
}

export function GetDecodedDefList(payrollUploadNames, cookies, setCustomerDefinitionList) {
  let customerDefList = [];
  let resolve = Promise.resolve();

  payrollUploadNames.forEach(name => {
    resolve = resolve.then(() => DecodeDefinitionString(name, cookies, customerDefList));
  });
  resolve.then(resolvedDefs => {
    setCustomerDefinitionList(resolvedDefs);
  });
};

function DecodeDefinitionString(uploadNames, cookies, customerDefList) {
  let decodedDefinition = GetEncryptedDefinitionString(uploadNames, cookies).then(definition => {
    let defDecoded = window.atob(definition.Return);
    let defObj = JSON.parse(defDecoded);
    customerDefList.push(defObj);
    return customerDefList;
  });
  return decodedDefinition;
}

function GetEncryptedDefinitionString(uploadNames, cookies) {
  let timecardCustomers = GetTimecardCustomerDefinitions(uploadNames, cookies).then(definitionString => {
    return definitionString;
  });
  return timecardCustomers;
}