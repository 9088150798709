import React from 'react'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Messages({
    messages
}) {
  return (
    <Card className='status-wrapper'>
    {messages.map((messages,index)=>{
        return <Card key={index} className='padding margin-bottom'>
                    <Row>
                        <Col sm={8}>
                            <h6><b>{messages.Action}</b> | {messages.ActionDescription}</h6>
                            <p>{messages.Message}</p>
                        </Col>
                        <Col>
                            <p>{messages.MessageDate.split('T')[0]}</p>
                        </Col>
                    </Row>
                </Card>
    })}
    </Card>
  )
}

export default Messages