import EmployeeIdSelectionView from "./EmployeeIdSelectionView";

export default function EmployeeIdSelection({
    selectedIdType,
    setSelectedIdType,
    idModifier,
    setIdModifier,
    customSelectStyles
}){
    const idTypes = [
        {value: "AsnID", label: "Assignment ID"},
        {value: "AltAsnID", label: "Alternate Assignment ID"},
        {value: "EEID", label: "Employee ID"},
        {value: "AltID", label: "Alternate ID"},
        {value: "VMSID", label: "VMS ID"}
    ];

    return(
        <>
            <EmployeeIdSelectionView
                idTypes={idTypes}
                selectedIdType={selectedIdType}
                setSelectedIdType={setSelectedIdType}
                idModifier={idModifier}
                setIdModifier={setIdModifier}
                customSelectStyles={customSelectStyles}
            />
        </>
    )
}