import Select from 'react-select';
import React from "react";
import '../../Payroll.css';
import { Button, Form } from 'react-bootstrap';
import { AgGridReact } from "ag-grid-react";
import { AddSelectedInvoice, ApplyInvoices} from './AddApplyInvoices';

export default function InvoiceMatchingView({
    matchCustomers,
    customer,
    HandleCustomerSelection,
    HandleSaveInvoices,
    HandleLoadRemitFile,
    HandleDateAdd,
    HandleDateSubtract,
    customSelectStyles,

    remitRowData,
    setRemitRowData,
    defaultColDef,
    remitCols,
    onSecondaryGridReady,
    rowClassRules,

    invoicesRowData,
    invoiceCols,

    matchedRowData,
    setMatchedRowData,
    matchedCols,
    onFirstDataRendered,

    mismatchedRowData,
    setMismatchedRowData,
    mismatchedCols,
    gridApi,
    onGridReady,
    onMatchGridReady,
    HandleExportMatchProgress,

    remitSum,
    matchedSum,
    setMatchedSum,
    mismatchedSum,
    setMismatchedSum,
}) {
    return (
        <>
            <div className='matching-card'>
                <div className='spaced totals-gap'>
                    <Select
                        options={matchCustomers}
                        value={customer}
                        placeholder="Select a Customer"
                        onChange={(e) => HandleCustomerSelection(e)}
                        styles={customSelectStyles}
                    />
                    <div className="labelled-loader totals-gap">
                        <p className="label-gap">Load Remit File:</p>
                        <Form.Group>
                            <Form.Control
                                type="file"
                                size="sm"
                                onChange={(e)=>HandleLoadRemitFile(e)}
                            />
                        </Form.Group>
                    </div>
                    <div>
                    <Button
                            onClick={() => AddSelectedInvoice(
                                gridApi,
                                matchedRowData,
                                setMatchedRowData,
                                matchedSum,
                                setMatchedSum,
                            )}
                            className='aging-buttons totals-gap'
                        >Add Selected</Button>
                        <Button
                            onClick={() => ApplyInvoices(
                                remitRowData,
                                setRemitRowData,
                                invoicesRowData,
                                setMatchedRowData,
                                setMismatchedRowData,
                                setMatchedSum,
                                setMismatchedSum
                            )}
                            className='aging-buttons totals-gap'
                        >Apply Invoices
                        {/* <Badge bg="secondary">4</Badge> */}
                        </Button>
                        <Button
                            onClick={() => HandleSaveInvoices()}
                            className='aging-buttons totals-gap'
                        >Save Invoices</Button>
                        <Button
                            onClick={() => HandleExportMatchProgress()}
                            className='aging-buttons totals-gap'
                        >Export Matches</Button>
                    </div>
                </div>
            </div>
            <div className='matching-deck negative-vert-gap'>
                {matchedRowData&&<div className='card oap-card'>
                    <div className='grid-header red-background'>
                        <h6 className='header-text'>No Match Found</h6>
                        {mismatchedSum && <h6 className='header-text total-background'>Total: {mismatchedSum}</h6>}
                    </div>
                    <div className='ag-theme-alpine'>
                        <AgGridReact
                            rowData={mismatchedRowData}
                            defaultColDef={defaultColDef}
                            columnDefs={mismatchedCols}
                            onGridReady={onGridReady}
                            rowSelection={'multiple'}
                        />
                    </div>
                </div>}
                {mismatchedRowData&&<div className='card oap-card'>
                    <div className='grid-header blue-background'>
                        <h6 className='header-text'>Matched Invoices</h6>
                        {matchedSum && <h6 className='header-text total-background'>Total: {matchedSum}</h6>}
                    </div>
                    <div className='ag-theme-alpine'>
                        <AgGridReact
                            rowData={matchedRowData}
                            defaultColDef={defaultColDef}
                            columnDefs={matchedCols}
                            onGridReady={onMatchGridReady}
                        />
                    </div>
                </div>}
            </div>
            {remitRowData&&<div className='matching-deck negative-vert-gap'>
                <div className='card oap-card'>
                    <div className='grid-header'>
                        <h6 className='header-text'>Remit File Data</h6>
                        <div>
                            <Button
                                className='card-header-button small-button-shadow'
                                onClick={()=>HandleDateAdd()}
                            >Date +</Button>
                            <Button
                                className='card-header-button small-button-shadow'
                                onClick={()=>HandleDateSubtract()}
                            >Date -</Button>
                        </div>
                        {remitSum && <h6 className='header-text total-background'>Total: {remitSum}</h6>}
                    </div>
                    
                    <div className='ag-theme-alpine'>
                        <AgGridReact
                            rowData={remitRowData}
                            defaultColDef={defaultColDef}
                            columnDefs={remitCols}
                            onGridReady={onSecondaryGridReady}
                            rowClassRules={rowClassRules}
                            rowSelection={'multiple'}
                            rowMultiSelectWithClick={true}
                        />
                    </div>
                </div>
                <div className='card oap-card'>
                    <div className='grid-header'>
                        <h6 className='header-text'>Transaction Data</h6>
                    </div>
                    <div className='ag-theme-alpine'>
                        <AgGridReact
                            rowData={invoicesRowData}
                            defaultColDef={defaultColDef}
                            columnDefs={invoiceCols}
                            onFirstDataRendered={onFirstDataRendered}  
                        />
                    </div>
                </div>
            </div>}
        </>
    );
}