import { Form } from "react-bootstrap";

export default function PunchesInOut({
    punchesInOutSet
}){
    return(
        <>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">Punch Date</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesInOutSet.punchDateCol}
                    onChange={(e)=>{punchesInOutSet.setPunchDateCol(e.target.value)}}
                />
            </div>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">In Type</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesInOutSet.inTypeCol}
                    onChange={(e)=>{punchesInOutSet.setInTypeCol(e.target.value)}}
                />
            </div><div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">In Punch</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesInOutSet.inPunchCol}
                    onChange={(e)=>{punchesInOutSet.setInPunchCol(e.target.value)}}
                />
            </div>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">Out Type</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesInOutSet.outTypeCol}
                    onChange={(e)=>{punchesInOutSet.setOutTypeCol(e.target.value)}}
                />
            </div><div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">Out Punch</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesInOutSet.outPunchCol}
                    onChange={(e)=>{punchesInOutSet.setOutPunchCol(e.target.value)}}
                />
            </div>
            <div className="div-horizontal-margin flex-card-row center-align">
                <Form.Label className="label-gap">Punch Day</Form.Label>
                <Form.Control 
                    type="text"
                    className="input-text-modal-xs"
                    value={punchesInOutSet.punchDayCol}
                    onChange={(e)=>{punchesInOutSet.setPunchDayCol(e.target.value)}}
                />
            </div>
        </>
    )
}