import React, {useState, useEffect} from "react";
import EditTableView from "./EditTableView";
import Emailer from "./Emailer";
import EditNumberCell from "Components/CustomComponents/CustomHooks/useEditNumberCell";
import { GetAgingBottomRow } from "../Grid/GetAgingGridValues";

// function is start of emailer modal for emailing invoices:
// allows editing of balances of selected invoices prior to send
export default function EditTable({
  selectedRowData,
  setSelectedRowData,
  selectedCustomerID,
  showMailer,
  onHide,
  colDefs
}) {

  // sets values for collecting row data
  const [gridApi, setGridApi] = useState();
  const [rowData, setRowData] = useState();
  const [mailerStep, setMailerStep] = useState(0);
  const [frameworkComponents] = useState({
    editNumberCell: EditNumberCell
  })
  // for "Total" line at bottom of grid
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState();

  // for building totals line in email table
  const [zeroToThirty, setZeroToThirty] = useState();
  const [thirtyOneToSixty, setThirtyOneToSixty] = useState();
  const [sixtyOneToNinety, setSixtyOnToNinety] = useState();
  const [overNinety, setOverNinety] = useState()
  const [balanceShown, setBalanceShown] = useState();

  // sets rules and definitions for grid columns
  const [defaultColDef] = useState ({
    sortable: true,
    resizable: true,
    filter: true,       
  });

  const onFirstDataRendered = (params) => {
    setGridApi(params.api);
    params.columnApi.autoSizeAllColumns();
  };

  // sets the original display data in the grid from the selected rows
  useEffect(() => {
    if (selectedRowData) {
      setRowData(selectedRowData)
    };
  }, [selectedRowData]);

  // updates bottom row based on rows in grid
  useEffect(()=>{
    if (rowData && rowData.length > 0) {       
      let pinnedPlaceholder = GetAgingBottomRow(rowData);
      setPinnedBottomRowData(pinnedPlaceholder);
    }
  },[rowData]);

  
  const onFilterChanged = (e) => {
  let data = [];
  gridApi.forEachNodeAfterFilter(node => {
    data.push(node.data);
  })
  let pinnedRow = GetAgingBottomRow(data);
  setPinnedBottomRowData(pinnedRow);
  };

  const onCellValueChanged = (e) => {
    let data = [];
    gridApi.forEachNode(node => {
      data.push(node.data);
    })
    let pinnedRow = GetAgingBottomRow(data);
    setPinnedBottomRowData(pinnedRow);
    setSelectedRowData(data);
  };

  const EmailTable = (e) => {
    setZeroToThirty(pinnedBottomRowData[0].Age_0_30);
    setThirtyOneToSixty(pinnedBottomRowData[0].Age_31_60);
    setSixtyOnToNinety(pinnedBottomRowData[0].Age_61_90);
    setOverNinety(pinnedBottomRowData[0].Age_Ovr_90);
    setBalanceShown(pinnedBottomRowData[0].BalanceAmount);
    setMailerStep(1);
  };

  const editTableParams = {
    rowData,
    onFirstDataRendered,
    colDefs,
    defaultColDef,
    pinnedBottomRowData,
    onFilterChanged,
    showMailer,
    onHide,
    onCellValueChanged,
    EmailTable,
    frameworkComponents
  };

  const emailerParams = {
    selectedRowData,
    selectedCustomerID,
    showMailer,
    onHide,
    zeroToThirty,
    thirtyOneToSixty,
    sixtyOneToNinety,
    overNinety,
    balanceShown
  };

  return (
    <>
    {(mailerStep === 0) ?
      < EditTableView
        editTableParams={editTableParams}
      />
      :
      <Emailer
        emailerParams={emailerParams}
      />
    }
    </>
  );
};