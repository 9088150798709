import React from 'react'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Work({
    workHistory
}) {
  return (
    <Card className='status-wrapper'>
        {workHistory.map((workHistory,index)=>{
            return <Card key={'workHistory'+index} className='padding margin-bottom'>
                        <Row>
                            <Col>
                                <Row>
                                    <p><b>{workHistory.Employer}</b> | {workHistory.JobTitle}</p>
                                </Row>
                                <Row>
                                    <p><b>Start:</b> {workHistory.FromDate?workHistory.FromDate.split('T')[0]:'None'} | <b>End:</b> {(workHistory.ToDate?workHistory.ToDate.split('T')[0]:'None')}</p>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
        })}
    </Card>
  )
}

export default Work