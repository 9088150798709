import CustomerTreeDropdown from 'Components/CustomComponents/CustomerTreeDropdown/CustomerTreeDropdown';
import React from 'react'
import { Form, Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';

export default function SearchFilters({
  formData,
  branches,
  defaultBranch,
  serviceReps,
  orderStatuses,
  HandleChange,
  onSubmit,
  loading
}) {
  return (
    <>
    <div className='three-column-div div-vertical-margin'>
      <div>
        <Form.Label className='label-gap'>Branches:</Form.Label>
            {(branches&&defaultBranch)&&
            <Select isMulti defaultValue={defaultBranch} onChange={(e)=>HandleChange(e, 'BranchIds', 'BranchID')} options={branches.map((branch)=>{return{value:branch,label:branch.BranchName}})} />
            }
      </div>
      <div>
        <Form.Label>Service Reps:</Form.Label>
            {serviceReps&&
            <Select isMulti options={serviceReps.map(rep=>{return{value:rep,label:rep.RepFullName}})} onChange={(e)=>HandleChange(e, 'SRIdents', 'SrIdent')} />
            }
      </div>
      <div>
        <Form.Label>Order Status Group:</Form.Label>
            {orderStatuses&&
            <Select isMulti onChange={(e)=>HandleChange(e,'OrderStatus','OrderGroup')} options={orderStatuses} 
            defaultValue={[orderStatuses.find((status)=> status.label === "Open")]} 
            />
            }
      </div>
    </div>
    <div className='two-col-even center-align justify-spaced div-vertical-margin'>
      <div className='label-gap'>
        <Form.Label>Customers:</Form.Label>
        <CustomerTreeDropdown closeMenuOnSelect={false} isMulti onChange={(e)=>HandleChange(e, 'CustomerIds', 'CustomerID')} defaultBranchIDs={formData&&formData.BranchIds} />
      </div>
      <div>
        <Form.Label>Date Created Range:</Form.Label>
        <div className='three-column-div'>
          <Form.Control onChange={(e)=>HandleChange(e, 'StartDate')} type='date' defaultValue={new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toJSON().split('T')[0]} />
          <Form.Control onChange={(e)=>HandleChange(e, 'EndDate')} type='date' defaultValue={new Date().toJSON().split('T')[0]} />
          <Button variant='primary' className='aging-buttons' onClick={()=>onSubmit()}>{loading?<Spinner animation='border' size='sm' />:'Search'}</Button>
        </div>
      </div>
    </div>
    </>
  )
}