import React from "react";
import '../../Payroll.css';
import { Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import PieChart from "../Visualizations/PieChart";
import OAPReconcile from "./OAPReconcile";
import OAPNotes from "./OAPNotes";
import OAPSearch from "./OAPSearch";
import InvoiceMatchingController from "../InvoiceMatching/InvoiceMatchingController";
import LockboxPrep from "./LockboxPrep";
import AllOaps from "../Visualizations/AllOAPs";
import ReconciledOaps from "../Visualizations/ReconciledOaps";
import NotReconciledOaps from "../Visualizations/NotReconciledOaps";
import CustomerNewOAPGrid from "../Visualizations/CustomerNewOAPGrid";
import CustomerLeftoverOAPGrid from "../Visualizations/CustomerLeftoverOAPGrid";
import LeftoverOAPs from "../Visualizations/LeftoverOAPs";
import { ExportReconciled } from "../FileHandling/ExportOAPs";
import { FcCollapse, FcExpand } from "react-icons/fc"
import { BsInfoCircleFill } from "react-icons/bs";
import ModuleInfo from "../Visualizations/ModuleInfo";

export default function OAPHomeView({
    headerText,
    processingWeek,
    localRunDate,
    
    HandleLoadOAPs,
    HandleLoadPayFile,

    HandleLoadDashboard,
    HandLoadInvoiceMatch,
    HandleLoadReconcile,
    HandleLoadNotes,
    HandleLoadSearch,
    HandleLoadLockboxPrep,

    HandleEmployerSwitch,
    employer,

    totalInOaps,
    setTotalInOaps,
    totalReconciled,
    setTotalReconciled,
    totalNotReconciled,
    setTotalNotReconciled,

    oapRowData,
    setOapRowData,

    openDashboard,
    newOapList,
    leftoverOapList,

    openReconcile,
    setOpenReconcile,
    formattedDate,
    dataHold,

    openNotes,
    openSearch,
    openLockboxPrep,

    openInvoiceMatch,
    setOpenInvoiceMatch,
    matchCustomer,
    setMatchCustomer,
    matchedInvoices,
    setMatchedInvoices,

    openAllOaps,
    setOpenAllOaps,
    openReconciled,
    setOpenReconciled,
    openNotReconciled,
    setOpenNotReconciled,
    openCustomerGrid,
    setOpenCustomerGrid,
    openLeftoverOaps,
    setOpenLeftoverOaps,

    pieCustomer,
    setPieCustomer,
    newDisplayArray,
    setNewDisplayArray,

    headerExpanded,
    setHeaderExpanded,

    showInfo,
    HandleShowInfo,
    HandleHideInfo
}) {
    return (
        <>
            {showInfo && <ModuleInfo
                show={HandleShowInfo}
                onHide={HandleHideInfo}
                headerText={headerText}
            />}
            <div className={headerExpanded && "top-card"}>
                {headerExpanded &&<><div className="grouped-input margin-top">
                    <h3>{headerText}</h3>
                    <div className="spaced">
                        <p>Last File Load: {localRunDate}</p>
                        <div className="labelled-loader">
                            <p className="label-gap">Load OAP File:</p>
                            <Form.Group>
                                <Form.Control
                                    type="file"
                                    size="sm"
                                    onChange={(e) => HandleLoadOAPs(e)}
                                />
                            </Form.Group>
                        </div>
                        <div className="labelled-loader">
                            <p className="label-gap">Load Payments File:</p>
                            <Form.Group>
                                <Form.Control
                                    type="file"
                                    size="sm"
                                    onChange={(e) => HandleLoadPayFile(e)}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
                <div className="oap-nav">
                    <Button
                        className="module-button"
                        onClick={() => HandleLoadDashboard()}
                    >Dashboard</Button>

                    <Button
                        className="module-button"
                        onClick={() => HandLoadInvoiceMatch()}
                    >Match Invoices</Button>

                    <Button
                        className="module-button"
                        onClick={() => HandleLoadReconcile()}
                    >Reconcile OAPs</Button>
                    <Button
                        className="module-button"
                        onClick={() => HandleLoadNotes()}
                    >OAP Notes</Button>
                    <Button
                        className="module-button"
                        onClick={() => HandleLoadSearch()}
                        >OAP Search</Button>
                    <Button
                        className="module-button"
                        onClick={() => HandleLoadLockboxPrep()}
                        >Lockbox Prep</Button>
                    <div className="employer-toggle">
                        <Form.Check
                            type="switch"
                            label={employer}
                            onChange={()=>HandleEmployerSwitch()}
                        />
                    </div>
                    <Button
                            onClick={() => ExportReconciled(oapRowData, formattedDate)}
                            className="header-export-button"
                    >Export Payments</Button>
                   
                </div></>}
                
                {!oapRowData || !oapRowData.length ? <p>please load oap and payment files to continue</p> : null}
                {openDashboard && oapRowData && <div className="oap-totals">
                    
                    <Button
                        className="standard-button totals-gap"
                        onClick={() => {
                            setOpenAllOaps(true);
                            setOpenReconciled(false);
                            setOpenNotReconciled(false);
                            setOpenCustomerGrid(false);
                            setOpenLeftoverOaps(false);
                        }}
                    >Total: {totalInOaps}</Button>

                    <Button
                        className="totals-gap reconciled-background"
                        onClick={() => {
                            setOpenAllOaps(false);
                            setOpenReconciled(true);
                            setOpenNotReconciled(false);
                            setOpenCustomerGrid(false);
                            setOpenLeftoverOaps(false);
                        }}
                    >Reconciled: {totalReconciled}</Button>

                    <Button
                        className="standard-button totals-gap red-background"
                        onClick={() => {
                            setOpenAllOaps(false);
                            setOpenReconciled(false);
                            setOpenNotReconciled(true);
                            setOpenCustomerGrid(false);
                            setOpenLeftoverOaps(false);
                        }}
                    >Not Reconciled: {totalNotReconciled}</Button>
                    <Button
                        className="standard-button totals-gap total-background "
                        onClick={() => {
                            setOpenAllOaps(false);
                            setOpenReconciled(false);
                            setOpenNotReconciled(false);
                            setOpenCustomerGrid(false);
                            setOpenLeftoverOaps(true);
                        }}
                    >Leftover OAPs</Button>
                     {headerExpanded &&
                    <OverlayTrigger overlay={<Tooltip
                        style={{position:"fixed"}}
                    >Colapse Header</Tooltip>}>
                        <Button
                            onClick={() => setHeaderExpanded(false)}
                            className="aging-buttons white-background"
                        ><FcCollapse/></Button>
                        </OverlayTrigger>}
                    {!headerExpanded &&
                    <OverlayTrigger overlay={<Tooltip
                        style={{position:"fixed"}}
                    >Expand Header</Tooltip>}>
                        <Button
                            onClick={() => setHeaderExpanded(true)}
                            className="aging-buttons white-background"
                        ><FcExpand/></Button>
                        </OverlayTrigger>}
                    
                    <OverlayTrigger overlay={<Tooltip
                        style={{position:"fixed"}}
                    >Page Info</Tooltip>}>
                        <Button
                            onClick={() => HandleShowInfo()}
                            className="aging-buttons tab-color"
                        ><BsInfoCircleFill/></Button>
                    </OverlayTrigger>
                </div>}
                {!openDashboard && <div className="oap-totals">
                    <p className="totals-gap blue-text">Total OAPs: {totalInOaps}</p>
                    <p className="totals-gap">Total Reconciled: {totalReconciled}</p>
                    <p className="totals-gap red-text">Total Not Reconciled: {totalNotReconciled}</p>
                    {headerExpanded &&
                    <OverlayTrigger overlay={<Tooltip
                        style={{position:"fixed"}}
                    >Collapse Header</Tooltip>}>
                        <Button
                            onClick={() => setHeaderExpanded(false)}
                            className="aging-buttons white-background"
                        ><FcCollapse/></Button>
                        </OverlayTrigger>}
                    {!headerExpanded &&
                    <OverlayTrigger overlay={<Tooltip
                        style={{position:"fixed"}}
                    >Expand Header</Tooltip>}>
                        <Button
                            onClick={() => setHeaderExpanded(true)}
                            className="aging-buttons white-background"
                        ><FcExpand/></Button>
                        </OverlayTrigger>}
                    
                    <OverlayTrigger overlay={<Tooltip
                        style={{position:"fixed"}}
                    >Info</Tooltip>}>
                        <Button
                            onClick={() => HandleShowInfo()}
                            className="aging-buttons tab-color"
                        ><BsInfoCircleFill/></Button>
                    </OverlayTrigger>
                </div>}
            </div>
            
            {openDashboard && newOapList && newOapList.length &&<div className="cardHolder">
                <PieChart
                    newOapList={newOapList}
                    leftoverOapList={leftoverOapList}
                    setPieCustomer={setPieCustomer}
                    setNewDisplayArray={setNewDisplayArray}
                    setOpenAllOaps={setOpenAllOaps}
                    setOpenReconciled={setOpenReconciled}
                    setOpenNotReconciled={setOpenNotReconciled}
                    setOpenCustomerGrid={setOpenCustomerGrid}
                />
                <div className="card oap-card">
                    {openAllOaps && <AllOaps
                        oapRowData={oapRowData}
                        leftoverOapList={leftoverOapList}
                    />}
                    {openReconciled && <ReconciledOaps
                        oapRowData={oapRowData}
                        leftoverOapList={leftoverOapList}
                    />}
                    {openNotReconciled && <NotReconciledOaps
                        oapRowData={oapRowData}
                        leftoverOapList={leftoverOapList}
                    />}
                    {openLeftoverOaps && <LeftoverOAPs leftoverOapList={leftoverOapList}/>}
                    {openCustomerGrid &&
                        <>
                            <CustomerNewOAPGrid 
                                pieCustomer={pieCustomer}
                                newOapsArray={newDisplayArray}
                            />
                            <CustomerLeftoverOAPGrid 
                                pieCustomer={pieCustomer}
                                leftoverOapsArray={leftoverOapList}
                            />
                        </>
                    }
                </div>
            </div>}
            <div>
                {openReconcile && <OAPReconcile
                    setOpenReconcile={setOpenReconcile}
                    formattedDate={formattedDate}
                    dataHold={dataHold}
                    processingWeek={processingWeek}
                    employer={employer}

                    oapRowData={oapRowData}
                    setOapRowData={setOapRowData}
                    
                    setOpenInvoiceMatch={setOpenInvoiceMatch}
                    matchCustomer={matchCustomer}
                    setMatchCustomer={setMatchCustomer}
                    matchedInvoices={matchedInvoices}

                    setTotalInOaps={setTotalInOaps}
                    setTotalReconciled={setTotalReconciled}
                    setTotalNotReconciled={setTotalNotReconciled}

                    headerExpanded={headerExpanded}
                />}
            </div>
            <div>
                {openInvoiceMatch && <InvoiceMatchingController
                    matchCustomer={matchCustomer}
                    setMatchCustomer={setMatchCustomer}
                    setMatchedInvoices={setMatchedInvoices}
                />}
            </div>
            <div>
                {openNotes && <OAPNotes />}
            </div>
            <div>
                {openSearch && <OAPSearch />}
            </div>
            <div>
                {openLockboxPrep && <LockboxPrep
                    headerExpanded={headerExpanded}
                    dataHold={dataHold}
                />}
            </div>
        </>
    )
}