import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet'
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';
import JobOrderStatus from './JobOrderStatus'

function JobOrderStatusLogic({ show, setShow, jobOrder }) {
    const {REACT_APP_API_URL} = process.env;
    const {cookies} = useContext(CustomerContext);
    const [defaultStatus, setDefaultStatus] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [canSubmit, setCanSubmit] = useState(false);
    const { data: statuses } = useFetchGet(`${REACT_APP_API_URL}/Candidates/GetOrderStatusList?token=${cookies.get('customer').Token}&unfiltered=${true}`,cookies)
    const { data: response } = useFetchPost((canSubmit&&`${REACT_APP_API_URL}/Candidates/UpdateOrderStatus?token=${cookies.get('customer').Token}&orderid=${jobOrder.OrderID}&statusid=${selectedStatus.Orderstatusident}`),cookies,canSubmit)

    useEffect(()=>{
        if(statuses){
            let status = statuses.find(status=>status.OrderStatus===jobOrder.OrderStatus)
            setDefaultStatus({value:status, label:status.OrderStatus})
            setSelectedStatus(status)
        }
    // eslint-disable-next-line
    },[statuses])
    
    useEffect(()=>{
        if(response){
            setCanSubmit(false);
        }
    // eslint-disable-next-line
    },[response])
  return (
    <JobOrderStatus
        setCanSubmit={setCanSubmit}
        setSelectedStatus={setSelectedStatus}
        jobOrder={jobOrder}
        statuses={statuses}
        show={show}
        setShow={setShow}
        defaultStatus={defaultStatus}
        response={response}
        canSubmit={canSubmit}
    ></JobOrderStatus>
  )
}

export default JobOrderStatusLogic