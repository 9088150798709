import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import AddCustomListPopup from './AddCustomListPopup'
import useFetch from 'Components/CustomComponents/Fetch/useFetch';

function AddCustomListPopupLogic({ 
  show, 
  setShow,
  defaultPostObj 
}){
    const [nameError, setNameError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [canPost, setCanPost] = useState(false);
    const [selectedBranches, setSelectedBranches] = useState(defaultPostObj?.Branches?defaultPostObj.Branches.map(branch=>{return{value:branch, label:branch.BranchName}}):null);
    const { cookies, selectedBranch } = useContext(CustomerContext)
    const [postObj, setPostObj] = useState(defaultPostObj?defaultPostObj:{
        LabelCategoryID:1,
        LabelName: '',
        LabelDescription:'',
        BranchID: selectedBranch?selectedBranch.BranchID:0,
        BranchName: selectedBranch?selectedBranch.BranchName:''
    });
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const { status } = useFetch((canPost&&`${REACT_APP_API_URL}/Candidates/${defaultPostObj?'Patch':'Post'}CustomLabel?token=${cookies.get('customer').Token}`),defaultPostObj?'PATCH':'POST',postObj)
    const { data: labelBranches } = useFetch((defaultPostObj&&`${REACT_APP_API_URL}/Candidates/GetCustomLabelBranches?Token=${cookies.get('customer').Token}&LabelID=${defaultPostObj.LabelID}`),'GET')

    useEffect(()=>{
      if(labelBranches){
        setSelectedBranches(labelBranches.map(branch=>{return{value:branch,label:branch.BranchName}}))
      }
    },[labelBranches])
    useEffect(()=>{
      if(status){
        setCanPost(false);
      }
    },[status])

    useEffect(()=>{
      if(selectedBranch){
        postObj.BranchID = selectedBranch.BranchID
        postObj.BranchName = selectedBranch.BranchName
        setPostObj(postObj)
      }
      // eslint-disable-next-line
    },[selectedBranch])

    function HandleChange(e, fieldName){
        let newPostObj = {...postObj}
        newPostObj[fieldName] = e.target.value;
        setPostObj(newPostObj);
        if(fieldName==='LabelName')setNameError(false);
        else if(fieldName==='LabelDescription')setDescriptionError(false);
    }

    const HandleSubmit = (e) => { 
      e.preventDefault();
      if(labelBranches&&labelBranches.length>0){
        labelBranches.forEach(branch => {
          if(postObj.Branch){
            postObj.Branch.push({
              BranchID: branch.BranchID,
              Action: 'Del'
            })
          }else{
            postObj.Branch = [{
              BranchID: branch.BranchID,
              Action: 'Del'
            }]
          }
        });
      }
        selectedBranches.forEach(branch=>{
        let matchingBranch = null;
        if(postObj.Branch){
          matchingBranch = postObj.Branch.find(obj=> {
            return obj.BranchID===branch.value.BranchID
          })
        }else{
          postObj.Branch = []
        }
        if(matchingBranch){
          matchingBranch.Action = "Add"
        }else{
          postObj.Branch.push({
            BranchID: branch.value.BranchID,
            Action: 'Add'
          })
        }
      })
        if(ValidatePostObj()){
          setCanPost(true);
        }
     }

    const ValidatePostObj = () =>{
      let status = true;
      if(!postObj.LabelName||postObj.LabelName===''){
        setNameError('Please add a List Name')
        status = false
      }if(!postObj.LabelDescription||postObj.LabelDescription===''){
        setDescriptionError('Please add a List Description')
        status = false
      }
      return status;
    }

    function HandleClose(){
        setNameError(false)
        setDescriptionError(false)
        setPostObj({
            LabelCategoryID:1,
            LabelName:'',
            LabelDescription:''
        });
        setShow(false);
    }

    return(
        <AddCustomListPopup
          defaultPostObj={defaultPostObj}
          postObj={postObj}
          show={show}
          HandleClose={HandleClose}
          HandleSubmit={HandleSubmit}
          nameError={nameError}
          descriptionError={descriptionError}
          HandleChange={HandleChange}
          status={status}
          canPost={canPost}
          selectedBranches={selectedBranches}
          setSelectedBranches={setSelectedBranches}
        ></AddCustomListPopup>
    )
}

export default AddCustomListPopupLogic;