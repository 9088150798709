import React from 'react'
import { useEffect, useState } from 'react';
import {Card, Col, Row, Accordion, Button } from 'react-bootstrap'
import BuzzOffer from './BuzzOffer/BuzzOffer';
import CandidateStatusPopupLogic from './CandidateStatusPopup/CandidateStatusPopupLogic';
import JobOrderDetailsPopupLogic from 'Components/ATS/Orders/JobOrderModals/JobOrderDetailsPopup/JobOrderDetailsPopupLogic';
import PopupErrorBoundary from 'Components/Compliance/Grid/PopupErrorBoundary/PopupErrorBoundary';

function Statuses({status, candidateInfo}) {
    const [formattedStatuses, setFormattedStatuses] = useState()
    const [changeStatusIsOpen, setChangeStatusIsOpen] = useState(false);
    const [openBuzzPopup, setOpenBuzzPopup] = useState(false);
    const [openJobOrderDetails, setOpenJobOrderDetails] = useState(false);
    const [buzzOfferParams, setBuzzOfferParams] = useState();
    const [jobOrderID, setJobOrderID] = useState()

    useEffect(()=>{
        let statusesObjArray = []
        status.forEach((status,index,array)=>{
            if(index===0||status.OrderID!==array[index-1].OrderID){
                statusesObjArray.push({...status, Statuses:[status]})
            }else{
                statusesObjArray[statusesObjArray.length-1].Statuses.push(status)
            }
        })
        setFormattedStatuses(statusesObjArray)
    },[status])

    const ChangeCandidateStatus = (jobOrderID) => {
        setBuzzOfferParams(null)
        setJobOrderID(jobOrderID)
        setChangeStatusIsOpen(true)
      }

    const OpenJobOrderDetails = (orderID) => { 
        setJobOrderID(orderID);
        setOpenJobOrderDetails(true);
     }

  return (
    <div className='status-wrapper'>
    {changeStatusIsOpen&&<PopupErrorBoundary><CandidateStatusPopupLogic show={changeStatusIsOpen} setShow={setChangeStatusIsOpen} candidateInfo={candidateInfo} defaultRequestID={jobOrderID} /></PopupErrorBoundary>}
    {openBuzzPopup&&<PopupErrorBoundary><BuzzOffer show={openBuzzPopup} setShow={setOpenBuzzPopup} EmployeeID={candidateInfo.EmployeeID} OrderID={buzzOfferParams.OrderID} /></PopupErrorBoundary>}
    {openJobOrderDetails&&<PopupErrorBoundary><JobOrderDetailsPopupLogic defaultActiveKey='jobDetails' orderID={jobOrderID} show={openJobOrderDetails} setShow={setOpenJobOrderDetails} /></PopupErrorBoundary>}
    {(formattedStatuses&&formattedStatuses.length>0)?
    <Accordion defaultActiveKey={candidateInfo.OrderID?candidateInfo.OrderID:formattedStatuses[0].OrderID}>
        {formattedStatuses.map((status,index)=>{
            return (
                <Accordion.Item eventKey={status.OrderID} key={'status-order-accordion-item '+index}>
                    <Accordion.Header>
                        <section className='center-items full-width'><p className='linked-cell' onClick={()=>OpenJobOrderDetails(status.OrderID)}>{status.OrderID}</p><p>&nbsp;| {status.CustomerName} | {status.DepartmentName}</p></section>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className='margin-bottom'>
                            <Col><Button className='full-width' onClick={(e)=>ChangeCandidateStatus(status.OrderID)}>Update Status</Button></Col>
                        </Row>
                    
                        {status.Statuses.map((status,index)=>{
                            return (
                            <Card key={'statusBody'+index+status.Status+status.Date+status.Message} className='padding margin-bottom'>
                                <Row>
                                    <Col sm={8}>
                                        <p>{status.Status} - {status.Description}</p>
                                    </Col>
                                    <Col>
                                        <p>{status.Date.split('T')[0]}</p>
                                    </Col>
                                    {status.Message&&<p>{status.Message}</p>}
                                </Row>
                            </Card>)
                        })}
                    </Accordion.Body>
                </Accordion.Item>
                )
        })}
    </Accordion>
    :
    <h3>No status history</h3>}
    </div>
  )
}

export default Statuses