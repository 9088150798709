import React from 'react'
import { Button, Modal, Form, Alert, Spinner } from 'react-bootstrap'
import Select from 'react-select';

function UpdateDrugTestPopup({ date, submitted, drugTestOptions, error, HandleChange, setIsApproved, show, setShow, loading, HandleSubmit }) {
  return (
    <Modal show={show} onHide={setShow}>
      {submitted?
      <>
      <Modal.Header closeButton>
          <h3>Enter Drug Test</h3>
      </Modal.Header>
      <Modal.Body className='text-center'>
          <h5>Request has been submitted succesfully!</h5>
          <p><b>It takes up to 15 minutes for the information that was just submitted to sync.<br/> Please do not put this request in again until 15 minutes have passed and you have refreshed the grid.<br/> Thank you.</b></p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={(e)=>setShow(false)} variant='secondary'>Close</Button>
      </Modal.Footer>
      </>
      :
      <>   
      <Modal.Header closeButton>
          <h3>Enter Drug Test</h3>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Label>Positive/Negative<span className='red-text'>*</span></Form.Label>
          <Select options={[{value:false,label:'Drug Test - Positive'},{value:true,label:'Drug Test - Negative'}]} onChange={(e)=>setIsApproved(e.value)} />

          <Form.Label>Date of test<span className='red-text'>*</span> </Form.Label>
          <Form.Control onChange={(e)=>HandleChange(e,'Date')} defaultValue={date} type='date' />

          <Form.Label>Drug Test type<span className='red-text'>*</span></Form.Label>
          {drugTestOptions?
          <Select onChange={(e)=>HandleChange(e,'DrugTest')} options={drugTestOptions.map(test=>{return{value:test,label:test.DrugTestType}})} placeholder="Search..." />
          :
          <Spinner animation='border' />
          }

          <Form.Label>Notes</Form.Label>
          <Form.Control onChange={(e)=>HandleChange(e,'Notes')} />

          {error&&
          <Alert variant='warning'>{error}</Alert>
          }
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='success' disabled={loading} onClick={HandleSubmit}>{loading?'Submitting...':'Submit'}</Button>
        <Button onClick={(e)=>setShow(false)} variant='secondary'>Close</Button>
      </Modal.Footer>
      </>
      }
    </Modal>
  )
}

export default UpdateDrugTestPopup