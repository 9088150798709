import { GetDocs } from "../../CallApi";

export default function HandleReportSearch(e, setFormListData, selectedDocType, cookies, setLoading){
  e.preventDefault();
  setLoading(true);

  const formData = new FormData(e.currentTarget);
  const searchString = formData.get("searchString")?.toString();
  const docType = selectedDocType ? selectedDocType.value.toString() : null;
  const rangeStart = formData.get("rangeStart")?.toString();
  const rangeEnd = formData.get("rangeEnd")?.toString();

  const paramsFilled = (searchString || docType) && rangeStart && rangeEnd

  if (paramsFilled){
    GetDocs(cookies, searchString, rangeStart, rangeEnd, docType).then(docs => {
      setFormListData(docs);
      setLoading(false);
    });
  } else {
    setLoading(false);
    window.alert(`File Name or Doc Type must be included with date range for search. Please fill out form correctly and try again.`, "Okay");
  }
}