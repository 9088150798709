import React, { useContext, useState } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import GridLogic from '../Grid/GridLogic'

function Vendor(){
    const {
        cookies
    } = useContext(CustomerContext)
    const [defaultColDefs] = useState([
        {headerName:'Vendor',field:'VendorName'},
        {headerName:'Attestation',field:'AttestCompliance',cellStyle:ChangeBackground},
        {headerName:'Packet',field:'PacketCompliance',cellStyle:ChangeBackground},
        {headerName:'First Name',field:'FirstName'},
        {headerName:'Last Name',field:'LastName'},
        {headerName:'Employee ID', field:'employeeid',cellClass:'linked-cell'},
        {headerName:'Rep', field:'RepName'},
        {headerName:'Customer',field:'CustName'},
        {headerName:'Department',field:'DeptName'},
        {headerName:'Assignment ID',field:'assignmentID', cellClass:'linked-cell'},
        {headerName:'Days on Assignment', field:'DaysOnAssginment'},
        {headerName:'Hours on Assignment',field:'TotalHours'},
        {headerName:'Last Pay Date',field:'LastPayDate',valueFormatter: (date)=>{return TransformDate(date)}},
        {headerName:'Order Type',field:'OrderType'},
    ]);
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const [currentGrid] = useState('Vendor');
    const [currentPage] = useState('Vendor');
    const [apiUrl] = useState(`${REACT_APP_API_URL}/Compliance/GetDocStatusVendors?token=${cookies.get('customer').Token}`);
    const [customerColumnDefs] = useState([
        {headerName:'Employee ID', field:'employeeid',cellClass:'linked-cell'},
        {headerName:'Vendor',field:'VendorName'},
        {headerName:'First Name',field:'FirstName'},
        {headerName:'Last Name',field:'LastName'},
        {headerName:'Attestation',field:'AttestCompliance',cellStyle:ChangeBackground},
        {headerName:'Packet',field:'PacketCompliance',cellStyle:ChangeBackground},
        {headerName:'Background',field:'BackGroundCompliance'},
        {headerName:'Days on Assignment', field:'DaysOnAssginment'},
        {headerName:'Hours on Assignment',field:'TotalHours'},
        {headerName:'Customer',field:'CustName'},
        {headerName:'Department',field:'DeptName'},
        {headerName:'Branch',field:'BranchName'},
    ]);
    const [show] = useState(false);

    function ChangeBackground(params){
        if(params.value){
            params.value=params.value.trim()
            if(params.value==='NA'){
                return {backgroundColor: 'rgb(202 202 202)'}
            }else if(params.value==='No'||params.value==='Waiting on Candidate'||params.value==='Started but incomplete'){
                return {backgroundColor: '#f5ff91'}
            // }else if(params.value==='Needs Review'||params.value==='Waiting on SR to finish'){
            //     return {backgroundColor: 'rgb(252 191 118)'}
            }else if(params.value === 'Yes'||params.value === 'Complete - Authorized'||params.value==='Completed - Accepted'||params.value==='Pass'||params.value==='Pass'){
                return {backgroundColor: '#9dff9e'};
            }else if(params.value==='Completed - Rejected'||params.value==='Complete - Not Authorized'||params.value==='Fail'){
                return {backgroundColor: '#ed2938'}
            }else{
                return {backgroundColor: '#f5ff91'}
            }
        }
    }

    function TransformDate(date){
        if(date.value){
            return date.value.split('T')[0];
        }else{
            return 'None'
        }
    }

    function HandleClick(e){
        if(e.colDef.field==='employeeid'){
            openInNewTab('https://beyond.ontempworks.com/employee/'+e.data.employeeid)
        }else if(e.colDef.field==='assignmentID'){
            openInNewTab('https://beyond.ontempworks.com/assignment/'+e.data.assignmentID)

        }
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    function SortData(data){
        let filteredData = [];
        data.forEach(candidate=>{
            if(candidate.AttestCompliance==='No'||candidate.PacketCompliance==='No'){
                filteredData.push(candidate)
            }
        })
        return filteredData
    }

    return(
        <GridLogic
            popupInfo={{
                show:show,
            }}
            currentPage={currentPage}
            currentGrid={currentGrid}
            apiUrl={apiUrl}
            defaultColDefs={defaultColDefs}
            customerColumnDefs={customerColumnDefs}
            HandleClick={HandleClick}
            SortData={SortData}
        ></GridLogic>
    )
}

export default Vendor;