import React, {useState, useContext} from "react";
import PasteInvoicesView from "./PasteInvoicesView";
import { PatchOapData } from "./CallAPI";
import CustomerContext from "Components/CustomComponents/Context/CustomerContext";
import { GetAgingCustomer, GetInvoiceAmount, GetInvoiceBalance, GetRowID } from "./ValueGetters/GetReconcileGridValues";
import { GetMinimumOapBalance } from "./ValueGetters/GetUpdatedBalances";

export default function PasteInvoices({
    reconcileRows,
    setReconcileRows,
    selectedOap,
    selectedOapRowData,
    showPasteInvoices,
    setShowPasteInvoices,
    dataHold,
    processingWeek,
    employer,
    gridApi,
    selectedRep,
    HandleRefreshData
}) {
    const { cookies } = useContext(CustomerContext);
    const [invoicePasteBody, setInvoicePasteBody] = useState();
    const handleClose = () => setShowPasteInvoices(false);
    const onInvoicePush = (e) => {
        // get values of paste into correct format
        const myInvoicesArray = invoicePasteBody.split('\n');
        const invoicesArrayOfObj = [];
        myInvoicesArray.forEach((pair) => {
                        
            const myInvoicesObj = {
                ...selectedOapRowData[0],
                OAPRowID: 0
            };
            let pairArray = pair.split('\t');
            if (pairArray.length > 1) {
                myInvoicesObj["InvoiceNumber"] = pairArray[0];
                myInvoicesObj["PayAmount"] = (pairArray[1]).replace(/[$,]/g, '').trim();
                invoicesArrayOfObj.push(myInvoicesObj);
            }
        });

        AddNewInvoiceItems(invoicesArrayOfObj, dataHold).then(()=>{
            HandleRefreshData()
            handleClose()
        })
    };

    function AddNewInvoiceItems(invoicesArrayOfObj, dataHold) {
        
        return new Promise((resolve)=>{
            resolve(
                invoicesArrayOfObj.forEach((invoice) => {

                    invoice.OAPBalance = GetMinimumOapBalance(invoice, [...reconcileRows,...invoicesArrayOfObj]); // in line only oap amount - pay amount
                    invoice.InvoiceBalance = GetInvoiceBalance(invoice, dataHold);
                    invoice.InvoiceAmount = GetInvoiceAmount(invoice, dataHold);
                    invoice.AgingCustomer = GetAgingCustomer(invoice, dataHold);
                    invoice.ROWID = GetRowID(invoice);

                    PatchOapData(invoice, cookies);
                })
            )
        })
    }

    
    return (
        <PasteInvoicesView
            selectedOap={selectedOap}
            invoicePasteBody={invoicePasteBody}
            setInovoicePasteBody={setInvoicePasteBody}
            showPasteInvoices={showPasteInvoices}
            handleClose={handleClose}
            onInvoicePush={onInvoicePush}
        />
    )
}