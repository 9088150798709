import React, { useContext, useState } from 'react'
import MessageCodeForm from './MessageCodeForm'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';

function MessageCodeFormLogic({
    employeeID
}) {
    const { cookies } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const [apiUrl] = useState(`${REACT_APP_API_URL}/Candidates/GetMessageCodes?Token=${cookies.get('customer').Token}`)
    const [placeholderPostObj] = useState({})
    const [selectedMessageCode,setSelectedMessageCode] = useState();
    const [note, setNote] = useState();
    const [canPost, setCanPost] = useState(false);
    const { status, error, loading } = useFetchPost((canPost&&`${REACT_APP_API_URL}/Candidates/PostEmployeeMessage?Token=${cookies.get('customer').Token}&EmployeeID=${employeeID}&ActionID=${selectedMessageCode.value.ActionID}&Message=${note}`),cookies,placeholderPostObj) 

    const mapFunction = (code) => { 
        return {value: code, label: code.Action}
     }

    const handleSubmit = (e) => {
        e.preventDefault()
        setCanPost(true)
    }
  return (
    <MessageCodeForm
        selectedMessageCode={selectedMessageCode}
        setSelectedMessageCode={setSelectedMessageCode}
        note={note}
        setNote={setNote}
        handleSubmit={handleSubmit}
        apiUrl={apiUrl}
        mapFunction={mapFunction}
        status={status}
        error={error}
        loading={loading}
    ></MessageCodeForm>
  )
}

export default MessageCodeFormLogic