import React, { useEffect, useContext, useState } from 'react'
import SearchFilters from './SearchFilters';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import useFetchPost from 'Components/CustomComponents/Fetch/useFetchPost';
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';

function SearchFiltersLogic({ postObj, setPostObj, setOrderStatus, loading }) {
    const { cookies } = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;
    const [defaultBranch, setDefaultBranch] = useState();
    const [serviceRepPostObj] = useState([]);
    const [formData, setFormData] = useState(postObj)
    const [orderStatuses, setOrderStatuses] = useState();
    const { data: unformattedOrderStatuses } = useFetchGet((postObj&&`${REACT_APP_API_URL}/Candidates/GetOrderStatusList?token=${cookies.get('customer').Token}`),cookies)
    const { data: branches } = useFetchGet((postObj&&`${REACT_APP_API_URL}/Candidates/GetBranchList?token=${cookies.get('customer').Token}`),cookies)
    const { data: serviceReps } = useFetchPost((postObj&&`${REACT_APP_API_URL}/Candidates/GetServiceReps?token=${cookies.get('customer').Token}`),cookies,serviceRepPostObj)
    
    useEffect(()=>{
        if(unformattedOrderStatuses){
            setOrderStatuses(unformattedOrderStatuses.map((status)=>{return{value:status,label:status.OrderGroup}}))
        }
    },[unformattedOrderStatuses])
    useEffect(()=>{
        if(branches){
            branches.forEach(branch=>{
                if(branch.BranchName===cookies.get('customer').BranchName){
                    setDefaultBranch([{value:branch,label:branch.BranchName}])
                }
            })
        }
    // eslint-disable-next-line
    },[branches])

    useEffect(()=>{
        let defaultPostObj = {
            BranchIds: [cookies.get('customer').BranchID],
            SRIdents: [],
            CustomerIds: [],
            ParentCustomerIds: null,
            OrderStatus:["Open"],
            ShowAllDepartments: true,
            StartDate:new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toJSON().split('T')[0],
            EndDate: new Date().toJSON().split('T')[0],
        }
        setPostObj(defaultPostObj)
        setFormData(defaultPostObj)
    // eslint-disable-next-line
    },[])

    const HandleChange = (e, objName, valueName) => { 
        if(objName==='StartDate'||objName==='EndDate'){
            let newObj = {...formData};
            newObj[objName] = e.target.value;
            setFormData(newObj)
        }else if(e.length>0){
            // Must update state state to cause re-render and call useFetchPost, unless I make a new object react won't re-render
            let newObj = {...formData};
            newObj[objName] = e.map(obj=>obj.value[valueName])
            setFormData(newObj)
        }else if(objName==='ParentCustomerIds'){
            let newObj = {...formData}
            newObj.ParentCustomerIds = e.value.CustomerID
            setFormData(newObj)
        }else{
            let newObj = {...formData};
            newObj[objName] = []
            setFormData(newObj)
        }
     }
    const onSubmit = () => {
        setPostObj(formData)
    }

  return (
    <SearchFilters
        formData={formData}
        setDefaultBranch={setDefaultBranch}
        HandleChange={HandleChange}
        orderStatuses={orderStatuses}
        branches={branches}
        serviceReps={serviceReps}
        defaultBranch={defaultBranch}
        setOrderStatus={setOrderStatus}
        onSubmit={onSubmit}
        loading={loading}
    ></SearchFilters>
  )
}

export default SearchFiltersLogic