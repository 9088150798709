
export default function GetWeeklyTotals(
  processDefinitions,
  timecardRows
) {
  let customerIdentifier = !isNaN(processDefinitions.customerID) ? processDefinitions.customerID : null;
  let weeklyTotals = [];
  switch (processDefinitions.processType) {
    case 'Totals: By Column':
      let coloumnHoursTotals = [];

      timecardRows.forEach(row => {
        let employeeFullName = row.EmployeeName ? row.EmployeeName : row.LastName + ', ' + row.FirstName;

        let mappedColTotals = {
          EmployeeName: employeeFullName,
          IdNumber: row.IdNumber,
          IdType: processDefinitions.idType,
          CustomerID: customerIdentifier,
          OTLaw: processDefinitions.otLaw,
          Reg: parseFloat(row.Reg),
          OT: parseFloat(row.OT),
          DT: row.DT !== undefined ? parseFloat(row.DT) : 0,
          MP: row.MP !== undefined ? parseFloat(row.MP) : 0,
        }

        coloumnHoursTotals.push(mappedColTotals);
      });

      weeklyTotals = coloumnHoursTotals;
      break;

    case 'Totals: By Type':
      let typeHoursTotals = [];
      let regValues = [
        "AG5 REG", 
        "RG", 
        "RT", 
        "Regular", 
        "REG", 
        "Regular.", 
        "Approved", //Approved value is placeholder for Tapfin, who have no hours type column
        "Regular Time", 
        "ST"
      ]; 
      let otValues = [
        "AG5 OT",
        "OT",
        "Daily Overtime",
        "Weekly Overtime",
        "Overtime.",
        "OT1",
        "O.T STRAIGHT",
        "1.43 OT - Acara",
        "Employnet OT - 1.4373",
        "Employnet OT - 1.4508",
        "Overtime"
      ];
      let dtValues = ["AG5 DT", "AG5 DBLT", "DT", "Double Time", "DBLE STRAIGHT", "OT2"];
      let mpValues = ["MEAL PREMIUM", "Meal Premium", "Meal Penalty 1", "Meal Penalty 2", "MBP"];
      //let departmentMap = processDefinitions.departments;

      timecardRows.forEach(row => {
        let employeeFullName = row.EmployeeName ? row.EmployeeName : row.LastName + ', ' + row.FirstName;

        let rawReg = regValues.includes(row.HoursType) ? parseFloat(row.TotalHours) : 0;
        let rawOT = otValues.includes(row.HoursType) ? parseFloat(row.TotalHours) : 0;
        if (rawOT !== 0 && employeeFullName === "Calvin, Karenthiea") {
          console.log(employeeFullName, ': ', rawOT);
          console.log(row);
        }

        let mappedTotals = {
          EmployeeName: employeeFullName,
          IdNumber: row.IdNumber,
          IdType: processDefinitions.idType,
          CustomerID: customerIdentifier,
          OTLaw: processDefinitions.otLaw,
          Reg: regValues.includes(row.HoursType) ? rawReg : 0,
          OT: otValues.includes(row.HoursType) ? rawOT : 0,
          DT: dtValues.includes(row.HoursType) ? parseFloat(row.TotalHours) : 0,
          MP: mpValues.includes(row.HoursType) ? parseFloat(row.TotalHours) : 0,
        }
        typeHoursTotals.push(mappedTotals);
      });
      weeklyTotals = typeHoursTotals;
      break;

    case 'Punches: By Column':
      // sum dailies and separate into reg, ot, dt, mp
      break;

    case 'Punches: In/Out':
      // sum dailies and separate into reg, ot, dt, mp
      break;

    case 'Punches: By Type':
      // sum dailies and separate into reg, ot, dt, mp
      break;

    default: console.info('Correct type not found');
  }
  return weeklyTotals;
}