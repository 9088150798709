import React, { useState, useContext } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import CustomerInfoPopupView from './CustomerInfoPopupView';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';

export default function CustomerInfoPopup({
  selectedCustomerID,
  show,
  onHide
}) {
  const { cookies } = useContext(CustomerContext)
  const { REACT_APP_API_URL } = process.env;
  const { data: contacts } = useFetchGet(`${REACT_APP_API_URL}/OrderRequest/getcustomercontacts?customerID=${selectedCustomerID}&token=${cookies.get('customer').Token}`, cookies);

  const [contactsDefaultColDef] = useState ({
    sortable: true,
    resizable: true,
    filter: false        
  });

  const [contactsColDefs] = useState([
    {field: 'Title'},
    {field: 'FullName', headerName: "Name"},
    {field: 'Phone',
      cellRenderer: (Phone) => {
        return < span > <a href="tel: " {...Phone.value}>{Phone.value}</a></span >
    }},
    {field: 'Email',
      cellRenderer: (Email) => {
        return <span><a href="mailto: "{...Email.value}>{Email.value}</a></span>
      }},
  ]);

  const contactsOnGridReady = (params) => { 
    params.api.sizeColumnsToFit()
  };

  return (
    <CustomerInfoPopupView
      contacts={contacts&&contacts.data}
      contactsDefaultColDef={contactsDefaultColDef}
      contactsColDefs={contactsColDefs}
      contactsOnGridReady={contactsOnGridReady}
      show={show}
      onHide={onHide}
    />
  );
};