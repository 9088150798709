import React, { useState, useContext } from 'react'
import CustomerSearch from './CustomerSearch'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext'
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet'

function CustomerSearchLogic({ setRowData, setAdvancedSearch }){
    const { cookies } = useContext(CustomerContext)
    const [searching, setSearching] = useState(false);
    const [alert, setAlert] = useState(null);
    const [view, setView] = useState(true);
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const { data:branches } = useFetchGet(`${REACT_APP_API_URL}/Candidates/GetBranchList?token=${cookies.get('customer').Token}`,cookies)
    const [hireStatusOptions] = useState([
        {value:{HireStatusID:'WP',HireStatus:'Web Pending'},label:'Web Pending'},
        {value:{HireStatusID:'A',HireStatus:'Eligible and Active'},label:'Eligible and Active'},
        {value:{HireStatusID:'RH',HireStatus:'Eligible for Rehire'},label:'Eligible for Rehire'},
        {value:{HireStatusID:'I',HireStatus:'Inactive'},label:'Inactive'},
        {value:{HireStatusID:'FP',HireStatus:'File Pending'},label:'File Pending'},
        {value:{HireStatusID:'FT',HireStatus:'Placed Full Time'},label:'Placed Full Time'},
        {value:{HireStatusID:'NH',HireStatus:'Not Hired'},label:'Not Hired'},
        {value:{HireStatusID:'DNA',HireStatus:'Do Not Assign'},label:'Do Not Assign'},
        {value:{HireStatusID:'Sub',HireStatus:'SubContractor'},label:'SubContractor'},

    ])
    const [washedStatusOptions] = useState([
        {value:{WashedStatusID:0,WashedStatus:'Familiar'},label:'Familiar'},
        {value:{WashedStatusID:127,WashedStatus:'Web Applicant'},label:'Web Applicant'},
        {value:{WashedStatusID:128,WashedStatus:'Unfamiliar'},label:'Unfamiliar'},
        {value:{WashedStatusID:129,WashedStatus:'Rejected Applicant'},label:'Rejected Applicant'},
        {value:{WashedStatusID:130,WashedStatus:'Unfinished Web Applicant'},label:'Unfinished Web Applicant'},
        {value:{WashedStatusID:131,WashedStatus:'ReApply Applicant'},label:'ReApply Applicant'},
        {value:{WashedStatusID:132,WashedStatus:'Resume Parsed'},label:'Resume Parsed'},
    ])
    const [postObj, setPostObj] = useState({
        Branches: [{BranchName: cookies.get('customer').BranchName,BranchID: cookies.get('customer').BranchID}],
        IsAssigned: false,
        IsCandidate: true,
        HireStatus: [],
        WashStatus: [],
        Zip: null,
        DateCreatedStart: new Date(new Date().setDate(new Date().getDate() - 7)).toJSON(),
        DateCreatedEnd: new Date().toJSON(),
    });
    const [defaultValues, setDefaultValues] = useState({
        Branches: [{value:{BranchName: cookies.get('customer').BranchName,BranchID: cookies.get('customer').BranchID},label:cookies.get('customer').BranchName}],
        IsAssigned: {value:true, label:'Yes'},
        IsCandidate: {value:false, label:'No'},
        HireStatus: [],
        WashStatus: [], 
        Zip: null,
        DateCreatedStart: new Date(new Date().setDate(new Date().getDate() - 7)).toJSON(),
        DateCreatedEnd: new Date().toJSON(),
    });

    const SetPreset = (preset) => { 
        setView(false);
        if(preset==='Recent Applicants'){
            let recentApplicantsSearch = {
                Branches: [
                    {BranchName:cookies.get('customer').BranchName, BranchID:cookies.get('customer').BranchID}
                ],
                IsAssigned: false, 
                HireStatus: [],
                Zip: null,
                DateCreatedStart: new Date(new Date().setDate(new Date().getDate() - 7)).toJSON(),
                DateCreatedEnd: new Date().toJSON(),
                WashStatus: [
                    {WashedStatusID: 127,WashedStatus: "Web Applicant"}
                ]
            }
            setPostObj(recentApplicantsSearch)
            let newDefaultValues = {
                Branches: [
                    {value:{BranchName:cookies.get('customer').BranchName, BranchID:cookies.get('customer').BranchID},label:cookies.get('customer').BranchName}
                ],
                IsAssigned: {value:false,label:'No'},
                HireStatus: [],
                Zip: null,
                DateCreatedStart: recentApplicantsSearch.DateCreatedStart,
                DateCreatedEnd: recentApplicantsSearch.DateCreatedEnd,
                WashStatus: recentApplicantsSearch.WashStatus.map(status=>{
                    let matchingPair = washedStatusOptions.find(option => option.value.WashedStatusID===status.WashedStatusID);
                    if(matchingPair){
                        return matchingPair;
                    }
                    return matchingPair;
                })
            }
            setDefaultValues(newDefaultValues)
            setTimeout(()=>{
                return setView(true)
            },[1])
        }
     }

    function OnSubmit(){
        setSearching(true)
        setAlert(null)
        postObj.DateCreatedStart = (postObj.DateCreatedStart.split('T')[0]+'T00:00:00.000Z')
        postObj.DateCreatedEnd = (postObj.DateCreatedEnd.split('T')[0]+'T23:59:59.999Z')
        if(postObj.Zip) postObj.Zip.Distance = parseInt(postObj.Zip.Distance)
        if(postObj.PayDateCount==="") postObj.PayDateCount = 0;

        fetch(`${REACT_APP_API_URL}/Candidates/EmployeeSearch?token=${cookies.get('customer').Token}`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(postObj)
        })
        .then(res=>{
            if(res.status===200){
                return res.json()
              }else if(res.status===401){
                cookies.remove('customer', {path:'/'})
                window.location.reload();
              }else if(res.status===404){
                setAlert({variant:'danger',message:'Too many search results, try again'})
              }else if(res.status===503||res.status===500){
                setTimeout(() => {
                  OnSubmit()
                }, 1000);
                return [];
              }else if(!res.ok){
                res.text().then(text=>{
                  if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                    setTimeout(() => {
                      OnSubmit()
                    }, 1000);
                  }else{
                    throw Error(res.statusText)
                  }
                })
              }
        })
        .then(data=>{
            setAlert({variant:'primary',message:`${data.length} results`})
            setRowData(data)
            setSearching(false)
        })
        .catch(err=>{
            setSearching(false)
            console.error(err)
        })
    }

    function HandleChange(e, fieldName){
        if(fieldName==='IsAssigned'){
            postObj.IsAssigned = e.value;
            setPostObj(postObj)
        }else if(fieldName==='IsCandidate'){
            postObj.IsCandidate = e.value;
            setPostObj(postObj)
        }else if(e.target.type==='date'){
            postObj[fieldName] = e.target.value;
            setPostObj(postObj)
        }else if(fieldName==='Zip'){
            if(postObj.Zip){
                postObj.Zip.Distance = e.target.value
                setPostObj(postObj)
            }else{
                postObj.Zip = {Distance: e.target.value}
                setPostObj(postObj)
            }
        }else if(fieldName==='PayDateCount'){
            if(e.target.value===''){
                postObj.PayDateCount = '';
                setPostObj(postObj)
            }else{
                postObj.PayDateCount = Number(e.target.value);
                setPostObj(postObj)
            }
        }else{
            postObj[fieldName] = e.target.value;
            setPostObj(postObj)
        }
    }

    function HandleOptionChange(e, fieldName){
        if(fieldName==='Zip'){
            if(postObj.Zip){
                postObj.Zip.Zip = e.value.Zip
                setPostObj(postObj)
            }else{
                postObj.Zip = {Zip: e.value.Zip, Distance: 0}
                setPostObj(postObj)
            }
        }else{
            postObj[fieldName] = e.map(obj=>{
                return obj.value
            })
            setPostObj(postObj)
        }
    }

    return(
        <>
        {view&&
            <CustomerSearch
                OnSubmit={OnSubmit}
                HandleChange={HandleChange}
                HandleOptionChange={HandleOptionChange}
                searching={searching}
                alert={alert}
                washedStatusOptions={washedStatusOptions}
                hireStatusOptions={hireStatusOptions}
                SetPreset={SetPreset}
                defaultValues={defaultValues}
                setAdvancedSearch={setAdvancedSearch}
                branches={branches}
            ></CustomerSearch>
        }
        </>
    )
}

export default CustomerSearchLogic;