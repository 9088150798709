import { PushCustomerDefinition } from "../CallTimecardAPI";

export default function PostCustomerDefinition(customerIdentifiers, columnMapDefinition, cookies){
    const customerID = customerIdentifiers.timecardCustomerID;
    const customerDefinitions = {
        customer: customerID,
        customerName: customerIdentifiers.customerName,
        useDepartments: customerIdentifiers.useDepartments,
        otLaw: customerIdentifiers.otLaw,
        type: customerIdentifiers.processType,
        identifier: customerIdentifiers.idType,
        idModifier: customerIdentifiers.timecardIdModifier,
        weDate: customerIdentifiers.weekEndingDay,
        firstDataRow: customerIdentifiers.startReadRow,
        columns: columnMapDefinition,
    };

    const definitionString = window.btoa(JSON.stringify(customerDefinitions));
    PushCustomerDefinition(customerID, definitionString, cookies);
}