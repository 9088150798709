import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import AddCustomListPopupLogic from './AddCustomListPopup/AddCustomListPopupLogic'
import { AgGridReact } from 'ag-grid-react'
import './CustomLists.css'
import { BsArrowClockwise } from 'react-icons/bs'
import CandidatesGridModalLogic from '../CandidatesGrid/CandidatesGridModal/CandidatesGridModalLogic'
import AllBranchesLogic from 'Components/Compliance/Grid/AdvancedSearch/AllBranches/AllBranchesLogic'
import ConfirmDeletePopup from './ConfirmDeletePopup/ConfirmDeletePopup'

export default function CustomLists({
  HandleCellClicked,
  columnDefs,
  defaultColDef,
  filteredLists,
  onGridReady,
  CreateNewCustomList,
  addCustomListPopupIsOpen,
  handleCloseCustomListPopup,
  gettingData,
  setCustomLists,
  openCandidatesList,
  setOpenCandidatesList,
  candidates,
  editListDefaultPostObj,
  showConfirmDeletePopup,
  handleCloseConfirmDeletePopup,
  selectedRow
}) {
  return (
    <div>
      {showConfirmDeletePopup&&<ConfirmDeletePopup show={showConfirmDeletePopup} setShow={handleCloseConfirmDeletePopup} labelID={selectedRow.data.LabelID} />}
      {addCustomListPopupIsOpen && <AddCustomListPopupLogic setShow={handleCloseCustomListPopup} show={addCustomListPopupIsOpen} defaultPostObj={editListDefaultPostObj} />}
      {(openCandidatesList&&candidates)&&<CandidatesGridModalLogic show={openCandidatesList} setShow={setOpenCandidatesList} rowData={(candidates&&candidates.candidates)} otherColumnDefs={[]} currentPageName={(candidates&&candidates.label.LabelName)} customColumnName='Custom Lists' />}
      <div className='vertical-card-fit three-column-div'>
        <h3>Candidates Lists</h3>
        <AllBranchesLogic/>
        <div className='right-align'>
          <Button className='standard-button label-gap' onClick={(e)=>CreateNewCustomList(e)}>Add List</Button>
          <Button className='standard-button' disabled={gettingData} onClick={()=>setCustomLists(null)}>{gettingData? <Spinner size='sm' animation="border" /> : <BsArrowClockwise />}</Button>
        </div>
      </div>
      <div className='card oap-card tall-card'>
        {filteredLists?
        <div id="myGrid" className="ag-theme-alpine grid">
          <AgGridReact
            defaultColDef={defaultColDef}
            rowData={filteredLists}
            onGridReady={onGridReady}
            onCellClicked={HandleCellClicked}
            columnDefs={columnDefs}
          ></AgGridReact>
        </div>
        :
        <Spinner animation='border' />
        }
      </div>

    </div>
  )
}
