import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from 'Components/CustomComponents/Context/CustomerContext';
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import CandidateStatusForm from './CandidateStatusForm';

function CandidateStatusFormLogic({
  CandidateID,
  OrderRequestID,
  defaultStatusID,
  defaultRequestID,
}) {
    const [showCompanyRejectOptions, setShowCompanyRejectOptions] = useState(false);
    const [showCandidateRejectOptions, setShowCandidateRejectOptions] = useState(false);
    const [defaultOption] = useState({value:'',label:defaultRequestID});
    const [attemptingToSubmit, setAtteptingToSubmit] = useState(false);
    const [error, setError] = useState();
    const [status, setStatus] = useState();
    const [formError, setFormError] = useState(null)
    const [candidateStatusOptions, setCandidateStatusOptions] = useState();
    const [selectedCandidateStatus, setSelectedCandidateStatus] = useState();
    const [candidateRejectOptions] = useState([
        {
            "CandidateStatusID": 26,
            "Status": 250,
            "Description": "Candidate Not Interested - Distance"
        },
        {
            "CandidateStatusID": 25,
            "Status": 260,
            "Description": "Candidate Not Interested - Environment"
        },
        {
            "CandidateStatusID": 27,
            "Status": 270,
            "Description": "Candidate Not Interested - Pay Rate"
        },
        {
            "CandidateStatusID": 28,
            "Status": 280,
            "Description": "Candidate Not Interested - Schedule"
        },
        {
            "CandidateStatusID": 41,
            "Status": 283,
            "Description": "Candidate Not Interested - Hiring Requirements"
        },
        {
            "CandidateStatusID": 44,
            "Status": 285,
            "Description": "Candidate Not Interested - Vax Requirement"
        },
        {
            "CandidateStatusID": 8,
            "Status": 290,
            "Description": "Candidate Not Interested - Other"
        },
        {
            "CandidateStatusID": 32,
            "Status": 540,
            "Description": "Candidate Rejected Offer - Other"
        },
        {
            "CandidateStatusID": 33,
            "Status": 530,
            "Description": "Candidate Rejected Offer - Pay Rate"
        },
        {
            "CandidateStatusID": 34,
            "Status": 520,
            "Description": "Candidate Rejected Offer - Accepted Another Job"
        },
    ])
    const [companyRejectReasons] = useState([
        {
            "CandidateStatusID": 22,
            "Status": 220,
            "Description": "Company Rejected - Hiring Requirements"
        },
        {
            "CandidateStatusID": 40,
            "Status": 225,
            "Description": "Company Rejected - Vax Requirements"
        },
        {
            "CandidateStatusID": 23,
            "Status": 230,
            "Description": "Company Rejected - Not Good Fit"
        },
        {
            "CandidateStatusID": 24,
            "Status": 240,
            "Description": "Company Rejected - No Show"
        },
        {
            "CandidateStatusID": 7,
            "Status": 430,
            "Description": "Customer Rejected Candidate"
        },
        {
            "CandidateStatusID": 35,
            "Status": 330,
            "Description": "Customer Declined to Interview Candidate"
        },
    ])
    const {
        cookies
    } = useContext(CustomerContext)
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const [postObj, setPostObj] = useState({
        statuscodeid: 0,
        notes: '',
        candidateid: CandidateID,
        orderid: OrderRequestID
    });
    const {data: unformattedCandidateStatusOptions} = useFetchGet(`${REACT_APP_API_URL}/Candidates/GetCandidateStatusList?token=${cookies.get('customer').Token}`,cookies)

    useEffect(()=>{
        if(unformattedCandidateStatusOptions){
            setCandidateStatusOptions(unformattedCandidateStatusOptions.map(status=>{
                if(defaultStatusID===status.Status){
                    setSelectedCandidateStatus({
                        value:status,
                        label:(status.Status+' | '+status.Description),
                        isDisabled:status.isDisabled
                    })
                }
                    return{
                        value:status,
                        label:(status.Status+' | '+status.Description),
                        isDisabled:status.isDisabled
                    }
                })
            );
        }
    // eslint-disable-next-line
    },[unformattedCandidateStatusOptions])

    const handleChange = (e, fieldName) => { 
        if(e.label==='Company Rejected'){
            setShowCandidateRejectOptions(false)
            setShowCompanyRejectOptions(true);
        }else if(e.label==='Candidate Not Interested'){
            setShowCompanyRejectOptions(false)
            setShowCandidateRejectOptions(true);
        }else if(fieldName==='notes'){
            postObj.notes = e;
            setPostObj(postObj)
        }else if(fieldName==='statuscodeid'){
            setSelectedCandidateStatus(e)
            setShowCandidateRejectOptions(false)
            setShowCompanyRejectOptions(false)
            setFormError(null)
            postObj[fieldName] = e.value.CandidateStatusID;
            setPostObj(postObj)
        }
    }
    
    const handleSubmit = (e) => { 
        setAtteptingToSubmit(true);
        if(!(postObj.statuscodeid>=0)){
            setFormError('Please select a Status Code')
            setAtteptingToSubmit(false);
        }else{
            PostCandidateStatus();
        }
     }

    const PostCandidateStatus = () => {
        fetch(`${REACT_APP_API_URL}/Candidates/PostTWCandidateStatus?token=${cookies.get('customer').Token}&candidatestatusid=${postObj.statuscodeid}&notes=${postObj.notes}&candidateid=${postObj.candidateid}&orderrequestid=${postObj.orderid}`,{
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(postObj)
            })
            .then(res=>{
                setStatus(res.status)
                if(res.status===200){
                    return true;
                }else if(res.status===401){
                    cookies.remove('customer', {path:'/'})
                    window.location.reload();
                  }else if(res.status===503||res.status===500){
                    setTimeout(() => {
                      PostCandidateStatus()
                    }, 1000);
                    return [];
                  }else if(!res.ok){
                    res.text().then(text=>{
                      if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                        setTimeout(() => {
                          PostCandidateStatus()
                        }, 1000);
                      }else{
                        throw Error(res.statusText)
                      }
                    })
                  }
            })
            .then(data=>{
                setAtteptingToSubmit(false)
            })
            .catch(error=>{
                console.error(error)
                setError(error.message)
            })
    }
  return (
    <CandidateStatusForm
      candidateStatusOptions={candidateStatusOptions}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      attemptingToSubmit={attemptingToSubmit}
      error={error}
      formError={formError}
      defaultOption={defaultOption}
      showCompanyRejectOptions={showCompanyRejectOptions}
      showCandidateRejectOptions={showCandidateRejectOptions}
      candidateRejectOptions={candidateRejectOptions}
      companyRejectReasons={companyRejectReasons}
      selectedCandidateStatus={selectedCandidateStatus}
      status={status}
    ></CandidateStatusForm>
  )
}

export default CandidateStatusFormLogic