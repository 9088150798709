import React, { useEffect, useContext, useState } from 'react'
import CustomerContext from "Components/CustomComponents/Context/CustomerContext"
import useFetchGet from 'Components/CustomComponents/Fetch/useFetchGet';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';

function AllBranchesMulti({
    selectedBranches,
    setSelectedBranches
}){
    const {REACT_APP_API_URL} = process.env;
    const [allBranches, setAllBranches] = useState();
    const{ cookies } = useContext(CustomerContext)
    const { data: unformattedBranches } = useFetchGet(`${REACT_APP_API_URL}/Compliance/GetBranches?Token=${cookies.get('customer').Token}`, cookies)

    useEffect(()=>{
        if(unformattedBranches){
            TransformBranches(unformattedBranches)
        }
        // eslint-disable-next-line
    },[unformattedBranches])

    function TransformBranches(branches){
        const transformedBranches = branches.map((branch)=>{
            if(branch.BranchName===cookies.get('customer').BranchName&&!selectedBranches){
                setSelectedBranches([{value: branch, label: branch.BranchName}])
                return {value: branch, label: branch.BranchName}
            }else{
                return {value: branch, label: branch.BranchName}
            }
        })
        transformedBranches.unshift({value:{BranchName:null},label:'All'})
        setAllBranches(transformedBranches)
    }

    return(
        <div className="all-branches-wrapper">
            {allBranches ?
                <Row>
                    <Select options={allBranches} value={selectedBranches} onChange={(e)=>setSelectedBranches(e)} isMulti={true} />
                </Row>
            : null}
        </div>
    )
}

export default AllBranchesMulti;